import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryCoachList: createAction('queryCoachList'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordCoachList = createAction('recordCoachList')

const effects = {
  *queryCoachList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_COACH_LIST, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      yield put(changeLoading({ loading: false }))
      yield put(recordCoachList(response.responseData.result))
    } else {
      yield put(changeLoading({ loading: false }))
      message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryCoachList, effects.queryCoachList);
  }
];

// reducer
export const coachStore = handleActions(
  {
    recordCoachList(state, { payload: result }) {
      return {
        ...state,
        pagesize: result.pagesize,
        coachList: result.list,
        totalpage: result.totalpage,
        page: result.page,
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    coachList: [],
    pagesize: 0,
    totalpage: 0,
    page: 1
  }
)

// 保存用户信息
export async function launchHandleUser(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.HANDLE_USER, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 保存教练
export async function launchSaveCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.SAVE_COACH, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 邀请
export async function launchInviteCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.INVITE_COACH, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 解除
export async function launchReleaseCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.RELEASE_COACH, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}


export async function launchGetCoachList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_COACH_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchGetCoachTimeTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_COACH_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchGetCoachTimeTableIgnore(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_COACH_TIMETABLE_IGNORE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

