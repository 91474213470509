import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar, Card } from 'antd';
import { connect } from 'react-redux';
import { EditOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { actions as venueActions, launchEnterVenue, launchGetVenueDetail } from '../../../redux/venue-model';
import { actions as loginActions } from '../../../redux/loginModel';
import { actions as descActions } from '../../../redux/desc-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import { VenueListStyles } from '../../../style/page/venue/venue-list-styles';

import SaveVenueController from './venue-save-controller';
import { PageStyles, TableStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN_LISTS.VENUE_LIST;

const confirm = Modal.confirm;
const { Meta } = Card;

class VenueListController extends BaseController {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        };

        this.requestEnterVenue = this.requestEnterVenue.bind(this);

        this.handleAddVenue = this.handleAddVenue.bind(this);
        this.handleEnterVenue = this.handleEnterVenue.bind(this);

        this.renderVenueList = this.renderVenueList.bind(this);
    }

    componentDidMount() {
        this.props.queryVenueList({ });
    }

    handleAddVenue() {
        this.props.dispatch(descActions.recordDescid(undefined));
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_VENUE.path}`);
    }

    handleEnterVenue(venue) {

        let that = this;
        confirm({
            title: '确认切换场馆？',
            content: '',
            okText: '确定',
            cancelText: '取消',
            onOk() {
            that.requestEnterVenue(venue);
            },
            onCancel() {
            
            },
        });

        
    }

    requestEnterVenue(venue) {
        this.changeLoadingState(true);
        launchEnterVenue(
            {venueid: venue.venueid},
            response => {
                this.changeLoadingState(false);
                message.success(response.msg);

                this.props.recordSelectVenue(response.result);
            },
            msg => {
                this.changeLoadingState(false);
                message.error(msg);
            }
        );
    }

    renderVenueList() {
        console.log(this.props.enterVenue);
        let that = this;

        return (
            
            <div style={VenueListStyles.venueList}>
                {
                    this.props.venueList && this.props.venueList.map((item, index) => {
                        
                        return (
                            <Card
                                key={`venue_${index}`}
                                hoverable
                                style={ this.props.enterVenue.venueid === item.venueid ? VenueListStyles.venueItem_selected : VenueListStyles.venueItem}
                                cover={<div style={VenueListStyles.venueLogoBox}> <img style={VenueListStyles.venueLogo} src={item.venueposter} /> </div>}
                                actions={[
                                    // <Link to={{
                                    //     pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_VENUE.path}`,
                                    //     state: { editVenue: item }
                                    // }}>
                                    <div onClick={() => {
                                        // that.props.dispatch(descActions.recordDescid(item.venuedesc));
                                        that.changeLoadingState(true);
                                        launchGetVenueDetail(
                                            { venueid: item.venueid },
                                            res => {
                                                console.log(res);
                                                
                                                that.props.history.push({
                                                    pathname: `${that.props.match.url}/${NAMESPACE.children.SAVE_VENUE.path}`,
                                                    state: { 
                                                        editVenue: item, 
                                                        desc: res.desc 
                                                    }
                                                });
                                                that.changeLoadingState(false);
                                            },
                                            fail => {
                                                message.error(fail);
                                                that.changeLoadingState(false);
                                            }
                                        );
                                        
                                    }}>
                                        <EditOutlined />
                                        <span style={{ marginLeft: 10 }}>编辑</span>
                                    </div>
                                    ,
                                    <div onClick={() => { this.handleEnterVenue(item) }}>
                                        <ImportOutlined />
                                        <span style={{ marginLeft: 10 }}>进入</span>
                                    </div>
                                ]}
                            >
                                <Meta
                                    title={item.venuename}
                                    description={item.venuebrief ? item.venuebrief : '-'}
                                    style={{ height: 100, overflow: 'hidden', paddingTop: 5, paddingBottom: 5 }}
                                />
                            </Card>
                        )
                    })
                }

                {/* <Link to={{
                    pathname: `${this.props.match.url}/${PathConstant.SAVE_VENUE.path}`
                }}> */}
                    <Card
                        onClick={this.handleAddVenue}
                        hoverable
                        bodyStyle={VenueListStyles.plusBodyStyle}
                        style={{...VenueListStyles.venueItem, ...VenueListStyles.plusBodyStyle}}
                    >
                        <PlusOutlined style={{fontSize: 50, marginBottom: 25}} />
                        <Meta
                            title='添加一个场馆'
                        />
                    </Card>
                {/* </Link> */}
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                
            </div>
          )
        });
    }

    render() {
        return (
            <Spin spinning={this.props.loading || this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={{...TableStyles.tableBox, marginTop: 60}}>
                    {
                        this.renderVenueList()
                    }
                </div>

            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
    const venueStore = store['venueStore'];
    const utilStore = store['utilStore'];
    const userInfo = store['loginStore'];
    return {
        enterVenue: userInfo.enterVenue,
        loading: venueStore.loading,
        venueList: venueStore.venueList,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        queryVenueList: params => {
            dispatch(venueActions.queryVenueList(params));
        },
        changeLoading: params => {
            dispatch(venueActions.changeLoading(params));
        },
        recordSelectVenue: params => {
            dispatch(loginActions.recordSelectedVenue(params));
        },
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(VenueListController);
