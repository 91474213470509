import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryUserList: createAction('queryUserList'),
  queryCoachUserList: createAction('queryCoachUserList'),
  queryUnVipUserList: createAction('queryUnVipUserList'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordUserList = createAction('recordUserList')

const effects = {
  *queryUserList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_USER_LIST, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      yield put(changeLoading({ loading: false }))
      yield put(recordUserList(response.responseData.result))
    } else {
      yield put(changeLoading({ loading: false }))
      message.error(response.msg)
    }
  },

  *queryCoachUserList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;


    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_COACH_USER_LIST, payload);

    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      yield put(changeLoading({ loading: false }));
      yield put(recordUserList(response.responseData.result))
    } else {
      yield put(changeLoading({ loading: false }));
      message.error(response.msg)
    }
  },

  *queryUnVipUserList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_UNVIP_USER_LIST, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      yield put(changeLoading({ loading: false }));
      yield put(recordUserList(response.responseData.result))
    } else {
      yield put(changeLoading({ loading: false }));
      message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryUserList, effects.queryUserList);
  },
  function* () {
    yield takeLatest(actions.queryUnVipUserList, effects.queryUnVipUserList);
  },
  function* () {
    yield takeLatest(actions.queryCoachUserList, effects.queryCoachUserList);
  }
];

// reducer
export const userStore = handleActions(
  {
    recordUserList(state, { payload: result }) {
      return {
        ...state,
        pagesize: result.pagesize,
        userList: result.list,
        totalpage: result.totalpage,
        page: result.page,
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    userList: [],
    pagesize: 0,
    totalpage: 0,
    page: 1
  }
)



export async function launchQueryCoachUserList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.QUERY_COACH_USER_LIST, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}


export async function launchUpdateUserTmpUsername(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.UPDATE_USER_TMP_NAME, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

