import React, { Component } from 'react';

import BaseController from '../../base/base-controller';
import { HomeStyles } from '../../../style/web/home/home-mobile-styles';

export default class Home extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        };
    }

    componentDidMount() {
       
    }

    render() {
        
        return (
            <div id="container" style={HomeStyles.container}>
                <div id="content" style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', flexShrink:0 }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', height: 330, backgroundColor: '#fff', flexShrink:0 }}>
                        <img style={{ position: 'absolute', top: 0, right: 0, width: 352, height: 330 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/acceb6c61d584b418c92aaeaeb751e4f.png"  />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', backgroundColor: '#fff', flexShrink:0 }}>
                        <img mode="widthFix" style={{ width: 105, objectFit: 'widthFix', marginLeft: 23, marginTop: 23 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/4082b9be198648a688f6009d6957b511.png" />
                        <span style={{ fontSize: '20px', fontFamily: 'PingFangSC-Medium', fontWeight: 250, color: '#343434', lineHeight: '28px', marginLeft: 25, marginTop: 23 }}>一站式专业SAAS平台</span>
                    </div>

                    <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <img mode="widthFix" style={{ width: '100%', objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/4869265bf8394c50968eae31d3ec283e.png" />
                    </div>

                    {/* 服务人群 */}
                    <div style={{ marginTop: 46, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <div style={HomeStyles.areaTitle}>服务人群</div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                            <img mode="widthFix" style={{ marginTop: 35, width: 249, objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/47cf489363b54b49976e2b52c5d1389d.png" />
                            <div style={{ display: 'flex', width: '100%', position: 'relative', marginTop: 29 }}>
                                <img mode="contain" style={{ marginLeft: 50, width: 58, height: 39, objectFit: 'contain' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/a132f8e0ab1c41fd87e873a3954e30d4.png" />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 84, bottom: 0}}>健身类工作室</div>
                            </div>

                            <div style={{...HomeStyles.serviceItemDesc, marginTop: 26 }}>瑜伽、健身、舞蹈等类型工作室，帮助工作室做到线上的会员管理、教练管理、课程管理、约课管理等，有效提高工作效率，增加工作室曝光量</div>
                        </div>

                        <div style={{ marginTop: 45, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                            <img mode="widthFix" style={{ marginTop: 35, width: 249, objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/082be65d1ab84011a387b0eadd315f68.png" />
                            <div style={{ display: 'flex', width: '100%', position: 'relative', marginTop: 29 }}>
                                <img mode="contain" style={{ marginLeft: 50, width: 58, height: 39, objectFit: 'contain' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/f17bbba1ca37448bacce9128f27e0102.png" />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 84, bottom: 0}}>教练</div>
                            </div>

                            <div style={{...HomeStyles.serviceItemDesc, marginTop: 26 }}>线上认证教练后生成在线简历，随时随地一键转发，求职更便捷。在线安排课表，处理学生约课。教练多店课程可即时同步，无需另外添加。教练免费认证，后续享受多种服务。</div>
                        </div>

                        <div style={{ marginTop: 45, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                            <img mode="widthFix" style={{ marginTop: 35, width: 249, objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/645dfdb54a3b4750bb227d37bd3eb4b8.png" />
                            <div style={{ display: 'flex', width: '100%', position: 'relative', marginTop: 29 }}>
                                <img mode="contain" style={{ marginLeft: 50, width: 58, height: 39, objectFit: 'contain' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/fa81f911bcca4ab9b41199dab2fe842d.png" />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 84, bottom: 0}}>健身爱好者</div>
                            </div>

                            <div style={{...HomeStyles.serviceItemDesc, marginTop: 26 }}>在线学习课程，掌握周边场馆动态，观看达人分享，记录成长历程，交流运动健身经验</div>
                        </div>
                    </div>


                    {/* 产品特点 */}
                    <div style={{ marginTop: 46, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <div style={HomeStyles.areaTitle}>产品特点</div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 33, width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <img mode="contain" style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/0569028483ee4e3aa9ded8c2c2ad128d.png" />
                                </div>

                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={HomeStyles.productItemName}>终身免费升级</span>
                                        <span style={HomeStyles.productItemDesc}>快速迭代，积极更新升级，7*24小时技术支持，有问题随时解决。根据各工作室需求进行优化，匹配适用所有门店</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 60, width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={HomeStyles.productItemName}>详细指导</span>
                                        <span style={HomeStyles.productItemDesc}>专属客服讲解咨询，有疑惑随时沟通，专业客服即时解答，详细的操作文档，随时查阅，快捷了解</span>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <img mode="contain" style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/461976b46e6240d4ad2770eae06e4433.png" />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 60, width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <img mode="contain" style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/eaedc090acf746bf91f296b5a8fcadde.png" />
                                </div>

                                <div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={HomeStyles.productItemName}>轻量操作，办公便捷</span>
                                        <span style={HomeStyles.productItemDesc}>轻量级应用，操作便捷，快速上手</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* 功能介绍 */}
                    <div style={{ marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <div style={HomeStyles.areaTitle}>功能介绍</div>

                        <div style={{ marginTop: 38, display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/282ff6726b5f41b3acd92b33dabab7bb.png" />
                                <span style={HomeStyles.funcItemName}>会员管理</span>
                                <span style={HomeStyles.funcItemDesc}>告别杂乱无章，多类会员统一管理</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/abbfe63f7da842f998d360c2352d287d.png" />
                                <span style={HomeStyles.funcItemName}>卡项管理</span>
                                <span style={HomeStyles.funcItemDesc}>支持多种卡项，满足各类会馆需求</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/7dca695e85bd41db9d1cd5b9df136f62.png" />
                                <span style={HomeStyles.funcItemName}>课程管理</span>
                                <span style={HomeStyles.funcItemDesc}>线上安排课程，团课私教都搞定</span>
                            </div>

                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/f2be5bc9a629459cbe0520c25a9ce039.png" />
                                <span style={HomeStyles.funcItemName}>教练管理</span>
                                <span style={HomeStyles.funcItemDesc}>教练统一管理，教练端实时同步课程</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/966ec3618e544e9a89a945cd2b949b81.png" />
                                <span style={HomeStyles.funcItemName}>多店管理</span>
                                <span style={HomeStyles.funcItemDesc}>轻松管理多门店，一套系统就能搞定</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/50ac4284357e4c5c93241b909e9c027b.png" />
                                <span style={HomeStyles.funcItemName}>约课管理</span>
                                <span style={HomeStyles.funcItemDesc}>会员一键约课，约课更便捷</span>
                            </div>

                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/5a221a1550c04804a99861b6fed4822f.png" />
                                <span style={HomeStyles.funcItemName}>简历生成</span>
                                <span style={HomeStyles.funcItemDesc}>教练免费认证，精美简历一键转发</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/e5df22cb0ef84964a7fea415903d07c5.png" />
                                <span style={HomeStyles.funcItemName}>在线课程</span>
                                <span style={HomeStyles.funcItemDesc}>随时随地，线上跟练，学习不停</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/83ed081c8e0a4977aae115d5b282813a.png" />
                                <span style={HomeStyles.funcItemName}>运动社区</span>
                                <span style={HomeStyles.funcItemDesc}>交流健身经验，互相激励一起进步</span>
                            </div>

                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/333bd1d497ae4ce28e0095c988d0d0c8.png" />
                                <span style={HomeStyles.funcItemName}>权限管理</span>
                                <span style={HomeStyles.funcItemDesc}>支持多角色账户，权限划分明晰</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/c69196771f12440ea7c217fa55592268.png" />
                                <span style={HomeStyles.funcItemName}>综合平台</span>
                                <span style={HomeStyles.funcItemDesc}>不仅仅是管理系统，更是可增加曝光的平台</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginLeft: 25, alignItems: 'center', justifyContent: 'center'}}>
                                <span style={{...HomeStyles.funcItemName}}>更多功能</span>
                                <span style={{...HomeStyles.funcItemName}}>欢迎咨询</span>
                            </div>
                        </div>
                    </div>

                    {/* 扫码体验 */}
                    <div style={{ marginTop: 59, display: 'flex', flexDirection: 'column', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <img mode="widthFix" style={{ width: 263, objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/4609b820f7c74ab49d70a804af3b2e96.png" />

                        <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 0, width: 146 }}>
                            <span style={{ marginTop: 142, fontSize: '13px', fontFamily: 'PingFangSC-Medium', fontWeight: 250, color: '#000000', lineHeight: '19px' }}>扫码咨询</span>
                            <span style={{ marginTop: 12, fontSize: '13px', fontFamily: 'PingFangSC-Regular', fontWeight: 200, color: '#000000', lineHeight: '19px' }}>ONE ME平台小程序</span>
                            <img style={{ marginTop: 12, objectFit: 'contain', width: 78, height: 78  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/75c1412bd482444d9a726d5d0aeee12b.png" />
                        </div>
                    </div>

                    {/* 诚招代理 */}
                    <div style={{ marginTop: 45, display: 'flex', flexDirection: 'column', position: 'relative', width: '100%',  backgroundColor: '#fff', flexShrink:0 }}>
                        <img mode="widthFix" style={{ width: '100%', objectFit: 'widthFix' }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/e4ec19bfb3494c4b96d82cd214e5835c.png" />

                        <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', right: 0, top: 0, width: 146 }}>
                            <div style={{ marginTop: 19, fontSize: '14px', fontFamily: 'PingFangSC-Medium', fontWeight: 250, color: '#3F3F3F', lineHeight: '20px' }}>诚招代理</div>
                            <div style={{ marginTop: 11, width: 125, fontSize: '11px', fontFamily: 'PingFangSC-Regular', fontWeight: 200, color: '#3F3F3F', lineHeight: '17px' }}>现诚招全国各城市代理，提成丰厚，诚邀合作！</div>
                            <div style={{ marginTop: 11, width: 125, fontSize: '11px', fontFamily: 'PingFangSC-Regular', fontWeight: 200, color: '#3F3F3F', lineHeight: '17px' }}>联系邮箱：chao.li@soso-code.com</div>
                            
                        </div>
                    </div>

                </div>   
            </div>
        );
    }
}