import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Avatar, List, Divider, Button, Table, Modal, message, Popconfirm, Popover, Spin, Tag } from 'antd';
import { connect } from 'react-redux';
import { AppointmentStyles } from '../../../style/page/appointment/appointment-styles';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { 
  actions as appointmentActions, 
  launchQueryTimeTable, 
  launchQueryNextTimeTable, 
  launchQueryPreTimeTable, s, launchAppointmentCourse, launchAppointmentListOfTableCourse, launchCutCourse, launchCancelCourse
} from '../../../redux/appointment-model';
import VipUserSelectView from '../../../view/appointment/vipuser-select-view';
import TIMETABLE_404 from '../../../resources/timetable_404.png'
import { PageStyles, TableStyles } from '../../../style/common-styles';
import { LeftOutlined, RightOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.APPOINTMENT_TABLE;

const confirm = Modal.confirm;  
const cancel = Modal.cancel;

class AppointmentController extends BaseController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      currentTimeTable: undefined,
      currentMonday: undefined,
      currentSunday: undefined,
      preTableid: undefined,
      nextTableid: undefined,

      appointCourse: undefined,

      popoverVisible: false,
      courseSaveVisible: false,
      vipuserrVisible: false
    };

    let columnWidth = 100;
    this.columns = [
      { title: '课表', width: 80, dataIndex: 'begindate', key: 'begindate', align: 'center' },
      { title: '星期一', key: 'week1', width: columnWidth, render: (row) => { return this.renderTableCell(row.week1.courses, 1, row.begindate); }},
      { title: '星期二', key: 'week2', width: columnWidth, render: (row) => { return this.renderTableCell(row.week2.courses, 2, row.begindate); }},
      { title: '星期三', key: 'week3', width: columnWidth, render: (row) => { return this.renderTableCell(row.week3.courses, 3, row.begindate); }},
      { title: '星期四', key: 'week4', width: columnWidth, render: (row) => { return this.renderTableCell(row.week4.courses, 4, row.begindate); }},
      { title: '星期五', key: 'week5', width: columnWidth, render: (row) => { return this.renderTableCell(row.week5.courses, 5, row.begindate); }},
      { title: '星期六', key: 'week6', width: columnWidth, render: (row) => { return this.renderTableCell(row.week6.courses, 6, row.begindate); }},
      { title: '星期日', key: 'week7', width: columnWidth, render: (row) => { return this.renderTableCell(row.week7.courses, 7, row.begindate); }}
    ];

    this.changeLoadingState = this.changeLoadingState.bind(this);
    this.selectedVipUser = this.selectedVipUser.bind(this);
    this.queryAppointmentListOfTableCourse = this.queryAppointmentListOfTableCourse.bind(this);

    this.handleAppointmentCourse = this.handleAppointmentCourse.bind(this);
    this.handleQueryTable = this.handleQueryTable.bind(this);
    this.handleAddAppointment = this.handleAddAppointment.bind(this);
    this.handleAppointmentVisibled = this.handleAppointmentVisibled.bind(this);
    this.handleCutCourse = this.handleCutCourse.bind(this);
    this.handleCancelCourse = this.handleCancelCourse.bind(this);
    this.handleDeleteTimeTableCourse = this.handleDeleteTimeTableCourse.bind(this);
    this.renderTableCell = this.renderTableCell.bind(this);
    this.renderNotFoundTimeTable = this.renderNotFoundTimeTable.bind(this);
    this.renderAppointmentList = this.renderAppointmentList.bind(this);
  }

  componentDidMount() {
    this.changeLoadingState(true);
    launchQueryTimeTable(
      {},
      response => {
        this.changeLoadingState(false);
        // message.success(response.msg);
        this.setState({
          currentTimeTable: response.result.timetable,
          currentMonday: response.result.monday,
          currentSunday: response.result.sunday,
          preTableid: response.result.pretable,
          nextTableid: response.result.nexttable
        });

        if (response.result.timetable) {
          this.props.queryAppointmentTable({ tableid: response.result.timetable.tableid });
        }
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 选定会员
  selectedVipUser(vipUser) {
    let that = this;
    confirm({
      title: <span style={{ whiteSpace: 'pre-wrap' }}>{`会员: ${vipUser.username} \n预约: ${this.state.appointCourse.daydate} ${this.state.appointCourse.weekdate} \n课程: ${this.state.appointCourse.coursename} ？`}</span>,
      content: '请确认与用户是否沟通妥当',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        that.handleAppointmentCourse(vipUser);
      },
      onCancel() {
        
      },
    });
  }

  // 查询预约人员列表
  queryAppointmentListOfTableCourse(course) {
    launchAppointmentListOfTableCourse(
      {
        tcid: course.tcid
      },
      response => {
        this.changeLoadingState(false);
        this.setState({ 
          appointCourse: course,
          popoverVisible: `popoverVisible${course.tcid}`,
          appointmentList: response.result.list 
        });
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 取消课程
  cancelCourse(appointment) {
    this.changeLoadingState(true);
    launchCancelCourse(
      {
        userid: appointment.userid,
        tcid: appointment.tcid
      },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.props.queryAppointmentTable({ tableid: this.state.currentTimeTable.tableid });
        this.queryAppointmentListOfTableCourse(this.state.appointCourse);
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 旷课请求
  cutCourse(appointment) {
    this.changeLoadingState(true);
    launchCutCourse(
      {
        userid: appointment.userid,
        tcid: appointment.tcid
      },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.queryAppointmentListOfTableCourse(this.state.appointCourse);
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 预约课程
  handleAppointmentCourse(vipUser) {

    this.changeLoadingState(true);
    launchAppointmentCourse(
      {
        userid: vipUser.userid,
        tableid: this.state.currentTimeTable.tableid,
        tcid: this.state.appointCourse.tcid
      },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.setState({
          vipuserrVisible: false,
        });

        this.props.queryAppointmentTable({ tableid: this.state.currentTimeTable.tableid });
        this.queryAppointmentListOfTableCourse(this.state.appointCourse);
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 查询上一期/下一期 课表
  handleQueryTable(isLeft) {
    if (isLeft === true) {
      if (this.state.preTableid !== undefined) {
        launchQueryTimeTable(
          {tableid: this.state.preTableid},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryAppointmentTable({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      } else {
        launchQueryPreTimeTable(
          {monday: this.state.currentMonday},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,  
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryAppointmentTable({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      }
    } else {
      if (this.state.nextTableid !== undefined) {
        launchQueryTimeTable(
          {tableid: this.state.nextTableid},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryAppointmentTable({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      } else {
        launchQueryNextTimeTable(
          {monday: this.state.currentMonday},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,  
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryAppointmentTable({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      }
    }
  }

  handleAddAppointment(course) {
    this.setState({
      appointCourse: course,

      popoverVisible: false,
      vipuserrVisible: true
    })
  }

  handleAppointmentVisibled(visible, course) {
    
    if (visible === true) {
      this.changeLoadingState(true);
      this.queryAppointmentListOfTableCourse(course);
    } else {
      this.setState({ 
        popoverVisible: undefined,
        
      }, () => {
        this.setState({
          appointmentList: []
        });
      });
    }
  }

  // 旷课
  handleCutCourse(appointment) {

    this.setState({ 
      popoverVisible: undefined 
    });

    let that = this;
    confirm({
      title: <span style={{ whiteSpace: 'pre-wrap' }}>{`会员: ${appointment.username} \n旷课: ${this.state.appointCourse.daydate} ${this.state.appointCourse.weekdate} \n课程: ${this.state.appointCourse.coursename} ？`}</span>,
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {

        that.cutCourse(appointment);
      },
      onCancel() {
        
      },
    });
  }

  handleCancelCourse(appointment) {

    this.setState({ 
      popoverVisible: undefined 
    });

    let that = this;
    confirm({
      title: <span style={{ whiteSpace: 'pre-wrap' }}>{`会员: ${appointment.username} \n取消: ${this.state.appointCourse.daydate} ${this.state.appointCourse.weekdate} \n课程: ${this.state.appointCourse.coursename} ？`}</span>,
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {

        that.cancelCourse(appointment);
      },
      onCancel() {
        
      },
    });
  }

  handleDeleteTimeTableCourse() {
    
  }

  renderHeader() {
    return (
      <div style={AppointmentStyles.header}>
        <Button onClick={()=>{this.handleQueryTable(true)}} style={AppointmentStyles.headerLeftButton} icon={<LeftOutlined />} type="primary" size="default" ></Button>
        <span style={AppointmentStyles.headerTitle}>{this.state.currentMonday ? `${this.state.currentMonday} 至 ${this.state.currentSunday}` : '-'}</span>
        <Button onClick={()=>{this.handleQueryTable(false)}} style={AppointmentStyles.headerRightButton} icon={<RightOutlined />} type="primary" size="default" ></Button>
      </div>
    );
  }

  renderTableCell(arr, weekindex, timeStr) {
    if (arr.length > 0) {       
      return (
        <div>
          {
            arr.map((item, index) => {
              const appointmentList = this.renderAppointmentList(item);
              return (
                
                <div key={`grid_${index}`}>
                  {/* <Popconfirm 
                    title="选择操作" 
                    onConfirm={() => { this.handleEditTimeTableCourse(item) }} 
                    onCancel={() => { this.handleDeleteTimeTableCourse(item) }} 
                    okText="编辑" 
                    // trigger={'contextMenu'}
                    trigger={this.state.popoverVisible ? 'contextMenu' : 'click'}
                    cancelText="删除"> */}

                    <div style={AppointmentStyles.courseItem}>
                      <span style={AppointmentStyles.courseTime}>{`${item.begindate} - ${item.enddate}`}</span>
                      <span style={AppointmentStyles.courseName}>{item.coursename}</span>

                      <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                        <div style={{display: 'flex', flex: 1}}>
                            <span style={AppointmentStyles.coachName}>{item.coachname}</span>
                        </div>

                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
                            
                            <Popover
                              content={appointmentList}
                              trigger="click"
                              visible={this.state.popoverVisible === `popoverVisible${item.tcid}`}
                              onVisibleChange={(visible) => { 
                                this.handleAppointmentVisibled(visible, item); 
                              }}
                            >
                                <Tag 
                                    color="#87d068"
                                    onClick={(e) => {  e.stopPropagation();   }}
                                >{`${item.haspeople ? item.haspeople : 0}/${item.maxpeople}`}</Tag>
                            </Popover>
                        </div>
                      </div>
                    </div>
                  {/* </Popconfirm> */}
                  {
                    index !== arr.length-1 ?
                      <div style={AppointmentStyles.itemLine}>
                        <Divider dashed />
                      </div>
                      :
                      null
                  }
                </div>
              )
            })
          }
        </div>
      );
    } else {
      return (
        <div 
          style={AppointmentStyles.emptyCourseCell} 
          onClick={() => {
            // this.handleCourseCellClick(weekindex, timeStr);
          }}>
        </div>
      )
    }
  }

  renderNotFoundTimeTable() {
    if (!this.state.currentTimeTable && this.state.isLoading === false && this.props.loading === false) {
      return (
        <div style={AppointmentStyles.emptyBox}> 
            <img style={AppointmentStyles.emptyIcon} src={TIMETABLE_404} alt="" />

            <span style={AppointmentStyles.emptyDesc}> 本周还未创建课表 </span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderAppointmentList(course) {

    const header = (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 350}}>
        <div style={{display: 'flex', flex: 1}}>
          <span>预约人员</span>
        </div>

        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Button onClick={() => {this.handleAddAppointment(course)}} shape="circle" icon={<PlusOutlined />} type="primary" size="small" ></Button>
        </div>
      </div>
    );

    return(
      <Spin spinning={this.state.isLoading}>
        <div style={{maxHeight: 400}}>
          <List
            loading={this.state.isLoading}
            size="small"
            itemLayout="horizontal"
            header={header}
            dataSource={this.state.appointmentList}
            renderItem={item => (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 44}}>
                <div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                  <Avatar src={item.portrait} icon={<UserOutlined />} />
                  <span style={{marginLeft: 15, width: 50, textAlign: 'left'}}>{item.username}</span>
                  <span style={{marginLeft: 15}}>{item.phone}</span>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', with: 100, justifyContent: 'flex-end'}}>
                  <a style={{marginRight: 10}} href="javascript:void(0)" onClick={() => { this.handleCancelCourse(item) }} >取消</a>
                  {
                    item.state === 1 ?
                    <a onClick={() => { this.handleCutCourse(item) }} href="javascript:void(0)" >旷课</a>
                    :
                    null
                  }
                </div>
              </div>
            )}
          />
        </div>
      </Spin>
    );
  }

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            
        </div>
      )
    });
  }

  render() {
    
    return (
      <Spin spinning={this.state.isLoading || this.props.loading}>
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={TableStyles.tableBox}>

          {
            this.renderHeader()
          }

          {
            this.renderNotFoundTimeTable()
          }

          {
            this.state.currentTimeTable ?
              <Table 
                  rowKey={record => record.begindate}
                  columns={this.columns} 
                  dataSource={this.props.appointmentTable} 
                  scroll={{ x: '1800' }} 
                  pagination={false}  
                  bordered
              />
              :
              null
          }
        </div>

        <Modal
            visible={this.state.vipuserrVisible}
            onOk={this.handleOk}
            onCancel={() => { this.setState({ vipuserrVisible: false }) }}
            footer={null}
            width={"1100px"}
            destroyOnClose={true}
            bodyStyle={{ padding: "20px 48px 48px 48px" }}
        >
            <VipUserSelectView selectedVipUser={this.selectedVipUser} />
        </Modal>
      </div>
      </Spin>
    );
  }
}



const mapStateToProps = store => {
  const appointmentStore = store['appointmentStore'];
  const utilStore = store['utilStore'];
  return {
    loading: appointmentStore.loading,
    appointmentTable: appointmentStore.appointmentTable,
    // isShow: utilStore.isShow[PathConstant.APPOINTMENT_TABLE.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryAppointmentTable: params => {
      dispatch(appointmentActions.queryAppointmentTable(params));
    },
    changeLoading: params => {
      dispatch(appointmentActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentController);

