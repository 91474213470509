import React from 'react';
import { Table, Spin, Upload, Modal, Mentions, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { BrowserRouter as Router,Route} from 'react-router-dom';
import { actions as queryActions } from '../../../redux/query-model'
import { filterParams } from '../../../util/helper/request-helper';
import { MAIN } from '../../../constants/routeConstants';
import FormItem from '../../../view/common/form-item';
import BaseController from '../../base/base-controller';
import AppointPrivateView from '../../../view/appoint-private/appoint-private-view';
import ChangeCoachForCourseView from '../../../view/appoint-private/change-coach-for-course-view';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../style/common-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { launchGetAppointPrivateList, launchCancelAppointPrivate, launchAppointPrivate, launchChangeAppointCoach, launchChangeAppointTime, launchUnhandlePrivateNotifyList } from '../../../redux/appointment-model';

let { confirm } = Modal;

const MAIN_LISTS = MAIN.children;
const NAMESPACE = MAIN_LISTS.APPOINTMENT_PRIVATE_UNHANDLE;

class AppointPrivateUnhandleListController extends BaseController {
    constructor(props) {
        super(props);

        let showBack = undefined;
        if (props.location.state) {
            showBack = props.location.state.showBack;
        }

        this.state = {
            ...this.state,
            dataInfo: {},
            showBack,
            queryParams: {},

            modalAppointShowing: false
        };

        let that = this;
        this.columns = [
            { title: '用户', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '上课人数', width: '10%', dataIndex: 'peoplecount', key: 'peoplecount', align: 'center' },
            { title: '扣除次数', width: '10%', dataIndex: 'reducetime', key: 'reducetime', align: 'center' },
            { title: '手机号', width: '10%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '课程', width: '20%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '教练', width: '10%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
                render: state => {
                    if (state === 1) {
                        return <Tag color='red'>待确认</Tag>;
                    } else if (state === 10 || state === 11) {
                        return <Tag color='green'>已确认</Tag>;
                    } else if (state === 20 || state === 21) {
                        return <Tag>已取消</Tag>;
                    } else {
                        return null;
                    }
                }
            },
            { title: '上课时间', width: '20%', align: 'center', 
                render: appoint => {
                    return <span>{`${appoint.daydate} ${appoint.begindate}`}</span>
                }
            },
            // { title: '操作', width: 200, key: 'operate', align: 'center', 
            //     render: appoint => {
            //         return (
            //             <div>
                           
            //             </div>
            //         );
            //     }
            // },
        ];

        this.cancelAppoint = this.cancelAppoint.bind(this);
        this.handleAppointPrivate = this.handleAppointPrivate.bind(this);
        this.handleChangeCoach = this.handleChangeCoach.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

    }

    componentDidMount() {
        let queryParams = this.queryParams();

        // if (!queryParams.beginTime) queryParams.beginTime = LLCDateHelper.todayStartTimestampOfSecond();
        // if (!queryParams.endTime) queryParams.endTime = queryParams.beginTime + 60 * 60 * 23 + 60 * 59 + 59;
        // this.props.configQueryParams(queryParams);
        this.queryAppointPrivateList(queryParams.page ? queryParams.page : 1, queryParams);
    }

    componentWillUnmount() {
        this.props.configQueryParams({});
    }

    queryAppointPrivateList(page, extra) {

        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }

        queryParams.page = page;

        this.changeLoadingState(true);
        launchUnhandlePrivateNotifyList(
            filterParams(queryParams),
            res => {
                this.setState({ dataInfo: res });
                
                this.changeLoadingState(false);
            },  
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    cancelAppoint(appoint) {
        let that = this;
        confirm({
            title: `确定要取消 ${appoint.username} 的 ${appoint.coursename} ？`,
            content: '',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchCancelAppointPrivate(
                    { id: appoint.id },
                    res => {
                        that.queryAppointPrivateList();
                    },
                    fail => {
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    // 换绑教练
    handleChangeCoach(e) {
        const { coach } = e;
        const { editAppoint } = this.state;
        this.changeLoadingState(true);
        launchChangeAppointCoach(
            {
                id: editAppoint.id,
                coachid: coach.coachid
            },
            res => {
                // this.changeLoadingState(false);
                this.setState({ modalChangeCoachShowing: false, editAppoint: undefined });
                message.success('更改成功');
                this.queryAppointPrivateList();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    handleAppointPrivate(appoint) {
        this.changeLoadingState(true);
        let endMinute = LLCDateHelper.convertDateToMinute(appoint.begindate) + appoint.duration;
        appoint.enddate = LLCDateHelper.convertMinuteToDate(endMinute);

        if (appoint.id) {
            launchAppointPrivate(
                appoint,
                res => {
                    // this.changeLoadingState(false);
                    message.success('预约成功');
                    this.setState({
                        modalAppointShowing: false
                    })
                    this.queryAppointPrivateList();
                },
                fail => {
                    message.error(fail);
                    this.changeLoadingState(false);
                }
            );
        } else {
            launchChangeAppointTime(
                appoint,
                res => {
                    // this.changeLoadingState(false);
                    message.success('更改成功');
                    this.setState({
                        modalAppointShowing: false
                    })
                    this.queryAppointPrivateList();
                },
                fail => {
                    message.error(fail);
                    this.changeLoadingState(false);
                }
            );
        }
    }

    handleSearchClick() {
        this.queryAppointPrivateList()
    }

    
    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    renderSearchBar() {
        let { types, categories } = this.state;
        let queryParams = this.queryParams();

        let beginTime = "";
        let endTime = "";
        let timeValue = [beginTime, endTime];
        
        if (queryParams.beginTime) {
            let convert = LLCDateHelper.formatDate(queryParams.beginTime);
            timeValue[0] = moment(convert, 'YYYY-MM-DD');
        }
        if (queryParams.endTime) {
            let convert = LLCDateHelper.formatDate(queryParams.endTime);
            timeValue[1] = moment(convert, 'YYYY-MM-DD');
        }

        return (
            <div style={TableSearchStyles.searchBox}>
                <div style={TableSearchStyles.searchIputBox}>

                    <FormItem 
                        mode="query" itemKey="用户：" type="input" itemPlaceHolder="请输入用户名"
                        itemValue={queryParams.username} 
                        onValueChanged={(username) => {
                            queryParams.username = username;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="手机号：" type="input" itemPlaceHolder="请输入手机号"
                        itemValue={queryParams.phone} 
                        onValueChanged={(phone) => {
                            queryParams.phone = phone;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="上课时间：" type="range-time" 
                        itemValue={timeValue} showTime={false} 
                        onValueChanged={(beginTime, endTime) => {
                            queryParams.beginTime = beginTime;
                            queryParams.endTime = endTime;
                            
                            this.props.configQueryParams(queryParams);
                        }} />

                </div>

                <div style={TableSearchStyles.operateBox}>
                    <div style={TableSearchStyles.searchButtonContainer}>
                        <Button
                            style={TableSearchStyles.searchButton}
                            type="primary"
                            onClick={this.handleSearchClick}>搜索</Button>
                        <Button
                            style={TableSearchStyles.clearButton}
                            onClick={this.handleClearClick}>清空</Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let { showBack } = this.state;

        let headerParams = {
            title: NAMESPACE.name,
            extra: <div>
                
            </div>
        };
        if (showBack) {
            headerParams = {
                title: NAMESPACE.name,
                extra: <div>
                    
                </div>,
                onBack: () => {
                    this.props.history.goBack();
                }
            };
        }
        

        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
            {
                this.renderPageHeader(headerParams)
            }

            <div style={TableStyles.tableBox}>
                {
                    // this.renderSearchBar()
                }

                <Table
                    rowKey={record => record.id}
                    columns={this.columns}
                    dataSource={this.state.dataInfo.list}
                    scroll={{ 
                        // x: 1200, 
                        // y: 450
                    }}
                    pagination={{
                        total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                        pageSize: this.state.dataInfo.pagesize,
                        current: this.state.dataInfo.page,
                        onChange: val => {
                            this.queryAppointPrivateList(val)
                        }
                    }}
                ></Table>
            </div>

            <Modal
                title="课程预约"
                centered={true}
                visible={this.state.modalAppointShowing}
                destroyOnClose={true}
                width={"956px"}
                onCancel={() => { this.setState({ modalAppointShowing: false }) }}
                footer={null}
            >
                <AppointPrivateView 
                    appoint={this.state.appoint}
                    isLoading={this.state.isLoading}
                    appoint={this.state.editAppoint}
                    onSubmit={this.handleAppointPrivate}
                />
            </Modal>

            <Modal
                    title="授课教练"
                    visible={this.state.modalChangeCoachShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                    footer={null}
                >
                    <ChangeCoachForCourseView 
                        onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                        onOk={this.handleChangeCoach}
                        isLoading={this.state.isLoading}
                        appoint={this.state.editAppoint}
                    />
                </Modal>
        </div>
        </Spin>
        );
    }
}

const mapStateToProps = ({ queryStore }) => {
    
    return {
        queryStore
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointPrivateUnhandleListController);


