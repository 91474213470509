import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';



export async function launchGetCoachFinance(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_COACH_FINANCE, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  


export async function launchGetCoachFinanceList(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_COACH_FINANCE_LIST, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  

  export async function launchGetCourseTimesList(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_COURSE_TIMES_LIST, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  

  export async function launchGetCourseTimesOfVip(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_COURSE_TIMES_OF_VIP, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }

  export async function launchGetCourseTimesOfCoach(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_COURSE_TIMES_OF_COACH, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  

  export async function launchGetStaticOrder(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_STATIC_ORDER, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.responseData.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  