import { handleActions, createAction } from 'redux-actions';

export const actions = {
    configQueryParams: createAction('configQueryParams'),
    clearQueryParams: createAction('clearQueryParams'),
};

const effects = {
  
};

export const watchers = [
  
]

export const queryStore = handleActions(
    {
        // 设置查询参数
        configQueryParams(state, { payload: pageQuery }) {
            
            let newState = {
                ...state,
                ...pageQuery
            };
            return newState;
        },

        clearQueryParams(state, { payload }) {
            let newState = {
                
            };
            return newState;
        }
    },
    {
        params: {}
    }
);
