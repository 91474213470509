import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class MainManager extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="子账户" />

                <SectionTitle text="一.什么是子账户？" />
                <NormalContent text={<span>1.主账户登录后，在<Highlight text="账户管理" />中创建的账户，就是子账户。</span>} />

                <SectionTitle text="二.创建子账户" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/bc7fc8b7c8374b858eb575b00ed3212b.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“账户管理”后，在右侧就会展示当前场馆的子账户列表。</span>} />
                <NormalContent text={<span>2.在账户管理页面，点击右上角的“<Highlight text="新增管理员" />”，进入编辑管理员页面。</span>} />
                <NormalContent text={<span>3.其中账户的属性如下：</span>} />
                <KeyValue title="姓名：" value="管理员的姓名" />
                <KeyValue title="登录账号：" value="管理员登录时输入的账号" />
                <KeyValue title="手机号：" value="管理员的手机号" />
                <KeyValue title="密码：" value="管理员登录时输入的密码" />
                <KeyValue title="确认密码：" value="重复输入管理员登录时输入的密码" />
                <KeyValue title="权限：" value="多选分配管理员的权限。每一个权限，对应后台管理系统中的一个左侧菜单项。只有具有相应权限，才可以访问对应的菜单项。" />

                <SectionTitle text="三.管理子账户" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/d4c5ba53f67e406ebe306635f3ec9f37.png" />
                <NormalContent text={<span>1.禁用/恢复子账户：禁用子账户后，被禁用的子账户无法登录进行操作。</span>} />
                <NormalContent text={<span>2.修改子账户密码：主账户可以不用原密码直接修改子账户的密码。（子账户还可以自行登录后，点击右上角的“修改密码”按钮，进行修改）</span>} />
                <NormalContent text={<span>3.编辑子账户信息/权限：对于已创建的子账户，可以对其姓名、手机号、权限进行修改。</span>} />

            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainManager);
