import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AppointPrivateUnhandle extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="私教预约申请" />

                <SectionTitle text="一.查看会员发起的私教预约申请" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/84d61891678444519ec581967db94ed8.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“私教预约申请”后，在右侧就会展示当前场馆所有会员发起的私教课预约申请列表。</span>} />
                <NormalContent text={<span>2.私教预约申请页面，展示的是会员发起的私教课预约申请，需要通知教练到<Highlight text="ONE ME平台" />小程序 - “我的”页面进行确认或取消处理。</span>} />
                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointPrivateUnhandle);
