export const CourseSaveStyles = {
    editBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    editLeftBox: {
        flex: 1,
        // textAlign: 'center'
        // display: 'flex',
        // justifyContent: 'center'
    },
    editSingleBox: {
        marginTop: 40
        // textAlign: 'center'
    },
    editRightBox: {
        flex: 1,
        // display: 'flex',
        // justifyContent: 'center'
    },
    infoBox: {
        marginTop: 80
    },
    vipStateBox: {
        marginTop: 60
    },
    baseInfoItem: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        marginTop: 16
    },
    vipStateItem: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
    },
    baseInfoLeftBox: {
        width: 200, 
        textAlign: 'right',
    },
    vipStateLeftBox: {
        width: 80, 
        textAlign: 'right',
    },
    createUserLink: {
        marginLeft: 15,
        fontSize: 12
    },
    vipCardLeftBox: {
        width: 130, 
        textAlign: 'right',
    },
    identifyItem: {
        display: 'flex', 
        flexDirection: 'row', 
        // alignItems: 'center',
        marginTop: 16
    },
    identifyLeftBox: {
        width: 100, 
        textAlign: 'right',
    },
    uploadButton: {
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    operateBox: {
        width: 100,
        marginTop: 80,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clearButton: {
        marginRight: 80
    },
    saveButton: {
        // marginLeft: 80
    },
    cardType_Select: {
        width: 122,
        height: 32
    },
    addressInput: {
        width: 250
    },
    operateBox: {
        textAlign: 'center'
    },
    previewButton: {
        marginLeft: 10
    }
}