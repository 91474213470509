import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';

import { actions as userActions } from '../../redux/user-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import { PageRoutes } from '../../constants/routeConstants';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';
import { isEmpty } from '../../util/helper/string-helper';
import { UserOutlined } from '@ant-design/icons';

const confirm = Modal.confirm;

class UserSelectView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nickname: '',
            username: '',
            phone: '',

            selectedRowKeys: []
        };

        this.columns = [
            { title: '头像', width: 100, dataIndex: 'portrait', key: 'portrait', align: 'center', render: url => <Avatar src={url} icon={<UserOutlined />} /> },
            { title: '昵称', width: 150, dataIndex: 'nickname', key: 'nickname', align: 'center' },
            { title: '姓名', width: 150, dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: 170, dataIndex: 'phone', key: 'phone', align: 'center' },
        ]

        this.userSelected = this.userSelected.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        if (this.props.initialQuery === false) return;
        
        if (this.props.selectType) {
            if (this.props.selectType === 1) {
                this.props.queryUserList({ page: this.props.page });
            } else if (this.props.selectType === 2) {
                this.props.queryUnVipUserList({ page: this.props.page });
            } else if (this.props.selectType === 3) {
                this.props.queryCoachUserList({ page: this.props.page });
            }
        } else {
            this.props.queryUserList({ page: this.props.page });
        }
        
    }
    requestUserList = page => {
        let params = {
            page,
            nickname: this.state.nickname,
            phone: this.state.phone,
            username: this.state.username
        };

        if (this.props.selectType) {
            if (this.props.initialQuery === false) {
                if (isEmpty(params.nickname) && isEmpty(params.phone) && isEmpty(params.username)) {
                    message.error('请至少填写一项查询条件');
                    return;
                }
            }
            
            if (this.props.selectType === 1) {
                this.props.queryUserList(params);
            } else if (this.props.selectType === 2) {
                this.props.queryUnVipUserList(params);
            } else if (this.props.selectType === 3) {
                this.props.queryCoachUserList(params);
            }
        } else {
            this.props.queryUserList(params);
        }
    }

    userSelected() {
        let selectID = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.props.userList.length; i++) {
            if (this.props.userList[i].userid === selectID) {
                this.props.selectedUser && this.props.selectedUser(this.props.userList[i]);
                break;
            }
        }
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.userid]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    handleSearchClick() {
        this.requestUserList(1);
    }

    handleClearClick() {
        this.setState({
            nickname: '',
            phone: '',
            username: ''
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>昵称</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ nickname: e.target.value })}
                        value={this.state.nickname} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>姓名</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ username: e.target.value })}
                        value={this.state.username} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ phone: e.target.value })}
                        value={this.state.phone} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

  render() {
    const { selectedRowKeys } = this.state;
    
    const rowSelection = {
        type: 'radio',
        onChange: this.onSelectedRowKeysChange,
        selectedRowKeys
    };

    const hasSelected = selectedRowKeys.length > 0;
    return (
        <div>
            <div style={PageStyles.tableContainer}>
                {
                    this.renderSearchBar()
                }
                <Button
                    type="primary"
                    onClick={this.userSelected}
                    disabled={!hasSelected}
                >确定</Button>
                <Table
                    rowKey={record => record.userid}
                    loading={this.props.loading}
                    columns={this.columns}
                    dataSource={this.props.userList}
                    scroll={{ y: 485 }}
                    rowSelection={rowSelection}
                    pagination={{
                        total: this.props.totalpage * this.props.pagesize,
                        pageSize: this.props.pagesize,
                        current: this.props.page,
                        onChange: val => {
                            this.props.requestUserList({ page: val })
                        }
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            this.selectRow(record);
                        },
                    })}
                ></Table>
            </div>
        </div>
    );
  }
}

const mapStateToProps = store => {
  const userStore = store['userStore'];
  const utilStore = store['utilStore'];
  return {
    loading: userStore.loading,
    userList: userStore.userList,
    pagesize: userStore.pagesize,
    totalpage: userStore.totalpage,
    page: userStore.page,
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryUserList: params => {
        dispatch(userActions.queryUserList(params));
    },
    queryUnVipUserList: params => {
        dispatch(userActions.queryUnVipUserList(params));
    },
    queryCoachUserList: params => {
        dispatch(userActions.queryCoachUserList(params));
    },
    changeLoading: params => {
        dispatch(userActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectView);