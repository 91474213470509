import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';
import { actions as queryActions } from '../../../redux/query-model'
import { launchGetCourseList } from '../../../redux/course-model';
import LLCDateHelper from "date-helper";
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { PageStyles, SearchBarStyles, TableStyles } from '../../../style/common-styles';
import SaveCourseController from './course-save-controller';
import { CourseListStyles } from '../../../style/page/course/course-list-styles';
import { filterParams } from '../../../util/helper/request-helper';
import { PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.COURSE_LIST;

const confirm = Modal.confirm;

class CourseListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            dataInfo: {}
        };

        let that = this;
        this.columns = [
            { title: '课程名称', width: '20%', dataIndex: 'coursename', key: 'coursename', align: 'left', render: (coursename) => <span style={CourseListStyles.courseName}>{coursename}</span> },
            { title: '课程时长(分)', width: '10%', dataIndex: 'coursetime', key: 'coursetime', align: 'center' },
            { title: '容纳人数', width: '10%', align: 'center', render: course => <span>{course.type === 1 ? course.maxpeople : '-'}</span> },
            { title: '开课人数', width: '10%', align: 'center', render: course => <span>{course.type === 1 ? course.minpeople : '-'}</span> },
            { title: '课程类型', width: '10%', dataIndex: 'type', key: 'type', align: 'center', 
                render: type => { 
                    let typeStr = '-';
                    let colorStr = '';
                    switch (type) {
                        case 1:
                            typeStr = '团课';
                            colorStr = 'magenta';
                            break;
                        case 2:
                            typeStr = '私教课';
                            colorStr = 'orange';
                            break;
                        default:
                            typeStr = '-';
                    }
                    return <Tag color={colorStr}>{typeStr}</Tag>
                } 
            },
            { title: '课程级别', width: '10%', dataIndex: 'coursetype', key: 'coursetype', align: 'center', 
                render: coursetype => { 
                    let typeStr = '-';
                    switch (coursetype) {
                        case 1:
                            typeStr = '初级';
                            break;
                        case 2:
                            typeStr = '中级';
                            break;
                        case 3:
                            typeStr = '高级';
                            break;
                        default:
                            typeStr = '-';
                    }
                    return <span>{typeStr}</span>
                } 
            },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', render: state => <Tag color={state===1 ? '#87d068' : '#f50'} >{state === 1 ? '正常' : '冻结'}</Tag> },
            { title: '提前取消(时)', width: '10%', dataIndex: 'cancelbefore', key: 'cancelbefore', align: 'center' },
            { title: '操作', width: '10%', key: 'operate', align: 'center', 
                render: (course) => {
                    
                    return (
                        <a onClick={(e) => { 
                            e.stopPropagation();
                            
                            // that.props.dispatch(descActions.recordDescid(course.coursedetail));
                            that.props.history.push({
                                pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_COURSE.path}`,
                                state: { editCourse: course }
                            });
                        }}>编辑</a> 
                    );
                }
            }
        ];

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.queryParams = this.queryParams.bind(this);
        this.queryCourseList = this.queryCourseList.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();

        this.queryCourseList(queryParams.page ? queryParams.page : 1, queryParams);
    }

    handleSearchClick() {
        this.queryCourseList(1)
    }
    
    
    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    handleAddButtonClick() {
        
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_COURSE.path}`);
    }

    queryCourseList(page) {
        
        let queryParams = this.queryParams();
        queryParams.page = page;

        this.changeLoadingState(true);
        launchGetCourseList(
            filterParams(queryParams),
            res => {
                this.setState({
                    dataInfo: res
                });
                this.changeLoadingState(false);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    renderSearchBar() {
        let queryParams = this.queryParams();

        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>课程名</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入"
                            onChange={e => {
                                queryParams.coursename = e.target.value;
                                this.props.configQueryParams(queryParams);
                            }}
                            value={queryParams.coursename} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">添加课程</Button>
            </div>
          )
        });
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderSearchBar()
                    }

                    <Table
                        rowKey={record => record.courseid}
                        loading={this.props.loading}
                        columns={this.columns}
                        dataSource={this.state.dataInfo.list}
                        scroll={{ 
                            // x: 1200, 
                            // y: 485 
                        }}
                        pagination={{
                            total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                            pageSize: this.state.dataInfo.pagesize,
                            current: this.state.dataInfo.page,
                            onChange: val => {
                                this.queryCourseList(val)
                            }
                        }}
                    ></Table>
                </div>
            </div>
            </Spin>
        );
    }
    
}

const mapStateToProps = ({ queryStore }) => {
  return {
    queryStore
  };
}

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
      const pageQuery = {};
      pageQuery[NAMESPACE.name] = params;
      dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseListController);
