import React from 'react';
import {
    Form, Input, Button, Checkbox, Modal, Card, Spin, message
} from 'antd';
import cookie from 'cookie-lza';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actions } from '../../redux/loginModel';
import { launchSaveVenue } from '../../redux/venue-model';
import * as PathConstant from '../../constants/routeConstants';
import { PageRoutes, MAIN_LISTS } from '../../constants/routeConstants';
import BaseController from '../base/base-controller';
import { LoginStyles, VenueListStyles } from '../../style/login-styles';
import AddVenueView from '../../view/venue/venue-fast-create-view';
import { isEmpty } from '../../util/helper/string-helper';
import { kSuper_Admin_Auth } from '../../constants/authConstants';
import { IndexStyles } from '../../style/web/index-pc-styles';
import { ExportOutlined, PlusOutlined } from '@ant-design/icons';

const { Meta } = Card;

class LoginController extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            username: '',
            passwd: '',
            remember: true,
            venueModalVisible: false,
        };

        this.selectedVenue = this.selectedVenue.bind(this);

        this.handleFastAddVenue = this.handleFastAddVenue.bind(this);
        this.handleFastCreateVenue = this.handleFastCreateVenue.bind(this);
        this.handleLoginTapped = this.handleLoginTapped.bind(this);
        this.handleRememberChanged = this.handleRememberChanged.bind(this);
        this.renderLoginContent = this.renderLoginContent.bind(this);
    }

    componentDidMount() {
        
        if (this.props.loginState && this.props.venueSelected !== 1) {
            this.props.queryVenueList();
        }
    }

    handleLoginTapped() {
        let { username, passwd, remember } = this.state;
        console.log(username, passwd, remember);
        if (isEmpty(username) || isEmpty(passwd)) {
            message.warn('账号密码不能为空');
            return;
        }
        this.props.login(username, passwd, remember);
    }

    handleRememberChanged(e) {
        this.setState({ remember: e.target.checked });
    }

    selectedVenue(venueid) {
        this.props.enterVenue(venueid);
    }

    handleFastCreateVenue() {
        let info = this.addVenueView.getVenuename();
        this.changeLoadingState(true);
        launchSaveVenue(
            { venuename: info.venuename, state: 2 },
            response => {
                message.success(response.msg);
                this.changeLoadingState(false);

                this.props.enterVenue(response.result.venue.venueid);
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false)
            }
        );
    }

    // handleSubmit = (e) => {
    //     e.preventDefault();
    //     this.props.form.validateFields((err, values) => {
    //         if (!err) {
    //             this.props.login(values.username, values.password, values.remember);
    //         }
    //     });
    // };

    handleFastAddVenue() {
        this.setState({ venueModalVisible: true });
    }

    renderNav() {
        return (
            <div style={IndexStyles.navBar}>
                <div style={IndexStyles.content}>

                    <div style={IndexStyles.leftBox}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            window.open(`https://${window.location.host}`, '_blank');
                        }}>
                            <img style={{ objectFit: 'contain', width: 40, height: 40, marginLeft: 0  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/5bddea7ea3104b8687a4c5a1d4a4222c.png" />
                            <img style={{ objectFit: 'contain', width: 88, height: 17, marginLeft: 10  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/8771d127e9404c36aa0101a76257232a.png" />
                        </div>
                    </div>
                   
                    <div style={IndexStyles.rightBox}>
                        <div style={IndexStyles.loginBtn} onClick={() => {
                            // window.open(`https://${window.location.host}/login`, '_blank');
                        }}>
                            登录
                        </div>

                        <div style={IndexStyles.registerBtn} onClick={() => {
                            window.open(`https://${window.location.host}/om/register`, '_blank');
                        }}>
                            注册
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLoginContent() {
        const { username, passwd, remember } = this.state;

        return (
            <div style={LoginStyles.container}>
                {
                    this.renderNav()
                }
                <img style={{ width: 800, height: 610, objectFit: 'cover', position: 'absolute', left: 0, bottom: 0 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/533c3e7f9ca04d318760c8769e55d028.jpg" />

                <div style={{ display: 'flex', position: 'relative', width: '100vw', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '50%' }}>
                        <span style={{ marginLeft: 40, marginTop: '20vh', fontSize: '32px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#000000', lineHeight: '45px' }}>登录ONE ME</span>
                        <span style={{ marginLeft: 40, marginTop: 12, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#858585', lineHeight: '21px' }}>会员管理系统</span>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 40, marginTop: 68, width: 380, height: 52, background: '#F5F5F5', borderRadius: 26 }}>
                            <img style={{ width: 20, height: 20, marginLeft: 30 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/07526861d1e542f2b33ef0db2ac82f2e.png" />
                            <Input value={username} onChange={(e) => {
                                this.setState({ username: e.target.value })
                            }} style={{ margin: 0, padding: 0, width: 300, height: 45, marginLeft: 10 }} placeholder="请输入登录账号" bordered={false} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 40, marginTop: 31, width: 380, height: 52, background: '#F5F5F5', borderRadius: 26 }}>
                            <img style={{ width: 20, height: 20, marginLeft: 30 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/25a069cdf30143f8acf3626c702f3e3a.png" />
                            <Input.Password value={passwd} onChange={(e) => {
                                this.setState({ passwd: e.target.value })
                            }} style={{ margin: 0, padding: 0, paddingLeft: 10, width: 300, height: 45 }} placeholder="请输入密码" bordered={false} />
                        </div>

                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 60, marginTop: 15 }}>
                            <Checkbox
                                checked={remember}
                                onChange={this.handleRememberChanged}
                            >记住我</Checkbox>
                        </div> */}

                        <div onClick={this.handleLoginTapped} style={{ cursor: 'pointer', display: 'flex', marginLeft: 145, marginTop: 20, alignItems: 'center', justifyContent: 'center', width: 166, height: 52, background: '#FDD100', borderRadius: 26, cursor: 'pointer', fontSize: '18px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#000000', lineHeight: '25px' }}>
                            登录
                        </div>
                    </div>
                </div> 
            </div>
        );
    }

    renderVenueList() {
        return (
            <Spin spinning={this.props.loading || this.state.isLoading}>
            <div style={VenueListStyles.container}>
                <div style={VenueListStyles.header}>
                <Button onClick={() => { this.props.logoutUser(this) }} icon={<ExportOutlined />} style={{position: 'absolute', right: 30}} type="link">
                </Button>
                    
                    <span style={VenueListStyles.headerTitle}>请选择一个场馆</span>
                </div>

                <div style={VenueListStyles.venueList}>
                    {
                        this.props.venueList && this.props.venueList.map((item, index) => {
                            return (
                                <Card
                                    onClick={() =>{ this.selectedVenue(item.venueid) }}
                                    hoverable
                                    style={VenueListStyles.venueItem}
                                    cover={<div style={VenueListStyles.venueLogoBox}> <img style={VenueListStyles.venueLogo} src={item.venueposter} /> </div>}
                                >
                                    <Meta
                                        title={item.venuename}
                                        description={<div style={{display: 'flex', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden' }}>{item.venuebrief}</div>}
                                    />
                                </Card>
                            )
                        })
                    }

                    <Card
                        onClick={this.handleFastAddVenue}
                        hoverable
                        bodyStyle={VenueListStyles.plusBodyStyle}
                        style={{...VenueListStyles.venueItem, ...VenueListStyles.plusBodyStyle}}
                    >
                        <PlusOutlined style={{fontSize: 50, marginBottom: 25}} />
                        <Meta
                            title='添加一个场馆'
                        />
                    </Card>
                </div>

                <Modal
                    title="快速创建场馆"
                    visible={this.state.venueModalVisible}
                    onOk={this.handleFastCreateVenue}
                    onCancel={() => { this.setState({ venueModalVisible: false }); }}
                    okText="确认"
                    cancelText="取消"
                >
                    <AddVenueView 
                        isLoading={this.state.isLoading}
                        onRef={ ref => this.addVenueView = ref} 
                    />
                </Modal>
            </div>
            </Spin>
        );
    }

    render() {
        // debugger
        if (this.props.loginState && this.props.venueSelected) {
            const beforePathname = this.props.location.pathname;
            let redirectPathname;
            
            if (beforePathname === '/login' || !beforePathname) {
                if (this.props.user.authrole === kSuper_Admin_Auth) {
                    redirectPathname = PathConstant.BCG_ROOT_Name + '/' + MAIN_LISTS.TAGS_LIST.path;
                } else {
                    redirectPathname = PathConstant.BCG_ROOT_Name + '/' + MAIN_LISTS.OVER_VIEW.path;
                }
                
            } else {
                redirectPathname = beforePathname;
            }
            return <Redirect to={'/' + redirectPathname}/>;
        }

        if (this.props.loginState) {
            if (this.props.user.authrole === kSuper_Admin_Auth) {
                return <Redirect to={'/' + PathConstant.BCG_ROOT_Name + '/' + MAIN_LISTS.TAGS_LIST.path}/>;
            } else {
                if (this.props.venueSelected && this.props.venueSelected.venueid) {
                    return <Redirect to={'/' + PathConstant.BCG_ROOT_Name + '/' + MAIN_LISTS.OVER_VIEW.path}/>;
                } else {
                    return this.renderVenueList();
                }
            }
        } else {
            return this.renderLoginContent();
        }
        // }
    }
}

const mapStateToProps = ({ loginStore: { loginState, venueSelected, venueList, loading, user } }) => {
    return {
        loginState,
        venueSelected,
        venueList,
        loading,
        user
    };
};

const mapDispatchToProps = dispatch => ({
    login(accountname, passwd, remember) {
        dispatch(actions.loginRequest({ accountname, passwd, remember }));
    },
    logoutUser: params => {
        // 清空localStorage
        localStorage.clear();
        cookie.clear();
        dispatch(actions.logoutUser(params))
        params.props.history.push('/login');
    },
    queryVenueList() {
        dispatch(actions.queryVenueList({  }));
    },
    enterVenue(venueid) {
        dispatch(actions.enterVenueRquest({ venueid }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginController);