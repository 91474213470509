import React from 'react'
import { Select, Input, Switch, InputNumber, Button, Menu, message, Radio, Modal, Spin, Upload, Tag, Table } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import { priceFen, priceYuan } from '../../../util/helper/number-helper';
import { actions as courseActions, launchAddCourseCoach, launchGetCourseCoachList, launchSaveCourse, launchUpdateCourseCoachState } from '../../../redux/course-model';
import { actions as descActions } from '../../../redux/desc-model';
import DescEditView from '../../../view/common/desc-edit-view';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import CourseDetailEditController from './course-detail-edit-controller';
import FormItem from '../../../view/common/form-item';
import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { CourseSaveStyles } from '../../../style/page/course/course-save-styles';
import { uploadFileToQiniu, uploadProjectImageToQiniu } from '../../../util/request';
import DescShowView from '../../../view/desc/desc-show-view';
import { InfoCircleOutlined, PlusOutlined, UsergroupAddOutlined, PictureOutlined } from '@ant-design/icons';
import LLCDateHelper from 'date-helper';
import CoachSelectView from '../../../view/coach/coach-select-view';
import { launchGetUploadToken } from '../../../redux/upload-token-model';


const NAMESPACE = MAIN_LISTS.COURSE_LIST.children.SAVE_COURSE;

const Option = Select.Option;
const confirm = Modal.confirm;
const { TextArea } = Input;



class SaveCourseController extends BaseController {
    constructor(props) {
    super(props);

    let editCourse = {};
    let descItems = [];
    if (this.props.location.state && this.props.location.state.editCourse) {
        editCourse = this.props.location.state.editCourse;
        if (editCourse.coursedesc) {
            descItems = JSON.parse(editCourse.coursedesc);
        }
    }
    

    this.state = {
        ...this.state,

        dataInfo: {},
        coursename: '',
        coursebrief: '',
        courseposter: undefined,
        coursedetail: undefined,
        coursetime: undefined,
        maxpeople: undefined,
        minpeople: undefined,
        coursetype: undefined,
        cancelbefore: 2,
        bookbefore: 2,
        state: 2,

        uploadLoading: false,
        modalCoachShowing: false,

        descItems,

        descVisible: false,
        currentStep: '1',
        ...editCourse
    };

    let that = this;
    this.coachColumns = [
        { title: '姓名', width: 80, dataIndex: 'coachname', key: 'coachname', align: 'center' },
        { title: '手机号', width: 120, dataIndex: 'coachphone', key: 'coachphone', align: 'center' },
        // { title: '教练类型', width: 80, dataIndex: 'coachtype', key: 'coachtype', align: 'center', 
        //     render: coachtype => {
        //         let str = '-';
        //         if (coachtype === 1) str = '全职';
        //         else if (coachtype === 2) str = '兼职';
        //         return <span>{str}</span> 
        //     }
        // },
        { title: '状态', width: 120, dataIndex: 'state', key: 'state', align: 'center', render: 
            state => {
                if (state === 1) {
                    return (
                        <Tag color='#87d068'>正常</Tag> 
                    )
                } else if (state === 110) {
                    return (
                        <Tag color='#f50' >冻结</Tag> 
                    )
                }
                
            // <Tag color={state===1 ? '#87d068' : '#f50'} >{state === 1 ? '在职' : '离职'}</Tag> 
        }},
        // { title: '入职时间', width: 150, dataIndex: 'entrytime', key: 'entrytime', align: 'center', render: entrytime => <span>{ entrytime ? LLCDateHelper.formatDate(entrytime) : '-'}</span> },
        { title: '操作', width: 80, key: 'operate', align: 'center', 
            render: (coach) => {
                return (
                    <a 
                        href="javascript:void(0)" 
                        onClick={() => {
                           that.requestUpdateCourseCoachState(coach);
                        }
                    }> {coach.state === 1 ? '解绑' : '恢复'}</a> 
                );
            }
        }
    ];

    this.requestGetCourseCoachList = this.requestGetCourseCoachList.bind(this);
    this.requestUpdateCourseCoachState = this.requestUpdateCourseCoachState.bind(this);

    this.beforeUpload = this.beforeUpload.bind(this);
    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleCourseTypeChange = this.handleCourseTypeChange.bind(this);
    this.handleEditCourseDetail = this.handleEditCourseDetail.bind(this);
    this.handleAddCoachTapped = this.handleAddCoachTapped.bind(this);
    this.handleCoachChanged = this.handleCoachChanged.bind(this);
    
    this.saveCourse = this.saveCourse.bind(this);

    this.renderForm = this.renderForm.bind(this);

    this.checkAndUploadDesc = this.checkAndUploadDesc.bind(this);
    this.uploadDescPictures = this.uploadDescPictures.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderBaseInfo = this.renderBaseInfo.bind(this);
    this.renderCoachList = this.renderCoachList.bind(this);
    this.renderDesc = this.renderDesc.bind(this);
}

    requestAddCourseCoach(coach) {
        let { courseid } = this.state;
        
        this.changeLoadingState(true);
        launchAddCourseCoach(
            { courseid, coachid: coach.coachid },
            res => {

                 this.setState({ 
                    modalCoachShowing: false
                });
                
                this.changeLoadingState(false);
                this.requestGetCourseCoachList();
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        )
    }

    requestGetCourseCoachList() {
        let { courseid } = this.state;
        this.changeLoadingState(true);
        launchGetCourseCoachList(
            { courseid },
            res => {

                this.setState({
                    dataInfo: res
                });

                this.changeLoadingState(false);
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        )
    }

    requestUpdateCourseCoachState(cc) {
        let params = { id: cc.id, };
        if (cc.state === 1) {
            params.state = 110;
        } else {
            params.state = 1;
        }
        this.changeLoadingState(true);
        launchUpdateCourseCoachState(
            params,
            res => {

                message.success('修改成功');

                this.changeLoadingState(false);
                this.requestGetCourseCoachList();
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        )
        
    }

    handleSaveButtonClick() {
        if (this.checkFormNotHasEmptyItem()) {

            let that = this;
            confirm({
                title: '确认提交？',
                content: '',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    that.checkAndUploadDesc();
                    // that.saveCourse();
                },
                onCancel() {
                
                },
            });
        }
    }

    handleTypeChange(type) {
        this.setState({
            type
        });
    }

    handleCourseTypeChange(coursetype) {
        this.setState({
            coursetype
        });
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
        message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 2;
        if (!isPassSizeLimit) {
        message.error('图片大小需小于2Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file,
                2,
                res => {
                    this.setState({ 
                        courseposter: res.result.url,
                        uploadLoading: false
                    });
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }

    handleEditCourseDetail() {
        console.log(this.props.descid);
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.DESC_EDIT.path}`);
    }

    handleAddCoachTapped() {
        this.setState({ modalCoachShowing: true });
    }

    handleCoachChanged(coach) {
        // this.setState({ 
        //     coach,
        //     modalCoachShowing: false
        // });
        this.requestAddCourseCoach(coach);
    }

   
    // 上传场馆详情图片
    checkAndUploadDesc() {
        this.changeLoadingState(true);
        let editDescPictures = this.descEditView.getEditPictures();
        
        let descPicNames = [];
        for (let key in editDescPictures) {
            descPicNames.push(editDescPictures[key].file.name);
        }

        if (editDescPictures && editDescPictures.length > 0) {
            launchGetUploadToken(
                { type: 6, files: descPicNames },
                res => {
                    this.uploadDescPictures(res.pathList, (result) => {
                        if (result) {
                            this.saveCourse();
                        } else {
                            this.changeLoadingState(false);
                            message.error('图片上传失败，请重试');
                        }
                    });
                },
                fail => {
                    message.error(fail);
                    this.changeLoadingState(false);
                } 
            );  
        } else {
            this.saveCourse();
        }
    }

    // 上传详情图片
    uploadDescPictures(pathList, callback = () => {}) {

        let editDescPictures = this.descEditView.getEditPictures();
        let descItems = this.descEditView.getDescItems();
        
        for (let key = 0; key < editDescPictures.length; key++) {
            let picture = editDescPictures[key];

            if (!picture.customFlag) {

                let file = picture.file;

                let aPath = pathList[key];
                
                uploadFileToQiniu(
                    file,
                    aPath,
                    res => {
                        
                        descItems[picture.index].itemurl = aPath.url;
                        descItems[picture.index].itemkey = aPath.key;
                        descItems[picture.index].size = file.size;
                        picture.customFlag = 1;
                        this.descEditView.updateDescItems(descItems);
                        console.log(file, '上传成功一个图片');
                        if (key === editDescPictures.length-1) {
                            this.descEditView.updateEditPictures([]);
                            callback(true);
                        } else {
                            console.log('下一个');
                            this.descEditView.updateEditPictures(editDescPictures);
                            this.uploadDescPictures(pathList, callback);
                        }
                    },
                    fail => {
                        console.log(fail);
                        callback(false);
                    }
                );    

                break;
            } else {
                if (key === editDescPictures.length-1) {
                    callback(true);
                }
            }  
        }
    }

    checkFormNotHasEmptyItem = () => {
        if (isEmpty(this.state.coursename)) {
            message.error('请填写课程名称');
            return false;
        }
        if (!this.state.coursetype) {
            message.error('请选择课程类型');
            return false;
        }
        if (!this.state.type) {
            message.error('请选择课程类型');
            return false;
        }
        if (this.state.coursetime == null) {
            message.error('请填写课程时长');
            return false;
        }
        if (this.state.type === 1 && isEmpty(this.state.maxpeople)) {
            message.error('请填写最多容纳人数');
            return false;
        }

        if (this.state.cardtype === 2 && this.state.cardtimes == null) {
            message.error('请填写次卡次数');
            return false;
        }
        if (this.state.canexperience && !this.state.experienceprice) {
            message.error('请填写体验课价格');
            return false;
        }
        
        return true;
    }

    saveCourse = () => {
        const params = {
            courseid: this.state.courseid,
            coursename: this.state.coursename,
            coursebrief: this.state.coursebrief,
            courseposter: this.state.courseposter,
            coursedetail: this.props.descid,
            coursetime: this.state.coursetime,
            maxpeople: this.state.maxpeople,
            minpeople: this.state.minpeople,
            coursetype: this.state.coursetype,
            type: this.state.type,
            cancelbefore: this.state.cancelbefore,
            bookbefore: this.state.bookbefore,
            state: this.state.state,
            canexperience: this.state.canexperience,
            experienceprice: this.state.experienceprice,
            whenpeople: this.state.whenpeople,
            addcommission: this.state.addcommission,
            descList: this.descEditView.getDescItems()
        };

        this.changeLoadingState(true);
            launchSaveCourse(
                params,
                response => {
                    message.success(response.msg);
                    this.changeLoadingState(false);

                    this.props.dispatch(courseActions.queryCourseList({}));
                    this.props.history.goBack();
                },
                msg => {
                    message.error(msg);
                    this.changeLoadingState(false);
                }
            )
    }

    renderForm() {
        let { courseid, dataInfo } = this.state;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{width: '100%', paddingLeft: 24, paddingRight: 24, marginBottom: 20}}>
                    <Menu onClick={(e) => { 

                        if (e.key === '2' && (!dataInfo.list || dataInfo.list.length === 0)) {
                            this.requestGetCourseCoachList();
                        }

                        this.setState({ currentStep: e.key }) 

                    }} selectedKeys={[this.state.currentStep]} mode="horizontal">
                        <Menu.Item key="1"> <InfoCircleOutlined /> 基础信息 </Menu.Item>
                        {
                            courseid ?
                                <Menu.Item key="2"> <UsergroupAddOutlined /> 教练列表 </Menu.Item>
                                :
                                null
                        }

                        {
                            courseid ?
                                <Menu.Item key="3"> <PictureOutlined /> 课程详情 </Menu.Item>
                                :
                                null
                        }
                        
                    </Menu>
                </div>

                {
                    this.renderContent()
                }
            </div>
        );
    }

    renderContent() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {
                    this.renderBaseInfo()
                }

                {
                    this.renderCoachList()   
                }

                {
                    this.renderDesc()   
                }
                
            </div>
        );
    }

    renderBaseInfo() {
        const uploadButton = (
            <div style={CourseSaveStyles.uploadButton}>
                <PlusOutlined />
                <div> 上传</div>
            </div>
        );

        return (
            <div style={{display: this.state.currentStep === '1' ? 'flex' : 'none', flexDirection: 'column'}}>
                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程名称 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Input
                            allowClear
                            style={{...FormStyles.input_middle, width: 200}}
                            placeholder="请填写课程名称"
                            value={this.state.coursename}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ coursename: inputText })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程类型 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Select
                            style={{...CourseSaveStyles.cardType_Select, width: 200}}
                            placeholder="请选择课程类型"
                            value={this.state.type}
                            onChange={this.handleTypeChange}
                            >
                            <Option value={1}>团课</Option>
                            <Option value={2}>私教课</Option>
                        </Select>
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程级别 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Select
                            style={{...CourseSaveStyles.cardType_Select, width: 200}}
                            placeholder="请选择"
                            value={this.state.coursetype}
                            onChange={this.handleCourseTypeChange}
                            >
                            <Option value={1}>初级</Option>
                            <Option value={2}>中级</Option>
                            <Option value={3}>高级</Option>
                        </Select>
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程时长(分) :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                            min={0}
                            value={this.state.coursetime}
                            onChange={number => {
                                this.setState({ coursetime: number })
                            }}
                        />
                    </div>
                </div>

                {
                    this.state.type === 1 ?
                        <div style={FormStyles.formItem}>
                            <div style={FormStyles.leftBox}>
                                <span>可容纳人数 :</span>
                            </div>
                            <div style={FormStyles.rightBox}>
                                <InputNumber
                                    min={0}
                                    value={this.state.maxpeople}
                                    onChange={number => {
                                        this.setState({ maxpeople: number })
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                }

                {
                    this.state.type === 1 ?
                        <div style={FormStyles.formItem}>
                            <div style={FormStyles.leftBox}>
                                <span>开课最少人数 :</span>
                            </div>
                            <div style={FormStyles.rightBox}>
                                <InputNumber
                                    min={0}
                                    value={this.state.minpeople}
                                    onChange={number => {
                                        this.setState({ minpeople: number })
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                }

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>提前取消(时) :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                            min={0}
                            value={this.state.cancelbefore}
                            onChange={number => {
                                this.setState({ cancelbefore: number })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>提前预约(时) :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                            min={0}
                            value={this.state.bookbefore}
                            onChange={number => {
                                this.setState({ bookbefore: number })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>是否可体验:</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Switch 
                            checkedChildren="是"
                            unCheckedChildren="否"
                            checked={this.state.canexperience}
                            onChange={(checked) => {
                                if (checked) {
                                    this.setState({canexperience: 1});
                                } else {
                                    this.setState({canexperience: 0});
                                }
                        }}/>
                    </div>
                </div>

                {
                    this.state.canexperience ?
                        <div style={FormStyles.formItem}>
                            <div style={FormStyles.leftBox}>
                                <span>体验价格(元):</span>
                            </div>
                            <div style={FormStyles.rightBox}>
                                <InputNumber
                                    min={0}
                                    placeholder='请输入体验价格'
                                    style={FormStyles.input_middle}
                                    value={this.state.experienceprice ? priceYuan(this.state.experienceprice) : undefined}
                                    onChange={number => {
                                        this.setState({ experienceprice: priceFen(number) })
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                }

                <div style={{...FormStyles.formItem, alignItems: 'center'}}>
                    <div style={FormStyles.leftBox}>
                        <span>约课人数大于:</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                            min={0}
                            placeholder='请输入人数'
                            style={FormStyles.input_middle}
                            value={this.state.whenpeople ? this.state.whenpeople : undefined}
                            onChange={number => {
                                this.setState({ whenpeople: number })
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: 5 }}>
                        <span>时，增加提成（元）：</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                            min={0}
                            placeholder='请输入增加的提成金额'
                            style={FormStyles.input_middle}
                            value={this.state.addcommission ? priceYuan(this.state.addcommission) : undefined}
                            onChange={number => {
                                this.setState({ addcommission: priceFen(number) })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程简介 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <TextArea 
                            placeholder="请填写课程简介" 
                            style={CourseSaveStyles.addressInput}
                            autoSize={{ minRows: 2, maxRows: 6 }} 
                            value={this.state.coursebrief}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ coursebrief: inputText })
                            }}
                        />
                    </div>
                </div>

                {/* <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程介绍 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <a onClick={this.handleEditCourseDetail} >编辑</a>

                    {
                        this.props.descid ?
                            <a style={CourseSaveStyles.previewButton} onClick={ () => {this.setState({ descVisible: true })} } >预览</a>
                            :
                            null
                    }
                    </div>
                </div> */}

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>课程海报 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            onChange={info => {
                                this.handleUploadChange(info);
                            }}   
                        >
                            {this.state.courseposter ? <img style={{width: 100, height: 100, objectFit: 'cover'}} src={this.state.courseposter} alt="avatar" /> : uploadButton}
                        </Upload>
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>状态 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Radio.Group
                        className="radio"
                        onChange={e => this.setState({ state: e.target.value })}
                        value={this.state.state}
                        >
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>冻结</Radio>
                        <span style={FormStyles.hintText}>（冻结的课程不会在前端展示）</span>
                        </Radio.Group>
                    </div>
                </div>

                {/* <div style={CourseSaveStyles.operateBox}>
                <Button
                    className="add-manager-button"
                    type="primary"
                    onClick={this.handleSaveButtonClick}
                >提交</Button>
                </div> */}
            </div>
        );
    }

    renderCoachList() {
        let { dataInfo } = this.state;
        return (
            <div style={{display: this.state.currentStep === '2' ? 'flex' : 'none', width: '100%'}}>
                <Table
                    style={{ width: '100%' }}
                    rowKey={record => record.coachid}
                    // loading={this.state.isLoading}
                    columns={this.coachColumns}
                    dataSource={dataInfo.list}
                    scroll={{ 
                        // x: 1200, 
                        // y: 485 
                    }}
                    pagination={{
                        total: dataInfo.totalpage * dataInfo.pagesize,
                        pageSize: dataInfo.pagesize,
                        current: dataInfo.page,
                        onChange: val => {
                            this.queryTagList(val)
                        }
                    }}
                ></Table>
                
            </div>
        );
    }

    renderDesc() {
        return (
            <div style={{display: this.state.currentStep === '3' ? 'flex' : 'none'}}>
                
                <DescEditView onRef={ ref => this.descEditView = ref} desc={this.state.descItems ? this.state.descItems : undefined} />
            </div>
        );
    }

    renderNavHeader() {
        let { currentStep } = this.state;
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                {
                    currentStep === '1' || currentStep === '3' ?
                        <Button type="primary" size="small" onClick={this.handleSaveButtonClick}>提交</Button>
                        :
                        null
                }
                {
                    currentStep === '2' ?
                        <Button type="primary" size="small" onClick={this.handleAddCoachTapped}>添加教练</Button>
                        :
                        null
                }
                
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        console.log(this.props.descid, 'render');
// {/* <Route path={`${this.props.match.url}/${NAMESPACE.path}`} component={CourseDetailEditController} /> */}
        return (
            
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }


                <div style={TableStyles.tableBox}>
                    {
                        this.renderForm()
                    }
                </div>

                <Modal
                    visible={this.state.descVisible}
                    onCancel={() => { this.setState({ descVisible: false }) }}
                    destroyOnClose={true}
                    footer={null}
                    width={"456px"}
                    bodyStyle={{ padding: "48px" }}
                >
                    <DescShowView
                        descid={this.props.descid}
                        type={1} 
                    />
                </Modal>

                <Modal
                    centered={true}
                    loading={this.state.isLoading}
                    visible={this.state.modalCoachShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCoachShowing: false }) }}
                    footer={null}
                >
                    <CoachSelectView
                        onRecordSelect={this.handleCoachChanged} />
                </Modal>
            </div>
            </Spin>
        )
    }
}

const mapStateToProps = store => {
    const descStore = store['descStore'];
    const utilStore = store['utilStore'];
  
    return {
        descid: descStore.descid,
        // isShow: utilStore.isShow[PathConstant.SAVE_COURSE.path]
    }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(SaveCourseController)