import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AppointPrivate extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="私教预约" />

                <SectionTitle text="一.查看私教预约情况" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/b0eae83676fd481da6e66058d9c5858e.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“私教预约”后，在右侧就会展示当前场馆的所有私教预约列表。</span>} />

                <SectionTitle text="二.后台为学员添加私教预约" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/9eae89e7b0d140078adb51bbabb8f5b5.png" />
                <NormalContent text={<span>1.在私教预约页面，点击右上角的“<Highlight text="新增约课" />”按钮，弹出预约信息填充区域。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/913f79cf5d354dbab1daf1b1ada94e89.png" />
                <NormalContent text={<span>2.点击“选择会员课程”，会展示当前场馆所有会员购买的私教课，选中想要预约的会员私教课后，点击“确定”。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/9cce2925eef347f28f6ab14fc967cfd1.png" />
                <NormalContent text={<span>3.点击教练框，可以为此次私教课预约更换教练。</span>} />
                <NormalContent text={<span>4.点击上课日期，选择此次私教课预约的上课日期。</span>} />
                <NormalContent text={<span>5.在日期选择完成后，下方会出现所选教练在这一天的时间安排。其中绿色区域为可选时间段，点击绿色区域后，可以选择具体上课时间。</span>} />
                <NormalContent text={<span>6.点击提交，提示更新成功后，完成会员私教课预约。</span>} />

                <SectionTitle text="三.处理私教预约" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/e4663a6481c546cfaf5f9ac6760e95d9.png" />
                <NormalContent text={<span>1.如图所示，每个待上课的私教预约记录，都可以进行：取消、改时间、换教练操作。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointPrivate);
