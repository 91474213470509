import { call, put, takeLatest } from 'redux-saga/effects';
import { handleActions, createAction } from 'redux-actions';
import * as Apis from '../constants/apiConstants';
import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants";
import { launchPOSTRequest } from '../util/request';

export async function launchGetUploadToken(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.GET_FILE_UPLOADTOKENS, params);
        if (
            response.responseData.succ === RESPONSE_CODE.success &&
            response.responseData.result.status === SERVICE_CODE.Successed
            ) {
              success(response.responseData.result);
            } else {
              failed(response.msg);
            }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}

