import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AddCoach extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="场馆添加教练" />

                <SectionTitle text="一.教练登录ONE ME小程序，成为教练" />
                <PictureList pictureList={[
                    <PictureItem src="http://qiniu-media.soso-code.com/oneme/pic/help/93f15e89d9724dd6a0736155ce043a40.jpg" width={200} style={{ marginRight: 200 }} />,
                    <PictureItem src="http://qiniu-media.soso-code.com/oneme/pic/help/78332a233eaf4991b813d8e69a5cbd28.png" width={200} />,
                ]} />
                <NormalContent text={<span>1.告知教练，扫码（或者微信小程序搜索“ONE ME平台”）进入<Highlight text="ONE ME平台" />小程序。</span>} />
                <NormalContent text={<span>2.登录后，点击下方“<Highlight text="我的" />”一栏，进入我的页面。点击“<Highlight text="教练认证" />”，填写信息进行认证教练。</span>} />
                <NormalContent text={<span>3.提交认证申请后，<Highlight text="2个小时内" />获得审核结果，请认真填写实名认证信息，以保证顺利通过教练认证。</span>} />
                <NormalContent text={<span>4.教练（姓名，照片）将展现在场馆详情页。个人信息如：实名信息和简历信息，不会显示在平台和场馆内部。</span>} />

                <SectionTitle text="二.邀请教练" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/a5137f51b68d45789c514f2aecdb0d70.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“教练管理”后，在右侧就会展示当前场馆的教练列表。</span>} />
                <NormalContent text={<span>2.在教练管理页面，点击右上角的“<Highlight text="邀请教练" />”，弹出教练搜索页面。</span>} />
                <NormalContent text={<span>3.邀请教练时，只能通过输入<Highlight text="完整的教练手机号" />来查找教练。点击“邀请加入后”，告知教练进入<Highlight text="ONE ME平台" />小程序 - “我的”页面，接受邀请。</span>} />
                <NormalContent text={<span>4.只有通过第一步<Highlight text="教练认证" />后的人员，才能被搜索到，否则无法被邀请进入场馆。</span>} />
                
                <SectionTitle text="三.教练接受邀请" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/1009c503d76d498d986005d59d92d2d7.png" width={200} />
                <NormalContent text={<span>1.教练进入<Highlight text="ONE ME平台" />小程序 - “我的”页面，接受邀请后，即成为场馆教练。</span>} />

            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCoach);
