import React from 'react';
import { Table, Spin, Upload, Modal, Mentions, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { BrowserRouter as Router,Route} from 'react-router-dom';
import { actions as queryActions } from '../../../redux/query-model'
import { filterParams } from '../../../util/helper/request-helper';
import { MAIN } from '../../../constants/routeConstants';
import FormItem from '../../../view/common/form-item';
import BaseController from '../../base/base-controller';
import AppointCourseExperienceView from '../../../view/course-experience/appoint-course-experience-view';
import ChangeCoachForCourseView from '../../../view/appoint-private/change-coach-for-course-view';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../style/common-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { 
    launchGetCoursesExperienceList, launchCancelCoursesExperience, 
    launchBreakCoursesExperience, launchFinishCoursesExperience, 
    launchAppointCoursesExperience, launchContactCoursesExperience 
} from '../../../redux/course-experience-model';
import { PlusOutlined } from '@ant-design/icons';

let { confirm } = Modal;

const MAIN_LISTS = MAIN.children;
const NAMESPACE = MAIN_LISTS.COURSE_EXPERIENCE;

class CourseExperienceListController extends BaseController {
    constructor(props) {
        super(props);

        let showBack = undefined;
        if (props.location.state) {
            showBack = props.location.state.showBack;
        }

        this.state = {
            ...this.state,
            dataInfo: {},
            showBack,

            queryParams: {},

            modalAppointShowing: false
        };

        let that = this;
        
        this.columns = [
            { title: '用户', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '10%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '课程', width: '15%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '教练', width: '10%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '类型', width: '10%', dataIndex: 'coursetype', key: 'coursetype', align: 'center', 
                render: type => { 
                    let typeStr = '-';
                    let colorStr = '';
                    switch (type) {
                        case 1:
                            typeStr = '团课';
                            colorStr = 'magenta';
                            break;
                        case 2:
                            typeStr = '私教课';
                            colorStr = 'orange';
                            break;
                        default:
                            typeStr = '-';
                    }
                    return <Tag color={colorStr}>{typeStr}</Tag>
                } 
            },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
                render: state => {
                    if (state === 1) {
                        return <Tag color='red'>待沟通</Tag>;
                    } else if (state === 2) {
                        return <Tag color='blue'>待上课</Tag>;
                    } else if (state === 5) {
                        return <Tag color='green'>已完成</Tag>;
                    } else if (state === 10) {
                        return <Tag>已失约</Tag>;
                    } else if (state === 15) {
                        return <Tag>已取消</Tag>;
                    } else {
                        return null;
                    }
                }
            },
            { title: '上课时间', width: '10%', align: 'center', 
                render: experience => {
                    if (experience.daydate) {
                        return <span>{`${experience.daydate} ${experience.begindate}`}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
            { title: '操作', width: '25%', key: 'operate', align: 'center', 
                render: experience => {
                    return (
                        <div>
                            {
                                experience.state < 5 ?
                                    <a 
                                        onClick={(e) => { 
                                            that.contactExperience(experience); 
                                        }}
                                    >已沟通</a>
                                    :
                                    null
                            }

                            {
                                experience.state < 5 ?
                                    <a 
                                        style={{ marginLeft: 15 }}
                                        onClick={(e) => { 
                                            that.finishExperience(experience); 
                                        }}
                                    >完成</a>
                                    :
                                    null
                            }
                            
                            {
                                experience.state < 5 ?
                                    <a 
                                        style={{ marginLeft: 15 }}
                                        onClick={(e) => { 
                                            that.breakExperience(experience); 
                                        }} 
                                    >失约</a>
                                    :
                                    null
                            }

                            {
                                experience.state < 5 ?
                                    <a 
                                        style={{ marginLeft: 15 }}
                                        onClick={(e) => { 
                                            that.setState({
                                                editAppoint: experience,
                                                modalAppointShowing: true
                                            });
                                        }} 
                                    >指定时间/教练</a>
                                    :
                                    null
                            }

{
                                experience.state < 5 ?
                                    <a
                                        style={{ marginLeft: 15 }}
                                        onClick={(e) => { 
                                            that.cancelExperience(experience); 
                                        }} 
                                    >取消</a>
                                    :
                                    null
                            }
                        </div>
                    );
                }
            },
        ];

        this.cancelExperience = this.cancelExperience.bind(this);
        this.breakExperience = this.breakExperience.bind(this);
        this.contactExperience = this.contactExperience.bind(this);
        this.finishExperience = this.finishExperience.bind(this);
        this.handleAppointPrivate = this.handleAppointPrivate.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

    }

    componentDidMount() {
        let queryParams = this.queryParams();

        this.queryCourseExperienceList(queryParams.page ? queryParams.page : 1, queryParams);
    }

    componentWillUnmount() {
        this.props.configQueryParams({});
    }

    queryCourseExperienceList(page, extra) {

        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }

        queryParams.page = page;

        this.changeLoadingState(true);
        launchGetCoursesExperienceList(
            filterParams(queryParams),
            res => {
                this.setState({ dataInfo: res });
                
                this.changeLoadingState(false);
            },  
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    contactExperience(experience) {
        
        let that = this;
        confirm({
            title: `确定要已沟通完成 ${experience.username} 的体验课： ${experience.coursename} ？`,
            content: '请确认已经和用户沟通妥善。',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchContactCoursesExperience(
                    { id: experience.id },
                    res => {
                        message.success('更新成功');
                        that.queryCourseExperienceList();
                    },
                    fail => {
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    cancelExperience(experience) {
        let that = this;
        confirm({
            title: `确定要取消 ${experience.username} 的体验课： ${experience.coursename} ？`,
            content: '请确认已经和用户沟通妥善。',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchCancelCoursesExperience(
                    { id: experience.id },
                    res => {
                        message.success('取消成功');
                        that.queryCourseExperienceList();
                    },
                    fail => {
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    finishExperience(experience) {
        let that = this;
        confirm({
            title: `确定要完成 ${experience.username} 的体验课： ${experience.coursename} ？`,
            content: '请确认用户已经完成到店上课。',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchFinishCoursesExperience(
                    { id: experience.id },
                    res => {
                        message.success('更新成功');
                        that.queryCourseExperienceList();
                    },
                    fail => {
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    breakExperience(experience) {
        let that = this;
        confirm({
            title: `确定 ${experience.username} 失约体验课： ${experience.coursename} ？`,
            content: '请谨慎操作，失约只适用于用户无故旷课。如：在提前约好的时间前提下，没有联络没有到店。',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchBreakCoursesExperience(
                    { id: experience.id },
                    res => {
                        message.success('更新成功');
                        that.queryCourseExperienceList();
                    },
                    fail => {
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    handleAppointPrivate(appoint) {
        
        this.changeLoadingState(true);
        launchAppointCoursesExperience(
            { ...appoint },
            res => {
                // this.changeLoadingState(false);
                message.success('添加成功');
                this.setState({ modalAppointShowing: false, editAppoint: undefined })
                this.queryCourseExperienceList(this.state.dataInfo.page);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    handleSearchClick() {
        this.queryCourseExperienceList()
    }

    
    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    renderSearchBar() {
        let { types, categories } = this.state;
        let queryParams = this.queryParams();

        let beginTime = "";
        let endTime = "";
        let timeValue = [beginTime, endTime];
        
        if (queryParams.beginTime) {
            let convert = LLCDateHelper.formatDate(queryParams.beginTime);
            timeValue[0] = moment(convert, 'YYYY-MM-DD');
        }
        if (queryParams.endTime) {
            let convert = LLCDateHelper.formatDate(queryParams.endTime);
            timeValue[1] = moment(convert, 'YYYY-MM-DD');
        }

        return (
            <div style={TableSearchStyles.searchBox}>
                <div style={TableSearchStyles.searchIputBox}>

                    <FormItem 
                        mode="query" itemKey="用户：" type="input" itemPlaceHolder="请输入用户名"
                        itemValue={queryParams.username} 
                        onValueChanged={(username) => {
                            queryParams.username = username;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="手机号：" type="input" itemPlaceHolder="请输入手机号"
                        itemValue={queryParams.phone} 
                        onValueChanged={(phone) => {
                            queryParams.phone = phone;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="上课时间：" type="range-time" 
                        itemValue={timeValue} showTime={false} 
                        onValueChanged={(beginTime, endTime) => {
                            queryParams.beginTime = beginTime;
                            queryParams.endTime = endTime;
                            
                            this.props.configQueryParams(queryParams);
                        }} />

                </div>

                <div style={TableSearchStyles.operateBox}>
                    <div style={TableSearchStyles.searchButtonContainer}>
                        <Button
                            style={TableSearchStyles.searchButton}
                            type="primary"
                            onClick={this.handleSearchClick}>搜索</Button>
                        <Button
                            style={TableSearchStyles.clearButton}
                            onClick={this.handleClearClick}>清空</Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        let { showBack } = this.state;

        let headerParams = {
            title: NAMESPACE.name,
            extra: <div>
                <Button onClick={ () => { this.setState({ modalAppointShowing: true }) } } type="primary" size="small" icon={<PlusOutlined />}>新增约课</Button>
            </div>,
        };
        if (showBack) {
            headerParams = {
                title: NAMESPACE.name,
                extra: <div>
                    <Button onClick={ () => { this.setState({ modalAppointShowing: true }) } } type="primary" size="small" icon={<PlusOutlined />}>新增约课</Button>
                </div>,
                onBack: () => {
                    this.props.history.goBack();
                }
            };
        }
        
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
            {
                this.renderPageHeader(headerParams)
            }

            <div style={TableStyles.tableBox}>
                {
                    this.renderSearchBar()
                }

                <Table
                    rowKey={record => record.id}
                    columns={this.columns}
                    dataSource={this.state.dataInfo.list}
                    scroll={{ 
                        // x: 1200, 
                        // y: 450
                    }}
                    pagination={{
                        total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                        pageSize: this.state.dataInfo.pagesize,
                        current: this.state.dataInfo.page,
                        onChange: val => {
                            this.queryCourseExperienceList(val)
                        }
                    }}
                ></Table>
            </div>

            <Modal
                title="体验课预约"
                centered={true}
                visible={this.state.modalAppointShowing}
                destroyOnClose={true}
                width={"956px"}
                onCancel={() => { this.setState({ modalAppointShowing: false, editAppoint: undefined }) }}
                footer={null}
            >
                <AppointCourseExperienceView 
                    appoint={this.state.editAppoint}
                    isLoading={this.state.isLoading}
                    onSubmit={this.handleAppointPrivate}
                />
            </Modal>

            {/* <Modal
                title="授课教练"
                visible={this.state.modalChangeCoachShowing}
                destroyOnClose={true}
                width={500}
                onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                footer={null}
            >
                <ChangeCoachForCourseView 
                    onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                    onOk={this.handleChangeCoach}
                    isLoading={this.state.isLoading}
                    appoint={this.state.editAppoint}
                />
            </Modal> */}
        </div>
        </Spin>
        );
    }
}

const mapStateToProps = ({ queryStore }) => {
    
    return {
        queryStore
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseExperienceListController);


