import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, Modal } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { isEmpty } from '../../util/helper/string-helper';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import CoachSelectView from '../../view/coach/coach-select-view';

/**
 * 换绑教练
 */
export default class ChangeCoachView extends Component {
    constructor(props) {
        super(props);

        let course = props.course;
        
        this.state = {
            course,

            modalCoachShowing: false
        };

        this.handleCoachChanged = this.handleCoachChanged.bind(this);
    }

    handleCoachChanged(coach) {
        this.setState({ 
            coach,
            modalCoachShowing: false
        });
    }

    render() {
        let { vip } = this.state;
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
               
                <div style={VipUserSaveStyles.baseInfoItem}>
                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                        <span>绑定教练：</span>
                    </div>

                    <div onClick={() => { this.setState({ modalCoachShowing: true }) }}>
                        <Select
                            style={FormStyles.input_middle}
                            placeholder="请选择要绑定的教练"
                            showArrow={false}
                            value={this.state.coach ? this.state.coach.username : undefined}
                            dropdownStyle={{ display: "none" }}>
                        </Select>
                    </div>
                </div>

                <div style={{...styles.operateBox, marginTop: 20}}>
                    <Button onClick={ () => { this.props.onCancel && this.props.onCancel()} } >取消</Button>
                    <Button 
                        onClick={ () => { 
                            if (!this.state.coach) {
                                message.error('请选择教练');
                                return;
                            }

                            this.props.onOk && this.props.onOk({
                                coach: this.state.coach,
                            })} 
                        } 
                        style={{ marginLeft: 20 }} 
                        type="primary">确认</Button>
                </div>

                <Modal
                    centered={true}
                    visible={this.state.modalCoachShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCoachShowing: false }) }}
                    footer={null}
                >
                    <CoachSelectView 
                        onRecordSelect={this.handleCoachChanged} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}