export const HomeStyles = {
    container: { 
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        width: '100%', 
        position: 'relative', 
        flexShrink:0
    },

    areaBox: {
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        position: 'relative', 
        width: '100%', backgroundColor: '#fff', 
        flexShrink:0
    },
    areaTitle: {
        fontSize: '32px',
        fontFamily: 'Jdahei',
        color: '#000000',
        lineHeight: '37px',
        fontWeight: 'bold'
    },
    serviceItem: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
        position: 'relative', 
        width: '100%', height: 322,
        backgroundColor: '#fff', 
        flexShrink:0
    },
    serviceItemLeft: {
        marginRight: 74,
        display: 'flex', position: 'absolute',
        position: 'relative', 
        width: 494, height: 322,
        backgroundColor: '#fff',
        flexShrink:0
    },
    serviceItemRight: {
        marginLeft: 74,
        display: 'flex',
        position: 'relative', 
        width: 494, height: 322,
        backgroundColor: '#fff', 
        flexShrink:0
    },
    serviceItemName: {
        fontSize: '30px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 500,
        color: '#000000',
        lineHeight: '42px'
    },
    serviceItemDesc: {
        width: 350,
        fontSize: '14px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 400,
        color: '#515151',
        lineHeight: '30px'
    },
    productBox: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
        width: 1124, 
        marginTop: 98
    },
    productItem: {
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    },
    productItemIcon: {
        width: 116,
        height: 116
    },
    productItemName: {
        marginTop: 28,
        marginBottom: 17,
        fontSize: '22px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 500,
        color: '#000000',
        lineHeight: '30px'
    },
    productItemDesc: {
        fontSize: '15px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 400,
        color: '#515151',
        lineHeight: '30px'
    },
    funcBox: {
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center',
        width: 1124, 
        marginTop: 82
    },
    funcItem: {
        marginBottom: 56,
        display: 'flex', flexDirection: 'column',
        width: 165
    },
    funcLogo: {
        width: 50,
        height: 50,
        objectFit: 'contain'
    },
    funcItemName: {
        marginTop: 22,
        marginBottom: 14,
        fontSize: '18px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 500,
        color: '#000000',
        lineHeight: '25px'
    },
    funcItemDesc: {
        width: 165,
        fontSize: '15px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 400,
        color: '#515151',
        lineHeight: '30px'
    }
}