import React from 'react'

import { Select, Input, Button, Modal, Radio, Upload, message, Card, Menu } from 'antd'
import { Link } from 'react-router-dom';
import Zmage from 'react-zmage';
import { connect } from 'react-redux'
import { launchGetUploadToken } from '../../../redux/upload-token-model';
import FormItem from '../../../view/common/form-item';
import TagSelectView from '../../../view/tags/tag-select-view';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { launchSaveTag } from '../../../redux/tag-model';
import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';
import { uploadProjectImageToQiniu, uploadFileToQiniu } from '../../../util/request';
import { launchGetUserAuthDetail, launchPassAuth, launchRejectAuth } from '../../../redux/user-auth-model';
import { InfoCircleOutlined, FileOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.USER_AUTH_LIST.children.EDIT;

const { Option } = Select;
const confirm = Modal.confirm;

class UserAuthEditController extends BaseController {
  constructor(props) {
    super(props);

    let { userauth } = props.location.state;

    this.state = {
        ...this.state,
        userauth,
        currentStep: '1',
        modalTagShowing: false
    };

    this.handlePass = this.handlePass.bind(this);
    this.handleReject = this.handleReject.bind(this);
  }

  componentDidMount() {
    let { userauth } = this.state;
    
    this.changeLoadingState(true);
    launchGetUserAuthDetail(
        { userid: userauth.userid },
        res => {
            this.changeLoadingState(false);
        },
        fail => {
            message.error(fail);
            this.changeLoadingState(false);
        }
    );
  }

  handlePass() {
    let that = this;
    let { userauth } = this.state;
    confirm({
      title: '确定要通过吗？',
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {
          that.changeLoadingState(true);
            launchPassAuth(
                { userid: userauth.userid },
                res => {
                    that.changeLoadingState(false);
                    that.props.history.goBack();
                },
                fail => {
                    message.error(fail);
                    that.changeLoadingState(false);
                }
            );
      },
      onCancel() {
        
      },
    });
  }

  handleReject() {
    let that = this;
    let { userauth } = this.state;
    confirm({
      title: '确定要驳回吗？',
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {
          that.changeLoadingState(true);
            launchRejectAuth(
                { userid: userauth.userid },
                res => {
                    that.changeLoadingState(false);
                    that.props.history.goBack();
                },
                fail => {
                    message.error(fail);
                    that.changeLoadingState(false);
                }
            );
      },
      onCancel() {
        
      },
    });
  }

  renderForm() {
      
    return (
        <div>
            <div style={{width: '100%', paddingLeft: 24, paddingRight: 24, marginBottom: 20}}>
                <Menu onClick={(e) => { this.setState({ currentStep: e.key }) }} selectedKeys={[this.state.currentStep]} mode="horizontal">
                    <Menu.Item key="1"> <InfoCircleOutlined /> 基础信息 </Menu.Item>
                    <Menu.Item key="2"> <FileOutlined /> 简历信息 </Menu.Item>
                </Menu>
            </div>

            {
                this.renderContent()
            }
        </div>
    );
  }

  renderContent() {
    let { userauth } = this.state;

    if (this.state.currentStep === '1') {
        return (
            <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'column' }}>

                <Card hoverable style={{ width: '80%', marginLeft: 40 }}>
                    <FormItem itemKey="姓名：" type="show" itemValue={userauth.username}  />
                    <FormItem itemKey="照片：" type="show" itemValue={<Zmage src={userauth.cardPortrait} style={{ width: 100, objectFit: 'widthFix' }} />} itemStyles={{ alignItems: 'flex-start' }} keyStyles={{ marginTop: 5 }} />
                    <FormItem itemKey="简介：" type="show" valueStyles={{ width: 300 }} itemValue={userauth.brief} onValueChanged={this.handleNameChanged} />
                </Card>
                <Card hoverable style={{ width: '80%', marginLeft: 40, marginTop: 40 }}>
                    <FormItem itemKey="证件号：" type="show" itemValue={userauth.cardId}  />
                    <FormItem itemKey="证件照正面：" type="show" itemValue={<Zmage src={userauth.cardFront} style={{ width: 200, objectFit: 'widthFix' }} />} itemStyles={{ alignItems: 'flex-start' }} keyStyles={{ marginTop: 5 }} />
                    <FormItem itemKey="证件照背面：" type="show" itemValue={<Zmage src={userauth.cardBack} style={{ width: 200, objectFit: 'widthFix' }} />} itemStyles={{ alignItems: 'flex-start' }} keyStyles={{ marginTop: 5 }} />
                    <FormItem itemKey="手持证件照：" type="show" itemValue={<Zmage src={userauth.cardHold} style={{ width: 200, objectFit: 'widthFix' }} />} itemStyles={{ alignItems: 'flex-start' }} keyStyles={{ marginTop: 5 }} />
                </Card>
            </div>
        );
    } else if (this.state.currentStep === '2') {
        return (
            <div>

            </div>
        );
    } else {
        return null;
    }
  }

    renderNavHeader() {
        let { userauth } = this.state;
        return this.renderPageHeader({
        title: NAMESPACE.name,
        extra: (
            <div>
                {
                    userauth.state !== 20 ?
                        <div>
                            <Button onClick={ this.handlePass } type="primary" size="small">通过</Button>
                            <Button onClick={ this.handleReject } type="danger" size="small" style={{ marginLeft: 20 }} >驳回</Button>
                        </div>
                        :
                        null
                }
                
            </div>
        ),
        onBack: () => {
            this.props.history.goBack();
        }
        });
    }

  render() {
    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={PageStyles.contentContainer_nopadding}>
          {
            this.renderForm()
          }
        </div>

        <Modal
            centered={true}
            visible={this.state.modalTagShowing}
            destroyOnClose={true}
            width={"956px"}
            onCancel={() => { this.setState({ modalTagShowing: false }) }}
            footer={null}
        >
            <TagSelectView onRecordSelect={this.handlePtagChanged} />
        </Modal>
        
      </div>
    )
  }

}

const mapStateToProps = store => {
  const utilStore = store['utilStore'];
  // debugger
  return {
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.CREATE_MANAGER.path]
  }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(UserAuthEditController)