
import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, TimePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';
import { FormStyles } from '../../style/common-styles';


const confirm = Modal.confirm;
const Option = Select.Option;

class AddVenueView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            venuename: ''
        }

        this.props.onRef(this);

        this.getVenuename = this.getVenuename.bind(this);
    }

    componentDidMount() {
        
    }

    getVenuename() {
        return {
            venuename: this.state.venuename
        };
    }

    renderContent() {
        return (
            <Spin spinning={this.props.isLoading}>
            <div>
                <div style={styles.itemFirst}>

                <span style={styles.itemHint}>场馆名称 :</span>
                    <div style={styles.itemInput}>
                        <Input
                            allowClear
                            style={FormStyles.input_middle}
                            placeholder="请填写场馆名称"
                            value={this.state.venuename}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ venuename: inputText })
                            }}
                        />
                    </div>
                </div>
            </div>
            </Spin>
        );
    }

    render() {
        
        return (
            <div style={styles.container}>
                
                {
                    this.renderContent()
                }
            </div>
        );
    }
}

const mapStateToProps = store => {

  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVenueView);


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemFirst: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemHint: {
        width: 65, 
        textAlign: 'right',
        
    },
    itemInput: {
        marginLeft: 10
    },
}