import React from 'react';

import { PageHeader } from 'antd';

export default class BaseController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }

        this.changeLoadingState = this.changeLoadingState.bind(this);
    }

    changeLoadingState(state) {
        this.setState({ isLoading: state });
    }

    getQueryParams() {
        let params = {};
        for (let key in this.state.queryParams) {
            if (this.state.queryParams[key]) {
                params[key] = this.state.queryParams[key];
            }
        }

        return params;
    }

    renderPageHeader(params) {
        if (params.onBack) {
            return (
                <div style={{ position: 'fixed', zIndex: 99, width: '100%', paddingRight: '200px', backgroundColor: '#fff' }}>
                    <PageHeader 
                        style={{ height: '50px', width: '100%',  }}
                        onBack={params.onBack} 
                        title={params.title}
                        subTitle={ params.subTitle ? params.params : '' }
                        tags={ params.tags ? params.tags : [] }
                        extra={params.extra ? params.extra : undefined}
                    />
                </div>
            );
        } else {
            return (
                <div style={{ position: 'fixed', zIndex: 99, width: '100%', paddingRight: '200px', backgroundColor: '#fff' }}>
                <PageHeader 
                    style={{ height: '50px', width: '100%',  }}
                    title={params.title}
                    subTitle={ params.subTitle ? params.params : '' }
                    tags={ params.tags ? params.tags : [] }
                    extra={params.extra ? params.extra : undefined}
                />
                </div>
            );
        }
    }
}