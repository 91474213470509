import { SAP_CONTROL } from "../config/appConfig";
import { ENVIRONMENT } from "./appConstants";

/** 私钥 */
export const PRIVATE_KEY = 'OnemeYoga@2019&cxhh';

/** 域名 */
export let DOMAIN = 'https://codestation.soso-code.com';

if (SAP_CONTROL === ENVIRONMENT.DEV) {
    // 开发环境域名
    DOMAIN = 'http://192.168.31.110:8080/codestation-web';
} else if (SAP_CONTROL === ENVIRONMENT.TEST) {
    // 测试环境域名
    DOMAIN = 'http://business.level.soso-code.cn';
    // DOMAIN = 'http://codestation_test.soso-code.com'; // 以前的
} else {
    // 生产环境域名
    DOMAIN = 'https://codestation.soso-code.com';
}

export const PROXY_PART = '/webapi/business';

/** 模块 */
export const PART = {
    OPT_MANAGER: '/optmanager',
    OPT_VIPCARD_MOLD: '/optvipcardmold',
    OPT_USER: '/optuser',
    OPT_FINANCE: '/optfinance',
    OPT_VIPCARD: '/optvipcard',
    OPT_COACH: '/optcoach',
    OPT_COURSE: '/optcourse',
    OPT_TIMETABLE: '/opttimetable',
    OPT_TIMETABLE_COURSE: '/opttimetablecourse',
    OPT_APPOINTMENT: '/optappointment',
    OPT_DESC: '/optdesc',
    OPT_VENUE: '/optvenue',
    OPT_USERAUTH: '/optuserauth',
    OPT_SERVICE: '/optservice',
    OPT_COMMUNITY: '/optcommunity',
    OPT_ACTIVITY: '/optactivity',
    OPT_FORM: '/optform',
    OPT_MOLD: '/optmold',
    OPT_BANNER: '/optbanner',
    OPT_TAG: '/opttag',
    OPT_PARTNER: '/optpartner',
    OPT_VIPCOURSE: '/optvipcourse',
    OPT_COURSE_EXPERIENCE: '/optcourseexperience',
    OPT_STATIC: '/optstatic',
    OPT_ADDRESS: '/optaddress',
    
};


export const REQUEST_TYPE = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT'
};

export const RESPONSE_CODE = {
    success: 200,
    BadAccessToken: 460,
    BadRefreshToken: 461
};

export const SERVICE_CODE = {
    Successed: 1
};