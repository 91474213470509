import React from 'react';
import { Route } from 'react-router-dom';

import { MAIN } from '../../../../constants/routeConstants';
import List from './course-times-list';
import VIP_DETAIL from './course-times-detail-vip';
import COACH_DETAIL from './course-times-detail-coach';
import { PageStyles } from '../../../../style/common-styles';

const NAMESPACE = MAIN.children.STATIC_COURSE_TIMES;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      <Route
        // exact
        path={`${baseUrl}/${children.VIP_DETAIL.path}`}
        component={VIP_DETAIL} />
        <Route
        // exact
        path={`${baseUrl}/${children.COACH_DETAIL.path}`}
        component={COACH_DETAIL} />
        
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

