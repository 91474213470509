import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'

export async function queryBannerInfo(
  params = {},
  success = () => { },
  failed = () => { }
) {

  launchPOSTRequest(Apis.QUERY_BANNER_INFO, params)
    .then(response => {
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response);
      } else {
        failed(response.msg);
      }
    }).catch(rejectedValue => {
      failed(rejectedValue);
    });
}

// 保存banner
export async function launchSaveBanner(
  params = {},
  success = () => { },
  failed = () => { }
) {
  launchPOSTRequest(Apis.SAVE_BANNER_TYPE, params)
    .then(response => {
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response);
      } else {
        failed(response.msg);
      }
    }).catch(rejectedValue => {
      failed(rejectedValue);
    });
}
