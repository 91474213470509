import React, { Component } from 'react';
import { Input, Select, Radio, Button, Upload, message } from 'antd';
import PicturesWall from './picture-wall-view';
import { FileTextOutlined, PictureOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class DescEditView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editPicture: [],
            desc: props.desc ? props.desc : []
        };

        this.props.onRef(this);

        this.getEditPictures = this.getEditPictures.bind(this);
        this.getDescItems = this.getDescItems.bind(this);
        this.updateDescItems = this.updateDescItems.bind(this);
        this.updateEditPictures = this.updateEditPictures.bind(this);
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
    }

    getEditPictures() {
        return this.state.editPicture;
    }

    getDescItems() {
        return this.state.desc;
    }

    updateDescItems(desc) {
        this.state.desc = desc;
        this.setState({ 
            desc: this.state.desc, 
        });
    }

    updateEditPictures(pictures) {
        this.state.editPicture = pictures;
        this.setState({ 
            editPicture: this.state.editPicture
        });
    }

    beforeUpload = (file) => {
        console.log(file);
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
        
        }
        return false; // 不调用默认的上传方法
    }

    handleChange = async ({ file, fileList }, index) => {

        let preview = await getBase64(file);
        
        this.state.editPicture.push({ 
            file: fileList[fileList.length-1],
            preview,
            index
        });
        
        this.setState({ editPicture: this.state.editPicture });
    };

    handleAddItem(itemtype, index) {
        let { desc, editPicture } = this.state;
        desc.splice(index, 0, { itemtype });

        // debugger
        for (let key in editPicture) {
            let aEditPicture = editPicture[key];
            console.log(aEditPicture.index, index);
            if (aEditPicture.index >= index) {
                aEditPicture.index++;
            }
        }

        this.setState({ desc, editPicture });
    }

    handleDeleteButton(index) {
        let { desc , editPicture} = this.state;
        desc.splice(index, 1);

        for (let i = 0; i < editPicture.length; i++) {
            let aEditPicture = editPicture[i];
            if (aEditPicture.index === index) {
                editPicture.splice(i, 1);
                break;
            }
        }

        for (let key in editPicture) {
            let aEditPicture = editPicture[key];
            if (aEditPicture.index > index) {
                aEditPicture.index--;
            }
        }

        this.setState({ desc, editPicture });
    }

    renderOperateBar(index) {
        
        return (
            <div style={styles.operateBar}>

                <Button onClick={() => { this.handleAddItem(1, index) }} icon={<FileTextOutlined />} type="link">
                    文字
                </Button>

                <Button onClick={() => { this.handleAddItem(2, index) }} icon={<PictureOutlined />} type="link">
                    图片
                </Button>
            </div>
        );
    }

    renderItem(item, index) {
        if (item.itemtype === 1) {
            return (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', position: 'relative', marginTop: 10 }}>
                    <TextArea 
                        style={styles.itemTextInput} 
                        rows={4} autoSize={false} 
                        value={item.itemcontent} 
                        onChange={(e) => {
                            item.itemcontent = e.target.value;

                            this.setState({ desc: this.state.desc });
                        }}
                        placeholder='请输入内容' />
                    <Button onClick={() => {this.handleDeleteButton(index)}} style={styles.minusButton} shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
                </div>
                
            );
        } else if (item.itemtype === 2) {

            let src = item.itemurl;
            for (let key in this.state.editPicture) {
                if (this.state.editPicture[key].index === index) {
                    src = this.state.editPicture[key].preview
                    break;
                }
            }

            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ display: 'flex', width: '90%' }}>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        onChange={info => {this.handleChange(info, index) }}   
                    >
                        {
                            src ? 
                            <div style={styles.uploadedImg}>
                                <img style={styles.itemImage} src={src} alt="avatar" /> 
                            </div>
                            :
                            <div style={styles.uploadButton}>
                                <PlusOutlined />
                                <div>上传照片</div>
                            </div>
                        }
                    </Upload>
                    <Button onClick={() => {this.handleDeleteButton(index)}} style={styles.minusButton} shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={styles.itemContainer}>
                {
                    this.state.desc && this.state.desc.map((item, index) => {
                        return (
                            <div key={`desc_${index}`} style={styles.itemBox}>
                                {
                                    this.renderOperateBar(index)
                                }
                                
                                {
                                    this.renderItem(item, index)
                                }
                            </div>
                        )
                    })
                }
                {
                    this.renderOperateBar(this.state.desc.length)
                }
            </div>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        minHeight: '500px',
        backgroundColor: '#f5f5f5',
        marginBottom: 20,
        alignItems: 'center'
    },
    operateBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative'
    },
    itemTextInput: {
        width: '90%',
        resize: 'none'
    },
    uploadButton: {
        // width: '90%',
        width: '350px',
        height: '50px',
        position: 'relative'
        // backgroundColor: '#F6F6F6',
    },
    uploadedImg: {
        position: 'relative',
    },
    itemImage: {
        // width: '90%',
        width: '340px',
    },
    minusButton: {
        position: 'absolute',
        top: -5,
        right: 5,
        zIndex: 10
    }
}