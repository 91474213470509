import React from 'react';
import {
    Form, Input, Button, Checkbox, Modal, Card, Spin, message
} from 'antd';
import { connect } from 'react-redux';
import BaseController from '../../base/base-controller';
import { LoginStyles, VenueListStyles } from '../../../style/login-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { IndexStyles } from '../../../style/web/index-pc-styles';

const { Meta } = Card;

class RegisterController extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            username: '',
            passwd: '',
        };

        this.handleRegisterTapped = this.handleRegisterTapped.bind(this);
        this.renderRegisterContent = this.renderRegisterContent.bind(this);
    }

    componentDidMount() {
        
    }

    handleRegisterTapped() {
        let { username, passwd, remember } = this.state;
        console.log(username, passwd, remember);
        if (isEmpty(username) || isEmpty(passwd)) {
            message.warn('账号密码不能为空');
            return;
        }
        this.props.login(username, passwd, remember);
    }

    renderRegisterContent() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative',
            width: '100vw', height: '100vh', alignItems: 'center', backgroundColor: '#fff' }}>

                <img style={{ width: 800, height: 610, objectFit: 'cover', position: 'absolute', left: 0, bottom: 0 }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/533c3e7f9ca04d318760c8769e55d028.jpg" />

                <div style={{ display: 'flex', position: 'relative', width: '100vw', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <span style={{ marginLeft: 40, marginTop: '20vh', fontSize: '32px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#000000', lineHeight: '45px' }}>注册ONE ME</span>
                        <span style={{ marginLeft: 40, marginTop: 12, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#858585', lineHeight: '21px' }}>会员管理系统</span>

                        <img style={{ marginLeft: 140, marginTop: 50, objectFit: 'contain', width: 155, height: 155  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/75c1412bd482444d9a726d5d0aeee12b.png" />
                        <span style={{ marginLeft: 60, marginTop: 20, fontSize: '20px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#000000', lineHeight: '45px' }}>扫码登录ONE ME小程序，咨询入驻！</span>
                    </div>
                </div> 
            </div>
        );
    }

    render() {

        return this.renderRegisterContent();
    }
}

const mapStateToProps = (store) => {
    return {

    };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterController);