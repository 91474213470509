import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class Timetable extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="团课课表" />

                <SectionTitle text="一.创建课表" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/8e7a82d6e8cc463cb181c7da1d87bd20.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“团课课表”后，在右侧就会展示当前周的课表。</span>} />
                <NormalContent text={<span>2.课表有两种生成方式：创建 和 复制。</span>} />
                <NormalContent text={<span>3.<Highlight text="创建课表" />：创建一个空白课表。</span>} />
                <NormalContent text={<span>4.<Highlight text="复制课表" />：从近几周的课表中，选一个复制，成为新的一周课表。然后可在其基础上进行修改。</span>} />

                <SectionTitle text="二.开启/关闭课表" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/ee7707b8318f4826b7f8a04c19a24e91.png" />
                <NormalContent text={<span>1.如图所示，在课表左上角有一个开关，新创建的课表默认为关闭状态，只要开启状态的课表，才会展示给前端（小程序、APP等）。通常在课表编辑完善后，再行开启。</span>} />

                <SectionTitle text="三.编辑课表课程" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/0f1c4d00b69b4e29aad1d5321dc0fac9.png" />
                <NormalContent text={<span>1.如图所示，在课表左上角有一个“<Highlight text="+" />”号按钮。点击加号，会弹出一个空白的课表课程编辑页面，补全信息后，点击“确定”，即完成新的课表课程添加。</span>} />
                <NormalContent text={<span>2.点击课表中<Highlight text="空白格子中心位置" />，也可以进行新的课程创建，弹出的课表课程编辑页面，会默认填充所点击格子的日期和时间。</span>} />
                <NormalContent text={<span>3.点击课表中<Highlight text="已有课程" />，可以对已加入的课程进行编辑和删除。删除前需要确认所有约课会员都已经沟通好。</span>} />

                <SectionTitle text="四.课表操作" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/92beb8616bb44d1da9378590e4148887.png" />
                <NormalContent text={<span>1.如图所示，课表日期左右两侧按钮，点击后分别会进入上一周，下一周的课表。</span>} />
                <NormalContent text={<span>2.如图所示，课表偏下侧两边有两个圆形箭头，当浏览器大小不能一次显示整周课表时，可以通过左右两个按钮，对课表进行左右移动操作。</span>} />

            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timetable);
