export const VenueListStyles = {
    container: {
        width: '100%',
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 36,
        paddingRight: 36,
        minHeight: 550
    },
    container_nopadding: {
        width: '100%',
        minHeight: 550
    },
    header: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 15
    },
    headerTitle: {
        fontSize: 24,
        // color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
    },
    venueList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    venueItem: {
        width: 240,
        marginRight: 25,
        marginBottom: 25
    },
    venueItem_selected: {
        width: 240,
        marginRight: 25,
        marginBottom: 25,
        boxShadow: '0px 0px 6px #FDD100'
    },
    plusBodyStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    venueLogoBox: {
        width: 240, 
        height: 240, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    venueLogo: { 
        width: 200, 
        height: 200, 
        objectFit: 'cover' 
    }
}