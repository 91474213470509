import React from 'react';
import { Table, Statistic, Divider, Modal, DatePicker, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu, Card, Spin, Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment'

// import { Bar } from '@antv/g2plot';
import LLCDateHelper from 'date-helper'
import Decimal from 'decimal.js';
import { priceYuan } from '../../../../util/helper/number-helper';
import { MAIN, MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import { filterParams } from '../../../../util/helper/request-helper';
import FormItem from '../../../../view/common/form-item';
import { actions as queryActions } from '../../../../redux/query-model';
import { TableOperateBarStyles, TableStyles, TableSearchStyles, FormStyles, PageStyles } from '../../../../style/common-styles';
import { isEmpty } from '../../../../util/helper/string-helper';
import { launchGetStaticAppointOverview } from '../../../../redux/static-model';
import { kAdmin_User_Auth, UserAuth } from '../../../../constants/authConstants';
import { launchGetCoachFinanceList, launchGetStaticOrder } from '../../../../redux/finance-model';

const NAMESPACE = MAIN.children.STATIC_ORDER;

const { RangePicker } = DatePicker;

class StaticOrderController extends BaseController {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            list: [],
            dataInfo: {}
        };

        let that = this;
        this.columns = [
            { title: '会员姓名', width: '16%', dataIndex: 'username', key: 'username', align: 'center' },
            // { title: '手机号', width: 120, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '订单类型', width: '16%', dataIndex: 'type', key: 'type', align: 'center', 
                render: (type) => {
                    if (type === 1) {
                        return <Tag color="purple">私教卡</Tag>
                    } else if (type === 2) {
                        return <Tag color="magenta">会员卡</Tag>
                    } else {
                        return null;
                    }
                }
            },
            { title: '会员卡', width: '16%', key: 'cardname', align: 'center',
                render: order => {
                    if (order.cardname) {
                        if (order.cardname) {
                            return <span>{order.cardname}{order.cardtype === 2 ? `（${order.cardtimes}次）` : `（${order.cardduration}天）`}</span>
                        } else {
                            return <span>-</span>
                        }
                    } else {
                        return <span>-</span>
                    }
                }
            },
            
            { title: '私教课', width: '16%', key: 'coursename', align: 'center', 
                render: order => {

                    if (order.coursename) {
                        return <span>{order.coursename}（{order.coursetimes}次/{order.coachname}）</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
            { title: '价格（元）', width: '16%', dataIndex: 'payment', key: 'payment', align: 'center', 
                render: payment => {
                    return (
                        <span>{priceYuan(payment)}</span>
                    )
                }
            },
            { title: '操作时间', width: '20%', dataIndex: 'createtime', key: 'createtime', align: 'center', 
                render: createtime => {
                    return (
                        <span>{LLCDateHelper.formatDate(createtime, 'yyyy-MM-dd hh:mm')}</span>
                    )
                }
            },
            // { title: '操作', width: 80, key: 'operate', align: 'center', 
            //     render: (coach) => {
            //         return (
            //             <a 
            //                 href="javascript:void(0)" 
            //                 onClick={() => {
            //                     this.props.history.push({
            //                         pathname: `${this.props.match.url}/${NAMESPACE.children.DETAIL.path}`,
            //                         state: {
            //                             coach,
            //                             selecteddate: that.state.selecteddate
            //                         }
            //                     });
            //                 }
            //             }>详情</a> 
            //         );
            //     }
            // }
        ];

        this.requestGetStaticOrder = this.requestGetStaticOrder.bind(this);

        this.onSelectedDateChanged = this.onSelectedDateChanged.bind(this);

        this.calculateFinance = this.calculateFinance.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();

        this.requestGetStaticOrder(queryParams);
    }

    requestGetStaticOrder(extra) {
        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }
        

        this.changeLoadingState(true);
        launchGetStaticOrder(
            filterParams(queryParams),
            res => {
                let { selecteddate } = res;
                let list = this.calculateFinance(res.list);
                
                this.setState({
                    list,
                    selecteddate
                });
                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    calculateFinance(list) {
        let staticPrice = 0;
        for (let i = 0; i < list.length; i++) {
            let aOrder = list[i];

            staticPrice += aOrder.payment;
        }

        this.setState({ staticPrice });

        return list;
    }

    onSelectedDateChanged(date, dateString) {
        this.setState({ selecteddate: dateString });

        let queryParams = this.queryParams();
        queryParams.selecteddate = dateString;
        this.props.configQueryParams(queryParams);

        this.requestGetStaticOrder(queryParams);
    }

   
    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    render() {
        let { list, selecteddate, staticPrice } = this.state;
        
        let dateValue = undefined;
        if (selecteddate) {
            selecteddate = selecteddate+"-01"
            dateValue = LLCDateHelper.timeStrToTimestamp(selecteddate);
        }

        console.log(this.state, 'this.state');
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={{...PageStyles.pageContainer, backgroundColor: '#ECEFF3', width: '100%'}}>
                
                <div style={{...TableStyles.tableBox, marginTop: 20}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span>统计日期：</span>
                                    <DatePicker value={moment.unix(dateValue)} onChange={this.onSelectedDateChanged} picker="month" />
                                </div>
                                <Divider />
                                <Statistic title="订单总金额" value={priceYuan(staticPrice)} />
                                
                            </Card>
                        </div>
                        <div style={{ width: 30 }} />
                       
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                        <Table
                            rowKey={record => record.id}
                            loading={this.props.loading}
                            columns={this.columns}
                            dataSource={list}
                            scroll={{ 
                                // x: 1200, 
                                // y: 485 
                            }}
                            // pagination={}
                        ></Table>
                        {/* <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 0, paddingTop: 20, paddingRight: 0, paddingBottom: 10 }}>
                            <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <span style={ queryParams.tabIndex === 1 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(1) }}>团课预约{ this.state.dataInfo.appoints ? `（${this.state.dataInfo.appoints.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 2 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(2) }}>私教课预约{ this.state.dataInfo.privates ? `（${this.state.dataInfo.privates.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 3 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(3) }}>体验课预约{ this.state.dataInfo.experiences ? `（${this.state.dataInfo.experiences.length}）` : '' }</span>
                                </div>

                                {
                                    this.renderTab()
                                }
                            </div>
                            <div style={{ marginTop: 20, width: '100%', height: 1, backgroundColor: '#E2E2E2' }} />

                            <div style={{ paddingLeft: 20, paddingRight:20, width: '100%' }}>
                                {
                                    this.renderTabContent()
                                }
                            </div>
                        </Card> */}
                    </div>
                </div>

                
            </div>
            </Spin>
        );
    }
}


const mapStateToProps = ({ queryStore, loginStore }) => {
    
    return {
        queryStore,
        user: loginStore.user
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticOrderController);

const styles = {
    tabTitle: { marginLeft: 20 },
    tabTitle_select: { marginLeft: 20, color: '#1875F6' },
    groupTitle: { marginLeft: 20 },
    groupTitle_select: { marginLeft: 20, color: '#1875F6' }
}