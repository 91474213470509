import React, { Component } from 'react';

import { Select, Input, Row, Col, Button, Table, Tag, Modal, message } from 'antd';
import { isEmpty } from '../../util/helper/string-helper';

export default class ChangePasswdModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            manager: {},
            oldpasswd: '',
            passwd: '',
            confirmPasswd: ''
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    
    show(manager) {
        this.setState({
            visible: true,
            manager
        });
    }

    hide() {
        this.setState({
            visible: false,
            manager: {},
            oldpasswd: '',
            passwd: '',
            confirmPasswd: ''
        });
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                onCancel={() => { 
                    this.setState({ 
                        visible: false,
                        oldpasswd: '',
                        passwd: '',
                        confirmPasswd: ''
                    }) 
                }}
                destroyOnClose={true}
                footer={null}
                closable={false}
                maskClosable={false}
                width={367}
                bodyStyle={{ 
                    padding: 0,
                    paddingTop: 14,
                    paddingBottom: 27
                }}
            >
                <div style={styles.content}>

                    <div style={styles.header}>
                        <span style={styles.headerTitle}>修改密码</span>
                    </div>

                    <div style={styles.form}>
                    {
                        this.state.manager ? 
                            <div style={styles.usernameItem}>
                                <span style={styles.itemKey}>姓名：</span>
                                <span style={styles.usernameValue}>{this.state.manager.username}</span>
                            </div> 
                        :
                        <div style={styles.formItem}>
                            <span style={styles.itemKey}>原密码：</span>

                            <Input.Password
                                autocomplete='new-password'
                                allowClear
                                placeholder="请填写原密码"
                                value={this.state.oldpasswd}
                                style={{width: 160}}
                                onChange={e => {
                                    let inputText = e.target.value;
                                    this.setState({ oldpasswd: inputText })
                                }}
                            />
                        </div>
                    }
                        

                        <div style={styles.formItem}>
                            <span style={styles.itemKey}>新密码：</span>

                            <Input.Password
                                autocomplete='off'
                                allowClear
                                placeholder="请填写新密码"
                                value={this.state.passwd}
                                style={{width: 160}}
                                onChange={e => {
                                    let inputText = e.target.value;
                                    this.setState({ passwd: inputText })
                                }}
                            />
                        </div>

                        <div style={styles.formItem}>
                            <span style={styles.itemKey}>确认密码：</span>

                            <Input.Password
                                autocomplete='off'
                                allowClear
                                placeholder="请确认密码"
                                value={this.state.confirmPasswd}
                                style={{width: 160}}
                                onChange={e => {
                                    let inputText = e.target.value;
                                    this.setState({ confirmPasswd: inputText })
                                }}
                            />
                        </div>

                        <div style={styles.operateBox}>
                            
                            <Button 
                                style={{marginRight: 73/2.}}
                                onClick={() => {
                                    this.hide();
                            }}>取消</Button>

                            <Button 
                                style={{marginLeft: 73/2.}}
                                type="primary"
                                onClick={() => {
                                    if (isEmpty(this.state.passwd)) {
                                        message.error('密码不能为空');
                                        return;
                                    }
                                    if (this.state.passwd !== this.state.confirmPasswd) {
                                        message.error('两次密码输入不一致');
                                        return;
                                    }

                                    let params = {};
                                    if (this.state.manager) {
                                        params = {
                                            passwd: this.state.passwd, 
                                            userid: this.state.manager.userid
                                        };
                                    } else {
                                        params = {
                                            oldpasswd: this.state.oldpasswd,
                                            newpasswd: this.state.passwd
                                        };
                                    }
                                     
                                    this.props.okTapped && this.props.okTapped(params);
                                }}
                            >确定</Button>
                        </div>
                    </div>

                </div>
          </Modal>
        );
    }
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerTitle: {
        fontFamily: 'PingFangSC-Medium',
        fontSize: 16
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    usernameItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 38
    },
    itemKey: {
        width: 130,
        textAlign: 'right',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16
    },
    usernameValue: {
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16
    },
    formItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 17
    },
    operateBox: {
        display: 'flex',
        width: 367,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 28
    }
}
