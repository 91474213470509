import {
  handleActions,
  createAction
} from 'redux-actions';

import * as routerPath from '../constants/routeConstants'

export const actions = {
  windowResize: createAction('onresize'),
  // 设置header相关信息标题
  setHeaderContent: createAction('setHeaderContent'),
  setHeaderContentDetail: createAction('setHeaderContentDetail'),
  // 设置深度页面的隐藏
  setPageHiddenState: createAction('setPageHiddenState'),
  changeLoading: createAction('changeLoading')
}
export const utilStore = handleActions(
  {
    windowResize (state, { payload: { windowHeight }}) {
      return {
        ...state,
        windowHeight
      };
    },
    setHeaderContent (state, { payload: headerContent }) {
      return {
        ...state,
        title: headerContent.title,
        isRoot: !(headerContent.rootPath),
        rootPath: headerContent.rootPath
      };
    },
    setHeaderContentDetail (state, { payload: headerDetail }) {
      return {
        ...state,
        titleDetail: headerDetail
      }
    },
    setPageHiddenState (state, { payload: isShowPagePath }) {
      // 先全部false
      // pageHiddenState
      // 这里找isShow的匹配的变为true
      for (let key in state.isShow){
        state.isShow[key] = false;
      }
      state.isShow[isShowPagePath] = true;
      return {
        ...state
      }
    }
  },
  {
    windowHeight: window.innerHeight,
    title: '',
    titleDetail: '',
    isRoot: true,
    rootPath: '',
    isShow: initPageHiddenState(),
  }
);
/**
 * 初始化显示页面的数组
 */
function initPageHiddenState() {
  let isShow = {};
  for (let key in routerPath) {
    if (routerPath[key].hasOwnProperty('path'))
      isShow[routerPath[key].path] = false
  }
  return isShow;
}