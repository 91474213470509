export const BCG_ROOT_Name = 'console';


export const PageRoutes = {
  MAIN: {
    path: 'console',
    children: {
      OVER_VIEW: { path: 'overview', name: '场馆概览',
        children: {
          
        }
      },
      PARTNER: { path: 'partner', name: '合作商家',
        children: {
          EDIT: { path: 'edit', name: '编辑商家' }
        }
      },
      STATIC_COACH_FINANCE: { path: 'static-coach-finance', name: '教练财务',
        children: {
          DETAIL: { path: 'detail', name: '教练财务明细' }
        }
      },
      STATIC_COURSE_TIMES: { path: 'static-course-times', name: '课程销卡',
        children: {
          VIP_DETAIL: { path: 'vip-detail', name: '会员销卡明细' },
          COACH_DETAIL: { path: 'coach-detail', name: '教练销卡明细' },
        }
      },
      STATIC_ORDER: { path: 'static-order', name: '开卡统计',
        children: {
          // VIP_DETAIL: { path: 'vip-detail', name: '会员销卡明细' },
          // COACH_DETAIL: { path: 'vip-detail', name: '教练销卡明细' },
        }
      },
      TAGS_LIST: { path: 'tags', name: '标签管理',
        children: {
          EDIT: { path: 'edit', name: '编辑标签' }
        }
      },
      VIPCARD_LIST: { path: 'vipcardlist', name: '会员卡管理',
        children: {
          SAVE_VIPCARD: { path: 'save-vipcard', name: '编辑会员卡' },
        }
      },
      VIPUSER_LIST: { path: 'vipuserlist', name: '会员管理',
        children: {
          SAVE_VIPUSER: { path: 'add-vipuser', name: '编辑会员' },
          VIPUSER_DETAIL: { path: 'vipuser-detail', name: '会员详情' },
        }
      },
      VIPCOURSE_LIST: { path: 'vipcourselist', name: '会员私教课管理',
        children: {
          
        }
      },
      USER_LIST: { path: 'userlist', name: '用户管理',
        children: {
          EDIT: { path: 'edit', name: '编辑商品' },
        }
      },
      USER_AUTH_LIST: { path: 'userauthlist', name: '用户认证管理',
        children: {
          EDIT: { path: 'edit', name: '编辑用户认证' },
        }
      },
      COACH_LIST: { path: 'coachlist', name: '教练管理',
        children: {
          SAVE_COACH: { path: 'save-coach', name: '编辑教练' },
        }
      },
      COURSE_LIST: { path: 'courselist', name: '课程管理',
        children: {
          SAVE_COURSE: { path: 'save-course', name: '编辑课程',
            children: {
              DESC_EDIT: {path: 'desc-edit', name: '详情编辑' }
            }
          },
        }
      },
      TIMETABLE_LIST: { path: 'timetablelist', name: '团课课表',
        children: {
          
        }
      },
      APPOINTMENT_TABLE: { path: 'appointmenttable', name: '团课预约',
        children: {
          
        }
      },
      APPOINTMENT_PRIVATE: { path: 'appoint-private', name: '私教预约',
        children: {
          
        }
      },
      APPOINTMENT_PRIVATE_UNHANDLE: { path: 'appoint-private-handle', name: '私教预约申请',
        children: {
          
        }
      },
      COURSE_EXPERIENCE: { path: 'course-experience', name: '体验课预约',
        children: {
          
        }
      },
      VENUE_LIST: { path: 'venuelist', name: '场馆管理',
        children: {
          SAVE_VENUE: { path: 'save-venue', name: '编辑场馆',
            children: {
              DESC_EDIT: {path: 'desc-edit', name: '详情编辑' }
            }
          },
        }
      },
      MANAGER_LIST: { path: 'managerlist', name: '账户管理',
        children: {
          SAVE_MANAGER: { path: 'save-manager', name: '编辑账号' },
        }
      },
      BANNER_LIST: { path: 'bannerlist', name: 'banner管理',
        children: {
          
        }
      },
    }
  },
};

export const MAIN = PageRoutes.MAIN;
export const MAIN_LISTS = MAIN.children;

const addParent = function(node, parent) {
  for (let name in node) {
    if (parent) {
      node[name].parent = parent;
    }
    if (node[name].children) {
      addParent(node[name].children, node[name]);
    } 
  }
  return;
}

addParent(PageRoutes);
