import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, Input, DatePicker, InputNumber, Modal } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { priceFen, priceYuan } from '../../util/helper/number-helper';
import { isEmpty } from '../../util/helper/string-helper';
import BaseSelectView from '../base/base-select-view';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import VipCardMoldSelectView from '../../view/vipuser/vipcard-mold-select-view';
import UserSelectView from '../../view/vipuser/user-select-view';
import { launchQueryVipState } from '../../redux/vipuser-model';

const { Option } = Select;

/**
 * 转让课程
 */
export default class TransferCourseView extends BaseSelectView {
    constructor(props) {
        super(props);

        // 转让方
        let vip = props.vip;
        let course = props.course;
        let newCard = {};
        this.state = {
            ...this.state,
            giveVip: vip,
            newCard,
            course,

            userListVisible: false
        };

        this.selectedUser = this.selectedUser.bind(this);
    }

    selectedUser(user) {
        this.setState({
            user,
            userListVisible: false
        });
    }

    render() {                                                      
        let { giveVip, user, course } = this.state;

        let userShowStr = undefined;
        if (user) {
            userShowStr = user.nickname;
            
            if (user.phone && user.phone.length > 4) {
                userShowStr = `${userShowStr}（${user.phone.slice(-4)}）`;
            }
        }

        console.log(this.state.isLoading);
        return (
            <Spin spinning={this.props.isLoading || this.state.isLoading}>
            <div style={styles.itemContainer}>
                <div style={{ paddingLeft: 250 }}>


                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>受让方：</span>
                        </div>

                        <div onClick={() => { this.setState({ userListVisible: true }); }}>
                            <Select
                                style={FormStyles.input_long}
                                placeholder="请选择受让方"
                                showArrow={false}
                                value={userShowStr}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.user ?
                            <div>

                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>受让方姓名：</span>
                                    </div>

                                    <div>
                                        <Input
                                            allowClear
                                            style={FormStyles.input_middle}
                                            placeholder="请填写受让方姓名"
                                            value={this.state.user.username}
                                            onChange={e => {
                                                let inputText = e.target.value;
                                                this.setState({ user: {...this.state.user, username: inputText} })
                                            }}
                                            />
                                    </div>
                                </div>

                                <div style={VipUserSaveStyles.infoBox}>
                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>开始时间：</span>
                                        </div>
                                        <DatePicker 
                                            style={{ width: 200 }}
                                            placeholder='课程生效时间' 
                                            onChange={(date, dateString) => {

                                                let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                
                                                let nowTimestamp = LLCDateHelper.achiveTimestampOfSecond();
                                                let todayStartTimestamp = LLCDateHelper.todayStartTimestampOfSecond();
                                                let duration = 0; // 转让方剩余时长
                                                console.log(course);
                                                if (course.starttime > nowTimestamp) {
                                                    duration = course.endtime - course.starttime;
                                                } else {
                                                    duration = course.endtime - todayStartTimestamp;
                                                }
                                                
                                                if (duration < 0) duration = 0;

                                                this.setState({
                                                    newCard: {
                                                        ...this.state.newCard, 
                                                        starttime,
                                                        endtime: starttime + duration
                                                    }
                                                });
                                            }}
                                            disabledDate={currentDate => {
                                                let todayTimestamp = new Date(new Date().toLocaleDateString()).getTime() / 1000
                                                if (parseInt(currentDate.valueOf() / 1000) < todayTimestamp) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                            value={this.state.newCard.starttime ? moment.unix(this.state.newCard.starttime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>结束时间：</span>
                                        </div>
                                        <DatePicker
                                            style={{ width: 200 }}
                                            placeholder='会员卡过期时间' 
                                            onChange={(date, dateString) => {
                                                let selectedEndTime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                selectedEndTime = selectedEndTime + 24 * 60 * 60 - 1;
                                                
                                                this.setState({
                                                    newCard: {...this.state.newCard, endtime: selectedEndTime}
                                                });
                                            }}
                                            value={this.state.newCard.endtime ? moment.unix(this.state.newCard.endtime) : undefined}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                    {
                        this.state.user ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { user, newCard } = this.state;

                                    if (!newCard.starttime || !newCard.endtime) {
                                        message.error('请填写开始/结束时间');
                                        return;
                                    }

                                    if (this.props.onSubmit) {
                                        this.props.onSubmit(giveVip, user, course, newCard);
                                    }
                                }}
                            >提交</Button>
                            :
                            null
                    }
                </div>

                <Modal
                    visible={this.state.userListVisible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ userListVisible: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <UserSelectView 
                        selectType={1}
                        initialQuery={false}
                        selectedUser={this.selectedUser} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}