import React from 'react'
import { Select, Input, InputNumber, Steps, Button, message, DatePicker, Modal, Spin, Upload } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment';
import { actions as coachActions, launchHandleUser, launchSaveCoach } from '../../../redux/coach-model';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { PageStyles, FormStyles, TableStyles } from '../../../style/common-styles';
import { CoachSaveStyles } from '../../../style/page/coach/coach-save-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import UserSelectView from '../../../view/vipuser/user-select-view';
import { uploadProjectImageToQiniu } from '../../../util/request';
import { PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.COACH_LIST.children.SAVE_COACH;

const Option = Select.Option;
const confirm = Modal.confirm;
const Step = Steps.Step;
const { TextArea } = Input;

class SaveCoachController extends BaseController {
    constructor(props) {
        super(props);

        let editVipCardMold = {};
        if (this.props.location.state && this.props.location.state.vipCardMold) {
            editVipCardMold = this.props.location.state.vipCardMold;
        }

        this.state = {
            ...this.state,
            user: undefined,
            coach: {
                basicsalary: 0,
                publicpay: 0,
                privatepay: 0
            },

            currentStep: 0,
            userListVisible: false,
            uploadLoading: false,

            ...editVipCardMold
        };

        this.showUserList = this.showUserList.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.createUser = this.createUser.bind(this);
        this.saveFirstStep = this.saveFirstStep.bind(this);
        this.saveSecondStep = this.saveSecondStep.bind(this);

        this.handlePreStepButtonClick = this.handlePreStepButtonClick.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
        this.handleCoachTypeChange = this.handleCoachTypeChange.bind(this);

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
    }

    showUserList() {
        this.setState({ userListVisible: true });
    }

    selectedUser(user) {
        this.setState({
            user,
            userListVisible: false
        });
    }

    createUser() {
        this.setState({
            user: {}
        });
    }

    saveFirstStep() {
        this.changeLoadingState(true);
        launchHandleUser(
            this.state.user,
            response => {
                message.success(response.msg);
                this.changeLoadingState(false);
                this.setState({ 
                    currentStep: 1,
                    user: response.result.user
                });
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        );
    }

    saveSecondStep() {
        this.changeLoadingState(true);
        launchSaveCoach(
            {
                userid: this.state.user.userid,
                ...this.state.coach
            },
            response => {
                this.changeLoadingState(false);
                message.success(response.msg);
                this.setState({ currentStep: 2 });
                this.props.queryCoachList({ page: 1 });
            },
            msg => {
                this.changeLoadingState(false);
                message.error(msg);
            }
        );
    }

    handleSaveButtonClick() {
        if (!this.checkFormNotHasEmptyItem(this.state.currentStep)) {
            return;
        } 
        if (this.state.currentStep === 0) {
            this.saveFirstStep();
        } else if (this.state.currentStep === 1) {
            this.saveSecondStep();
        } else if (this.state.currentStep === 2) {
            this.props.history.goBack();
        }       
    }

    handlePreStepButtonClick() {
        if (this.state.currentStep === 1) {
            this.setState({
                currentStep: 0
            });
        }
    }

    handleCoachTypeChange(coachtype) {
        this.setState({
            coach: {
                ...this.state.coach,
                coachtype
            }
        });
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
          message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 4;
        if (!isPassSizeLimit) {
          message.error('图片大小需小于4Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info, index) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file,
                1,
                res => {
                    console.log(res, index, 'uploadProjectImageToQiniu');
                    if (index === 0) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                identityfront: res.result.url 
                            } 
                        });
                    } else if (index === 1) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                identityback: res.result.url 
                            } 
                        });
                    }

                    this.setState({ uploadLoading: false });
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }

    checkFormNotHasEmptyItem = (index) => {
        if (index === 0) {
            if (!this.state.user.username || isEmpty(this.state.user.username)) {
                message.error('请填写姓名');        
                return false;
            }
            
        } else if (index == 1) {
            if (!this.state.coach.entrytime) {
                message.error('请填写入职时间');        
                return false;
            }
        }
        
        return true;
    }

    renderStepContent() {
        if(this.state.currentStep === 0) {
            return (
                <div style={CoachSaveStyles.editBox}>
                    {
                        this.renderBaseInfoBox()
                    }

                    {
                        this.renderIdentifyBox()
                    }
                </div>
            );
        } else if (this.state.currentStep === 1) {
            return (
                <div>
                    {
                        this.renderCoachInfo()
                    }
                </div>
            );
        } else if (this.state.currentStep == 2) {
            return (
                <div>
                    <span>恭喜添加成功！</span>
                </div>
            );
        } else {
            return null;
        }
    }

    renderBaseInfoBox() {
        return (
            <div style={CoachSaveStyles.editLeftBox}>
                <div style={CoachSaveStyles.infoBox}>
                    <div style={CoachSaveStyles.baseInfoItem}>
                        <div style={CoachSaveStyles.baseInfoLeftBox}>
                            <span>用户：</span>
                        </div>
                        <div onClick={this.showUserList}>
                            <Select
                                showArrow={false}
                                style={FormStyles.input_middle}
                                placeholder="选择用户"
                                // disabled={true}
                                value={this.state.user ? this.state.user.nickname : undefined}
                                dropdownStyle={{ display: "none" }}>
                            {/* {this.props.communityOption} */}
                            </Select>
                        </div>

                        {/* <a 
                            href="javascript:void(0)" 
                            style={CoachSaveStyles.createUserLink}
                            onClick={this.createUser}
                        >新建用户</a> */}
                    </div>
                    {
                        this.state.user ?
                        <div style={CoachSaveStyles.baseInfoItem}>
                            <div style={CoachSaveStyles.baseInfoLeftBox}>
                                <span>手机号：</span>
                            </div>
                            <Input
                                allowClear
                                style={FormStyles.input_middle}
                                placeholder="请填写手机号"
                                value={this.state.user.phone}
                                onChange={e => {
                                    let inputText = e.target.value;
                                    this.setState({ user: {...this.state.user, phone: inputText} })
                                }}
                                />
                        </div>
                        :
                        null
                    }
                </div>

                {
                    this.state.user ?
                        <div style={CoachSaveStyles.infoBox}>
                            <div style={CoachSaveStyles.baseInfoItem}>
                                <div style={CoachSaveStyles.baseInfoLeftBox}>
                                    <span>姓名：</span>
                                </div>
                                <Input
                                    allowClear
                                    style={FormStyles.input_middle}
                                    placeholder="请填写姓名"
                                    value={this.state.user.username}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        this.setState({ user: {...this.state.user, username: inputText} })
                                    }}
                                    />
                            </div>

                            <div style={CoachSaveStyles.baseInfoItem}>
                                <div style={CoachSaveStyles.baseInfoLeftBox}>
                                    <span>身份证号：</span>
                                </div>
                                <Input
                                    allowClear
                                    style={FormStyles.input_middle}
                                    placeholder="身份证号"
                                    value={this.state.user.useridentity}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        this.setState({ user: {...this.state.user, useridentity: inputText} })
                                    }}
                                    />
                            </div>
                        </div>
                        :
                        null
                    }
            </div>
        );
    }

    renderIdentifyBox() {
        const uploadButton = (
            <div style={CoachSaveStyles.uploadButton}>
            <PlusOutlined />
            <div> 上传</div>
            </div>
        );

        if (this.state.user) {
            return (
                <div style={CoachSaveStyles.editRightBox}>
                    <div style={CoachSaveStyles.infoBox}>
                        <div style={CoachSaveStyles.identifyItem}>
                            <div style={CoachSaveStyles.identifyLeftBox}>
                                <span>身份证正面：</span>
                            </div>
                                <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={this.beforeUpload}
                                onChange={info => {
                                    this.handleUploadChange(info, 0);
                                }}   
                                >
                                    {this.state.user.identityfront ? <img style={{width: 256, height: 108, objectFit: 'cover'}} src={this.state.user.identityfront} alt="avatar" /> : uploadButton}
                                </Upload>
                        </div>

                        <div style={CoachSaveStyles.identifyItem}>
                            <div style={CoachSaveStyles.identifyLeftBox}>
                                <span>身份证背面：</span>
                            </div>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={this.beforeUpload}
                                    onChange={info => {
                                        this.handleUploadChange(info, 1);
                                    }}
                                    >
                                        {this.state.user.identityback ? <img style={{width: 256, height: 108, objectFit: 'cover'}} src={this.state.user.identityback} alt="avatar" /> : uploadButton}
                                    </Upload>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderCoachInfo() {
        return (
            <div style={CoachSaveStyles.editSingleBox}>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>教练类型 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Select
                        style={CoachSaveStyles.cardType_Select}
                        placeholder="请选择"
                        value={this.state.coach.coachtype}
                        onChange={this.handleCoachTypeChange}
                        >
                        <Option value={1}>全职</Option>
                        <Option value={2}>兼职</Option>
                        </Select>
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>联系地址 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <TextArea 
                            placeholder="请填写地址" 
                            style={CoachSaveStyles.addressInput}
                            autoSize={{ minRows: 2, maxRows: 6 }} 
                            value={this.state.coach.address}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ coach: { ...this.state.coach, address: inputText } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>大班课提成（元）:</span>
                    </div>
                        <div style={FormStyles.rightBox}>
                            <InputNumber
                            min={0}
                            value={this.state.coach.basicsalary / 100}
                            onChange={number => {
                                this.setState({ coach: { ...this.state.coach, basicsalary: number * 100 } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>私教课提成（元）:</span>
                    </div>
                        <div style={FormStyles.rightBox}>
                            <InputNumber
                            min={0}
                            value={this.state.coach.publicpay / 100}
                            onChange={number => {
                                this.setState({ coach: { ...this.state.coach, publicpay: number * 100 } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>底薪（元）:</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                        min={0}
                        value={this.state.coach.privatepay / 100}
                        onChange={number => {
                            this.setState({ coach: { ...this.state.coach, privatepay: number * 100 } })
                        }}
                    />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox}>
                        <span>入职时间：</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <DatePicker 
                            size='default' 
                            placeholder='入职时间' 
                            showTime={true}
                            onChange={(date, dateString) => {
                                let entrytime = date.unix();
                                this.setState({
                                    coach: {
                                        ...this.state.coach, 
                                        entrytime
                                    }
                                });
                            }}
                            value={this.state.coach.entrytime ? moment.unix(this.state.coach.entrytime) : undefined}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderOperate() {
        let buttonTitle = '';
        switch (this.state.currentStep) {
            case 0:
                buttonTitle = '下一步';
                break;
            case 1:
                buttonTitle = '提交';
                break;
            case 2:
                buttonTitle = '完成';
                break;
            default:

        }

        let visible = false;
        if (this.state.currentStep === 0 && this.state.user) {
            visible = true;
        } else if (this.state.currentStep === 1) {
            visible = true;
        } else if (this.state.currentStep === 2) {
            visible = true;
        }

        return (
            <div style={CoachSaveStyles.operateBox}>

                {
                    this.state.currentStep === 1 ?
                        <Button
                            style={CoachSaveStyles.clearButton}
                            onClick={this.handlePreStepButtonClick}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        >上一步</Button>
                        :
                        null
                }

                {
                    visible ?
                        <Button
                            type="primary"
                            style={CoachSaveStyles.saveButton}
                            onClick={this.handleSaveButtonClick}
                        >{buttonTitle}</Button>
                        :
                        null
                }
            </div>
        );
    }

    renderHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderHeader()
                }

                <div style={TableStyles.tableBox}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '60%'}}>
                            <Steps current={this.state.currentStep}>
                                <Step title="确认信息"  />
                                <Step title="填写资料" />
                                <Step title="完成" />
                            </Steps>
                        </div>
                    </div>

                    {
                        this.renderStepContent()
                    }

                    {
                        this.renderOperate()
                    }
                </div>
            
                <Modal
                    visible={this.state.userListVisible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ userListVisible: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <UserSelectView 
                        selectType={3}
                        selectedUser={this.selectedUser} />
                </Modal>
                
            </div>
            </Spin>
        )
    }
}

const mapStateToProps = store => {
  // const managerInfoData = store['managerlist'];
  const utilStore = store['utilStore'];
  // debugger
  return {
    // page: managerInfoData.page,
    // windowHeight: utilStore.windowHeight,
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.SAVE_COACH.path]
  }
}

const mapDispatchToProps = dispatch => {
    return {
      queryCoachList: params => {
        dispatch(coachActions.queryCoachList(params));
      },
      changeLoading: params => {
        dispatch(coachActions.changeLoading(params));
      }
    }
  }

// export default CreateCommunitytController
export default connect(mapStateToProps, mapDispatchToProps)(SaveCoachController)