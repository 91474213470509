import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class VipChangegrade extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="会员升级/降级" />

                <SectionTitle text="一.什么是会员升级和降级？" />
                <NormalContent text={<span>1.当用户只购买了私教课，那么此时的用户，会成为场馆的私教卡会员，只能够预约所购买的私教课程。</span>} />
                <NormalContent text={<span>2.根据场馆自身的情况，如果场馆的业务希望让购买私教课的会员，也可以预约场馆的团课，那么可以将私教卡会员<Highlight text="升级为私教可约团课" />，这样此会员在私教课有效期间，也可以预约场馆的团课。</span>} />
                <NormalContent text={<span>3.根据场馆自身的情况，如果不想让只购买私教课的会员能够预约团课，那么只要不升级，或者对已升级的会员进行<Highlight text="降级为私教不可约团课" />，就可以了。</span>} />

                <SectionTitle text="二.私教会员升级" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/07476018b1664f38b453d9cb1b41106d.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，找到想要续卡/购课的会员，然后点击这个会员记录后面的“<Highlight text="详情" />”按钮，进入会员详情页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/77ff8117c3f04f6c851b63855f1c4491.jpg" />
                <NormalContent text={<span>2.如图所示，点击右上角的“<Highlight text="升级为私教可约团课" />”按钮，将私教课会员升级为可预约团课。</span>} />
                <NormalContent text={<span>3.会员升级，只适用于在场馆只购买了私教课的会员。</span>} />
                <NormalContent text={<span>4.通过私教卡升级后的会员，在私教课次数用尽或者私教课到期后，则无法预约场馆的团课。</span>} />
                <NormalContent text={<span>5.通过私教卡升级后的会员，可以“降级”，这样在会员只有私教课时，会员将无法再预约场馆的团课。</span>} />

                <SectionTitle text="三.升级后的会员降级" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/07476018b1664f38b453d9cb1b41106d.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，找到想要续卡/购课的会员，然后点击这个会员记录后面的“<Highlight text="详情" />”按钮，进入会员详情页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/60ff70516fcc4ff092ea19236a677e8f.jpg" />
                <NormalContent text={<span>2.如图所示，点击右上角的“<Highlight text="降级为私教不可约团课" />”按钮，将升级后的私教会员降级。</span>} />
                <NormalContent text={<span>3.通过私教升级后的会员，可以“降级”，这样在会员只有私教课时，会员将无法再预约场馆的团课。</span>} />
                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipChangegrade);
