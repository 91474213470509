import React, { Component } from 'react';
import { connect } from 'react-redux';
// 路径
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

// 引入自定义组件
import DescEditView from '../../../view/desc/desc-edit-view';
import { PageStyles, TableStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN_LISTS.VENUE_LIST.children.SAVE_VENUE.children.DESC_EDIT;

class VenueDescEditController extends BaseController {

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            
        </div>
      ),
      onBack: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={TableStyles.tableBox}>
          <DescEditView 
            type={2}
            descid={this.props.descid}
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.descid !== this.props.descid) {
  //     this.props.history.goBack();
  //   }
  // }
}
// 从store接收state数据
const mapStateToProps = store => {
  // 在这里如果id变化了就跳转
  const descStore = store['descStore'];
  return {
    descid: descStore.descid
  }
}
const mapDispatchToProps = dispatch => { return {} };

export default connect(mapStateToProps, mapDispatchToProps)(VenueDescEditController);