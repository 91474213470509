import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';
import { actions as queryActions } from '../../../redux/query-model'
import { actions as coachActions, launchInviteCoach, launchGetCoachList } from '../../../redux/coach-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import { PageStyles, SearchBarStyles, TableStyles } from '../../../style/common-styles';
import SaveCoachController from './coach-save-controller';
import CoachInviteView from '../../../view/coach/coach-invite-view';
import { filterParams } from '../../../util/helper/request-helper';
const NAMESPACE = MAIN_LISTS.COACH_LIST;

const confirm = Modal.confirm;

class CoachListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            dataInfo: {},

            modalInvitingShowing: false
        };

        let that = this;

        this.columns = [
            { title: '姓名', width: '16%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '16%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '教练类型', width: '16%', dataIndex: 'coachtype', key: 'coachtype', align: 'center', 
                render: coachtype => {
                    let str = '-';
                    if (coachtype === 1) str = '全职';
                    else if (coachtype === 2) str = '兼职';
                    return <span>{str}</span> 
                }
            },
            { title: '状态', width: '16%', dataIndex: 'state', key: 'state', align: 'center', render: state => <Tag color={state===1 ? '#87d068' : '#f50'} >{state === 1 ? '在职' : '离职'}</Tag> },
            { title: '入职时间', width: '16%', dataIndex: 'entrytime', key: 'entrytime', align: 'center', render: entrytime => <span>{ entrytime ? LLCDateHelper.formatDate(entrytime) : '-'}</span> },
            { title: '操作', width: '20%', key: 'operate', align: 'center', 
                render: (coach) => {
                    return (
                        <a 
                            href="javascript:void(0)" 
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_COACH.path}`,
                                    state: {
                                        coach
                                    }
                                });
                            }
                        }>编辑</a> 
                    );
                }
            }
        ];

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);

        this.handleInviteUser = this.handleInviteUser.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.queryCoachList = this.queryCoachList.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();
        this.queryCoachList(queryParams.page ? queryParams.page : 1, queryParams);
    }

    queryCoachList(page) {
        let queryParams = this.queryParams();
        queryParams.page = page;
        this.changeLoadingState(true);
        launchGetCoachList(
            filterParams(queryParams),
            res => {
                
                this.setState({
                    dataInfo: res
                });
                this.changeLoadingState(false);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    handleAddButtonClick() {
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_COACH.path}`);
    }

    handleInviteClick() {
        this.setState({
            modalInvitingShowing: true
        });
    }

    handleSearchClick() {
        this.queryCoachList(1)
    }


    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    handleAddButtonClick() {
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_COACH.path}`);
    }

    handleInviteUser(user) {
        console.log(user);
        let that = this;
        confirm({
            title: <span style={{ whiteSpace: 'pre-wrap' }}>{`确认邀请 ${user.username} 加入本场馆 ？`}</span>,
            content: '',
            okText: '确定',
            cancelText: '取消',
            onOk() {
      
                launchInviteCoach(
                    {userid: user.userid},
                    res => {
                        confirm({
                            title: <span style={{ whiteSpace: 'pre-wrap' }}>{`邀请成功，请通知教练确认！`}</span>,
                            content: '',
                            okText: '确定',
                            cancelText: '取消',
                            onOk() {},
                            onCancel() {}
                        });
                    },
                    fail => {
                        message.error(fail);
                    }
                );
            },
            onCancel() {
              
            },
        });
    }

    renderSearchBar() {
        let queryParams = this.queryParams();

        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>姓名</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入"
                            onChange={e => {
                                queryParams.username = e.target.value;
                                this.props.configQueryParams(queryParams);
                            }}
                            value={queryParams.username} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入"
                            onChange={e => {
                                queryParams.phone = e.target.value;
                                this.props.configQueryParams(queryParams);
                            }}
                            value={queryParams.phone} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleInviteClick } type="primary" size="small">邀请教练</Button>
            </div>
          )
        });
    }

    render() {
        let { dataInfo } = this.state;
        return (

            <div style={PageStyles.pageContainer}>
                {
                    this.renderHeader()
                }


                <div style={TableStyles.tableBox}>
                    {
                        this.renderSearchBar()
                    }
                    
                    <Table
                        rowKey={record => record.coachid}
                        loading={this.state.isLoading}
                        columns={this.columns}
                        dataSource={dataInfo.list}
                        scroll={{ 
                            // x: 1200, 
                            // y: 485 
                        }}
                        pagination={{
                            total: dataInfo.totalpage * dataInfo.pagesize,
                            pageSize: dataInfo.pagesize,
                            current: dataInfo.page,
                            onChange: val => {
                              this.queryTagList(val)
                          }
                        }}
                    ></Table>
                </div>

                <Modal
                    visible={this.state.modalInvitingShowing}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ modalInvitingShowing: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <CoachInviteView  
                        selectedUser={this.handleInviteUser}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ queryStore }) => {
    return {
      queryStore
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
      }
    }
  }

// const mapStateToProps = store => {
//   const coachStore = store['coachStore'];
//   const utilStore = store['utilStore'];
//   return {
//     loading: coachStore.loading,
//     coachList: coachStore.coachList,
//     pagesize: coachStore.pagesize,
//     totalpage: coachStore.totalpage,
//     page: coachStore.page,
//     // isShow: utilStore.isShow[PathConstant.COACH_LIST.path]
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     queryCoachList: params => {
//       dispatch(coachActions.queryCoachList(params));
//     },
//     changeLoading: params => {
//       dispatch(coachActions.changeLoading(params));
//     }
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(CoachListController);
