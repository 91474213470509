export const DescEditStyles = {
    container: {
        width: '100%',
        // height: '100%',
        minHeight: '732px',
        backgroundColor: '#fff',
        // background: '#F0F2F5',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    container_modal: {
        width: '100%',
        // height: '100%',
        minHeight: '500px',
        backgroundColor: '#fff',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    editBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '456px',
        height: '600px',
        overflow: 'scroll',
        overflowX: 'hidden',
        paddingBottom: 30,
        paddingLeft:40,
        paddingRight:40,
        border: '1px solid #6EA7E6'
    },
    operateButton: {
        marginTop: 20
    },
    editItem: {
        width: '100%',
        position: 'relative',
        marginTop: '15px'
    },
    editBottomItem: {
        color: '#7AB4EA',
        minWidth: 0,
        marginRight: '14px',
        float: 'left',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    edtiInput: {
        width: '100%',
        height: '95px',
        padding: '10px',
        backgroundColor: '#F6F6F6',
        border: 0,
        resize: "none",
        borderRadius:'2px',
        color: '#333333',
        lineHeight: '25px',
    },
    uploadBox: {
        marginTop: '15px',
        width: '100%',
        // textAlign: 'center'
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
        // backgroundColor: '#F6F6F6',
    },
    uploadButton: {
        width: '356px',
        height: '50px',
        
        // backgroundColor: '#F6F6F6',
    },
    itemImage: {
        width: '356px',
    },
    minusButton: {
        position: 'absolute',
        top: -5,
        right: -5
    }
}