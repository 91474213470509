import React from 'react';
import { Route } from 'react-router-dom';

import { MAIN } from '../../../constants/routeConstants';
import List from './appoint-private-unhandle-controller';
import { PageStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN.children.APPOINTMENT_PRIVATE_UNHANDLE;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      {/* <Route
        path={`${baseUrl}/${children.SAVE_COURSE.path}`}
        component={EDIT} /> */}
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

