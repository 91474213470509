import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class ManagerLogin extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="使用流程" />
                <NormalContent text={<span>新开通ONE ME平台的场馆馆主，建议仔细阅读使用文档，在分别了解各项功能前，先根据下面的流程顺序来熟悉使用会员管理系统。</span>} />
                <SectionTitle text="一.编辑场馆信息" />
                <NormalContent text={<span>馆主需要先根据“<Highlight text="场馆" />”文档来编辑自己的场馆信息，以达到在ONE ME平台上的展示。</span>} />
                <SectionTitle text="二.开通子账户" />
                <NormalContent text={<span>如果场馆需要其他伙伴一起操作，需要根据“<Highlight text="账户" />”文档来开通子账户，分配权限。</span>} />
                <SectionTitle text="三.邀请教练加入" />
                <NormalContent text={<span>在创建课程之前，需要根据“<Highlight text="教练" />”文档，让场馆的教练登录<Highlight text="ONE ME平台" />认证教练，并加入场馆的教练列表中。</span>} />
                <SectionTitle text="四.创建课程" />
                <NormalContent text={<span>根据“<Highlight text="课程" />”文档，为场馆创建课程。</span>} />
                <SectionTitle text="五.加入会员" />
                <NormalContent text={<span>根据“<Highlight text="会员" />”文档，为场馆加入会员。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerLogin);
