import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryTimeTableList: createAction('queryTimeTableList'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordTimeTableList = createAction('recordTimeTableList')

const effects = {
  *queryTimeTableList({ payload }) {
    yield put(changeLoading({ loading: true }))
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_TIMETABLE_COURSE, payload);
    
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {

      let list = response.responseData.result.list;
      let finalArr = collecteCourses(list);

      yield put(changeLoading({ loading: false }))
      yield put(recordTimeTableList(finalArr))
    } else {
      yield put(changeLoading({ loading: false }))
      message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryTimeTableList, effects.queryTimeTableList);
  }
];

// reducer
export const timetableStore = handleActions(
  {
    recordTimeTableList(state, { payload: finalArr }) {
      return {
        ...state,
        
        timetableList: finalArr,
        
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    timetableList: [],
    pagesize: 0,
    totalpage: 0,
    page: 1
  }
)

// 查询课表
export async function launchQueryTimeTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 查询上期课表
export async function launchQueryPreTimeTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_PRE_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 查询下期课表
export async function launchQueryNextTimeTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_NEXT_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 创建课表
export async function launchCreateTimeTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CREATE_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 保存课程
export async function launchSaveTimeTableCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.SAVE_TIMETABLE_COURSE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 更新课表状态
export async function launchUpdateTimeTableState(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.UPDATE_TIMETABLE_STATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 更新课表课程状态
export async function launchUpdateTimeTableCourseState(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.UPDATE_TIMETABLE_COURSE_STATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 查询近期课表
export async function launchQueryRecent(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_RECENT_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 拷贝课表
export async function launchCopyTable(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.COPY_TIMETABLE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}


// 整理课程
function collecteCourses(list) {
  let finalArr = [];

  let preTime = undefined;
  for (let i = 0; i < list.length; i++) {
    if (preTime === list[i].begindate) {

      let row = finalArr[finalArr.length - 1];
      if (list[i].weekdate === '星期一') {
        row.week1.courses.push(list[i]);
      } else if (list[i].weekdate === '星期二') {
        row.week2.courses.push(list[i]);
      } else if (list[i].weekdate === '星期三') {
        row.week3.courses.push(list[i]);
      } else if (list[i].weekdate === '星期四') {
        row.week4.courses.push(list[i]);
      } else if (list[i].weekdate === '星期五') {
        row.week5.courses.push(list[i]);
      } else if (list[i].weekdate === '星期六') {
        row.week6.courses.push(list[i]);
      } else if (list[i].weekdate === '星期日') {
        row.week7.courses.push(list[i]);
      }

    } else {
      let row = {
        begindate: list[i].begindate,
        week1: { courses: [] },
        week2: { courses: [] },
        week3: { courses: [] },
        week4: { courses: [] },
        week5: { courses: [] },
        week6: { courses: [] },
        week7: { courses: [] },
      };

      if (list[i].weekdate === '星期一') {
        row.week1.courses = [list[i]];
      } else if (list[i].weekdate === '星期二') {
        row.week2.courses = [list[i]];
      } else if (list[i].weekdate === '星期三') {
        row.week3.courses = [list[i]];
      } else if (list[i].weekdate === '星期四') {
        row.week4.courses = [list[i]];
      } else if (list[i].weekdate === '星期五') {
        row.week5.courses = [list[i]];
      } else if (list[i].weekdate === '星期六') {
        row.week6.courses = [list[i]];
      } else if (list[i].weekdate === '星期日') {
        row.week7.courses = [list[i]];
      }

      finalArr.push(row);
    }

    preTime = list[i].begindate;
  }

  return finalArr;
}