import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Upload, Card, Spin, Input, Button, Modal, message, Collapse } from 'antd';
import { connect } from 'react-redux';
import { queryBannerInfo, launchSaveBanner } from '../../../redux/banner-model';
import { uploadProjectImageToQiniu } from '../../../util/request';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { BannerListStyles } from '../../../style/page/banner/banner-list-styles';
import BannerEditView from '../../../view/banner/banner-edit-view';
import { PageStyles, TableStyles } from '../../../style/common-styles';
import { EditOutlined, UploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.BANNER_LIST;

const confirm = Modal.confirm;
const Panel = Collapse.Panel;
const { TextArea } = Input;
const { Meta } = Card;

class BannerListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            editingBanner: undefined,

            cacheBanners: {},

            activeKey: "1",
            editModalVisible: false,
            uploadLoading: false
        };

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.saveBanner = this.saveBanner.bind(this);

        this.handleSaveBannerType = this.handleSaveBannerType.bind(this);
        this.handleAddBanner = this.handleAddBanner.bind(this);
        this.handleDeleteBanner = this.handleDeleteBanner.bind(this);

        this.renderPanelExtra = this.renderPanelExtra.bind(this);
        this.renderBanners = this.renderBanners.bind(this);
        this.renderOperateButton = this.renderOperateButton.bind(this);
    }

    componentDidMount() {
        this.changeLoadingState(true);
        queryBannerInfo(
            {},
            response => {
                let banners = response.responseData.result.banners;
                for (let i = 0; i < banners.length; i++) {
                    let aB = banners[i];
                   aB.banners = JSON.parse(aB.banners);
                }

                this.setState({
                    banners
                });
                this.changeLoadingState(false);
            },
            msg => {
                // message.error(msg.message);
                this.changeLoadingState(false);
            }
        );
    }

    handleSaveBannerType(bannerType) {
        let that = this;
        confirm({
        title: '确定要保存修改吗？',
        content: '',
        okText: '确定',
        cancelText: '取消',
        onOk() {
            that.saveBanner(bannerType);
        },
        onCancel() {

        },
        });
    }

    handleAddBanner(bannerType, typeIndex) {
        bannerType.banners.push({
            type: 1,
            poster: undefined,
            sort: bannerType.banners.length
        });

        this.setState({
            banners: this.state.banners,
            editingBanner: bannerType.banners[bannerType.banners.length-1],
            editingTypeIndex: typeIndex,
            editingBannerIndex: bannerType.banners.length-1
        });
    }

    handleDeleteBanner(bannerType, index) {
        let that = this;
        confirm({
        title: '确定要删除该banner吗？',
        content: '',
        okText: '确定',
        cancelText: '取消',
        onOk() {
            bannerType.banners.splice(index, 1);
            that.setState({
                banners: that.state.banners
            });
        },
        onCancel() {

        },
        });
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
        message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 2;
        if (!isPassSizeLimit) {
        message.error('图片大小需小于2Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info, banner) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file, 
                3,
                res => {
                    
                    banner.poster = res.result.url;
                    this.setState({ 
                        banners: this.state.banners,
                        uploadLoading: false 
                    });
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }


    saveBanner(bannerType) {
        this.changeLoadingState(true);
        launchSaveBanner(
            bannerType,
            response => {
                bannerType.isEditing = false;
                this.setState({
                    banners: this.state.banners
                });
                this.changeLoadingState(false);
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        );
    }


    renderPanelExtra(bannerType, index) {
        if (bannerType.isEditing === true) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button
                        size="small"
                        type="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            this.handleSaveBannerType(bannerType);
                        }}
                    >完成</Button>

                    <Button
                        style={{ marginLeft: 20}}
                        size="small"
                        type="danger"
                        onClick={(e) => {
                            e.stopPropagation();
                            bannerType = JSON.parse(this.state.cacheBanners[`${index}`]);
                            bannerType.isEditing = false;
                            this.state.banners.splice(index, 1, bannerType);
                            this.setState({
                                banners: this.state.banners
                            })
                        }}
                    >取消</Button>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        size="small"
                        type="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            let cache = JSON.stringify(bannerType);
                            this.state.cacheBanners[`${index}`] = cache;
                            bannerType.isEditing = true;
                            this.setState({
                                cacheBanners: this.state.cacheBanners,
                                activeKey: `${index+1}`,
                                banners: this.state.banners
                            })
                        }}
                    >编辑</Button>
                </div>
            );
        }
    }

    renderBanners() {
        return (
            <Collapse activeKey={this.state.activeKey} bordered={false} onChange={(key) => { this.setState({ activeKey: key }) }}>
                {
                    this.state.banners && this.state.banners.map((item, index) => {

                        const extra = this.renderPanelExtra(item, index);
                        
                        return (
                            <Panel 
                                header={item.typename} 
                                key={`${index+1}`}
                                extra={extra}
                            >
                                <div style={BannerListStyles.bannerList}>
                                {
                                    item.banners && item.banners.map((aBanner, bannerindex) => {
                                        return (
                                            <Card
                                                hoverable
                                                bodyStyle={{padding: 0}}
                                                style={BannerListStyles.bannerItem}
                                                cover={<div style={BannerListStyles.bannerLogoBox}> <img style={BannerListStyles.bannerLogo} src={aBanner.poster} /> </div>}
                                                actions={item.isEditing ? [
                                                    <div onClick={() => {
                                                        this.setState({
                                                            editingBanner: aBanner,
                                                            editingTypeIndex: index,
                                                            editingBannerIndex: bannerindex,
                                                            editModalVisible: true
                                                        })
                                                    }}>
                                                        <EditOutlined style={{ color: "#333" }} />
                                                        <span style={{ marginLeft: 5, color: '#333', fontSize: '13px' }}>内容</span>
                                                    </div>,
                                                    <Upload
                                                        showUploadList={false}
                                                        beforeUpload={this.beforeUpload}
                                                        onChange={info => {
                                                            this.handleUploadChange(info, aBanner);
                                                        }}   
                                                    >
                                                        <UploadOutlined style={{ color: "#333" }} />
                                                        <span style={{ marginLeft: 5, color: '#333', fontSize: '13px' }}>图片</span>
                                                    </Upload>,
                                                    <div onClick={() => { this.handleDeleteBanner(item, bannerindex) }}>
                                                        <DeleteOutlined style={{ color: '#333' }} />
                                                        <span style={{ marginLeft: 5, color: '#333', fontSize: '13px' }}>删除</span>
                                                    </div>
                                                ] : undefined}

                                            >
                                                {/* <Meta
                                                    title={aBanner.venuename}
                                                    description={item.venuebrief ? item.venuebrief : '-'}
                                                /> */}
                                            </Card>
                                        )
                                    })
                                }

                                {
                                    item.isEditing ?
                                        <Card
                                            onClick={() => {
                                                this.handleAddBanner(item, index);
                                            }}
                                            hoverable
                                            bodyStyle={BannerListStyles.plusBodyStyle}
                                            style={{...BannerListStyles.bannerItem, ...BannerListStyles.plusBodyStyle}}
                                        >
                                            <PlusOutlined style={{fontSize: 50, marginBottom: 25}} />
                                            <Meta
                                                title='添加一个banner'
                                            />
                                        </Card>
                                        :
                                        null
                                }
                            </div>
                            </Panel>
                        )
                    })
                }
            </Collapse>
        );
    }

    renderOperateButton() {
        if (this.state.isEdit === true) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
                style={{ marginTop: 20, marginRight: 20, marginBottom: 20 }}
                type="primary"
                onClick={() => {
                this.setState({
                    isEdit: false
                })

                }}
            >编辑</Button>
            </div>
        );
        } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
                style={{ marginTop: 20, marginRight: 20, marginBottom: 20 }}
                type="primary"
                onClick={this.handleSaveClick}
            >完成</Button>

            <Button
                style={{ marginTop: 20, marginRight: 20, marginBottom: 20 }}
                type="danger"
                onClick={() => {

                this.setState({
                    isEdit: true,
                    bannertype: this.bannertype
                })
                }}
            >取消</Button>
            </div>
        );
        }
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
        //   extra: this.renderOperateButton()
        });
      }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderBanners()
                    }
                </div>

                <Modal
                    visible={this.state.editModalVisible}
                    destroyOnClose={true}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ editModalVisible: false }) }}
                    footer={null}
                    title="编辑banner"
                    width={"500px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <BannerEditView 
                        editingBanner={this.state.editingBanner} 
                        editingTypeIndex={this.state.editingTypeIndex}
                        editingBannerIndex={this.state.editingBannerIndex}
                        okTapped={(editingBanner, typeIndex, bannerIndex) => {  
                            
                            this.state.banners[typeIndex].banners[bannerIndex] = editingBanner;

                            this.setState({
                                banners: this.state.banners,
                                editModalVisible: false
                            });
                        }}
                    />
                </Modal>
            </div>
            </Spin>
        );
    }
}



const mapStateToProps = store => {
  const utilStore = store['utilStore'];
  return {

    // isShow: utilStore.isShow[PathConstant.BANNER_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerListController);
