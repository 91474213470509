import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, DatePicker, TimePicker, Modal, Input, InputNumber } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { priceFen, priceYuan } from '../../util/helper/number-helper';
import { isEmpty } from '../../util/helper/string-helper';
import BaseSelectView from '../base/base-select-view';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import VipCourseSelectView from '../../view/vipcourse/vip-course-select-view';
import CoachSelectView from '../../view/coach/coach-select-view';
import UserSelectView from '../../view/vipuser/user-select-view';
import { launchQueryVipState } from '../../redux/vipuser-model';
import { launchGetCoachTimeTable } from '../../redux/coach-model';
import { launchGetTwopeoplecost } from '../../redux/venue-model';

const { Option } = Select;

const APPOINT_TABLE_TYPE = {
    TABLE_COURSE: 'TABLE_COURSE',
    PRIVATE_COURSE: 'PRIVATE_COURSE',
    COACH_ARRANGE: 'COACH_ARRANGE',
    CAN_APPOINT: 'CAN_APPOINT'
  };

/**
 * 转让会员卡
 */
export default class AppointPrivateView extends BaseSelectView {
    constructor(props) {
        super(props);

        // 转让方
        let appoint = props.appoint;

        this.state = {
            ...this.state,
            appoint: appoint ? appoint : { peoplecount: 1 },

            disabledMinutes: {},
            modalVipCourseShowing: false,
            modalCoachShowing: false
        };

        this.requestTwoPeopleCost = this.requestTwoPeopleCost.bind(this);
        this.queryTimeTable = this.queryTimeTable.bind(this);

        this.handleVipCourseChanged = this.handleVipCourseChanged.bind(this); 
        this.handleCoachChanged = this.handleCoachChanged.bind(this);       
        this.handleAppointItemTapped = this.handleAppointItemTapped.bind(this);
    }

    componentDidMount() {
        if (this.state.appoint.id) {
            this.queryTimeTable();
            
        }

        this.requestTwoPeopleCost();
    }

    requestTwoPeopleCost() {
        
        this.changeLoadingState(true);
        launchGetTwopeoplecost(
            {},
            res => {
                
                this.setState({
                    twopeoplecost: res.twopeoplecost
                });
                this.changeLoadingState(false);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    queryTimeTable() {
        let { appoint } = this.state;
        if (!appoint.daydate || !appoint.coachid) {
            return;
        }
        let params = {
            daydate: appoint.daydate,
            coachid: appoint.coachid,
            courseid: appoint.courseid,
            withworkinfo: 1
        };
        this.changeLoadingState(true);
        launchGetCoachTimeTable(
            params,
            res => {

                let allCourses = [];

                for (let i = 0; i < res.tablecourses.length; i++) {
                    let aTC = res.tablecourses[i];
                    aTC.type = APPOINT_TABLE_TYPE.TABLE_COURSE;
                    allCourses.push(aTC);
                }

                for (let i = 0; i < res.privatecourses.length; i++) {
                    let aPC = res.privatecourses[i];
                    aPC.type = APPOINT_TABLE_TYPE.PRIVATE_COURSE;

                    if (allCourses.length > 0) {
                        for (let j = 0; j < allCourses.length; j++) {
                            let aC = allCourses[j];
                            if (aPC.begintime < aC.begintime) {
                                allCourses.splice(j, 0, aPC);
                                break;
                            } else {
                                if (j === allCourses.length - 1) {
                                    allCourses.push(aPC);
                                    break;
                                  }
                            }
                        }
                    } else {
                        allCourses.push(aPC);
                    }
                
                }


                for (let i = 0; i < res.coacharranges.length; i++) {
                    let aCA = res.coacharranges[i];
                    aCA.type = APPOINT_TABLE_TYPE.COACH_ARRANGE;

                    if (allCourses.length > 0) {
                        for (let j = 0; j < allCourses.length; j++) {
                            let aC = allCourses[j];
                            if (aCA.begintime < aC.begintime) {
                                allCourses.splice(j, 0, aCA);
                                break;
                            } else {
                                if (j === allCourses.length - 1) {
                                    allCourses.push(aCA);
                                    break;
                                }
                            }
                        }
                    } else {
                        allCourses.push(aCA);
                    }
                }

                let coursetime = res.coursetime;
                let workbegintime = res.workbegintime;
                let workendtime = res.workendtime;

                // 开始
                let preC = undefined;
                if (allCourses.length > 0) {
                    for (let i = 0; i < allCourses.length; i++) {
                        let aC = allCourses[i];
                        if (aC.type === APPOINT_TABLE_TYPE.CAN_APPOINT) {
                            continue;
                        }

                        if (i == 0) {
                            if (aC.beginminute - workbegintime >= coursetime) {
                                let begindate = LLCDateHelper.convertMinuteToDate(workbegintime);

                                let aCanAppoint = {
                                type: APPOINT_TABLE_TYPE.CAN_APPOINT,
                                begindate,
                                enddate: aC.begindate,
                                beginminute: workbegintime,
                                endminute: aC.endminute
                                };

                                allCourses.splice(i, 0, aCanAppoint);
                            }
                        } else {
                            if (aC.beginminute - preC.endminute >= coursetime) {

                                let aCanAppoint = {
                                type: APPOINT_TABLE_TYPE.CAN_APPOINT,
                                begindate: preC.enddate,
                                enddate: aC.begindate,
                                beginminute: preC.endminute,
                                endminute: aC.beginminute
                                };
                                
                                allCourses.splice(i, 0, aCanAppoint);
                            }
                        }

                        if (i === allCourses.length - 1) {
                            if (workendtime - aC.endminute >= coursetime) {
                                let enddate = LLCDateHelper.convertMinuteToDate(workendtime);

                                let beginminute = aC.endminute;
                                let begindate = aC.enddate;
                                if (workbegintime > aC.endminute) {
                                    beginminute = workbegintime;
                                    begindate = LLCDateHelper.convertMinuteToDate(beginminute);
                                }

                                let aCanAppoint = {
                                type: APPOINT_TABLE_TYPE.CAN_APPOINT,
                                begindate,
                                enddate,
                                beginminute,
                                endminute: workendtime
                                };

                                allCourses.push(aCanAppoint);
                            }
                        }

                        preC = aC;
                    }
                } else {
                    let begindate = LLCDateHelper.convertMinuteToDate(workbegintime);
                    let enddate = LLCDateHelper.convertMinuteToDate(workendtime);
                    let aCanAppoint = {
                        type: APPOINT_TABLE_TYPE.CAN_APPOINT,
                        begindate,
                        enddate,
                        beginminute: workbegintime,
                        endminute: workendtime
                    };
                    allCourses.push(aCanAppoint);
                }

                console.log(allCourses);
                this.setState({
                    allCourses,
                    appoint: {
                        ...this.state.appoint,
                        begindate: undefined,
                        appointIndex: undefined,
                        appointItem: undefined
                    }
                })
                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    handleAppointItemTapped(aC, index) {
        if (index !== this.state.appointIndex) {
            this.setState({ 
                appointItem: aC,
                appointIndex: index,
                appoint: {
                    ...this.state.appoint,
                    begindate: aC.begindate
                }
            });
        }
    }

    handleVipCourseChanged(e) {
        let { appoint } = this.state;
        appoint.appointid = e.id;
        appoint.courseid = e.courseid;
        appoint.coursename = e.coursename;
        appoint.username = e.username;
        appoint.duration = e.duration;

        if (appoint.coachid !== e.coachid) {
            appoint.coachid = e.coachid;
            appoint.coachname = e.coachname;

            this.queryTimeTable();
        }

        this.setState({
            appoint,
            modalVipCourseShowing: false
        });
    }

    handleCoachChanged(e) {
        let { appoint } = this.state;
        appoint.coachid = e.coachid;
        appoint.coachname = e.username;
        
        this.setState({
            appoint,
            modalCoachShowing: false
        });
    }

    render() {                                                      
        let { appoint, twopeoplecost } = this.state;
        
        return (
            <Spin spinning={this.props.isLoading || this.state.isLoading}>
            <div style={styles.itemContainer}>
                <div style={{ paddingLeft: 250 }}>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>预约课程：</span>
                        </div>

                        <div onClick={() => { 
                            if(!this.state.appoint.id) {
                                this.setState({ modalVipCourseShowing: true }); 
                            }
                        }}>
                            <Select
                                disabled={this.state.appoint.id}
                                style={{...FormStyles.input_middle, width: 200}}
                                placeholder="请选择会员课程"
                                showArrow={false}
                                value={this.state.appoint.coursename}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>上课人数：</span>
                        </div>
                        <div style={VipUserSaveStyles.rightBox}>
                            <Select 
                                disabled={!twopeoplecost || twopeoplecost <= 0}
                                value={appoint.peoplecount}
                                style={{...VipUserSaveStyles.input_middle, width: 200}}
                                onChange={e => {
                                    appoint.peoplecount = e;
                                    this.setState({ appoint });
                                }}>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                            </Select>
                            {/* <InputNumber
                                allowClear
                                disabled={!twoplecost || twoplecost <= 0}
                                style={{...VipUserSaveStyles.input_middle, width: 200}}
                                placeholder="请填写上课人数"
                                value={appoint.peoplecount}
                                onChange={e => {
                                    let inputText = e;
                                    appoint.peoplecount = inputText;
                                    this.setState({ appoint });
                                }}
                            /> */}

                            {/* <span style={VipUserSaveStyles.hintText}>（展示在场馆详情，用于用户联络场馆）</span> */}
                        </div>
                    </div>

                    {
                        appoint.username ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>会员名称：</span>
                                </div>

                                <div onClick={() => { this.setState({ modalVipCourseShowing: true }); }}>
                                    <span>{appoint.username}</span>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        appoint.courseid ? 
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>教练：</span>
                                </div>

                                <div onClick={() => { 
                                    // if(this.state.appoint.id) {
                                        this.setState({ modalCoachShowing: true }); 
                                    // }
                                }}>
                                    <Select
                                        disabled={this.state.appoint.id}
                                        style={{...FormStyles.input_middle, width: 200}}
                                        placeholder="请选择教练"
                                        showArrow={false}
                                        value={appoint.coachname}
                                        dropdownStyle={{ display: "none" }}>
                                    </Select>
                                </div>
                            </div>
                            :
                            null
                    }

                    

                    {
                        appoint.coachid ? 
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>上课日期：</span>
                                </div>

                                <DatePicker
                                    size='default' 
                                    placeholder='请选择上课日期' 
                                    style={{ width: 200 }}
                                    onChange={(date, dateString) => {

                                        this.setState({
                                            appoint: {
                                                ...appoint,
                                                daydate: dateString
                                            }
                                        }, () => {
                                            this.queryTimeTable();
                                        });
                                    }}
                                    value={this.state.appoint.daydate ? moment.unix(LLCDateHelper.timeStrToTimestamp(this.state.appoint.daydate)) : undefined}
                                />
                            </div>
                            :
                            null
                    }

                </div>

                {
                        appoint.daydate ? 
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                {
                                    this.state.allCourses && this.state.allCourses.map((aC, index) => {
                                        let disabledHours = [];
                                        let beginDisabledMinutes = [];
                                        let endDisabledMinutes = [];

                                        let beginHour = undefined;
                                        let endHour = undefined;

                                        if (aC.type === APPOINT_TABLE_TYPE.CAN_APPOINT) {
                                            let beginArr = aC.begindate.split(':');
                                            beginHour = parseInt(beginArr[0]);
                                            let beginMinute = parseInt(beginArr[1]);

                                            let beginMinuteNum = aC.endminute - appoint.duration;
                                            endHour = parseInt(beginMinuteNum / 60);
                                            let endMinute = parseInt(beginMinuteNum % 60);
                                            
                                            for (let i = 0; i < beginHour; i++) {
                                                disabledHours.push(i);
                                            } 
                                            for (let i = endHour+1; i < 25; i++) {
                                                disabledHours.push(i);
                                            } 

                                            
                                            for (let i = 0; i < beginMinute; i++) {
                                                beginDisabledMinutes.push(i);
                                            }

                                            for (let i = endMinute+1; i < 61; i++) {
                                                endDisabledMinutes.push(i);
                                            }
                                        }

                                        return (
                                            <div 
                                                key={`appoint_${index}`}
                                                style={{ 
                                                    cursor: 'pointer',
                                                    display: 'flex', flexDirection: 'column', width: 200, height: 80, marginLeft: 20, marginTop: 20, borderRadius: 8,
                                                    backgroundColor: aC.type === 'CAN_APPOINT' ? '#61D69B' : '#F85633'
                                                }}
                                                onClick={() => {
                                                    if (aC.type === APPOINT_TABLE_TYPE.CAN_APPOINT) {
                                                        this.handleAppointItemTapped(aC, index);
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', width: '100%', padding: 10 }}>
                                                    <span style={{ fontWeight: 'bold', color: '#fff' }}>{`${aC.begindate} ~ ${aC.enddate}`}</span>
                                                </div>    
                                                {
                                                    index === this.state.appointIndex ?
                                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                            <TimePicker
                                                                size='default' 
                                                                placeholder='请选择上课时间' 
                                                                style={{ width: 160 }}
                                                                format="HH:mm"
                                                                disabledHours={(e) => {
                                                                    
                                                                    return disabledHours;
                                                                }}
                                                                disabledMinutes={(selectedHour) => {
                                                                    if (selectedHour === beginHour) {
                                                                        return beginDisabledMinutes;
                                                                    } else if (selectedHour === endHour) {
                                                                        return endDisabledMinutes;
                                                                    } else {
                                                                        return [];
                                                                    }
                                                                }}
                                                                onChange={(time, timeString) => {

                                                                    this.setState({
                                                                        appoint: {
                                                                            ...this.state.appoint,
                                                                            begindate: timeString
                                                                        }
                                                                    })
                                                                }}
                                                                value={appoint.begindate ? moment(appoint.begindate, 'HH:mm') : moment(aC.begindate, 'HH:mm')}
                                                            />
                                                        </div>
                                                        :
                                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span style={{ fontWeight: 'bold', color: '#fff' }}>{aC.type === 'CAN_APPOINT' ? '空' : '已预约'}</span>
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                    }

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                    {
                        appoint.begindate ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { appoint } = this.state;

                                    if (!appoint.begindate) {
                                        message.error('请选择预约时间');
                                        return;
                                    }
                                    if (!appoint.daydate) {
                                        message.error('请选择预约日期');
                                        return;
                                    }
                                    if (!appoint.courseid) {
                                        message.error('请选择预约课程');
                                        return;
                                    }
                                    if (!appoint.coachid) {
                                        message.error('请选择教练');
                                        return;
                                    }

                                    this.props.onSubmit && this.props.onSubmit(this.state.appoint);
                                }}
                            >提交</Button>
                            :
                            null
                    }
                </div>

                <Modal
                    visible={this.state.modalVipCourseShowing}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ modalVipCourseShowing: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <VipCourseSelectView 
                        onRecordSelect={this.handleVipCourseChanged} />
                </Modal>

                <Modal
                    centered={true}
                    visible={this.state.modalCoachShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCoachShowing: false }) }}
                    footer={null}
                >
                    <CoachSelectView 
                        onRecordSelect={this.handleCoachChanged} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}