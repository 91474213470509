
import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, TimePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';
import { FormStyles } from '../../style/common-styles';
import CourseSelectView from './course-select-view';
import CoachSelectView from './coach-select-view';


const confirm = Modal.confirm;
const Option = Select.Option;

class CourseSaveView extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            tcid: props.editTcid ? props.editTcid : undefined, // 课表课程id
            course: props.editCourse ? props.editCourse : undefined, // 课程
            coach: props.editCoach ? props.editCoach : undefined, // 教练
            weekindex: props.editWeekindex ? props.editWeekindex : undefined, // 周索引 （1-7）
            coursedate: props.editCoursedate ? props.editCoursedate : undefined, // 课程开始时间 09:00
            coursetime: props.editCoursetime ? props.editCoursetime : undefined, // 课程开始时间戳 

            coursedateShow: props.coursedateShow ? props.coursedateShow : undefined, // 日期Select展示 星期日（2019-05-05）
            coursedateNoWeek: props.coursedateNoWeek ? props.coursedateNoWeek : undefined, // 日期无星期

            courseListVisible: false,
            coachListVisible: false
        };

        // this.props.onRef(this);

        this.showCourseList = this.showCourseList.bind(this);
        this.selectedCourse = this.selectedCourse.bind(this);

        this.showCoachList = this.showCoachList.bind(this);
        this.selectedCoach = this.selectedCoach.bind(this);

        this.getCourse = this.getCourse.bind(this);
        this.editCourse = this.editCourse.bind(this);

        this.handleWeekIndexChange = this.handleWeekIndexChange.bind(this);
        this.handleCourseTimeChange = this.handleCourseTimeChange.bind(this);
    }

    componentDidMount() {
        
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.editCoursedate) {
    //         this.setState({
    //             tcid: nextProps.editTcid,
    //             coach: nextProps.editCoach,
    //             course: nextProps.editCourse,
    //             weekindex: nextProps.editWeekindex,
    //             coursedate: nextProps.editCoursedate,
    //             coursetime: nextProps.editCoursetime,

    //             coursedateShow: nextProps.coursedateShow,
    //             coursedateNoWeek: nextProps.coursedateNoWeek
    //         });
    //     }
    // }
    
    showCourseList() {
        this.setState({ courseListVisible: true });
    }

    selectedCourse(course) {
        this.setState({
            course,
            courseListVisible: false
        });
    }

    showCoachList() {
        this.setState({ coachListVisible: true });
    }

    selectedCoach(coach) {
        this.setState({
            coach,
            coachListVisible: false
        });
    }

    getCourse() {
        return {
            tcid: this.state.tcid,
            course: this.state.course,
            coach: this.state.coach,
            weekindex: this.state.weekindex,
            coursedate: this.state.coursedate,
            coursetime: this.state.coursetime
        };
    }

    editCourse(info) {
        this.setState({
            tcid: info.tcid,
            course: info.course,
            coach: info.coach,
            weekindex: info.weekindex,
            coursedate: info.coursedate,
            coursetime: info.coursetime
        });
    }

    handleWeekIndexChange(weekindex) {
        
        let coursedateShow = undefined;
        let coursedateNoWeek = undefined;
        switch(weekindex) {
            case 1:
                coursedateNoWeek = this.props.timetable.monday;
                coursedateShow = `星期一 （${this.props.timetable.monday}）`;
                break;
            case 2:
                coursedateNoWeek = this.props.timetable.tuesday;
                coursedateShow = `星期二 （${this.props.timetable.tuesday}）`;
                break;
            case 3:
                coursedateNoWeek = this.props.timetable.wednesday;
                coursedateShow = `星期三 （${this.props.timetable.wednesday}）`;
                break;
            case 4:
                coursedateNoWeek = this.props.timetable.thursday;
                coursedateShow = `星期四 （${this.props.timetable.thursday}）`;
                break;
            case 5:
                coursedateNoWeek = this.props.timetable.friday;
                coursedateShow = `星期五 （${this.props.timetable.friday}）`;
                break;
            case 6:
                coursedateNoWeek = this.props.timetable.saturday;
                coursedateShow = `星期六 （${this.props.timetable.saturday}）`;
                break;
            case 7:
                coursedateNoWeek = this.props.timetable.sunday;
                coursedateShow = `星期日 （${this.props.timetable.sunday}）`;
                break;
            default:
                coursedateShow = undefined;
                coursedateNoWeek = undefined;
        }

        if (this.state.coursedate) {
            let temp = `${coursedateNoWeek} ${this.state.coursedate}:00`;
            let date = new Date(temp);
            let coursetime = Date.parse(date) / 1000;
            console.log(coursetime, 'coursetime');
            this.setState({
                weekindex,
                coursedateShow,
                coursedateNoWeek,
                coursetime
            });
        } else {
            this.setState({
                weekindex,
                coursedateShow,
                coursedateNoWeek
            });
        }
    }

    handleCourseTimeChange(time, timeString) {
        
        if (this.state.coursedateNoWeek) {
        
            let temp = `${this.state.coursedateNoWeek} ${timeString}:00`;
            let date = new Date(temp);
            let coursetime = Date.parse(date) / 1000;
            
            this.setState({
                coursedate: timeString,
                coursetime,
                coursetimeMoment: time
            });
        } else {
            let temp = `1970-01-01 ${timeString}:00`;
            let date = new Date(temp);
            let coursetime = Date.parse(date) / 1000;

            this.setState({
                coursedate: timeString,
                coursetime,
                coursetimeMoment: time
            });
        }
    }

    renderContent() {
        
        return (
            <div>
                <div style={styles.itemFirst}>

                <span style={styles.itemHint}>课程 :</span>
                <div 
                    style={styles.itemInput}
                    onClick={this.showCourseList}>
                    <Select
                        style={FormStyles.input_middle}
                        placeholder="请选择"
                        showArrow={false}
                        value={this.state.course ? this.state.course.coursename : undefined}
                        dropdownStyle={{ display: "none" }}>
                    </Select>
                </div>
                </div>

                <div style={styles.item}>
                <span style={styles.itemHint}>教练 :</span>
                <div 
                    style={styles.itemInput}
                    onClick={this.showCoachList}>
                    <Select
                        style={FormStyles.input_middle}
                        placeholder="请选择"
                        showArrow={false}
                        value={this.state.coach ? this.state.coach.username : undefined}
                        dropdownStyle={{ display: "none" }}>
                    </Select>
                </div>
                </div>

                <div style={styles.item}>
                <span style={styles.itemHint}>上课日期 :</span>
                <div 
                    style={styles.itemInput}
                >
                    <Select
                        style={styles.type_Select}
                        placeholder="请选择"
                        value={this.state.coursedateShow}
                        onChange={this.handleWeekIndexChange}
                        >
                        <Option value={1}>{`星期一 （${this.props.timetable.monday}）`}</Option>
                        <Option value={2}>{`星期二 （${this.props.timetable.tuesday}）`}</Option>
                        <Option value={3}>{`星期三 （${this.props.timetable.wednesday}）`}</Option>
                        <Option value={4}>{`星期四 （${this.props.timetable.thursday}）`}</Option>
                        <Option value={5}>{`星期五 （${this.props.timetable.friday}）`}</Option>
                        <Option value={6}>{`星期六 （${this.props.timetable.saturday}）`}</Option>
                        <Option value={7}>{`星期日 （${this.props.timetable.sunday}）`}</Option>
                    </Select>
                </div>
                </div>

                <div style={styles.item}>
                <span style={styles.itemHint}>时间 :</span>
                <div 
                    style={styles.itemInput}
                >
                    <TimePicker 
                        format={"HH:mm"} 
                        onChange={this.handleCourseTimeChange} 
                        // value={this.state.coursetimeMoment} 
                        value={this.state.coursetime !== undefined ? moment.unix(this.state.coursetime, 'HH:mm'): undefined}
                    />
                </div>
                </div>

                {/* <div style={styles.line}></div> */}


                <div style={styles.operateBox}>
                    
                    <Button 
                        style={{marginRight: 0 }}
                        onClick={() => {
                            this.props.cancelTapped && this.props.cancelTapped();
                    }}>取消</Button>

                    <Button 
                        style={{marginLeft: 40}}
                        type="primary"
                        onClick={() => {
                            let course = this.getCourse();
                            this.props.okTapped && this.props.okTapped(course);
                        }}
                    >确定</Button>
                </div>
            </div>
        );
    }

    render() {
        
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.container}>
                
                {
                    this.renderContent()
                }

                <Modal
                    visible={this.state.courseListVisible}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ courseListVisible: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <CourseSelectView 
                        type={2}
                        selectedCourse={this.selectedCourse} />
                </Modal>

                <Modal
                    visible={this.state.coachListVisible}
                    onOk={this.handleOk}
                    onCancel={() => { 
                        this.setState({ 
                            // editTcid: undefined,
                            coachListVisible: false 
                        }); 
                    }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <CoachSelectView selectedCoach={this.selectedCoach} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
//   const userStore = store['userStore'];
//   const utilStore = store['utilStore'];
  return {
    // loading: userStore.loading,
    // userList: userStore.userList,
    // pagesize: userStore.pagesize,
    // totalpage: userStore.totalpage,
    // page: userStore.page,
    // isShow: utilStore.isShow[PathConstant.USER_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // queryUserList: params => {
    //     dispatch(userActions.queryUserList(params));
    // },
    // queryUnVipUserList: params => {
    //     dispatch(userActions.queryUnVipUserList(params));
    // },
    // changeLoading: params => {
    //     dispatch(userActions.changeLoading(params));
    // }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSaveView);


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemFirst: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10
    },
    itemHint: {
        width: 65, 
        textAlign: 'right',
        
    },
    itemInput: {
        marginLeft: 10
    },
    type_Select: {
        width: 220,
        height: 32,
        // marginLeft: 14
    },
    line: {
        marginTop: 26,
        width: '100%',
        height: 1,
        backgroundColor: '#E8E8E8'
    },
    operateBox: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 28
    }
}