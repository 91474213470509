import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class EditCoach extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="编辑教练" />

                <SectionTitle text="一.填充教练信息" />
                <NormalContent text={<span>1.可以根据场馆情况，填充教练信息，部分信息在接下来的统计功能中，能够协助场馆根据教练的线上课程数目，快速查看教练的授课提成信息等。</span>} />

                <SectionTitle text="二.解除合作" />
                <NormalContent text={<span>1.在编辑教练页面的最下方，有一个“解除合作”按钮，用以解除教练。</span>} />
                <NormalContent text={<span>2.在与教练解除合作前，需要确保<Highlight text="会员私教课" />中没有关联此教练。</span>} />

            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCoach);
