import React from 'react'
import { Tag, Input, Tooltip } from 'antd';
import { isEmpty } from '../../util/helper/string-helper';
import { PlusOutlined } from '@ant-design/icons';

export default class EditablePhoneGroup extends React.Component {

    constructor(props) {
        super(props);

        let tags = [];
        if (!isEmpty(this.props.notifyphone)) {
            tags = this.props.notifyphone.split(',');
        }

        this.state = {
            tags,
            inputVisible: false,
            inputValue: '',
        };

        this.updatePhone = this.updatePhone.bind(this);
    }

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });

    this.updatePhone(tags);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });

    this.updatePhone(tags);
  };

  updatePhone(tags) {
    let notifyphone = '';
    if (tags && tags.length > 0) {
        notifyphone = tags.join(',');
    }
    this.props.updatePhones && this.props.updatePhones(notifyphone);
  }

  saveInputRef = input => (this.input = input);

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag style={{ marginBottom: 10 }} key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 100 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ marginBottom: 10, background: '#fff', borderStyle: 'dashed' }}>
            <PlusOutlined /> 新手机号
          </Tag>
        )}
      </div>
    );
  }
}