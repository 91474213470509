import React from 'react';
import { Table, Statistic, Divider, Modal, DatePicker, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu, Card, Spin, Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment'

// import { Bar } from '@antv/g2plot';
import LLCDateHelper from 'date-helper'
import Decimal from 'decimal.js';
import { priceYuan } from '../../../../util/helper/number-helper';
import { MAIN, MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import { filterParams } from '../../../../util/helper/request-helper';
import FormItem from '../../../../view/common/form-item';
import { actions as queryActions } from '../../../../redux/query-model';
import { TableOperateBarStyles, TableStyles, TableSearchStyles, FormStyles, PageStyles } from '../../../../style/common-styles';
import { isEmpty } from '../../../../util/helper/string-helper';
import { launchGetStaticAppointOverview } from '../../../../redux/static-model';
import { kAdmin_User_Auth, UserAuth } from '../../../../constants/authConstants';
import { launchGetCoachFinance } from '../../../../redux/finance-model';

const NAMESPACE = MAIN.children.STATIC_COACH_FINANCE.children.DETAIL;

const { RangePicker } = DatePicker;

class CoachFinanceDetailController extends BaseController {
    constructor(props) {
        super(props)

        let coach = {};
        let selecteddate = undefined;
        if (this.props.location.state && this.props.location.state.coach) {
            coach = this.props.location.state.coach;
        }
        if (this.props.location.state && this.props.location.state.selecteddate) {
            selecteddate = this.props.location.state.selecteddate;
        }
        

        this.state = {
            ...this.state,
            selecteddate,
            coach,
            courselist: [],
            appointprivatelist: [],
            list: [],
            dataInfo: {}
        };

        this.ccourseClumns = [
            { title: '课程', width: '40%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '', width: '20%', dataIndex: 'username', key: 'username', align: 'center' },
            
            { title: '课时费（元）', width: '20%', key: 'commission', align: 'center', 
                render: course => {
                    if (course.attendance > course.whenpeople && course.addcommission) {
                        return (
                            <span>{priceYuan(course.commission)}（+{priceYuan(course.addcommission)}）</span>
                        )
                    } else {
                        return (
                            <span>{priceYuan(course.commission)}</span>
                        )
                    }
                }
            },
            { title: '课程时间', width: '20%', dataIndex: 'begintime', key: 'begintime', align: 'center', 
                render: begintime => {
                    return (
                        <span>{LLCDateHelper.formatDate(begintime, "yyyy-MM-dd hh:mm")}</span>
                    )
                }
            },
            
        ];

        this.privateClumns = [
            { title: '课程', width: '40%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '会员', width: '20%', dataIndex: 'username', key: 'username', align: 'center' },
            
            { title: '课时费（元）', width: '20%', key: 'commission', align: 'center', 
                render: course => {
                    return (
                        <span>{priceYuan(course.commission)}</span>
                    )
                }
            },
            { title: '课程时间', width: '20%', dataIndex: 'begintime', key: 'begintime', align: 'center', 
                render: begintime => {
                    return (
                        <span>{LLCDateHelper.formatDate(begintime, "yyyy-MM-dd hh:mm")}</span>
                    )
                }
            },
        ];

        this.requestGetCoachFinance = this.requestGetCoachFinance.bind(this);
        this.onSelectedDateChanged = this.onSelectedDateChanged.bind(this);
       
    }

    componentDidMount() {
        let { selecteddate, coach } = this.state;
        let queryParams = this.queryParams();
        queryParams.selecteddate = selecteddate;
        queryParams.userid = coach.coachid;

        this.props.configQueryParams(queryParams);
        this.requestGetCoachFinance(queryParams);
    }

    requestGetCoachFinance(extra) {
        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }

        this.changeLoadingState(true);
        launchGetCoachFinance(
            filterParams(queryParams),
            res => {
                // let list = this.calculateFinance(res.list);
                let { courselist, appointprivatelist } = res;
                this.setState({
                    // list,
                    courselist,
                    appointprivatelist
                });

                this.calculateFinance(courselist, appointprivatelist);

                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    calculateFinance(courselist, appointprivatelist) {
        

        let coursefee = 0;
        for (let c = 0; c < courselist.length; c++) {
            let aCourse = courselist[c];
            coursefee += aCourse.commission;

            if (aCourse.attendance > aCourse.whenpeople && aCourse.addcommission) {
                coursefee += aCourse.addcommission;
            }
        }

        let privatefee = 0;
        for (let p = 0; p < appointprivatelist.length; p++) {
            let aPrivate = appointprivatelist[p];
            privatefee += aPrivate.commission;
        }

        let staticPrice = coursefee + privatefee;

        this.setState({
            staticPrice
        });
    }

    onSelectedDateChanged(date, dateString) {
        this.setState({ selecteddate: dateString });

        let queryParams = this.queryParams();
        queryParams.selecteddate = dateString;
        this.props.configQueryParams(queryParams);

        this.requestGetCoachFinance(queryParams);
    }

   
    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    renderHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleSaveButtonClick } type="primary" size="small">保存</Button>
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        let { list, selecteddate, courselist, appointprivatelist, staticPrice } = this.state;
        let dateValue = undefined;
        if (selecteddate) {
            selecteddate = selecteddate+"-01"
            dateValue = LLCDateHelper.timeStrToTimestamp(selecteddate);
        }

        return (
            <Spin spinning={this.state.isLoading}>
            <div style={{...PageStyles.pageContainer, backgroundColor: '#fff', width: '100%'}}>
                {
                    this.renderHeader()
                }
                
                <div style={{...TableStyles.tableBox, marginTop: 60}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span>统计日期：</span>
                                    <DatePicker value={moment.unix(dateValue)} onChange={this.onSelectedDateChanged} picker="month" />
                                </div>
                                <Divider />
                                <Statistic title="教练总课时费" value={priceYuan(staticPrice)} />
                            </Card>
                        </div>
                        <div style={{ width: 30 }} />
                       
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                        <Divider orientation="left">
                            <span style={{ fontWeight: '900', color: '#1990FF' }}>团课上课记录（{courselist.length}节）</span>
                        </Divider>
                        <Table
                            rowKey={record => record.tcid}
                            // loading={this.props.loading}
                            columns={this.ccourseClumns}
                            dataSource={courselist}
                            scroll={{ 
                                // x: 1200, 
                                // y: 485 
                            }}
                            // pagination={}
                        ></Table>

                        <Divider orientation="left"><span style={{ fontWeight: '900', color: '#1990FF' }}>私教课上课记录（{appointprivatelist.length}节）</span></Divider>
                        <Table
                            rowKey={record => record.id}
                            // loading={this.props.loading}
                            columns={this.privateClumns}
                            dataSource={appointprivatelist}
                            scroll={{ 
                                // x: 1200, 
                                // y: 485 
                            }}
                            // pagination={}
                        ></Table>

                        {/* <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 0, paddingTop: 20, paddingRight: 0, paddingBottom: 10 }}>
                            <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <span style={ queryParams.tabIndex === 1 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(1) }}>团课预约{ this.state.dataInfo.appoints ? `（${this.state.dataInfo.appoints.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 2 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(2) }}>私教课预约{ this.state.dataInfo.privates ? `（${this.state.dataInfo.privates.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 3 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(3) }}>体验课预约{ this.state.dataInfo.experiences ? `（${this.state.dataInfo.experiences.length}）` : '' }</span>
                                </div>

                                {
                                    this.renderTab()
                                }
                            </div>
                            <div style={{ marginTop: 20, width: '100%', height: 1, backgroundColor: '#E2E2E2' }} />

                            <div style={{ paddingLeft: 20, paddingRight:20, width: '100%' }}>
                                {
                                    this.renderTabContent()
                                }
                            </div>
                        </Card> */}
                    </div>
                </div>

                
            </div>
            </Spin>
        );
    }
}


const mapStateToProps = ({ queryStore, loginStore }) => {
    
    return {
        queryStore,
        user: loginStore.user
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachFinanceDetailController);

const styles = {
    tabTitle: { marginLeft: 20 },
    tabTitle_select: { marginLeft: 20, color: '#1875F6' },
    groupTitle: { marginLeft: 20 },
    groupTitle_select: { marginLeft: 20, color: '#1875F6' }
}