
export const BackgroundStyles = {

    // 顶部信息栏
    topBox: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '21px',
        // position: 'fixed',
        // backgroundColor: '#fff',
        backgroundColor: '#f9f9f9',
    },

    infoBox: {
        display: 'flex',
        flex: 1
    },

    operateBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 15
    },

    operateItem: {
        color: 'rgba(0,0,0,0.65)',
        marginLeft: 10,
        cursor: 'pointer'
    },

    operateItemSep: {
        width: 1,
        height: 15,
        marginLeft: 10,
        backgroundColor: '#E2E2E2',
    },


    // 菜单 Icon
    menuIcon: {
        marginRight: 15,
        width: 14, 
        height: 14, 
        objectFit: 'contain'
    },

    // logo
    logoBox: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'colmun',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoIcon: {
        width:70, 
        height: 70, 
        objectFit: 'contain',
    },
    slideBar: { 
        height: '100%', 
        top: 0, 
        bottom: 0, 
        overflowY: 'scroll', 
        overflowX: 'hidden',          
        position: 'fixed', 
        zIndex: 999
    },
    contentStyle: { 
        marginTop: '30px', 
        display: 'flex', 
        position: 'relative', 
        paddingLeft: '200px', 
        width: '100%', 
        height: '100%', 
        flexDirection: 'column' 
    },
    contentContainer: { 
        display: 'flex',
        padding: 0, 
        boxSizing: 'border-box', 
        background: '#fff', 
        width: '100%',
        height: '100%',
    },
    infoBar: { 
        padding: 0, 
        margin: 0, 
        // backgroundColor: '#fff', 
        backgroundColor: '#f9f9f9', 
        position: 'fixed', 
        zIndex: 900, 
        width: '100%', 
        height: '30px' 
    }
}