import Decimal from 'decimal.js';

export function priceYuan(number) {
    if (!number) return 0;
    return Decimal.div(number, 100).toNumber();
}

export function priceFen(number) {
    if (!number) return 0;
    return Decimal.mul(number, 100).toNumber();
}