import React from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class PicturesWall extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          previewVisible: false,
          previewImage: '',
          fileList: props.defaultPictures ? props.defaultPictures : [],
        };
    }

    beforeUpload = (file) => {
        console.log(file);
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
        
        }
        return false; // 不调用默认的上传方法
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        
        console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        // ?imageMogr2/auto-orient/thumbnail/750x/blur/1x0/quality/75
        
        this.setState({
            previewImage: file.url ? file.url : file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }) => {
        
        this.setState({ fileList });
        debugger
        // console.log(fileList, 'fileList');
        this.props.updatePictures && this.props.updatePictures(fileList);
    };

    render() {
        const { previewVisible, previewImage, fileList, thumbList } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div style={{ width: '100%' }}>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    multiple={true}
                    onPreview={this.handlePreview}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    previewFile={ file => {
                        console.log(file);
                    }}
                >
                    {uploadButton}
                </Upload>
                
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}