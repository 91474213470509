import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class EditCourse extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="编辑课程" />

                <SectionTitle text="一.创建/编辑课程" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/4584e89cea8d43d0bcfab7f7b1934f6a.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“课程管理”后，在右侧就会展示当前场馆的课程列表。</span>} />
                <NormalContent text={<span>2.在课程管理页面，点击右上角的“<Highlight text="添加课程" />”以创建新的课程，点击课程列表项的“<Highlight text="编辑"/>”按钮可以编辑现有课程的信息。</span>} />
                <NormalContent text={<span>3.填好课程信息后，点击右上角的”保存“，提示更新成功后，完成课程编辑。</span>} />
                
                <SectionTitle text="二.课程的属性" />
                <KeyValue title="课程名称：" value="课程的名称，在前端（小程序、APP等）展示给用户。" />
                <KeyValue title="课程类型：" value={<span>课程有<Highlight text="团课" />，<Highlight text="私教课" />两种类型。团课用来在课表中安排，会员可以根据课表的时间情况进行预约；私教课需要单独购买，然后根据学员和教练的时间进行预约。</span>} />
                <KeyValue title="课程级别：" value="目前提供初级、中级、高级三种级别可供区分。" />
                <KeyValue title="课程时长：" value="单位为“分钟”。" />
                <KeyValue title="提前取消：" value="用户约课后，最少可提前取消预约的时间。（单位为“小时”）" />
                <KeyValue title="提前预约：" value="用户最少可提前预约的时间。（单位为“小时”）" />
                <KeyValue title="是否可体验：" value={<span>开启后，如果同时场馆设置“<Highlight text="每个用户可购买体验课次数" />”大于0次，则用户可以在前端预约此课程的体验课。（本平台不产生在线交易，具体费用需要在用户到馆后自行收取）</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/816bbbdaf8ef4758b2ad734dcbbcfca3.png" />
                <KeyValue title="课程简介：" value="课程的简单介绍，在前端（小程序、APP等）展示给用户。" />
                <KeyValue title="课程海报：" value="课程的展示图片，在前端（小程序、APP等）展示给用户。" />
                <KeyValue title="状态：" value={<span>当状态为“开启”时，课程才会在课程安排时被搜索到。状态为“关闭”时，则不会。（注意：此状态不会影响<Highlight text="课表课程" />的状态，也不会影响<Highlight text="会员私教课" />的状态。如：1.在课表中安排课程时，或者给会员购买私教课时，只能搜索到状态为“开启”的课程。2.但是已经安排在课表的课程，或者已经绑定给会员的私教课，不会受到此状态的影响。3.如果想要取消课表中的课程，需要到<Highlight text="课表管理" />中操作。）</span>} />
                
                <SectionTitle text="三.※ “课程”与“课表课程”的区别" />
                <NormalContent text={<span>1.在“课程管理”页面中，创建的课程，代表着场馆开设的课程。</span>} />
                <NormalContent text={<span>2.课程，可以被使用在多个场景中。比如可以将课程安排到<Highlight text="团课课表" />中，成为<Highlight text="课表课程" />；也可以在会员购买私教课时，绑定给会员，成为<Highlight text="会员私教课" />。</span>} />
                <NormalContent text={<span>3.不论是课表课程，还是会员私教课，都已经不受绑定前的课程修改所影响。比如在将一个课程插入到课表中或者绑定给会员私教课后，再修改课程的信息，如名称，时间等。都不会影响课表课程和会员私教课。这是一种“快照”操作，好比用户在商城购买商品，购买成功后，已经购买的商品，不会因为这个商城后期对商品的修改而变动。</span>} />
                <NormalContent text={<span>4.<Highlight text="※" /> 所以，在课程已经安排到课表之后，如果想修改课表中课程的信息，<Highlight text="需要在“课程管理”中修改课程的信息后，删除旧的课表课程，再重新加入到课表中" />。（比如课表中的 课程A 的时长为45分钟，如果想将时长改为60分钟，那么需要先将这个课程A的时长改为60分钟，再将课表中的课程A删除，再重新加入课程A。）</span>} />
              
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
