
import * as DominConfigs from './dominConstants';

/** 七牛 */
export const UPLOAD_TO_QiNiu = 'https://up-z1.qiniup.com'; // 上传七牛

/**
 * optmanager
 *****************************/
export const MANAGER_LOGIN = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/login`; // 登录
export const ENTER_VENUE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/entervenue`; // 选择场馆
export const CHANGE_PASSWD = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/updatepasswd`; // 更新自身账号密码
export const QUERY_MANAGER_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/getmanagers`; // 查询管理员列表
export const SAVE_MANAGER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/addmanager`; // 新建保存管理员
export const UPDATE_MANAGER_STATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/updatestate`; // 更新管理员状态
export const CHANGE_MANAGER_PASSWD = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/updatemanagerpasswd`; // 更新管理员密码


/**
 * optvipcardmold
 *****************************/
export const QUERY_VIPCARD_MOLD_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD_MOLD}/moldlist`; // 查询会员卡模列表
export const SAVE_VIPCARD_MOLD = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD_MOLD}/savemold`; // 保存会员卡模
export const UPDATE_VIPCARD_MOLD_STATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD_MOLD}/updatestate`; // 更新会员卡模状态

/**
 * optuser
 *****************************/
export const QUERY_USER_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USER}/userlist`; // 查询用户列表
export const QUERY_COACH_USER_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USER}/coachuserlist`; 
export const QUERY_UNVIP_USER_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USER}/unvipuserlist`; // 查询用户列表
export const HANDLE_USER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USER}/handleuser`; // 开通用户/保存用户认证信息
export const UPDATE_USER_TMP_NAME = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USER}/updatetmpname`; // 更新用户临时姓名


/**
 * optvipcard
 *****************************/
export const QUERY_VIPCARD_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/cardlist`; // 查询会员列表
export const QUERY_USER_VIPCARD = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/usecard`; // 查询用户会员卡
export const QUERY_VIPCARD_RELATED = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/related`; // 查询用户会员卡相关
export const BUY_PRIVATECOURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/buyprivatecourse`; // 购课
export const PROLONG_VIP = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/prolong`; // 延长时效
export const TRANSFER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/transfer`; // 转让
export const HANDLE_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/handlevipuser`; // 开通会员
export const FREEZE_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/freeze`; // 冻结
export const RESUME_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/resume`; // 恢复
export const RENEW_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/renew`; // 续卡
export const UPGRADE_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/upgrade`; // 升级
export const DEGRADE_VIPUSER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/degrade`; // 降级
export const UPDATE_VIP_USERNAME = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/updateusername`; // 修改会员姓名

export const GET_VIP_APPLY_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/vipapplylist`; 
export const CANCEL_VIP_APPLY = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCARD}/cancelvipapply`; 


/**
 * optcoach
 *****************************/
export const QUERY_COACH_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/coachlist`; // 查询教练列表
export const SAVE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/savecoach`; // 保存教练信息
export const INVITE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/invite`; 
export const RELEASE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/release`; 
export const GET_COACH_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/timetable`; 
export const GET_COACH_TIMETABLE_IGNORE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COACH}/timetableignore`; 

/**
 * optcourse
 *****************************/
export const QUERY_COURSE_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE}/courselist`; // 查询课程列表
export const SAVE_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE}/savecourse`; // 保存课程信息
export const ADD_COURSE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE}/savecoursecoach`; 
export const GET_COURSE_COACH_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE}/coursecoachlist`; 
export const UPDATE_COURSE_COACH_STATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE}/updatecoursecoahstate`; 


/**
 * opttimetable
 *****************************/
export const QUERY_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/timetable`; // 查询课表
export const QUERY_RECENT_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/recent`; // 查询近期课表
export const QUERY_PRE_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/pretable`; // 查询上期课表
export const QUERY_NEXT_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/nexttable`; // 查询下期课表
export const CREATE_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/createtable`; // 创建课表
export const COPY_TIMETABLE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/copytable`; // 拷贝课表
export const UPDATE_TIMETABLE_STATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE}/updatestate`; // 更新课表状态

/**
 * opttimetablecourse
 *****************************/
export const SAVE_TIMETABLE_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE_COURSE}/savecourse`; // 保存课表课程
export const QUERY_TIMETABLE_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE_COURSE}/courselist`; // 查询课表课程
export const UPDATE_TIMETABLE_COURSE_STATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TIMETABLE_COURSE}/updatestate`; // 查询课表课程状态

/**
 * optappointment
 *****************************/
export const APPOINTMENT_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/appointment`; // 预约课程
export const QUERY_TIMETABLE_COURSE_WITH_APPOINTMENT = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/courselist`; // 查询课表课程带有预约信息
export const QUERY_APPOINTMENT_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/appointmentlist`; // 查询课表课程预约列表
export const CUT_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/cutcourse`; // 用户旷课
export const CANCEL_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/cancelcourse`; // 用户取消预约
export const GET_APPOINTPRIVATE_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/privatelist`; 
export const CANCEL_APPOINTPRIVATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/cancelprivate`;
export const ADD_APPOINTPRIVATE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/addprivate`;
export const CHANGE_APPOINTPRIVATE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/changecoach`;
export const CHANGE_APPOINTPRIVATE_DATEINFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/changedateinfo`;
export const GET_UNHANDLE_APPOINT_NOTIFY_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_APPOINTMENT}/unhandleprivatenotify`;


/**
 * optvenue
 *****************************/
export const QUERY_VENUE_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VENUE}/list`; // 查询场馆列表
export const SAVE_VENUE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VENUE}/save`; // 保存场馆
export const GET_VENUE_DETAIL = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VENUE}/detail`; 
export const GET_TWO_PEOPLE_COST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VENUE}/gettwopeoplecost`; 

/**
 * optbanner
 *****************************/
export const QUERY_BANNER_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_BANNER}/list`; // 查询banner
export const SAVE_BANNER_TYPE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_BANNER}/save`; // 保存bannerType


/**
 * opttag
 *****************************/
export const SAVE_TAG = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TAG}/save`;
export const QUERY_TAG_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_TAG}/list`;

/**
 * optcourse
 *****************************/
export const GET_VIPCOURSES_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/list`;
export const GET_VIP_COURSES = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/vipcourses`;
export const PROLONG_COURSES = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/prolong`;
export const PROTIMES_COURSES = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/protimes`;

export const CHANGE_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/changecoach`;
export const TRANSFER_COURSE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/transfer`;
export const GET_VALID_PROVATECOURSES = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_VIPCOURSE}/validprivatecourses`;

/**
 * optcourseexperience
 *****************************/
export const GET_COURSES_EXPERIENCE_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/list`;
export const APPOINT_COURSES_EXPERIENCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/appoint`;
export const CONTACT_COURSES_EXPERIENCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/contact`;
export const CANCEL_COURSES_EXPERIENCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/cancel`;
export const BREAK_COURSES_EXPERIENCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/break`;
export const FINISH_COURSES_EXPERIENCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COURSE_EXPERIENCE}/finish`;

/**
 * optuserauth
 *****************************/
export const QUERY_USERAUTH_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USERAUTH}/list`; // 查询认证列表
export const QUERY_USERAUTH_DETAIL = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USERAUTH}/detail`; 
export const PASS_AUTH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USERAUTH}/pass`; 
export const REJECT_AUTH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_USERAUTH}/reject`; 




/**
 * optcommunity
 *****************************/
export const QUERY_COMMUNITY_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COMMUNITY}/getcommunities.do`; // 查询社群信息
export const SAVE_COMMUNITY = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_COMMUNITY}/savecommunity.do`; // 新建保存社群

/**
 * optdesc
 *****************************/
export const SAVE_DESC_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_DESC}/save`; // 保存图文详情
export const GET_DESC_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_DESC}/get`; // 获得图文详情
/**
 * optactivity
 *****************************/
export const QUERY_ACTIVITY_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_ACTIVITY}/getactivities.do`; // 查询活动信息
/**
 * optform
 */
export const QUERY_FORM_INFO = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FORM}/getactivityforms.do`; // 获取报名表单
export const UPDATE_FORM_AUDIT_STATUS = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FORM}/auditform.do`; // 更新用户报名状态
/**
 * optmold
 */
export const QUERY_ACTIVITY_MOLD = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MOLD}/getactivitymolds.do`; // 获取报名表单


/**
 * optservice 
 *****************************/
export const QueryServices = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_SERVICE}/optqueryservices.do`; // 查询认证信息
export const UpdateServicesToPass = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_SERVICE}/optservicepass.do`; // 查询认证信息
export const UpdateServicesToReject = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_SERVICE}/optservicereject.do`; // 查询认证信息

// 获取上传Token
export const GetUploadToken = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/uploadtoken`;
export const GET_FILE_UPLOADTOKENS = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_MANAGER}/filetokens`; 


/**
 * optstatic
 *****************************/
export const GET_STATIC_APPOINT_OVERVIEW = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_STATIC}/appoint`;

/**
 * optpartner
 *****************************/
export const GET_PARTNER_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_PARTNER}/list`;
export const SAVE_PARTNER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_PARTNER}/save`;


/**
 * optfinance
 *****************************/
export const GET_COACH_FINANCE_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/coachfinancelist`;
export const GET_COACH_FINANCE = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/coachfinance`;
export const GET_COURSE_TIMES_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/coursetimeslist`;
export const GET_COURSE_TIMES_OF_VIP = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/coursetimesofvip`;
export const GET_COURSE_TIMES_OF_COACH = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/coursetimesofcoach`;
export const GET_STATIC_ORDER = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_FINANCE}/staticorder`;


/**
 * optaddress
 *****************************/
export const GET_CITY_INFO_BY_LATLNG = `${DominConfigs.PROXY_PART}${DominConfigs.PART.OPT_ADDRESS}/citybylatlng`;