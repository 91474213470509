import React from 'react';
import { connect } from 'react-redux';
import md5 from 'js-md5';
import { message, AutoComplete, Input, Pagination, Empty, Button } from 'antd';
// import { Q_MAP_KEY } from '../../constants/appConstants';
import '../../style/map.css';
import NumIcon from '../../resources/num_icons.png';
import { CheckOutlined, SmileOutlined } from '@ant-design/icons';
import { launchGetCityInfoByLatlng } from '../../redux/address-model';


const Q_MAP_KEY = 'ONOBZ-BUICJ-A3MFF-KB62E-LESNV-4WBT5';
const { Option } = AutoComplete;
const getSig = function (requestUrl, params) {
  const SECRET_KEY = '0M3tVWkp87P3cO4pLqSm9hfmVo0Xx1yQ';
  return md5(`${requestUrl}?${params.toString()}${SECRET_KEY}`)
}

const LIST_TYPE = {
  "CITY": 'CITY_LIST',
  'POI': 'POI_LIST',
  "NO_RESULTS": "NO_RESULTS"
}

const ICON_POINT_TOP = [0, 35, 70, 105, 140, 175, 210, 245, 280, 315];
const ICON_POINT_LEFT = {
  inactive: 0,
  activated: 27
};
// export default 
class QMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapView = React.createRef();
    // this.infoDiv = React.createRef();
    this.getLocation = this.getLocation.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.searchComplete = this.searchComplete.bind(this);
    this.searchError = this.searchError.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.deleteMarkers = this.deleteMarkers.bind(this);
    this.deleteInfoWins = this.deleteInfoWins.bind(this);
    this.getInfoTab = this.getInfoTab.bind(this);
    this.getListItems = this.getListItems.bind(this);
    this.closeALLInfoWin = this.closeALLInfoWin.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.handlePageNumChange = this.handlePageNumChange.bind(this);
    this.handleConfirmBtnClick = this.handleConfirmBtnClick.bind(this);

    this.markers = [];
    this.activeMarkerIdx = undefined;

    this.infoWins = [];
    this.state = {
      searchList: [],
      listType: LIST_TYPE.NO_RESULTS,
      totalPage: 1,
      currPageIndex: 1,
      totalPoiNum: 0,
      searchKeyword: undefined,
      selectedPoi: undefined,
      activeListItemIdx: undefined
    };
  }

  // 初始化页面地图和搜索服务
  componentDidMount() {
    if (!window.qq) return;
    this.maps = window.qq.maps;
    this.centerLatlng = new this.maps.LatLng(39.916527, 116.397128);
    var myOptions = {
      zoom: 7,
      center: this.centerLatlng,
      mapTypeId: this.maps.MapTypeId.ROADMAP
    }
    this.map = new this.maps.Map(this.mapView.current, myOptions);
    this.searchService = new this.maps.SearchService({
      // panel: this.infoDiv.current,
      // map: this.map,
      autoExtend: true,
      complete: this.searchComplete,
      error: this.searchError
    });
    this.cityService = new this.maps.CityService();
    this.getLocation();
  }

  // 打开时根据浏览器ip获取大致方位
  getLocation() {
    const GET_LOCATION_URL = '/ws/location/v1/ip';
    const params = new URLSearchParams({ key: Q_MAP_KEY });
    params.append('sig', getSig(GET_LOCATION_URL, params));
    fetch(`/qqmap${GET_LOCATION_URL}?${params.toString()}`)
      .then(res => res.json())
      .then(res => {
        if (res.result && res.result.location) {
          const data = res.result;
          this.map.panTo(new this.maps.LatLng(data.location.lat, data.location.lng));
        }
      }).catch(err => {

        message.error(err);
      })
  }

  onInputChange(e) {
    this.setState({ searchKeyword: e.target.value });
    this.searchService.setLocation(null);
    this.searchService.search(e.target.value);
  }

  // 搜索成功回调
  searchComplete({ type, detail }) {
    console.log('----------------\ntype:')
    console.dir(type);
    console.log('detail');
    console.dir(detail);
    this.setState({
      selectedPoi: undefined,
      activeListItemIdx: undefined
    });
    if (type == LIST_TYPE.CITY) {
      if (detail.cities && Array.isArray(detail.cities)) {
        this.setState({
          searchList: detail.cities,
          listType: LIST_TYPE.CITY,
          totalPage: 1
        });
      }
    }
    if (type == LIST_TYPE.POI) {
      if (detail.pois && Array.isArray(detail.pois)) {
        this.setState({
          searchList: detail.pois,
          listType: LIST_TYPE.POI,
          totalPage: detail.pageCapacity + 1,
          totalPoiNum: detail.totalNum,
          currPageIndex: detail.pageIndex + 1
        });
        this.searchService.getLocation();
        const latlngBounds = new this.maps.LatLngBounds();
        this.deleteMarkers();
        this.deleteInfoWins();
        detail.pois.forEach((poi, i) => {
          latlngBounds.extend(poi.latLng);

          var marker = new this.maps.Marker({
            map: this.map,
            position: poi.latLng,
            title: i + 1,
            icon: this.getIcon(i, false)
          });
          var infoWin = new this.maps.InfoWindow({ map: this.map });
          this.markers.push(marker);
          this.infoWins.push(infoWin);

          this.maps.event.addListener(marker, 'click', () => {
            this.closeALLInfoWin();
            this.setActiveMarkerIdx(i);
            infoWin.open();
            infoWin.setContent(`<div style="text-align:center;white-space: nowrap;margin:10px;">${poi.name}</div>`);
            infoWin.setPosition(poi.latLng);
            this.map.panTo(poi.latLng);
            this.setState({ activeListItemIdx: i });
            if (poi.name && poi.id && poi.address) {
              this.setState({ selectedPoi: poi });
            }
          });
          this.maps.event.addListener(marker, 'mouseover', () => {
            marker.setIcon(this.getIcon(i, true));
          });
          this.maps.event.addListener(marker, 'mouseout', () => {
            if (this.activeMarkerIdx == i) return;
            marker.setIcon(this.getIcon(i, false));
          });
          /* marker.setTitle(i + 1);
          marker.setIcon(new this.maps.MarkerImage(
            NumIcon,
            new this.maps.Size(23, 35),
            new this.maps.Point(ICON_POINT_LEFT.inactive, ICON_POINT_TOP[i])
          )); */
        })
        this.map.fitBounds(latlngBounds);
      }
    }
    console.log('----------------------')
  }

  searchError(err) {
    this.setState({
      listType: LIST_TYPE.NO_RESULTS,
      totalPage: 1,
      currPageIndex: 1,
      totalPoiNum: 0,
      searchKeyword: undefined,
      selectedPoi: undefined,
      activeListItemIdx: undefined
    });
    console.error(err);
  }

  setActiveMarkerIdx = i => {
    this.markers.forEach((marker, idx) => marker.setIcon(this.getIcon(idx, false)));
    this.markers[i].setIcon(this.getIcon(i, true));
    this.activeMarkerIdx = i;
  }
  // 生成一个地图上Marker, num + 1为图片显示数字, active==true高亮 
  getIcon(num, active) {
    return new this.maps.MarkerImage(
      NumIcon,
      new this.maps.Size(23, 35),
      new this.maps.Point(active ? ICON_POINT_LEFT.activated : ICON_POINT_LEFT.inactive, ICON_POINT_TOP[num])
    );
  }

  // 删除所有marker
  deleteMarkers() {
    if (this.markers) {
      this.markers.forEach(marker => marker.setMap(null));
      this.markers.length = 0;
    }
    this.activeMarkerIdx = undefined;

  }

  // 删除所有悬浮框InfoWin
  deleteInfoWins() {
    if (this.infoWins) {
      this.infoWins.forEach(infoWin => infoWin.setMap(null));
      this.infoWins.length = 0;
    }
  }

  // 关闭页面所有悬浮框infoWin
  closeALLInfoWin() {
    if (this.infoWins.length > 0) {
      this.infoWins.forEach(infoWin => infoWin.close());
    }
  }

  // 用于AutoComplate的dataSource
  getOptions() {
    const { searchList, listType } = this.state;
    if (!Array.isArray(searchList) || searchList.length == 0) {
      return null;
    }
    switch (listType) {
      case LIST_TYPE.CITY:
        return searchList.map(city =>
          <Option
            key={`${city.resultNum}${city.cityName}`}>
            <span>{city.cityName}</span>
          </Option>);
      case LIST_TYPE.POI:
        return searchList.map(poi =>
          <Option
            key={`${poi.latLng.lat}${poi.latLng.lng}${poi.name}`}>
            <span>{poi.name}</span> {poi.address ? <span>{`- ${poi.address}`}</span> : null}
          </Option>);
      case LIST_TYPE.NO_RESULTS:
      default:
        return null;
    }
  }

  // 搜索结果列表上方一行字
  getInfoTab() {
    function InfoTab({
      listType = LIST_TYPE.NO_RESULTS,
      resultNum = 0
    }) {
      switch (listType) {
        case LIST_TYPE.NO_RESULTS:
          return null;
        case LIST_TYPE.POI:
          return <span style={{ fontSize: '12px' }}>共有<span style={{ fontWeight: 'bold' }}>{resultNum}</span>条相关结果</span>;
        case LIST_TYPE.CITY:
          return null;//<span>点击在此地区查看相关结果</span>
      }
    }
    return <InfoTab
      listType={this.state.listType}
      resultNum={this.state.totalPoiNum}
    />;
  }

  // 搜索结果列表每一项
  getListItems() {
    const { searchList, listType, activeListItemIdx } = this.state;
    if (!Array.isArray(searchList) || searchList.length == 0) {
      return null;
    }
    switch (listType) {
      case LIST_TYPE.CITY:
        return searchList.map((city, idx) =>
          <ListItem
            key={`${city.resultNum}${city.cityName}`}
            num={idx + 1}
            title={city.cityName}
            onClick={() => {
              this.searchService.setLocation(city.cityName);
              this.searchService.search(this.state.searchKeyword);
            }}
            description={`${city.resultNum}条相关地点`} />);
      case LIST_TYPE.POI:
        return searchList.map((poi, idx) =>
          <ListItem
            key={`${poi.latLng.lat}${poi.latLng.lng}${poi.name}`}
            title={poi.name}
            num={idx + 1}
            active={activeListItemIdx == idx}
            description={poi.address && poi.address}
            onClick={() => {
              this.closeALLInfoWin();
              this.setActiveMarkerIdx(idx);
              this.infoWins[idx].open();
              this.infoWins[idx].setContent(`<div style="text-align:center;white-space:nowrap;margin:10px;">${poi.name}</div>`);
              this.infoWins[idx].setPosition(poi.latLng);
              this.map.panTo(poi.latLng);
              this.setState({ activeListItemIdx: idx });
              if (poi.name && poi.id && poi.address) {
                this.setState({ selectedPoi: poi });
              }
            }}
            onMouseEnter={() => { this.markers[idx].setIcon(this.getIcon(idx, true)) }}
            onMouseLeave={() => {
              if (this.activeMarkerIdx == idx) return;
              this.markers[idx].setIcon(this.getIcon(idx, false));
            }} />);
      case LIST_TYPE.NO_RESULTS:
      default:
        return null;
    }
  }

  handlePageNumChange(pageIndex) {
    this.searchService.setPageIndex(pageIndex - 1);
    this.searchService.search(this.state.searchKeyword);
  }

  handleConfirmBtnClick() {
    const { selectedPoi } = this.state;
    const _this = this;
    if (selectedPoi.latLng) {
      
      // this.cityService.searchCityByLatLng(selectedPoi.latLng);
      
      // https://apis.map.qq.com/ws/geocoder/v1/?key=ONOBZ-BUICJ-A3MFF-KB62E-LESNV-4WBT5&location=31.229215,121.440601
      // const GET_LOCATION_URL = '/ws/geocoder/v1/';
      // const params = new URLSearchParams({ key: Q_MAP_KEY, location: `${selectedPoi.latLng.lat},${selectedPoi.latLng.lng}` });
      

      // this.changeLoadingState(true);
      launchGetCityInfoByLatlng(
            {lat: selectedPoi.latLng.lat, lng: selectedPoi.latLng.lng},
            response => {
              // console.log(response);
                // this.changeLoadingState(false);
                // message.success(response.msg);

                let { resp } = response;
                let res = JSON.parse(resp);
                let result = res.result;
                let ad_info = result.ad_info;
                selectedPoi.district = ad_info.district;
                selectedPoi.city = ad_info.city;
                selectedPoi.province = ad_info.province;
                
                _this.props.onConfirm(selectedPoi);
            },
            msg => {
                // this.changeLoadingState(false);
                message.error(msg);
            }
        );
      // launchGetCityInfoByLatlng

      // fetch(`https://apis.map.qq.com${GET_LOCATION_URL}?${params.toString()}`)
      // .then(res => res.json())
      // .then(res => {
      //   let result = res.result;
      //   let ad_info = result.ad_info;
      //   selectedPoi.district = ad_info.district;
      //   selectedPoi.city = ad_info.city;
      //   selectedPoi.province = ad_info.province;
        
      //   _this.props.onConfirm(selectedPoi);

      //   // if (res.result && res.result.location) {
      //   //   const data = res.result;
      //   //   this.map.panTo(new this.maps.LatLng(data.location.lat, data.location.lng));
      //   // }
      //   console.log(res);
      // }).catch(err => {

      //   message.error(err);
      // })
      
      // this.cityService.setComplete(function (results) {
      //   let detail = results.detail.detail;
      //   if (!detail) return;
      //   detail = detail.split(',');
      //   if (detail.length === 3) {
      //     selectedPoi.district = detail[0];
      //     selectedPoi.city = detail[1];
      //     selectedPoi.province = detail[1];
      //   }
      //   if (detail.length === 4) {
      //     selectedPoi.district = detail[0];
      //     selectedPoi.city = detail[1];
      //     selectedPoi.province = detail[2];
      //   }
      //   _this.props.onConfirm(selectedPoi);
      // });
      // this.cityService.setError(function (e) {
      //   console.log(e);
      //   message.error("获取地点信息出错");
      // });
    } else {
      message.warn("选择的地点并非一个具体位置");
    }
    // const { selectedPoi } = this.state;
    // this.props.onConfirm({
    // addressname: selectedPoi.name,
    // addresslat: selectedPoi.latLng.lat,
    // addresslng: selectedPoi.latLng.lng,
    // addressid: selectedPoi.id,
    // addressdetail: selectedPoi.address
    // });
  }

  render() {
    const mapView = <div ref={this.mapView} style={{ width: '800px', height: '650px', marginTop: '5px' }} />;
    const { searchList } = this.state;
    const isListEmpty = this.state.listType == LIST_TYPE.NO_RESULTS;
    let pagination = null;
    if (this.state.listType == LIST_TYPE.POI) {
      pagination = <Pagination
        simple
        hideOnSinglePage
        total={this.state.totalPoiNum}
        pageSize={10}
        current={this.state.currPageIndex}
        onChange={this.handlePageNumChange} />;
    }
    return (
      <div className="map" style={{ display: 'flex', flexFlow: 'row no-wrap' }}>
        <div className="left-bar" style={{ width: '300px', height: '650px', marginRight: '5px', paddingTop: '5px' }}>
          {/* <AutoComplete 
             onSearch={this.onInputChange}
           dataSource={this.getOptions()}
           >*/}
          <Input.Group compact>
            <Input.Search
              style={{ width: '200px' }}
              // size="large"
              placeholder="请输入要搜索的地点"
              onChange={this.onInputChange}
              onSearch={() => {
                this.searchService.setLocation(null);
                this.searchService.search(this.state.searchKeyword);
              }}
            // suffix={<Icon type="caret-down" theme="filled" />}
            />
            <Button
              style={{ width: '100px' }}
              type="primary"
              onClick={this.handleConfirmBtnClick}
              disabled={this.state.selectedPoi ? false : true}>选择此地址</Button>
          </Input.Group>
          {/* </AutoComplete> */}
          {/* <div ref={this.infoDiv} /> */}
          <div style={{ width: '100%', height: '600px', display: 'flex', flexDirection: 'column', justifyContent: isListEmpty ? 'center' : 'flex-start' }}>
            {isListEmpty ? <CustomizeEmpty /> : null}
            {this.getInfoTab()}
            {this.getListItems()}
            {pagination}
          </div>
        </div>
        {mapView}
      </div>
    );
  }
}

function ListItem({
  title = '',
  description = '',
  num = '',
  onMouseEnter = () => { },
  onMouseLeave = () => { },
  onClick = () => { },
  active = false
}) {
  return <div
    className={`list-item ${description ? '' : 'small'} ${active ? 'active' : ''}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}>
    <div className="left-box">
      <span className="num">{num}</span>
    </div>
    <div className="right-box">
      <div className={`title`}>{title}</div>
      {description ? <div className="description">{description}</div> : null}
    </div>
    {active ? <CheckOutlined className="checked-icon" /> : null}
  </div>;
}

function CustomizeEmpty() {
  return (
    <div style={{ textAlign: 'center' }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>Data Not Found</p>
    </div>
  );
}
/**
 *  POI_LIST	POI列表。
    CITY_LIST	POI检索结果城市列表。
    AREA_INFO	行政区划信息。
    GEO_INFO	地理位置信息。
    STATION_INFO	公交站点信息。
    LINE_INFO	公交线路信息。
    TRANSFER_INFO	公交换乘信息。
    DRIVING_INFO	驾车线路信息。
    MULTI_DESTINATION	起点或终点位置不唯一。
 */



    const mapStateToProps = store => {
     
      return {
         
      }
    }
    
    const mapDispatchToProps = dispatch => {
      return {
          dispatch,
        
      }
    }
  
  export default connect(mapStateToProps, mapDispatchToProps)(QMap);