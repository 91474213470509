import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { actions as managerActions, launchUpdateManagerState, launchChangePasswd } from '../../../redux/manager-model';
import LLCDateHelper from "date-helper";
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { UserAuth_List, UserAuth_KeyValue } from '../../../constants/authConstants';
import ChangePasswdModal from '../../../view/manager/change-passwd-modal';
import { ManagerListStyles } from '../../../style/page/manager/manager-list-styles';
import { PageStyles, TableStyles } from '../../../style/common-styles';
import { PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.MANAGER_LIST;

const confirm = Modal.confirm;

class ManagerListController extends BaseController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    };

    this.updateState = this.updateState.bind(this);

    this.handleUpdateManagerStateClick = this.handleUpdateManagerStateClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleChangePasswdClick = this.handleChangePasswdClick.bind(this);
    this.changePasswdConfirmed = this.changePasswdConfirmed.bind(this);

    this.columns = [
    { title: '姓名', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
    { title: '登录账号', width: '10%', dataIndex: 'accountname', key: 'accountname', align: 'center' },
    { title: '手机号', width: '10%', dataIndex: 'phone', key: 'phone', align: 'center' },
    { title: '权限', width: '45%', dataIndex: 'userauth', key: 'userauth', align: 'center', 
      render: userauth => {
          if (userauth && userauth.length > 0) {
            let authList = userauth.split(',');
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {
                        authList && authList.map((item, index) => {
                            
                            return (
                                <Tag key={`type_${index}`} style={{ marginRight: 5, marginBottom: 5 }}>{UserAuth_KeyValue[item]}</Tag>
                            )
                        })
                    }
                </div>
            )
          } else {
            return null;
          }
      }
    },
    { title: '创建时间', width: '10%', dataIndex: 'createtime', key: 'createtime', align: 'center', render: createtime => {return <span>{LLCDateHelper.formatDate(createtime)}</span> }},
    { title: '操作', width: '15%', key: 'operate', align: 'center',
      render: (manager) => {
        return (
          <div style={ManagerListStyles.operateBox}>
            <a
              href="javascript:void(0)" 
              onClick={() => {
                this.handleChangePasswdClick(manager);
              }}
            >修改密码</a>

            <a 
              style={ManagerListStyles.stateButton}
              href="javascript:void(0)" 
              onClick={() => {
                // this.handleUpdateManagerStateClick(manager);
                this.props.history.push({
                  pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_MANAGER.path}`,
                  state: {
                    manager
                  }
              });
              }}
            >编辑</a>

            <a 
              style={ManagerListStyles.stateButton}
              href="javascript:void(0)" 
              onClick={() => {
                this.handleUpdateManagerStateClick(manager);
              }}
            >{manager.state === 1 ? '禁用' : '恢复'}</a>
          </div>
        );
      }    
    }]
  }

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">新增管理员</Button>
        </div>
      )
    });
}

  render() {
    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={TableStyles.tableBox}>
          
          <Table
            className="manager-table"
            rowKey={record => record.userid}
            loading={this.props.loading}
            columns={this.columns}
            dataSource={this.props.managerList}
            // scroll={{ y: 485 }}
            pagination={{
              total: this.props.totalpage * this.props.pagesize,
              pageSize: this.props.pagesize,
              current: this.props.page,
              onChange: val => {
                this.queryManagerInfo(val)
            }
          }}
          ></Table>
        </div>

        <ChangePasswdModal 
          ref={changePasswdModal => this.changePasswdModal = changePasswdModal} 
          okTapped={this.changePasswdConfirmed}
        />
      </div>
    );
  }
  componentDidMount() {
    this.props.queryManagerInfo({ page: this.props.page });
  }
  // 搜索所有community
  queryManagerInfo = page => {
    this.props.queryManagerInfo({
      page
    });
  }
  
  // 新建账号按钮点击
  handleAddButtonClick = e => {
    this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_MANAGER.path}`);
  }

  // 修改密码
  handleChangePasswdClick(manager) {
    this.changePasswdModal && this.changePasswdModal.show(manager);
  }

  // 禁用/恢复按钮点击
  handleUpdateManagerStateClick(manager) {
    let operate = manager.state === 1 ? '禁用' : '恢复';
    let title = `确认要${operate}管理员：${manager.username} 吗？`;
    let content = manager.state === 1 ? '禁用期间，账户无法登录。' : '恢复后，可正常登录。'

    let that = this;
    confirm({
      title,
      content,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        let state = 1;
        if (manager.state === 1) {
          state = 2;
        }
        that.updateState({ userid: manager.userid, state });
      },
      onCancel() {
        
      },
    });
  }

  // 更新管理员状态
  updateState(params) {
    this.props.changeLoading(true);
    launchUpdateManagerState(
      params,
      response => {
        this.props.changeLoading(false);
        message.success('更新成功');
        this.props.queryManagerInfo({page: this.props.page});
      },
      msg => {
        this.props.changeLoading(false);
        message.error(msg);
      }
    )
  }

  // 修改管理员密码
  changePasswdConfirmed(params) {
    launchChangePasswd(
      params,
      response => {
        message.success('更新成功');
        this.changePasswdModal && this.changePasswdModal.hide();
      },
      msg => {
        message.error(msg);
      }
    )
  }
}



const mapStateToProps = store => {
  const managerInfoData = store['managerStore'];
  const utilStore = store['utilStore'];
  return {
    loading: managerInfoData.loading,
    managerList: managerInfoData.managerList,
    pagesize: managerInfoData.pagesize,
    totalpage: managerInfoData.totalpage,
    page: managerInfoData.page,
    // isShow: utilStore.isShow[PathConstant.MANAGER_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryManagerInfo: params => {
      dispatch(managerActions.queryManagerInfo(params));
    },
    changeLoading: params => {
      dispatch(managerActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerListController);
