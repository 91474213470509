import React from 'react';
import { Layout, Menu, message, Modal, Popover } from 'antd';
import { connect } from 'react-redux';
import { Link, Route, Redirect } from 'react-router-dom';
import Exception from 'ant-design-pro/lib/Exception';
import 'ant-design-pro/dist/ant-design-pro.css';
import cookie from 'cookie-lza';
// css
import '../../style/background.css'
// import creatHistory from 'history/createHashHistory' 
import { actions as loginActions, launchChangePasswd } from '../../redux/loginModel'
import { actions as utilActions } from '../../redux/util';
import { actions as queryActions } from '../../redux/query-model'
import { PageRoutes } from '../../constants/routeConstants';

import OverViewController from './overview/index';
import TagsListController from './tags/index';
import VipCardListController from './vipcard/index';
import VipUserListController from './vipuser/index';
import VipCourseListController from './vipcourse/index';
import CoachListController from './coach/index';
import CourseListController from './course/index';
import TimtTableListController from './timetable/index';
import AppointmentController from './appointment/index';
import BannerListController from './banner/index';
import VenueListController from './venue/index';
import UserListController from './user/index';
import ManagerListController from './manager/index';
import AppointPrivateListController from './appoint-private/index';
import CourseExperienceListController from './course-experience/index';
import UserAuthController from './user-auth/index';
import AppointPrivateUnhandleController from './appoint-private-unhandle/index';
import PartnerListController from './admin/partner/index';
import StaticCoachFinance from './finance/coach-finance/index';
import StaticCourseTimes from './finance/course-times/index';
import StaticOrder from './finance/static-order/index';

import ChangePasswdModal from '../../view/manager/change-passwd-modal';

import MENU_VIPCARD_MOLD from '../../resources/menu_vipcard_mold.png';
import MENU_USER from '../../resources/menu_user.png';
import MENU_VIPUSER from '../../resources/menu_vipuser.png';
import MENU_COACH from '../../resources/menu_coach.png';
import MENU_COURSE from '../../resources/menu_course.png';
import MENU_APPOINTMENT from '../../resources/menu_appointment.png';
import MENU_VIPCOURSE from '../../resources/menue_vipcourse.png';
import MENU_VENUESTATIC from '../../resources/menu_venuestatic.png';
import MENU_MARKET from '../../resources/menu_market.png';
import MENU_VENUE from '../../resources/menu_venue.png';
import MENU_MANAGER from '../../resources/menu_manager.png';
import MENU_BANNER from '../../resources/menue_banner.png';
import MENU_SCHEDULE from '../../resources/menue_schedule.png';
import MENU_TAGS from '../../resources/menu_tags.png';
import MENU_AUTH from '../../resources/menu_auth.png';
import MENU_PARTNER from '../../resources/menu_partner.png';
import MENU_STATIC from '../../resources/menu_static.png';


import { BackgroundStyles } from '../../style/background-styles';
import { kAdmin_User_Auth, kSuper_Admin_Auth, UserAuth } from '../../constants/authConstants';
import { PageStyles } from '../../style/common-styles';

const MAIN = PageRoutes.MAIN;
const MAIN_LISTS = MAIN.children;

const { Sider, Content } = Layout;
const confirm = Modal.confirm;
const { SubMenu } = Menu;

class BackgroundController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }

    this.handleChangePasswdClick = this.handleChangePasswdClick.bind(this);
    this.changePasswdConfirmed = this.changePasswdConfirmed.bind(this);
  }

  // 渲染信息栏
  renderInfoBar() {
    return (
      <div style={BackgroundStyles.topBox}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '200px' }}>

          <span style={{ cursor: 'pointer', marginLeft: 24, color: '#333', fontSize: '13px' }} onClick={() => {
            window.open(`https://${window.location.host}/help/use-flow`, '_blank');
          }}>帮助</span>

          <Popover 
            getPopupContainer={ triggerNode => { return triggerNode.parentNode || document.body; }}
            content={
              <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', alignItems: 'center' }}>
                  <img src="http://qiniu-media.soso-code.com/oneme/pic/help/93f15e89d9724dd6a0736155ce043a40.jpg" style={{ width: 150, height: 150, objectFit: 'contain' }} />
                  <span style={{ fontSize: '12px', marginTop: 5 }}>使用微信扫描小程序码</span>
              </div>
           }
          >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 24, }}>
            <img src="http://qiniu-media.soso-code.com/oneme/pic/help/ee6ddc4db3c4442795531dddc94dfc76.jpg" style={{ width: 20, height: 20 }} mode="contain" />
            <span style={{ cursor: 'pointer', color: '#333', fontSize: '13px', marginLeft: 5 }} onClick={() => {
              window.open(`http://${window.location.host}/help/use-flow`, '_blank');
            }}>ONE ME小程序</span>
          </div>
          </Popover>

          
        </div>


        <div style={BackgroundStyles.operateBox}>

          {/* 用户信息 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>{this.props.user ? this.props.user.username : ''}</span>
            <div style={BackgroundStyles.operateItemSep} />
            <a
              onClick={this.handleChangePasswdClick}
              style={BackgroundStyles.operateItem}
            >修改密码</a>
            <div style={BackgroundStyles.operateItemSep} />
            <a
              onClick={this.handleLogoutClick}
              style={BackgroundStyles.operateItem}
            >退出</a>
          </div>
        </div>
      </div>
    );
  }

  // 渲染Logo
  renderLogo() {
    console.log(this.props.enterVenue);
    // debugger;
    return (
      <div
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5, marginBottom: 10 }}>

        {
          this.props.enterVenue ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img 
                src={this.props.enterVenue.venuelogo}
                style={{ width: 100, height: 100, objectFit: 'cover' }} 
                alt="" 
              />

              <span style={{ color: '#fff', marginTop: 10, fontWeight: 'bold' }}>{this.props.enterVenue.venuename}</span>
            </div>
            :
            null
        }
      </div>
    );
  }

  // 渲染菜单
  renderMenu() {
    let { user } = this.props;
    let authList = [];
    if (user.userauth) {
      authList = user.userauth.split(',');
    }

    if (this.props.user.authrole === kSuper_Admin_Auth) { // 超级管理员
      return (
        <Menu
          style={{ textAlign: 'left' }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[this.props.match.params.rootPath]}
          onClick={() => {
            this.props.clearQueryParams();
          }}
        >
          <Menu.Item key={MAIN_LISTS.TAGS_LIST.path}> 
            <MenuItem path={MAIN_LISTS.TAGS_LIST} type='img' src={MENU_TAGS} />
          </Menu.Item>

          <Menu.Item key={MAIN_LISTS.USER_AUTH_LIST.path}>
            <MenuItem path={MAIN_LISTS.USER_AUTH_LIST} type='img' src={MENU_AUTH} />
          </Menu.Item>

          <Menu.Item key={MAIN_LISTS.PARTNER.path}>
            <MenuItem path={MAIN_LISTS.PARTNER} type='img' src={MENU_PARTNER} />
          </Menu.Item>

          <Menu.Item key={MAIN_LISTS.USER_LIST.path}>
            <MenuItem path={MAIN_LISTS.USER_LIST} type='img' src={MENU_USER} />
          </Menu.Item> 
        </Menu>
      );
    }

    console.log(user, user.authrole, authList);
    // debugger
    return (
      <Menu
        style={{ textAlign: 'left' }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[this.props.match.params.rootPath]}
        onClick={({ item, key, keyPath, domEvent }) => {
          this.props.clearQueryParams();
        }}
      >
        
        <Menu.Item key={MAIN_LISTS.OVER_VIEW.path}>
          <MenuItem path={MAIN_LISTS.OVER_VIEW} type='img' src={MENU_VENUESTATIC} />
        </Menu.Item>

        {
          user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.STATIC) !== -1 ?
            <SubMenu
              key="static-sub-menu"
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={MENU_STATIC} style={BackgroundStyles.menuIcon} alt="" />
                  <span>财务统计</span>
                </div>
              }
            >
              <Menu.Item key={MAIN_LISTS.STATIC_COACH_FINANCE.path}>
                <MenuItem path={MAIN_LISTS.STATIC_COACH_FINANCE} type='img' src={MENU_COACH} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.STATIC_COURSE_TIMES.path}>
                <MenuItem path={MAIN_LISTS.STATIC_COURSE_TIMES} type='img' src={MENU_COURSE} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.STATIC_ORDER.path}>
                <MenuItem path={MAIN_LISTS.STATIC_ORDER} type='img' src={MENU_VIPCARD_MOLD} />
              </Menu.Item>
            </SubMenu>
            :
            null
        }

        {
          user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.VIPUSER) !== -1 ?
            <SubMenu
              key="vip-sub-menu"
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={MENU_USER} style={BackgroundStyles.menuIcon} alt="" />
                  <span>用户管理</span>
                </div>
              }
            >
              <Menu.Item key={MAIN_LISTS.VIPCARD_LIST.path}>
                <MenuItem path={MAIN_LISTS.VIPCARD_LIST} type='img' src={MENU_VIPCARD_MOLD} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.VIPUSER_LIST.path}>
                <MenuItem path={MAIN_LISTS.VIPUSER_LIST} type='img' src={MENU_VIPUSER} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.VIPCOURSE_LIST.path}>
                <MenuItem path={MAIN_LISTS.VIPCOURSE_LIST} type='img' src={MENU_VIPCOURSE} />
              </Menu.Item>
            </SubMenu>
            :
            null
        }

        {
          user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COACH) !== -1 ?
            <Menu.Item key={MAIN_LISTS.COACH_LIST.path}>
              <MenuItem path={MAIN_LISTS.COACH_LIST} type='img' src={MENU_COACH} />
            </Menu.Item>
            :
            null
        }

        {
          user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1 ?
            <SubMenu
              key="course-sub-menu"
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={MENU_COURSE} style={BackgroundStyles.menuIcon} alt="" />
                  <span>课程管理</span>
                </div>
              }
            >
              <Menu.Item key={MAIN_LISTS.COURSE_LIST.path}>
                <MenuItem path={MAIN_LISTS.COURSE_LIST} type='img' src={MENU_COURSE} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.TIMETABLE_LIST.path}>
                <MenuItem path={MAIN_LISTS.TIMETABLE_LIST} type='img' src={MENU_SCHEDULE} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.APPOINTMENT_TABLE.path}>
                <MenuItem path={MAIN_LISTS.APPOINTMENT_TABLE} type='img' src={MENU_APPOINTMENT} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.APPOINTMENT_PRIVATE.path}>
                <MenuItem path={MAIN_LISTS.APPOINTMENT_PRIVATE} type='img' src={MENU_APPOINTMENT} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.APPOINTMENT_PRIVATE_UNHANDLE.path}>
                <MenuItem path={MAIN_LISTS.APPOINTMENT_PRIVATE_UNHANDLE} type='img' src={MENU_APPOINTMENT} />
              </Menu.Item>

              <Menu.Item key={MAIN_LISTS.COURSE_EXPERIENCE.path}>
                <MenuItem path={MAIN_LISTS.COURSE_EXPERIENCE} type='img' src={MENU_APPOINTMENT} />
              </Menu.Item>
            </SubMenu>
            :
            null
        }

        {
          user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.MARKET) !== -1 ?
            <SubMenu
              key="market-sub-menu"
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={MENU_MARKET} style={BackgroundStyles.menuIcon} alt="" />
                  <span>营销管理</span>
                </div>
              }
            >

              <Menu.Item key={MAIN_LISTS.BANNER_LIST.path}>
                <MenuItem path={MAIN_LISTS.BANNER_LIST} type='img' src={MENU_BANNER} />
              </Menu.Item>

            </SubMenu>
            :
            null
        }

        {
          this.props.user.authrole === kAdmin_User_Auth ?
            <Menu.Item key={MAIN_LISTS.VENUE_LIST.path}>
              <MenuItem path={MAIN_LISTS.VENUE_LIST} type='img' src={MENU_VENUE} />
            </Menu.Item>
            :
            null
        }
        {
          this.props.user.authrole === kAdmin_User_Auth ?
            <Menu.Item key={MAIN_LISTS.MANAGER_LIST.path}>
              <MenuItem path={MAIN_LISTS.MANAGER_LIST} type='img' src={MENU_MANAGER} />
            </Menu.Item>
            : null
        }

      </Menu>
    );
  }

  render() {
    let { loginState, user } = this.props;
    if (!loginState || loginState === 0) {
      return <Redirect to={'/login'}/>;
    }

    let authList = [];
    if (user.userauth) {
      authList = user.userauth.split(',');
    }

    const NotMatch = <div style={PageStyles.pageContainer}>
      <Exception type="404" backText="返回首页" redirect={'/login'} />
    </div>;
    
    return (
      <Layout>
        <Sider style={BackgroundStyles.slideBar}>
          {
            this.renderLogo()
          }
          
          {
            this.renderMenu()
          }
        </Sider>
        <Layout>
          <ChangePasswdModal
            ref={changePasswdModal => this.changePasswdModal = changePasswdModal} 
            okTapped={this.changePasswdConfirmed}
          />

        <div style={BackgroundStyles.infoBar}>
          {
            this.renderInfoBar()
          }
        </div>

        <Content style={{ marginTop: '30px', display: 'flex', position: 'relative', paddingLeft: '200px', width: '100%', height: '100%', flexDirection: 'column' }}>
        <div style={{ 
              display: 'flex',
              padding: 0, 
              boxSizing: 'border-box', 
              background: '#fff', 
              width: '100%',
              height: '100%',
            }}>
            <Route path={`/${MAIN.path}/:rootPath?/:subPath?`}
              render={(props) => {
                switch (this.props.match.params.rootPath) {
                  
                  // 超级管理员
                  case MAIN_LISTS.TAGS_LIST.path: // 标签管理
                    if (user && user.authrole === kSuper_Admin_Auth) {
                      return <TagsListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.USER_AUTH_LIST.path: // 用户认证管理
                    if (user && user.authrole === kSuper_Admin_Auth) {
                      return <UserAuthController {...props} />;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.USER_LIST.path: // 用户管理
                    if (user && user.authrole === kSuper_Admin_Auth) {
                      return <UserListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.PARTNER.path: // 合作商家管理
                    if (user && user.authrole === kSuper_Admin_Auth) {
                      return <PartnerListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                    

                  // 店铺管理员
                  case MAIN_LISTS.MANAGER_LIST.path:
                    if (user && user.authrole === kAdmin_User_Auth) {
                      return <ManagerListController {...props} />;
                    } else {
                      return NotMatch;
                    }

                  case MAIN_LISTS.OVER_VIEW.path:
                    return <OverViewController {...props}/>;

                  
                  // 财务
                  // 教练
                  case MAIN_LISTS.STATIC_COACH_FINANCE.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.STATIC) !== -1)) {
                      return <StaticCoachFinance {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  // 课程
                  case MAIN_LISTS.STATIC_COURSE_TIMES.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.STATIC) !== -1)) {
                      return <StaticCourseTimes {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  // 订单
                  case MAIN_LISTS.STATIC_ORDER.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.STATIC) !== -1)) {
                      return <StaticOrder {...props}/>;
                    } else {
                      return NotMatch;
                    }
                    

                  // 会员
                  case MAIN_LISTS.VIPCARD_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.VIPUSER) !== -1)) {
                      return <VipCardListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.VIPUSER_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.VIPUSER) !== -1)) {
                      return <VipUserListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.VIPCOURSE_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.VIPUSER) !== -1)) {
                      return <VipCourseListController {...props}/>;
                    } else {
                      return NotMatch;
                    }

                  // 教练
                  case MAIN_LISTS.COACH_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COACH) !== -1)) {
                      return <CoachListController {...props}/>;
                    } else {
                      return NotMatch;
                    }

                  // 课程
                  case MAIN_LISTS.COURSE_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <CourseListController {...props}/>;
                    } else {
                      return NotMatch;
                    }

                  case MAIN_LISTS.TIMETABLE_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <TimtTableListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.APPOINTMENT_TABLE.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <AppointmentController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.APPOINTMENT_PRIVATE.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <AppointPrivateListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.COURSE_EXPERIENCE.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <CourseExperienceListController {...props}/>;
                    } else {
                      return NotMatch;
                    }
                  case MAIN_LISTS.APPOINTMENT_PRIVATE_UNHANDLE.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                      return <AppointPrivateUnhandleController {...props} />;
                    } else {
                      return NotMatch;
                    }

                  // 场馆
                  case MAIN_LISTS.VENUE_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth)) {
                      return <VenueListController {...props} />;
                    } else {
                      return NotMatch;
                    }

                  // 营销
                  case MAIN_LISTS.BANNER_LIST.path:
                    if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.MARKET) !== -1)) {
                      return <BannerListController {...props} />;
                    } else {
                      return NotMatch;
                    }
                    
                  default:
                    return NotMatch;
                }
              }}
            />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
  componentDidMount() {
    
  }
  /* 头部显示标题开始 */
  // componentWillMount() {
  //   const { history } = this.props;
  //   history.listen(this.handleLocationChange);
  //   this.handleLocationChange(history.location);
  // }

  handleLocationChange = location => {
    // 在这分析match,按照常量url进行匹配,不是去404
    // 先搜索本身看本身的名字,看是否有父级,如果有父级直接把父级的url放到返回中.
    // 如果有上一级,绑定上一级的链接到返回三角上
    let paths = location.pathname.split("/");
    let headerName = '',rootPath = '',localPath = '';
    localPath = paths[paths.length - 1];
    let PathConstant = MAIN_LISTS.children;
    for (let key in PathConstant)
      if (PathConstant[key].path === paths[paths.length - 1]) {
        headerName = PathConstant[key].name;
        if (PathConstant[key].rootPath) {
          // 删除当前path,删除前面一个空缺和项目根路由
          paths.pop();
          paths.shift();
          paths.shift();
          rootPath = paths.join('/');
        }
      }
    this.props.setPageHiddenState(localPath)
    this.props.setHeaderContent({ title: headerName, rootPath })

    // 在这分析match,按照常量url进行匹配,不是去404
    // 先搜索本身看本身的名字,看是否有父级,如果有父级直接把父级的url放到返回中.
    // 如果有上一级,绑定上一级的链接到返回三角上
    
    // let paths = location.pathname.split("/"); // [httpsL, '', 域名, background, branch, name]
    // let headerName = '', parentPath = '', localPath = '';
    // localPath = paths[paths.length - 1];
    // let rootIndex = paths.findIndex(path => path === MAIN.path);
    // let subPaths = paths.slice(rootIndex); // 去掉https: 域名 root 数组最后的部分
    // const subPathsLength = subPaths.length;
    // let currIndex = 0;
    // const getMatchRoutePath = function (Paths) {
    //   let currPath = subPaths[currIndex];
    //   for (let key in Paths) {
    //     const pathObj = Paths[key];
    //     if (pathObj.path === currPath) {
    //       if (currIndex === subPathsLength - 1) {
    //         return pathObj;
    //       }
    //       else {
    //         currIndex++;
    //         if (pathObj.children) {
    //           return getMatchRoutePath(pathObj.children);
    //         }
    //       }
    //     }
    //   }
    // }

    // const currentPath = getMatchRoutePath(PageRoutes);
    // if (currentPath) {
    //   headerName = currentPath.name;
    //   if (currentPath.parent) {
    //     // 删除当前path,删除前面一个空缺和项目根路由
    //     paths.pop();
    //     paths.shift();
    //     paths.shift();
    //     this.parentPath = paths.join('/')
    //   }
    // }
  }

  handleHistoryClick = () => {
    this.props.history.push(`/${MAIN.path}/${this.parentPath}`);
  }
  /* 头部显示标题结束 */

  // 注销退出,退回登录页
  handleLogoutClick = () => {

    let that = this;
    confirm({
      title: '确定要退出吗？',
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        that.props.logoutUser(that);
      },
      onCancel() {
        
      },
    });
  }

  handleChangePasswdClick() {
    this.changePasswdModal && this.changePasswdModal.show();
  }

  changePasswdConfirmed(params) {
    launchChangePasswd(
      params,
      response => {
        message.success('修改成功，请重新登录', 2.5, ()=> {
          window.location.href = '/login';
        });
        this.changePasswdModal && this.changePasswdModal.hide();
      },
      msg => {
        message.error(msg);
      }
    )
  }
}

const mapStateToProps = state => {
  // 设置header的标题
  const userInfo = state['loginStore'];
  const headerContent = state['utilStore']
  return {
    enterVenue: userInfo.enterVenue,
    user: userInfo.user,
    loginState: userInfo.loginState,
    // userid: userInfo.userid,
    // userauth: userInfo.userauth,
    title: headerContent.title,
    titleDetail: headerContent.titleDetail,
    isRoot: headerContent.isRoot,
    rootPath: headerContent.rootPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // 注销用户具体函数,注销,清空所有store中的值
    logoutUser: params => {
      // 清空localStorage
      localStorage.clear();
      cookie.clear();
      dispatch(loginActions.logoutUser(params))
      params.props.history.push('/login');
    },
    setHeaderContent: params => {
      dispatch(utilActions.setHeaderContent(params));
    },
    setPageHiddenState: params => {
      dispatch(utilActions.setPageHiddenState(params));
    },
    clearQueryParams: params => {
      dispatch(queryActions.clearQueryParams());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundController);


const MenuItem = (props) => {
  return (
    <Link to={'/' + MAIN.path + '/' + props.path.path}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {
          props.type === 'img' ?
            <img src={props.src} style={BackgroundStyles.menuIcon} alt="" />
            :
            null
        }
        
        <span>{props.path.name}</span>
      </div>
    </Link>
  );
}