export const HomeStyles = {
    container: { 
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        width: '100%', 
        position: 'relative', 
        flexShrink:0,
        marginTop: 50
    },
    areaTitle: {
        fontSize: '16px',
        fontFamily: 'Jdahei',
        color: '#000000',
        lineHeight: '19px',
        fontWeight: 'bold'
    },
    serviceItemName: {
        fontSize: '15px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 250,
        color: '#000000',
        lineHeight: '21px'
    },
    serviceItemDesc: {
        width: 288,
        fontSize: '12px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 200,
        color: '#515151',
        lineHeight: '23px'
    },
    productItemIcon: {
        width: 116,
        height: 116
    },
    productItemName: {
        
        fontSize: '12px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 250,
        color: '#000000',
        lineHeight: '17px'
    },
    productItemDesc: {
        marginTop: 13,
        width: 144,
        fontSize: '11px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 200,
        color: '#515151',
        lineHeight: '23px'
    },
    funcItem: {
        display: 'flex', flexDirection: 'column',
        width: 99,
        marginBottom: 21
    },
    funcLogo: {
        width: 25,
        height: 25,
        objectFit: 'contain'
    },
    funcItemName: {
        marginTop: 10,
        marginBottom: 5,
        fontSize: '12px',
        fontFamily: 'PingFangSC-Medium',
        fontWeight: 250,
        color: '#000000',
        lineHeight: '17px'
    },
    funcItemDesc: {
        width: 99,
        fontSize: '11px',
        fontFamily: 'PingFangSC-Regular',
        fontWeight: 400,
        color: '#515151',
        lineHeight: '21px'
    }
}