import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';

import { actions as vipUserActions } from '../../redux/vipuser-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';

const confirm = Modal.confirm;

class VipUserSelectView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            phone: '',
            cardno: '',

            selectedRowKeys: []
        };

        this.columns = [
            { title: '姓名', width: 100, dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: 120, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '卡号', width: 130, dataIndex: 'cardno', key: 'cardno', align: 'center' },
            { title: '时限卡到期时间', width: 135, dataIndex: 'limitendtime', key: 'limitendtime', align: 'center', render: limitendtime => <span>{limitendtime === undefined || limitendtime === 0 ? '-' : LLCDateHelper.formatDate(limitendtime, "yyyy-MM-dd")}</span> },
            { title: '次卡剩余次数', width: 130, dataIndex: 'resttimes', key: 'resttimes', align: 'center' },
            { title: '次卡到期时间', width: 135, dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{endtime === undefined || endtime === 0 ? '-' : LLCDateHelper.formatDate(endtime, "yyyy-MM-dd")}</span> }
        ];

        this.vipUserSelected = this.vipUserSelected.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    handleSearchClick() {
        this.queryVipUserList(1);
    }

    handleClearClick() {
        this.setState({
            username: '',
            phone: '',
            cardno: ''
        })
    }

    queryVipUserList = page => {
        this.props.queryVipUserList({
            page,
            state: 1,
            username: this.state.username,
            phone: this.state.phone,
            cardno: this.state.cardno
        });
    }

    vipUserSelected() {
        let selectID = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.props.vipUserList.length; i++) {
            if (this.props.vipUserList[i].userid === selectID) {
                this.props.selectedVipUser && this.props.selectedVipUser(this.props.vipUserList[i]);
                break;
            }
        }
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.userid]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>姓名</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ username: e.target.value })}
                        value={this.state.username} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ phone: e.target.value })}
                        value={this.state.phone} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>卡号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ cardno: e.target.value })}
                        value={this.state.cardno} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    render() {
        const { selectedRowKeys } = this.state;
    
        const rowSelection = {
            type: 'radio',
            onChange: this.onSelectedRowKeysChange,
            selectedRowKeys
        };

        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <div style={PageStyles.tableContainer}>
                    {
                        this.renderSearchBar()
                    }
                   
                   <Button
                        type="primary"
                        onClick={this.vipUserSelected}
                        disabled={!hasSelected}
                    >确定</Button>
                    <Table
                        rowKey={record => record.userid}
                        loading={this.props.loading}
                        columns={this.columns}
                        dataSource={this.props.vipUserList}
                        rowSelection={rowSelection}
                        scroll={{ 
                            // x: 1200, 
                            y: 485 
                        }}
                        pagination={{
                            total: this.props.totalpage * this.props.pagesize,
                            pageSize: this.props.pagesize,
                            current: this.props.page,
                            onChange: val => {
                                this.queryVipUserList(val)
                            }
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                this.selectRow(record);
                            },
                        })}
                    ></Table>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.props.queryVipUserList({ state: 1, page: this.props.page });
    }
}

const mapStateToProps = store => {
  const userStore = store['vipUserStore'];
  return {
    loading: userStore.loading,
    vipUserList: userStore.vipUserList,
    pagesize: userStore.pagesize,
    totalpage: userStore.totalpage,
    page: userStore.page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryVipUserList: params => {
      dispatch(vipUserActions.queryVipUserList(params));
    },
    changeLoading: params => {
      dispatch(vipUserActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipUserSelectView);
