import React from 'react'

import { Select, Spin, Input, Button, Modal, Radio, Upload, message } from 'antd'
import { Link } from 'react-router-dom';

import { connect } from 'react-redux'
import { actions as managerActions, launchSaveManager } from '../../../redux/manager-model';

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';
import FormItem from '../../../view/common/form-item';
import { UserAuth_List } from '../../../constants/authConstants';

const NAMESPACE = MAIN_LISTS.MANAGER_LIST.children.SAVE_MANAGER;

class ManagerSaveController extends BaseController {
  constructor(props) {
    super(props);

    let manager = {};
    let userauth = [];
    if (props.location.state && props.location.state.manager) {
      manager = props.location.state.manager;
      if (manager.userauth && manager.userauth.length > 0) {
        userauth = manager.userauth.split(',');
      }
    }

    this.state = {
        ...this.state,
        username: '',
        accountname: '',
        phone: '',
        passwd: '',
        confirmPasswd: '',
        ...manager,
        
        userauth,

        requestLoading: false
    };

    this.handleCreateManagerButtonClick = this.handleCreateManagerButtonClick.bind(this);
    this.handleUserAuthChanged = this.handleUserAuthChanged.bind(this);
  }

  componentDidMount() {
    console.log(this.props, 'this.props');
  }

  handleUserAuthChanged(e) {
    
    this.setState({ userauth: e });
}

  renderForm() {
    let { userid }  = this.state;
    return (
      <div style={{ paddingTop: 20 }}>
        <div style={FormStyles.formItem}>
              <div style={FormStyles.leftBox}>
                <span>姓名:</span>
              </div>
              <div style={FormStyles.rightBox}>
                <Input
                  disabled={userid}
                  autocomplete='off'
                  allowClear
                  style={FormStyles.input_middle}
                  placeholder="请填写姓名"
                  value={this.state.username}
                  onChange={e => {
                    let inputText = e.target.value;
                    this.setState({ username: inputText })
                  }}
                />
              </div>
            </div>
            
            <div style={FormStyles.formItem}>
              <div style={FormStyles.leftBox}>
                <span>登录账号:</span>
              </div>
              <div style={FormStyles.rightBox}>
                <Input
                  disabled={userid}
                  autocomplete='off'
                  allowClear
                  style={FormStyles.input_middle}
                  placeholder="请填写登录账号"
                  value={this.state.accountname}
                  onChange={e => {
                    let inputText = e.target.value;
                    this.setState({ accountname: inputText })
                  }}
                />
              </div>
            </div>

            <div style={FormStyles.formItem}>
              <div style={FormStyles.leftBox}>
                <span>手机号:</span>
              </div>
              <div style={FormStyles.rightBox}>
                <Input
                  allowClear
                  style={FormStyles.input_middle}
                  placeholder="请填写手机号"
                  value={this.state.phone}
                  onChange={e => {
                    let inputText = e.target.value;
                    if (inputText.length > 11) {
                      inputText = inputText.substr(0, 11);
                    }
                    this.setState({ phone: inputText })
                  }}
                />
              </div>
            </div>
            
            {
              !userid ?
                <div style={FormStyles.formItem}>
                  <div style={FormStyles.leftBox}>
                    <span>密码:</span>
                  </div>
                  <div style={FormStyles.rightBox}>
                    <Input
                      autocomplete='off'
                      allowClear
                      style={FormStyles.input_middle}
                      placeholder="请填写密码"
                      value={this.state.passwd}
                      onChange={e => {
                        let inputText = e.target.value;
                        this.setState({ passwd: inputText })
                      }}
                    />
                  </div>
                </div>
                :
                null
            }
            
            {
              !userid ?
                <div style={FormStyles.formItem}>
                  <div style={FormStyles.leftBox}>
                    <span>确认密码:</span>
                  </div>
                  <div style={FormStyles.rightBox}>
                    <Input
                      autocomplete='off'
                      allowClear
                      style={FormStyles.input_middle}
                      placeholder="请确认密码"
                      value={this.state.confirmPasswd}
                      onChange={e => {
                        let inputText = e.target.value;
                        this.setState({ confirmPasswd: inputText })
                      }}
                      onBlur={() => {

                      }}
                    />
                  </div>
                </div>
                :
                null
            }

            <div style={{ width: '80%', height: 1, backgroundColor: '#f5f5f5', marginLeft:20, marginTop: 40, marginBottom: 40 }} />

            <div>
              <FormItem itemKey="权限：" type="check-box" valueList={UserAuth_List} itemValue={this.state.userauth} onValueChanged={this.handleUserAuthChanged} valueStyles={{ width: 600 }} />
            </div>

          {/* <div style={FormStyles.operateBox}>
            <Button
              className="add-manager-button"
              type="primary"
              onClick={this.handleCreateManagerButtonClick}
            >提交</Button>
          </div> */}
      </div>
    );
  }

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button type="primary" size="small" onClick={this.handleCreateManagerButtonClick}>保存</Button>
        </div>
      ),
      onBack: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={TableStyles.tableBox}>
          {
            this.renderForm()
          }
        </div>
      </div>
      </Spin>
    )
  }

  // 搜索按钮绑定事件
  handleSearchClick = () => {
    this.queryManagerInfo()
  }
  
  handleCreateManagerButtonClick() {
    if (this.checkFormNotHasEmptyItem()) {
      // 没空项目, 进行上传操作
      this.saveManager();
    }
  }

  // 表单是不是都填满了?
  checkFormNotHasEmptyItem = () => {

    if (!this.state.username) {
      message.error('请填写账户姓名');
      return false;
    }
    if (!this.state.accountname) {
      message.error('请填写登录账户');
      return false;
    }
    if (!this.state.phone) {
      message.error('请填写手机号');
      return false;
    }
    if (!this.state.userid && !this.state.passwd) {
      message.error('请填写密码');
      return false;
    }
    if (!this.state.userid && this.state.passwd !== this.state.confirmPasswd) {
      message.error('两次密码输入不一致');
      return false;
    }
    if (!this.state.userauth || this.state.userauth.length === 0) {
      message.error('请设置管理员权限');
      return false;
    }
    return true;
  }

  saveManager = () => {
    
    let params = {
      username: this.state.username,
      accountname: this.state.accountname,
      phone: this.state.phone,
      passwd: this.state.passwd,
      confirmPasswd: this.state.confirmPasswd,
      userauth: this.state.userauth.join(',')
    };

    if (this.state.userid) {
      params.userid = this.state.userid;
    }

    this.changeLoadingState(true);
    launchSaveManager(
      params,
      res => {
        this.props.dispatch(managerActions.queryManagerInfo({ page: this.props.page }));
        this.setState({
          username: '',
          accountname: '',
          phone: '',
          passwd: '',
          confirmPasswd: ''
        });
        if (this.state.userid) {
          message.success('修改成功');
        } else {
          message.success('新建账号成功');
        }
        this.props.history.goBack();
        this.changeLoadingState(false);
      },
      err => { 
        message.error(err);
        this.changeLoadingState(false);
      }
    );
  }
}

const mapStateToProps = store => {
  const utilStore = store['utilStore'];
  // debugger
  return {
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.CREATE_MANAGER.path]
  }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(ManagerSaveController)