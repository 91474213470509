
import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, TimePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';
import { FormStyles } from '../../style/common-styles';

import DescEditView from '../desc/desc-edit-view';
import DescShowView from '../desc/desc-show-view';

const confirm = Modal.confirm;
const Option = Select.Option;
const { TextArea } = Input;

class BannerEditView extends React.Component {
    constructor(props) {
        super(props)
        // debugger;
        // console.log(props.editingBanner);
        this.state = {
            editingBanner: props.editingBanner ? props.editingBanner : { type: 1 },
            editingTypeIndex: props.editingTypeIndex,
            editingBannerIndex: props.editingBannerIndex,
            editDescVisible: false
        }

        this.handleEditBannerDetail = this.handleEditBannerDetail.bind(this);
        this.handleBannerTypeChange = this.handleBannerTypeChange.bind(this);
    }

    componentDidMount() {
        
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.editingBanner) {
    //         this.setState({
    //             editingBanner: nextProps.editingBanner
    //         });
    //     }
    // }

    handleEditBannerDetail() {
        this.setState({
            editDescVisible: true
        });
    }

    handleBannerTypeChange(type) {
        this.setState({
            editingBanner: {
                ...this.state.editingBanner,
                type
            }
        });
    }

    renderContent() {
        return (
            <div>
                <div style={styles.itemFirst}>
                    <span style={styles.itemHint}>类型 :</span>
                    <div style={styles.itemInput}>
                        <Select
                            style={styles.cardType_Select}
                            // placeholder="请选择"
                            value={this.state.editingBanner.type}
                            onChange={this.handleBannerTypeChange}
                            >
                            <Option value={1}>应用内宣传页</Option>
                            {/* <Option value={2}>跳转链接</Option> */}
                        </Select>
                    </div>
                </div>

                {
                    this.state.editingBanner.type === 2 ?
                        <div style={styles.item}>
                            <span style={styles.itemHint}>链接url :</span>
                            <div style={styles.itemInput}>
                                <TextArea 
                                    placeholder="请填写链接url" 
                                    style={styles.addressInput}
                                    autoSize={{ minRows: 2, maxRows: 6 }} 
                                    value={this.state.editingBanner.linkurl}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        this.setState({ editingBanner: { ...this.state.editingBanner, linkurl: inputText } })
                                    }}
                                />  
                            </div>
                        </div>
                        :
                        <div style={styles.item}>
                            <span style={styles.itemHint}>宣传页 :</span>
                            <div style={styles.itemInput}>
                                <a onClick={this.handleEditBannerDetail} >编辑</a>

                                {
                                    this.state.editingBanner.linkdesc ?
                                        <a style={styles.previewButton} onClick={ () => {this.setState({ descVisible: true })} } >预览</a>
                                        :
                                        null
                                }
                            </div>
                        </div>
                }
            </div>
        );
    }

    render() {
        
        return (
            <div style={styles.container}>
                
                {
                    this.renderContent()
                }

                <div style={styles.operateBox}>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            e.stopPropagation();

                            this.props.okTapped && this.props.okTapped(this.state.editingBanner, this.state.editingTypeIndex, this.state.editingBannerIndex);
                        }}
                    >完成</Button>
                </div>

                <Modal
                    visible={this.state.editDescVisible}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ editDescVisible: false }) }}
                    footer={null}
                    title="编辑宣传页"
                    width={"456px"}
                    destroyOnClose={true}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <DescEditView 
                        type={3}
                        descid={this.state.editingBanner.linkdesc}
                        isModal={true}
                        saveSuccessed={(linkdesc) => { 
                            this.setState({ editingBanner: { ...this.state.editingBanner, linkdesc }, editDescVisible: false }) 
                        }}
                    />
                </Modal>

                <Modal
                    visible={this.state.descVisible}
                    onCancel={() => { this.setState({ descVisible: false }) }}
                    destroyOnClose={true}
                    footer={null}
                    width={"456px"}
                    bodyStyle={{ padding: "48px" }}
                >
                <DescShowView
                    descid={this.state.editingBanner.linkdesc}
                    type={3} 
                />
            </Modal>
            </div>
        );
    }
}

// 从store接收state数据
const mapStateToProps = store => {
    // 在这里如果id变化了就跳转
    // const descStore = store['descStore'];
    return {
        // descid: descStore.descid
    }
}
const mapDispatchToProps = dispatch => { return {} };

export default connect(mapStateToProps, mapDispatchToProps)(BannerEditView);


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemFirst: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15
    },
    itemHint: {
        width: 65, 
        textAlign: 'right',
        
    },
    itemInput: {
        marginLeft: 10
    },
    cardType_Select: {
        width: 150,
        height: 32
    },
    addressInput: {
        width: 250
    },
    previewButton: {
        marginLeft: 10
    },
    operateBox: {
        marginTop: 15,
        textAlign: 'center'
    }
}