import React from 'react'
import { Select, Input, Menu, InputNumber, Button, message, Radio, Modal, Spin, Upload, TimePicker, Switch } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import cookie from 'cookie-lza';
import { actions as venueActions, launchSaveVenue } from '../../../redux/venue-model';
import { launchGetUploadToken } from '../../../redux/upload-token-model';
import { actions as descActions } from '../../../redux/desc-model';
import { actions as loginActions } from '../../../redux/loginModel';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import DescEditView from '../../../view/common/desc-edit-view';
import { VenueSaveStyles } from '../../../style/page/venue/venue-save-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { uploadProjectImageToQiniu, uploadFileToQiniu } from '../../../util/request';
import MapController from '../../../view/map/map-controller';
import VenueDescEditController from './venue-desc-edit-controller'
import DescShowView from '../../../view/desc/desc-show-view';
import TagSelectView from '../../../view/tags/tag-select-view';
import EditableTagGroup from '../../../view/common/editable-tag-group';
import EditablePhoneGroup from '../../../view/common/editable-phone-group';
import { PageStyles, TableStyles } from '../../../style/common-styles';
import FormItem from '../../../view/common/form-item';
import { PlusOutlined, FileOutlined, OneToOneOutlined, PictureOutlined, InfoCircleOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.VENUE_LIST.children.SAVE_VENUE;

const Option = Select.Option;
const confirm = Modal.confirm;
const { TextArea } = Input;

class SaveVenueController extends BaseController {
    constructor(props) {
        super(props);

        let editVenue = {};
        let tagnames = [];
        if (this.props.location.state && this.props.location.state.editVenue) {
            editVenue = this.props.location.state.editVenue;
            
            if (editVenue.tags) {
                let tagList = JSON.parse(editVenue.tags);
                for (let key in tagList) {
                    tagnames.push(tagList[key].name);
                }
            }
        }

        let editVenuePictures = [];
        
        if (editVenue && editVenue.medias) {
            let pictureList = JSON.parse(editVenue.medias);
            
            for (let i = 0; i < pictureList.length; i++) {
                let aMedia = pictureList[i];
                let pathList = aMedia.key.split('/');
                editVenuePictures.push({
                    uid: aMedia.key,
                    url: aMedia.url,
                    size: aMedia.size,
                    status: 'done',
                    name: pathList[pathList.length-1],
                    customFlag: 1,
                    customUrl: aMedia.url,
                    customKey: aMedia.key,
                    key: aMedia.key,
                });
            }

        }
        this.editVenuePictures = editVenuePictures;
        editVenue.mediaList = this.editVenuePictures;

        let desc = undefined;
        if (this.props.location.state && this.props.location.state.desc) {
            desc = this.props.location.state.desc;
        }
        
        this.state = {
            ...this.state,
            venuename: '',
            venueposter: undefined,
            venuelogo: undefined,
            cardposter: undefined,
            cardmark: undefined,

            addressid: undefined,
            addressname: undefined,
            addressdetail: undefined,
            addresslat: undefined,
            addresslng: undefined,

            addressroom: undefined,
            venuebrief: '',
            notifyphone: undefined,
            state: 2,
            
            mapModalVisible: false,
            descVisible: false,

            uploadLoading: false,
            modalTagShowing: false,
            showcourseinfo: 0,
            tagnames,
            ...editVenue,
            desc,

            currentStep: '1'
        };

        this.checkInputContent = this.checkInputContent.bind(this);
        this.updatePhones = this.updatePhones.bind(this);
        this.handleSelectTagChange = this.handleSelectTagChange.bind(this);
        this.handleOpentimeChange = this.handleOpentimeChange.bind(this);
        this.handleClosetimeChange = this.handleClosetimeChange.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
        this.handleMapLocationChoose = this.handleMapLocationChoose.bind(this);
        this.handleeditVenueDetail = this.handleeditVenueDetail.bind(this);
        this.handlePicturesChanged = this.handlePicturesChanged.bind(this);
        this.checkAndUploadMedias = this.checkAndUploadMedias.bind(this);
        this.checkAndUploadDesc = this.checkAndUploadDesc.bind(this);
        this.uploadDescPictures = this.uploadDescPictures.bind(this);
        this.saveVenue = this.saveVenue.bind(this);

        this.renderForm = this.renderForm.bind(this);

        this.renderContent = this.renderContent.bind(this);
        this.renderBaseInfo = this.renderBaseInfo.bind(this);
        this.renderDesc = this.renderDesc.bind(this);
        this.renderExperience = this.renderExperience.bind(this);
        this.renderPictures = this.renderPictures.bind(this);
    }

    updatePhones(notifyphone) {
        //   console.log(notifyphone);
        this.setState({
            notifyphone
        });
    }

    // 点击”保存“按钮
    handleSaveButtonClick() {
        if (this.checkInputContent()) {

            let that = this;
            confirm({
                title: '确认提交？',
                content: '',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    that.checkAndUploadMedias();
                },
                onCancel() {
                
                },
            });
        }
    }

    // 上传场馆详情图片
    checkAndUploadDesc() {
        let editDescPictures = this.descEditView.getEditPictures();
        
        let descPicNames = [];
        for (let key in editDescPictures) {
            descPicNames.push(editDescPictures[key].file.name);
        }

        if (editDescPictures && editDescPictures.length > 0) {
            launchGetUploadToken(
                { type: 6, files: descPicNames },
                res => {
                    this.uploadDescPictures(res.pathList, (result) => {
                        if (result) {
                            this.saveVenue();
                        } else {
                            this.changeLoadingState(false);
                            message.error('图片上传失败，请重试');
                        }
                    });
                },
                fail => {
                    message.error(fail);
                    this.changeLoadingState(false);
                } 
            );  
        } else {
            this.saveVenue();
        }
    }

    // 上传场馆照片
    checkAndUploadMedias() {
        this.changeLoadingState(true);
        if (this.venuePicNames && this.venuePicNames.length > 0) {
            launchGetUploadToken(
                { type: 6, files: this.venuePicNames },
                res => {
                    this.uploadVenuePictures(res.pathList, (result) => {
                        if (result) {
                            this.checkAndUploadDesc();
                        } else {
                            this.changeLoadingState(false);
                            message.error('图片上传失败，请重试');
                        }
                    });
                },
                fail => {
                    message.error(fail);
                    this.changeLoadingState(false);
                }
            );
        } else {
            this.checkAndUploadDesc();
        }
    }

    // 上传详情图片
    uploadDescPictures(pathList, callback = () => {}) {

        let editDescPictures = this.descEditView.getEditPictures();
        let descItems = this.descEditView.getDescItems();
        
        for (let key = 0; key < editDescPictures.length; key++) {
            let picture = editDescPictures[key];

            if (!picture.customFlag) {

                let file = picture.file;

                let aPath = pathList[key];
                
                uploadFileToQiniu(
                    file,
                    aPath,
                    res => {
                        
                        descItems[picture.index].itemurl = aPath.url;
                        descItems[picture.index].itemkey = aPath.key;
                        descItems[picture.index].size = file.size;
                        picture.customFlag = 1;
                        this.descEditView.updateDescItems(descItems);
                        console.log(file, '上传成功一个图片');
                        if (key === editDescPictures.length-1) {
                            this.descEditView.updateEditPictures([]);
                            callback(true);
                        } else {
                            console.log('下一个');
                            this.descEditView.updateEditPictures(editDescPictures);
                            this.uploadDescPictures(pathList, callback);
                        }
                    },
                    fail => {
                        console.log(fail);
                        callback(false);
                    }
                );    

                break;
            } else {
                if (key === editDescPictures.length-1) {
                    callback(true);
                }
            }  
        }
    }

    // 上传场馆照片
    uploadVenuePictures(pathList, callback = () => {}) {

        for (let i = 0; i < this.editVenuePictures.length; i++) {
            let file = this.editVenuePictures[i];
            
            if (!file.customFlag) {

                let aPath = pathList[i];
                
                uploadFileToQiniu(
                    file,
                    aPath,
                    res => {
                        file.customFlag = 1;
                        file.customUrl = aPath.url;
                        file.customKey = aPath.key;

                        console.log(file, '上传成功一个图片');
                        if (i === this.editVenuePictures.length-1) {

                            let mediaList = [];
                            for (let key in this.editVenuePictures) {
                                let aEditPicture = this.editVenuePictures[key];
                                mediaList.push({
                                    key: aEditPicture.customKey,
                                    url: aEditPicture.customUrl,
                                    size: aEditPicture.size
                                });
                            }
                            this.state.mediaList = mediaList;

                            callback(true);
                        } else {
                            console.log('下一个');
                            this.uploadVenuePictures(pathList, callback);
                        }
                    },
                    fail => {
                        console.log(fail);
                        callback(false);
                    }
                );      
                
                break;
            } else {
                if (i === this.editVenuePictures.length-1) {
                    callback(true);
                }
            }
        }
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
        message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 2;
        if (!isPassSizeLimit) {
        message.error('图片大小需小于2Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info, index) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file,
                2,
                res => {
                    if (index === 0) {
                        this.setState({ 
                            venueposter: res.result.url,
                            uploadLoading: false
                        });
                    } else if (index === 1) {
                        this.setState({ 
                            venuelogo: res.result.url,
                            uploadLoading: false
                        });
                    } else if (index === 2) {
                        this.setState({ 
                            cardposter: res.result.url,
                            uploadLoading: false
                        });
                    }
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }

    handleSelectTagChange(e) {
        if (e) {
            this.editableTagGroup.addTags([e.name]);
        }

        this.setState({ 
          modalTagShowing: false
        });
      }

    handleOpentimeChange(time, timeStr) {
        console.log(time, timeStr);
        let opentime = undefined;
        if (timeStr) {
            let timeArr = timeStr.split(':');
            opentime = parseInt(timeArr[0])*60 + parseInt(timeArr[1]);
        }
        this.setState({ opentime });
    }

    handleClosetimeChange(time, timeStr) {
        console.log(time, timeStr);

        let closetime = undefined;
        if (timeStr) {
            let timeArr = timeStr.split(':');
            closetime = parseInt(timeArr[0])*60 + parseInt(timeArr[1]);
        }
        this.setState({ closetime });   
    }

        // 确认选择地图位置事件
    handleMapLocationChoose = (poi) => {
        // console.log(poi);
        this.setState({
            mapModalVisible: false,
            
            addressname: poi.name,
            addresslat: poi.latLng.lat,
            addresslng: poi.latLng.lng,
            addressid: poi.id,
            addressdetail: poi.address,
            province: poi.province,
            city: poi.city,
            district: poi.district
        });
        message.success('编辑成功');
    }

    checkInputContent() {
        if (isEmpty(this.state.venuename)) {
            message.error('请补全场馆名称');
            return false;
        }
        if (isEmpty(this.state.cardmark)) {
            message.error('请补全会员卡标识');
            return false;
        }
        if (this.state.cardmark.length > 4 || this.state.cardmark.length < 2) {
            message.error('会员卡标识为2~4位大写字母');
            return false;
        }

        let regex =/^[A-Z]+$/ig;
        if (!regex.test(this.state.cardmark)) {
            message.error('会员卡标识只能由大写字母组成');
            return false;
        }

        if (isEmpty(this.state.addressname)) {
            message.error('请补全场馆地址');
            return false;
        }
        if (isEmpty(this.state.opentime) || isEmpty(this.state.closetime)) {
            message.error('请补全场馆营业时间');
            return false;
        }

        return true;
    }

    saveVenue = () => {
        const params = {
            venuename: this.state.venuename,
            venueposter: this.state.venueposter,
            venuelogo: this.state.venuelogo,
            cardposter: this.state.cardposter,
            cardmark: this.state.cardmark,
            contact: this.state.contact,

            addressid: this.state.addressid,
            addressname: this.state.addressname,
            addressdetail: this.state.addressdetail,
            addresslat: this.state.addresslat,
            addresslng: this.state.addresslng,
            province: this.state.province,
            city: this.state.city,
            district: this.state.district,

            addressroom: this.state.addressroom,
            venuebrief: this.state.venuebrief,
            notifyphone: this.state.notifyphone,
            venuedesc: this.props.descid,
            state: this.state.state,
            showcourseinfo: this.state.showcourseinfo,
            pca: this.state.pca,

            opentime: this.state.opentime,
            closetime: this.state.closetime,
            mediaList: this.state.mediaList,
            descList: this.descEditView.getDescItems()
        };

        if(this.state.venueid) {
            params.venueid = this.state.venueid;
        }
        if (this.state.experiencetimes !== undefined) {
            params.experiencetimes = this.state.experiencetimes;
        }

        if (this.state.coursecostprivate !== undefined) {
            params.coursecostprivate = this.state.coursecostprivate;
        }

        if (this.state.twopeoplecost !== undefined) {
            params.twopeoplecost = this.state.twopeoplecost;
        }

        

        const tags = this.editableTagGroup.getTags();
        if (tags && tags.length > 0) {
            params.tags = tags.join(',');
        }

        let saveParams = {};
        for (let key in params) {
            if (!isEmpty(params[key])) {
                saveParams[key] = params[key];
            }
        }

        this.changeLoadingState(true);
        launchSaveVenue(
            saveParams,
            response => {
                message.success(response.msg);
                this.changeLoadingState(false);


                if (cookie.get('venueid') && cookie.get('venueid') === response.result.venue.venueid) {
                    this.props.dispatch(loginActions.recordSelectedVenue({entervenue: response.result.venue}));
                }

                this.props.history.goBack();
                // this.props.history.push(`/${PathConstant.BCG_ROOT_Name}/${PathConstant.SAVE_VENUE.rootPath}`);
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        )
    }

    handleeditVenueDetail() {
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.DESC_EDIT.path}`);
    }

    // 场馆照片修改
    handlePicturesChanged(pictures) { 
        
        this.editVenuePictures = pictures;
        this.state.mediaList = this.editVenuePictures;
        let venuePicNames = [];
        for (let i = 0; i < pictures.length; i++) {
            venuePicNames.push(pictures[i].name);
        }
        this.venuePicNames = venuePicNames;

        console.log(this.venuePicNames);
    }

    renderBaseInfo() {
        let opentime = undefined;
        let closetime = undefined;
        
        if (this.state.opentime) {
            opentime = `${parseInt(this.state.opentime / 60)}:${this.state.opentime % 60}`;
            opentime = moment(opentime, 'HH:mm');
        }
        if (this.state.closetime) {
            closetime = `${parseInt(this.state.closetime / 60)}:${this.state.closetime % 60}`;
            closetime = moment(closetime, 'HH:mm');
        }

        const uploadButton = (
            <div style={VenueSaveStyles.uploadButton_100}>
                <PlusOutlined />
                <div> 上传</div>
            </div>
        );

        return (
            <div 
                style={{flexDirection: 'column', width: '100%', 
                display: this.state.currentStep === '1' ? 'flex' : 'none'}}>
                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>场馆海报 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <div>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            onChange={info => {
                                this.handleUploadChange(info, 0);
                            }}   
                        >
                            {this.state.venueposter ? <img style={{width: 100, height: 100, objectFit: 'cover'}} src={this.state.venueposter} alt="avatar" /> : uploadButton}
                        </Upload>
                        </div>
                        <span style={VenueSaveStyles.hintText}>（展示给用户场馆形象的主要logo）</span>
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>场馆logo :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <div>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            onChange={info => {
                                this.handleUploadChange(info, 1);
                            }}   
                        >
                            {this.state.venuelogo ? <img style={{width: 100, height: 100, objectFit: 'cover'}} src={this.state.venuelogo} alt="avatar" /> : uploadButton}
                        </Upload>
                        </div>

                        <span style={VenueSaveStyles.hintText}>（用于单纯展示logo处，如管理系统菜单左上角）</span>
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>场馆名称 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Input
                            allowClear
                            style={VenueSaveStyles.input_middle}
                            placeholder="请填写场馆名称"
                            value={this.state.venuename}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ venuename: inputText })
                            }}
                        />
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>会员卡标识 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Input
                            allowClear
                            style={VenueSaveStyles.input_middle}
                            placeholder="请填写会员卡标识"
                            value={this.state.cardmark}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ cardmark: inputText })
                            }}
                        />

                        <span style={VenueSaveStyles.hintText}>（2-4位大写字母，如OM，生成会员卡为：OM00000001）</span>
                    </div>
                </div>

                {/* <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>会员卡封面 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <div>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            onChange={info => {
                                this.handleUploadChange(info, 2);
                            }}   
                        >
                            {this.state.cardposter ? <img style={{width: 100, height: 100, objectFit: 'cover'}} src={this.state.cardposter} alt="avatar" /> : uploadButton}
                        </Upload>
                        </div>

                        <span style={VenueSaveStyles.hintText}>（用于展示给用户）</span>
                    </div>
                </div> */}

                <div style={VenueSaveStyles.formItem_topAlign}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>场馆地址 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        
                        <div>
                            <div>
                                {
                                    this.state.addressid ?
                                    <Input
                                        allowClear
                                        style={VenueSaveStyles.input_middle}
                                        value={this.state.addressname}
                                        onChange={e => {
                                            let inputText = e.target.value;
                                            this.setState({ addressname: inputText })
                                        }}
                                    />
                                    :
                                    null
                                }
                                <a  style={this.state.addressid ? VenueSaveStyles.addressEditButton: {}} onClick={e => { e.preventDefault(); this.setState({ mapModalVisible: true }) }}>编辑</a>
                            </div>

                            {
                                this.state.addressid ?
                                    <Input
                                        allowClear
                                        style={VenueSaveStyles.addressDetailInput}
                                        placeholder="请填写详细地址，楼层，门牌号"
                                        value={this.state.addressroom}
                                        onChange={e => {
                                            let inputText = e.target.value;
                                            this.setState({ addressroom: inputText })
                                        }}
                                    />
                                    :
                                    null
                            }
                        </div>
                        
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>场馆简介 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <TextArea 
                            placeholder="请填写场馆简介" 
                            style={{...VenueSaveStyles.input_long, width: 450}}
                            autoSize={{ minRows: 2, maxRows: 6 }} 
                            value={this.state.venuebrief}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ venuebrief: inputText })
                            }}
                        />
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>营业时间 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <TimePicker value={opentime} allowClear onChange={this.handleOpentimeChange} format='HH:mm' placeholder="开始时间" />
                        <span style={{ marginLeft: 20, marginRight: 20 }}> ~ </span>
                        <TimePicker allowClear value={closetime} onChange={this.handleClosetimeChange} format='HH:mm' placeholder="结束时间" />
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span style={{ marginBottom: 10 }}>场馆类型 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <EditableTagGroup 
                            onRef={ ref => this.editableTagGroup = ref} 
                            defaultTags={this.state.tagnames}
                            selectHint="选择已有类型"
                            addHint="申请新类型"
                            triggerImport={() => {
                                this.setState({ modalTagShowing: true });
                            }}
                        />
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>联系电话 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Input
                            allowClear
                            style={VenueSaveStyles.input_middle}
                            placeholder="请填写用户联系电话"
                            value={this.state.contact}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ contact: inputText })
                            }}
                        />

                        <span style={VenueSaveStyles.hintText}>（展示在场馆详情，用于用户联络场馆）</span>
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>是否对外展示课程和教练 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Switch checkedChildren="展示" unCheckedChildren="关闭" checked={( this.state.showcourseinfo && this.state.showcourseinfo === 1) ? true : false} onChange={(e) => {
                            let showcourseinfo = 0;
                            if (e) {
                                showcourseinfo = 1;
                            }

                            this.setState({ showcourseinfo });
                        }} />

                        <span style={VenueSaveStyles.hintText}>（展示在场馆详情，用于用户联络场馆）</span>
                    </div>
                </div>


                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>私教卡可约团课 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={( this.state.pca !== undefined && this.state.pca === 1) ? true : false} onChange={(e) => {
                            let pca = 0;
                            if (e) {
                                pca = 1;
                            }

                            this.setState({ pca });
                        }} />

                        <span style={VenueSaveStyles.hintText}>（此前添加的成员仍需要手动修改）</span>
                    </div>
                </div>


                {/* <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span style={{ marginBottom: 10 }}>通知电话 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <EditablePhoneGroup 
                            notifyphone={ this.state.notifyphone } 
                            updatePhones={this.updatePhones}
                        />
                    </div>
                </div> */}

                <div style={VenueSaveStyles.formItem}>
                    <div style={VenueSaveStyles.leftBox}>
                        <span>状态 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <Radio.Group
                        className="radio"
                        onChange={e => this.setState({ state: e.target.value })}
                        value={this.state.state}
                        >
                        <Radio value={1}>开启</Radio>
                        <Radio value={2}>关闭</Radio>
                        <span style={VenueSaveStyles.hintText}>（关闭的场馆不会在前端展示）</span>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    }

    renderDesc() {
        return (
            <div style={{display: this.state.currentStep === '2' ? 'flex' : 'none'}}>
                
                <DescEditView onRef={ ref => this.descEditView = ref} desc={this.state.desc ? this.state.desc.descitems : undefined} />
            </div>
        );
    }

    renderExperience() {
        return (
            <div style={{display: this.state.currentStep === '3' ? 'flex' : 'none', flexDirection: 'column'}}>
                <div style={VenueSaveStyles.formItem}>
                    <div style={{...VenueSaveStyles.leftBox, width: 300}}>
                        <span>每个用户可购买体验课次数 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <InputNumber
                            min={0}
                            style={{ width: 150 }}
                            placeholder="请填写次数"
                            value={this.state.experiencetimes}
                            onChange={number => {
                                this.setState({ experiencetimes: number })
                            }}
                        />
                        <span style={VenueSaveStyles.hintText}>（同一课程只可体验一次）</span>
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={{...VenueSaveStyles.leftBox, width: 300}}>
                        <span>私教卡约团课扣除次数 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <InputNumber
                            min={0}
                            style={{ width: 150 }}
                            placeholder="请填写次数"
                            value={this.state.coursecostprivate}
                            onChange={number => {
                                this.setState({ coursecostprivate: number })
                            }}
                        />
                        <span style={VenueSaveStyles.hintText}>（私教卡约1次团课，扣除私教卡的次数。0次为不消耗次数，0.5次代表1节私教课可以约2节团课））</span>
                    </div>
                </div>

                <div style={VenueSaveStyles.formItem}>
                    <div style={{...VenueSaveStyles.leftBox, width: 300}}>
                        <span>私教卡2人上课扣除次数 :</span>
                    </div>
                    <div style={VenueSaveStyles.rightBox}>
                        <InputNumber
                            min={0}
                            style={{ width: 150 }}
                            placeholder="请填写次数"
                            value={this.state.twopeoplecost}
                            onChange={number => {
                                this.setState({ twopeoplecost: number })
                            }}
                        />
                        <span style={VenueSaveStyles.hintText}>（不填或填0则代表不支持2人上课））</span>
                    </div>
                </div>
            </div>
        )
    }

    renderPictures() {
        return (
            <div style={{display: this.state.currentStep === '4' ? 'flex' : 'none'}}>
                <FormItem itemKey="场馆照片：" type="picture-wall" itemStyles={{ alignItems: 'flex-start' }} updatePictures={this.handlePicturesChanged} defaultPictures={this.editVenuePictures} />
            </div>
        )
    }

    renderContent() {
        
        return (
            <div>
                {
                    this.renderBaseInfo()
                }

                {
                    this.renderDesc()   
                }

                {
                    this.renderExperience()
                }

                {
                    this.renderPictures()
                }
                
            </div>
        );
    }

    renderForm() {

        return (
            <div>
                <div style={{width: '100%', paddingLeft: 24, paddingRight: 24, marginBottom: 20}}>
                    <Menu onClick={(e) => { this.setState({ currentStep: e.key }) }} selectedKeys={[this.state.currentStep]} mode="horizontal">
                        <Menu.Item key="1"> <InfoCircleOutlined /> 基础信息 </Menu.Item>
                        <Menu.Item key="2"> <FileOutlined /> 场馆介绍 </Menu.Item>
                        <Menu.Item key="3"> <OneToOneOutlined /> 课程规则 </Menu.Item>
                        <Menu.Item key="4"> <PictureOutlined />场馆照片 </Menu.Item>
                    </Menu>
                </div>

                {
                    this.renderContent()
                }
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleSaveButtonClick } type="primary" size="small">保存</Button>
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderForm()
                    }
                </div>

                <Modal
                    visible={this.state.mapModalVisible}
                    onCancel={() => { this.setState({ mapModalVisible: false }) }}
                    destroyOnClose={false}
                    footer={null}
                    width={"1160px"}
                >
                    <MapController onConfirm={this.handleMapLocationChoose} />
                </Modal>

                <Modal
                    visible={this.state.descVisible}
                    onCancel={() => { this.setState({ descVisible: false }) }}
                    destroyOnClose={true}
                    footer={null}
                    width={"456px"}
                    bodyStyle={{ padding: "48px" }}
                >
                    <DescShowView
                        descid={this.props.descid}
                        type={2} 
                    />
                </Modal>

                <Modal
                    centered={true}
                    visible={this.state.modalTagShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalTagShowing: false }) }}
                    footer={null}
                    >
                    <TagSelectView selectType={1} onRecordSelect={this.handleSelectTagChange} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
    const descStore = store['descStore'];
    const utilStore = store['utilStore'];
    
//   console.log(utilStore.isShow[PathConstant.SAVE_VENUE.path]);
    return {
        descid: descStore.descid,
        // isShow: utilStore.isShow[PathConstant.SAVE_VENUE.path]
    }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(SaveVenueController)