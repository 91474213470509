import React from 'react'
import { Select, Input, InputNumber, Steps, Button, message, DatePicker, Modal, Spin, Upload } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment';

import { actions as coachActions, launchHandleUser, launchSaveCoach, launchReleaseCoach } from '../../../redux/coach-model';

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import { PageStyles, FormStyles, TableStyles } from '../../../style/common-styles';
import { CoachSaveStyles } from '../../../style/page/coach/coach-save-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import UserSelectView from '../../../view/vipuser/user-select-view';
import { uploadProjectImageToQiniu } from '../../../util/request';
import { priceYuan } from '../../../util/helper/number-helper';

const NAMESPACE = MAIN_LISTS.COACH_LIST.children.SAVE_COACH;

const Option = Select.Option;
const confirm = Modal.confirm;
const Step = Steps.Step;
const { TextArea } = Input;

class SaveCoachController extends BaseController {
    constructor(props) {
        super(props);

        let coach = {};
        if (this.props.location.state && this.props.location.state.coach) {
            coach = this.props.location.state.coach;
        }

        this.state = {
            ...this.state,
            user: undefined,
            coach,

            uploadLoading: false,
        };

        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
        this.handleCoachTypeChange = this.handleCoachTypeChange.bind(this);

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
    }

    handleSaveButtonClick() {
        this.changeLoadingState(true);
        launchSaveCoach(
            {
                ...this.state.coach
            },
            response => {
                this.changeLoadingState(false);
                message.success('更新成功');
                this.props.history.goBack();
            },
            msg => {
                this.changeLoadingState(false);
                message.error(msg);
            }
        );
    }

    handleDeleteButtonClick() {
        const { coach } = this.state;
        let that = this;
        confirm({
            title: <span style={{ whiteSpace: 'pre-wrap' }}>{`确认与 ${coach.username} 解除合作关系 ？`}</span>,
            content: '解除后请及时更换课表的绑定教练',
            okText: '确定',
            cancelText: '取消',
            onOk() {
      
                launchReleaseCoach(
                    { coachid: coach.coachid },
                    res => {
                        message.success('解除成功，请及时更换课表的绑定教练');
                        that.props.history.goBack();
                    },
                    fail => {
                        message.error(fail);
                    }
                );
            },
            onCancel() {
              
            },
        });
    }

    handleCoachTypeChange(coachtype) {
        this.setState({
            coach: {
                ...this.state.coach,
                coachtype
            }
        });
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
          message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 4;
        if (!isPassSizeLimit) {
          message.error('图片大小需小于4Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info, index) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file,
                1,
                res => {
                    console.log(res, index, 'uploadProjectImageToQiniu');
                    if (index === 0) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                identityfront: res.result.url 
                            } 
                        });
                    } else if (index === 1) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                identityback: res.result.url 
                            } 
                        });
                    }

                    this.setState({ uploadLoading: false });
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }

    checkFormNotHasEmptyItem = (index) => {
        if (index === 0) {
            if (!this.state.user.username || isEmpty(this.state.user.username)) {
                message.error('请填写姓名');        
                return false;
            }
            
        } else if (index == 1) {
            if (!this.state.coach.entrytime) {
                message.error('请填写入职时间');        
                return false;
            }
        }
        
        return true;
    }

    renderStepContent() {
        return (
            <div>
                {
                    this.renderCoachInfo()
                }
            </div>
        );
    }

    renderCoachInfo() {
        return (
            <div style={CoachSaveStyles.editSingleBox}>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>姓名：</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Input
                            style={FormStyles.input_middle}
                            placeholder="请填写姓名"
                            value={this.state.coach.username}
                            disabled={true}
                            />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>手机号：</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Input
                            style={FormStyles.input_middle}
                            placeholder="请填写手机号"
                            value={this.state.coach.phone}
                            disabled={true}
                            />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>教练类型 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <Select
                        style={CoachSaveStyles.cardType_Select}
                        placeholder="请选择"
                        value={this.state.coach.coachtype}
                        onChange={this.handleCoachTypeChange}
                        >
                            <Option value={0}>待定</Option>
                            <Option value={1}>全职</Option>
                            <Option value={2}>兼职</Option>
                        </Select>
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>联系地址 :</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <TextArea 
                            placeholder="请填写地址" 
                            style={CoachSaveStyles.addressInput}
                            autoSize={{ minRows: 2, maxRows: 6 }} 
                            value={this.state.coach.address}
                            onChange={e => {
                                let inputText = e.target.value;
                                this.setState({ coach: { ...this.state.coach, address: inputText } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>大班课提成（元）:</span>
                    </div>
                        <div style={FormStyles.rightBox}>
                            <InputNumber
                            min={0}
                            value={priceYuan(this.state.coach.publicpay)}
                            onChange={number => {
                                this.setState({ coach: { ...this.state.coach, publicpay: number * 100 } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>私教课提成（元）:</span>
                    </div>
                        <div style={FormStyles.rightBox}>
                            <InputNumber
                            min={0}
                            value={priceYuan(this.state.coach.privatepay)}
                            onChange={number => {
                                this.setState({ coach: { ...this.state.coach, privatepay: number * 100 } })
                            }}
                        />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>底薪（元）:</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <InputNumber
                        min={0}
                        value={priceYuan(this.state.coach.basicsalary)}
                        onChange={number => {
                            this.setState({ coach: { ...this.state.coach, basicsalary: number * 100 } })
                        }}
                    />
                    </div>
                </div>

                <div style={FormStyles.formItem}>
                    <div style={FormStyles.leftBox_Middle}>
                        <span>入职时间：</span>
                    </div>
                    <div style={FormStyles.rightBox}>
                        <DatePicker 
                            size='default' 
                            placeholder='入职时间' 
                            showTime={true}
                            onChange={(date, dateString) => {
                                let entrytime = date.unix();
                                this.setState({
                                    coach: {
                                        ...this.state.coach, 
                                        entrytime
                                    }
                                });
                            }}
                            value={this.state.coach.entrytime ? moment.unix(this.state.coach.entrytime) : undefined}
                        />
                    </div>
                </div>

                <div style={FormStyles.operateBox}>
                    <Button style={{ marginTop: 40 }} onClick={ this.handleDeleteButtonClick } type="danger" size="small">解除合作</Button>
                </div>
            </div>
        );
    }

    renderHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleSaveButtonClick } type="primary" size="small">保存</Button>
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderHeader()
                }

                <div style={TableStyles.tableBox}>

                    {
                        this.renderStepContent()
                    }
                </div>
                
            </div>
            </Spin>
        )
    }
}

const mapStateToProps = store => {
  // const managerInfoData = store['managerlist'];
  const utilStore = store['utilStore'];
  // debugger
  return {
    // page: managerInfoData.page,
    // windowHeight: utilStore.windowHeight,
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.SAVE_COACH.path]
  }
}

const mapDispatchToProps = dispatch => {
    return {
      queryCoachList: params => {
        dispatch(coachActions.queryCoachList(params));
      },
      changeLoading: params => {
        dispatch(coachActions.changeLoading(params));
      }
    }
  }

// export default CreateCommunitytController
export default connect(mapStateToProps, mapDispatchToProps)(SaveCoachController)