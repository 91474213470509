import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryCourseList: createAction('queryCourseList'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordCourseList = createAction('recordCourseList')

const effects = {
  *queryCourseList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_COURSE_LIST, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      
      yield put(recordCourseList(response.responseData.result))
      yield put(changeLoading({ loading: false }))
    } else {
      yield put(changeLoading({ loading: false }))
      message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryCourseList, effects.queryCourseList);
  }
];

// reducer
export const courseStore = handleActions(
  {
    recordCourseList(state, { payload: result }) {
      return {
        ...state,
        pagesize: result.pagesize,
        courseList: result.list,
        totalpage: result.totalpage,
        page: result.page,
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    courseList: [],
    pagesize: 0,
    totalpage: 0,
    page: 1
  }
)

// 保存课程
export async function launchSaveCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.SAVE_COURSE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 查询课程列表
export async function launchGetCourseList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_COURSE_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}



// 查询课程列表
export async function launchAddCourseCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.ADD_COURSE_COACH, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}


export async function launchGetCourseCoachList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_COURSE_COACH_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}



export async function launchUpdateCourseCoachState(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.UPDATE_COURSE_COACH_STATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.responseData.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

