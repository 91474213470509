

export const kAdmin_User_Auth = '9999999999';
export const kSuper_Admin_Auth = 'Super_Admin';

export const UserAuth_List = [
    { label: '用户管理', value: 'VIPUSER' },
    { label: '教练管理', value: 'COACH' },
    { label: '课程管理', value: 'COURSE' },
    { label: '营销管理', value: 'MARKET' },
    { label: '财务统计', value: 'STATIC' }
];

export const UserAuth_KeyValue = {
    VIPUSER: '用户管理',
    COACH: '教练管理',
    COURSE: '课程管理',
    MARKET: '营销管理',
    STATIC: '财务统计'
}

export const UserAuth = {
    VIPUSER: 'VIPUSER',
    COACH: 'COACH',
    COURSE: 'COURSE',
    MARKET: 'MARKET',
    STATIC: 'STATIC'
}