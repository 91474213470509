import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AppointClass extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="团课预约" />

                <SectionTitle text="一.查看团课预约情况" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/259a149f202640bfb187986984c11218.png" />
                <NormalContent text={<span>1.如图所示，在课表课程的右下角，有一个绿色区域显示“1/6”，代表着这节最多可容纳6个人的团课，有1个学员预约。</span>} />
                <NormalContent text={<span>2.点击此绿色区域，会展示这节团课的所有预约人员。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/9a48a841ae774caab65a922340231117.png" width={500} />

                <SectionTitle text="二.后台为学员添加团课预约" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/217ca143576948ec91012a76e265a7f0.png" width={500} />
                <NormalContent text={<span>1.如图所示，在点击绿色区域后所展示的预约情况区域中，右上角有一个“<Highlight text="+" />”号按钮，点击加号按钮，可以在后台为学员添加这节团课的预约。</span>} />

                <SectionTitle text="三.操作团课预约" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/378d6e7fb7b1470cadd5798f5fecf6b7.png" width={500} />
                <NormalContent text={<span>1.如图所示，在点击绿色区域后所展示的预约情况区域中，每一条记录后面都有两个按钮：取消、旷课。点击按钮，分别可以在后台取消学员的团课预约，和将学员的团课预约置为旷课状态。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointClass);
