import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class VipUser extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="会员" />

                <SectionTitle text="一.查询会员列表" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/891335e112dc43cbb26d4ffb772e7bc6.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，在右侧就会展示场馆的所有会员。</span>} />
                <NormalContent text={<span>2.在会员管理页面，点击会员记录后的“<Highlight text="详情" />”按钮，进入会员详情页面，查看详细会员信息。</span>} />

                <SectionTitle text="二.会员详情" />
                <NormalContent text={<span>1.如图所示，进入会员详情页面后，分为3个部分：<Highlight text="会员卡信息" />、<Highlight text="私教课信息" />、<Highlight text="会员卡明细" />。</span>} />
                <NormalContent text={<span>2.<Highlight text="会员卡信息" />：</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/9f81e90f0ed34c619009280b30e9726e.png" />
                <KeyValue title="会员卡号：" value={<span>此会员的会员卡号。</span>} />
                <KeyValue title="卡类型：" value={<span>卡类型目前分为<Highlight text="会员卡" />、<Highlight text="私教卡" />两种类型。会员卡是指会员拥有时限卡或者次卡，可以约场馆的团课；私教卡是指会员仅购买了私教课，只可以预约购买的私教课。</span>} />
                <KeyValue title="会员姓名：" value={<span>会员姓名。</span>} />
                <KeyValue title="卡状态：" value={<span>根据不同情况卡状态可能出现：正常、未激活、冻结等状态。</span>} />
                <KeyValue title="卡数据：" value={<span>此外还会根据会员持有的时限卡、次卡或者私教课，显示对应卡的时间、次数等数据。还可对其进行延长时限操作。</span>} />

                <NormalContent text={<span>3.<Highlight text="私教课信息" />：</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/32c12a8bb7e6473eb9bfad70e574e1a1.png" />
                <KeyValue title="私教课列表：" value={<span>私教课信息部分，展示的是此会员在场馆购买的所有私教课。</span>} />
                <KeyValue title="私教课操作：" value={<span>每条会员私教课记录，都可以在后台对其进行：<Highlight text="延长时限" />、<Highlight text="换绑教练" />、<Highlight text="转让课程" />。</span>} />

                <NormalContent text={<span>4.<Highlight text="会员卡明细" />：</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/6541e43ad8b24c8dba87a98eb94fbead.png" />
                <KeyValue title="明细列表：" value={<span>会员卡明细部分，展示了此会员有关的所有操作，比如：开卡、续卡、开课、转让、受让、冻结、恢复等。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipUser);
