import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class ChangeVenue extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="切换场馆" />

                <SectionTitle text="一.切换场馆" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/e93bbab3407b46afb8b71025b8757530.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“场馆管理”后，在右侧就会展示账号中现在的场馆列表。其中，有<span style={TextStyles.highlight}>淡黄色阴影</span>的是正在操作的场馆。</span>} />
                <NormalContent text="2.点击想要切换的场馆上的“进入”按钮，切换要操作的场馆。" />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVenue);
