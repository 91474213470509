import React from 'react';
import { Route } from 'react-router-dom';

import { PageRoutes, MAIN, MAIN_LISTS } from '../../../constants/routeConstants';
import List from './course-list-controller';
import EDIT from './course-save-controller';
import DESC_EDIT from './course-detail-edit-controller';
import { PageStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN.children.COURSE_LIST;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      <Route
        exact
        path={`${baseUrl}/${children.SAVE_COURSE.path}/${children.SAVE_COURSE.children.DESC_EDIT.path}`}
        component={DESC_EDIT} />
      <Route
        exact
        path={`${baseUrl}/${children.SAVE_COURSE.path}`}
        component={EDIT} />
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

