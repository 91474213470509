import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class CreateVenue extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="创建场馆" />

                <SectionTitle text="一.场馆编辑入口" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/f3cb5f655b86492291caac04c1c01c75.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“场馆管理”后，在右侧就会展示账号中现在的场馆列表。其中，有<span style={TextStyles.highlight}>淡黄色阴影</span>的是正在操作的场馆。</span>} />
                <NormalContent text="2.点击想要编辑的场馆上的“编辑”按钮，进入场馆信息编辑页面。" />

                <SectionTitle text="二.编辑场馆信息" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/13123b3506fd4c9fb693ee44ac862051.png" />
                <NormalContent text="1.如图所示，场馆信息分为4个部分：基础信息，场馆介绍，体验课信息，场馆照片。" />
                <NormalContent text={<span>2.<Highlight text="基础信息：" /></span>} />
                    <KeyValue title="场馆海报：" value="在前端（小程序、APP等）展示给用户的场馆图片，可以用场馆Logo或者场馆海报来展示。以正方形展示。" />
                    <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/b41ac71ac36746e19810dcf5390f5fed.jpeg" width={350} />
                    <KeyValue title="场馆logo：" value="在后台的会员管理系统中，左上角展示，及用以在多场馆中区分场馆。" />
                    <KeyValue title="场馆名称：" value="在前端（小程序、APP等）展示给用户的场馆名称。" />
                    <KeyValue title="会员卡标识：" value="录入会员时，自动生成会员卡号，会员卡号由：“会员卡标识”+“会员序号” 组成。（例：会员卡标识设置为OM，则会员卡号为：OM00000001。）" />
                    <KeyValue title="场馆地址：" value="将场馆展示给对应地区的用户。同时在前端（小程序、APP等）展示给用户的场馆地址。" />
                    <KeyValue title="场馆简介：" value="以简单的文字表述场馆的特色。在前端（小程序、APP等）展示给用户。" />
                    <KeyValue title="营业时间：" value="场馆每天的营业开始、起止时间。是影响用户在线约课最早和最晚时间的因素之一，另一个因素是教练的个人工作时间设置（在前端设置）。" />
                    <KeyValue title="场馆类型：" value="关联场馆的相关类型，主要是教学课程类型。同时在前端（小程序、APP等）展示给用户。" />
                    <KeyValue title="联系电话：" value="用户可以通过联系电话主动联系场馆进行约课和沟通。同时在前端（小程序、APP等）展示给用户。" />
                    <KeyValue title="状态：" value="当状态为“开启”时，场馆才会展示在前端（小程序、APP等）。状态为“关闭”时，不会对外展示。" />
                <NormalContent text={<span>3.<span style={TextStyles.highlight}>场馆介绍：</span>可由图文组成。在前端（小程序、APP等），用户进入场馆页面可以查看，用以详细了解场馆。</span>} />
                <NormalContent text={<span>4.<span style={TextStyles.highlight}>体验课信息：</span>一个用户可以在此场馆最多购买的体验课次数。当“每个用户可购买体验课次数”的数值大于0，且课程被设置为可以体验时，用户可以在前端发起购买体验课。（目前前端不会产生交易，具体产生的费用需要在线下用户到馆时自行收取）</span>} />

                <SectionTitle text="三.保存" />
                <NormalContent text={<span>1.在编辑页面的右上角点击“<Highlight text="保存" />”，在提示更新成功后，完成修改。</span>} />
                <NormalContent text={<span>2.确认场馆的状态设置为“<Highlight text="开启" />”后，打开前端（小程序、APP等）场馆列表页，进入对应的城市，或者搜索查询，就可以看到自己的场馆。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVenue);
