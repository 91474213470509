import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { actions as managerActions, launchUpdateManagerState, launchChangePasswd } from '../../../redux/manager-model';
import { actions as queryActions } from '../../../redux/query-model'
import TagSelectView from '../../../view/tags/tag-select-view';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { ManagerListStyles } from '../../../style/page/manager/manager-list-styles';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../style/common-styles';
import { launchGetTagList } from '../../../redux/tag-model';
import { filterParams } from '../../../util/helper/request-helper';
import { PictureOutlined, PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

const confirm = Modal.confirm;
const { Option } = Select;

class TagsListController extends BaseController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      dataInfo: {},
      queryParams: {}
    };

    let that = this;
    this.columns = [
    { title: '编号', width: '10%', dataIndex: 'id', key: 'id', align: 'center' },
    { title: '名称', width: '20%', dataIndex: 'name', key: 'name', align: 'center' },
    { title: '标签类型', width: '10%', dataIndex: 'isCourse', key: 'isCourse', align: 'center', 
      render: isCourse => {
        if (isCourse === 1) {
          return <Tag color="magenta">课程标签</Tag>
        } else {
          return <Tag>-</Tag>
        }
      }
    },
    { title: '图片', width: '20%', dataIndex: 'logo', key: 'logo', align: 'center', render: url => <Avatar shape="square" src={url} size={35} icon={<PictureOutlined />} /> },
    { title: '层级', width: '10%', dataIndex: 'level', key: 'level', align: 'center' },
    { title: '热度', width: '10%', dataIndex: 'hot', key: 'hot', align: 'center' },
    { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
      render: state => {
        if (state === 1) {
          return <Tag color="#87d068">正常</Tag>
        } else if (state === 10) {
          return <Tag color="#f50">待审核</Tag>
        } else if (state === 20) {
          return <Tag>关闭</Tag>
        }
      }
    },
    { title: '操作', width: '10%', key: 'operate', align: 'center',
      render: (tag) => {
        return (
            <div style={ManagerListStyles.operateBox}>

                <a
                href="javascript:void(0)" 
                onClick={(e) => {
                  e.stopPropagation();
                                
                  that.props.history.push({
                      pathname: `${this.props.match.url}/${NAMESPACE.children.EDIT.path}`,
                      state: {
                        tag
                      }
                  });
                }}
                >编辑</a>
            </div>
        );
      }    
    }];

    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleQueryPtagChange = this.handleQueryPtagChange.bind(this);
  }

  componentDidMount() {
    let queryParams = this.queryParams();

    this.queryTagList(queryParams.page ? queryParams.page : 1, queryParams);
  }
  
  queryTagList(page) {
    let queryParams = this.queryParams();
    queryParams.page = page;
    launchGetTagList(
      filterParams(queryParams),
      res => {
        
        this.setState({
          dataInfo: res
        });
      },
      fail => {

      }
    );
  }
  
  handleAddButtonClick = e => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${NAMESPACE.children.EDIT.path}`,
      state: {
                
      }
    });
  }

  /**
   * 
   */
  handleQueryPtagChange(e) {
    let queryParams = this.getQueryParams();
    if (e) {
      queryParams.pname = e.name;
      queryParams.pid = e.id;
    } else {
      queryParams.pname = undefined;
      queryParams.pid = undefined;
    }

    this.setState({ 
      modalTagShowing: false
    });

    this.props.configQueryParams(queryParams);
  }

  handleSearchClick() {
    this.queryTagList(1)
  }


  handleClearClick() {
      this.props.configQueryParams({});
  }

  queryParams() {
      let queryParams = this.props.queryStore[NAMESPACE.name];
      if (!queryParams) queryParams = {};
      return queryParams;
  }

  renderSearchBar() {
    let queryParams = this.queryParams();

    return (
        <div style={TableSearchStyles.searchBox}>
            <div style={TableSearchStyles.searchIputBox}>

                <div style={TableSearchStyles.searchItem_margin}>
                  <span>名称：</span>
                  
                  <Input
                    allowClear
                    style={TableSearchStyles.searchInput_shortpadding}
                    placeholder="请输入标签名称"
                    onChange={e => {
                      queryParams.name = e.target.value;
                      this.props.configQueryParams(queryParams);
                    }}
                    value={this.state.queryParams.name} />
                </div>

                <div style={TableSearchStyles.searchItem_margin}>
                  <span>状态：</span>
                  
                  <Select 
                    defaultValue={0} 
                    style={{...TableSearchStyles.searchInput_shortpadding, width: 100}}
                    onChange={e => {
                      queryParams.state = e;
                      this.props.configQueryParams(queryParams);
                    }}>
                    <Option value={0}>全部</Option>
                    <Option value={1}>开启</Option>
                    <Option value={10}>待审核</Option>
                    <Option value={20}>关闭</Option>
                  </Select>
                </div>

                <div style={TableSearchStyles.searchItem_margin}>
                  <span>层级：</span>
                  
                  <Select 
                      defaultValue={0} 
                      style={{...TableSearchStyles.searchInput_shortpadding, width: 100}}
                      onChange={e => {
                        queryParams.level = e;
                        this.props.configQueryParams(queryParams);
                      }}>
                      <Option value={0}>全部</Option>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                  </Select>
                </div>

                <div style={TableSearchStyles.searchItem_margin}>
                  <span>所属标签：</span>
                  <div onClick={ () => { this.setState({ modalTagShowing: true }) }}>
                      <Select
                          showArrow={false}
                          style={{...TableSearchStyles.searchInput_shortpadding}}
                          placeholder="选择父级标签"
                          allowClear={true}
                          onChange={this.handleQueryPtagChange}
                          value={queryParams.pname}
                          dropdownStyle={{ display: "none" }}>
                      </Select>
                  </div>
                </div>
            </div>

            <div style={TableSearchStyles.operateBox}>
                <div style={TableSearchStyles.searchButtonContainer}>
                    <Button
                        style={TableSearchStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={TableSearchStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        </div>
    );
}

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">新增标签</Button>
        </div>
      )
    });
  }

  render() {
    const { dataInfo } = this.state;
    const { loginUser } = this.props;

    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={TableStyles.tableBox}>
          
          {
            this.renderSearchBar()
          }

          <Table
            className="manager-table"
            rowKey={record => record.id}
            loading={this.state.isLoading}
            columns={this.columns}
            dataSource={dataInfo.list}
            // scroll={{ y: 485 }}
            pagination={{
              total: dataInfo.totalpage * dataInfo.pagesize,
              pageSize: dataInfo.pagesize,
              current: dataInfo.page,
              onChange: val => {
                this.queryTagList(val)
            }
          }}
          ></Table>
        </div>

        <Modal
          centered={true}
          visible={this.state.modalTagShowing}
          destroyOnClose={true}
          width={"956px"}
          onCancel={() => { this.setState({ modalTagShowing: false }) }}
          footer={null}
        >
          <TagSelectView onRecordSelect={this.handleQueryPtagChange} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ queryStore }) => {
  return {
    queryStore
  };
}

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
      const pageQuery = {};
      pageQuery[NAMESPACE.name] = params;
      dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsListController);
