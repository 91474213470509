import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

// 条件查询会员课程列表
export async function launchGetVipCourseList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.GET_VIPCOURSES_LIST, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}


export async function launchGetVipCourses(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.GET_VIP_COURSES, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
          success(response.responseData.result);
        } else {
          failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}

// 延长课程时效
export async function launchProlongCourses(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.PROLONG_COURSES, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

export async function launchProtimesCourses(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.PROTIMES_COURSES, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

// 换绑教练
export async function launchChangeCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.CHANGE_COACH, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

// 转让课程
export async function launchTransferCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.TRANSFER_COURSE, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

export async function launchGetValidVipCourses(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.GET_VALID_PROVATECOURSES, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}
