import React from 'react'

import { Select, Input, Button, Modal, Radio, Upload, message } from 'antd'
import { Link } from 'react-router-dom';

import { connect } from 'react-redux'
import { launchGetUploadToken } from '../../../redux/upload-token-model';
import FormItem from '../../../view/common/form-item';
import TagSelectView from '../../../view/tags/tag-select-view';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { launchSaveTag } from '../../../redux/tag-model';

import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';
import { uploadProjectImageToQiniu, uploadFileToQiniu } from '../../../util/request';

const NAMESPACE = MAIN_LISTS.TAGS_LIST.children.EDIT;

const { Option } = Select;

class TagEditController extends BaseController {
  constructor(props) {
    super(props);

    let { tag } = props.location.state;
    if (!tag) tag = { state: 1, isCourse: 2 }

    this.state = {
        ...this.state,
        tag,
        modalTagShowing: false
    };

    this.handleNameChanged = this.handleNameChanged.bind(this);
    this.handleLevelChanged = this.handleLevelChanged.bind(this);
    this.handlePtagChanged = this.handlePtagChanged.bind(this);
    this.handleIsCourseChanged = this.handleIsCourseChanged.bind(this);
    this.handleStateChanged = this.handleStateChanged.bind(this);
    this.handleHotChanged = this.handleHotChanged.bind(this);
    this.handleLogoChanged = this.handleLogoChanged.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  componentDidMount() {
    console.log(this.props, 'this.props');
  }

  handleLevelChanged(e) {
    const { tag } = this.state;
    tag.level = e;
    if (e === 1) {
      tag.pid = undefined;
      tag.pname = undefined;
    }
    this.setState({ tag });
  }

  handleNameChanged(e) {
    const { tag } = this.state;
    tag.name = e;
    this.setState({ tag });
  }

  handleLogoChanged(file) {
    this.editLogoPicture = file;
  }

  handlePtagChanged(e) {
    const { tag } = this.state;
    if (e) {
      if (e.id === tag.id) {
        message.error('不能选择自身为父标签');
        return;
      }
      tag.pid = e.id;
      tag.pname = e.name;
    } else {
      tag.pid = undefined;
      tag.pname = undefined;
    }

    this.setState({ 
      tag,
      modalTagShowing: false
    });
  }

  handleIsCourseChanged(e) {
    const { tag } = this.state;
    tag.isCourse = e;
    this.setState({ tag });
  }

  handleStateChanged(e) {
    const { tag } = this.state;
    tag.state = e;
    this.setState({ tag });
  }

  handleHotChanged(e) {
    const { tag } = this.state;
    tag.hot = e;
    this.setState({ tag });
  }

  handleSaveButtonClick() {
    const { tag } = this.state;
    const params = {};

    for (let key in tag) {
      if (tag[key]) {
        params[key] = tag[key];
      }
    }

    const saveCallback = (requestParams) => {
      launchSaveTag(
        requestParams,
        res => {
          
          message.success('保存成功');
          this.props.history.goBack();
          this.editLogoPicture = undefined;
          this.changeLoadingState(false);
        },
        err => { 
          message.error(err);
          this.changeLoadingState(false);
        }
      );
    };

    // 上传
    this.changeLoadingState(true);
    if (this.editLogoPicture) {
      let that = this;
      let filenames = [this.editLogoPicture.name];

      launchGetUploadToken(
        { type: 5, files: filenames },
        res => {

          let aPath = res.pathList[0];

          uploadFileToQiniu(
            that.editLogoPicture,
            aPath,
            res => {

              const { tag } = that.state;
              tag.logo = aPath.url;
              that.setState({
                tag
              });

              params.logo = aPath.url;
              saveCallback(params);
            },
            fail => {
                console.log(fail);
                this.changeLoadingState(false);
            }
        );   
        },
        fail => {
            this.changeLoadingState(false);
        } 
      );  
    } else {
      saveCallback(params);
    } 
  }

  renderForm() {
    return (
      <div>

        <FormItem itemKey="标签名称：" type="input" itemPlaceHolder="请填写标签名称" valueStyles={{ width: 300 }} itemValue={this.state.tag.name} onValueChanged={this.handleNameChanged} />    
        <FormItem itemKey="分类图片：" type="picture" itemValue={this.state.tag.logo} updatePictures={this.handleLogoChanged}  />
        <FormItem itemKey="层级：" itemPlaceHolder="请选择层级" itemValue={this.state.tag.level} type="select" valueList={[{value: 1, text: '1'}, {value: 2, text: '2'}]} onValueChanged={this.handleLevelChanged} />
        <FormItem itemKey="热度：" itemPlaceHolder="请填写热度" itemValue={this.state.tag.hot} type="input-number" itemValue={this.state.tag.hot} onValueChanged={this.handleHotChanged} />
        {
            this.state.tag.level > 1 ?
                <FormItem itemKey="所属标签：" type="select-modal" itemPlaceHolder="请选择父级标签" itemValue={this.state.tag.pname} valueStyles={{ width: 200 }} onSelectTapped={() => { this.setState({ modalTagShowing: true }); }} onClearSelect={this.handlePtagChanged} /> 
                :
                null
        }
        <FormItem itemKey="是否课程：" itemValue={this.state.tag.isCourse} type="radio" valueList={[{text: '课程标签', value: 1}, { text: '非课程标签', value: 2}]} onValueChanged={this.handleIsCourseChanged} />
        <FormItem 
          itemKey="状态：" itemValue={this.state.tag.state} type="radio" 
          valueList={[
            { text: '开启', value: 1 }, 
            { text: '关闭', value: 20 }
          ]} 
          onValueChanged={this.handleStateChanged} />
      </div>
    );
  }

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button onClick={ this.handleSaveButtonClick } type="primary" size="small">保存</Button>
        </div>
      ),
      onBack: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }

        <div style={PageStyles.contentContainer_nopadding}>
          {
            this.renderForm()
          }
        </div>

        <Modal
            centered={true}
            visible={this.state.modalTagShowing}
            destroyOnClose={true}
            width={"956px"}
            onCancel={() => { this.setState({ modalTagShowing: false }) }}
            footer={null}
        >
            <TagSelectView onRecordSelect={this.handlePtagChanged} />
        </Modal>
        
      </div>
    )
  }

}

const mapStateToProps = store => {
  const utilStore = store['utilStore'];
  // debugger
  return {
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.CREATE_MANAGER.path]
  }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(TagEditController)