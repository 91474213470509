import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';

import { actions as courseActions } from '../../redux/course-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';

const confirm = Modal.confirm;
const Option = Select.Option;

class CourseSelectView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            coursename: '',
            coursetype: undefined,

            selectedRowKeys: []
        };

        this.columns = [
            { title: '课程名称', width: 150, dataIndex: 'coursename', key: 'username', align: 'center' },
            { title: '课程时长(分)', width: 80, dataIndex: 'coursetime', key: 'coursetime', align: 'center' },
            { title: '课程类型', width: 80, dataIndex: 'coursetype', key: 'coursetype', align: 'center', 
                render: coursetype => { 
                    let typeStr = '-';
                    switch (coursetype) {
                        case 1:
                            typeStr = '初级';
                            break;
                        case 2:
                            typeStr = '中级';
                            break;
                        case 3:
                            typeStr = '高级';
                            break;
                        default:
                            typeStr = '-';
                    }
                    return <span>{typeStr}</span>
                } 
            }
        ]

        this.courseSelected = this.courseSelected.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleCourseTypeChange = this.handleCourseTypeChange.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        let params = {
            state: 1, 
            page: this.props.page 
        };
        if (this.props.type === 2) { // 团课课表
            params.type = 1;
        }
        this.props.queryCourseList(params);
    }

    queryCourseList = page => {
        let params = {
            page,
            state: 1,
            coursename: this.state.coursename,
            coursetype: this.state.coursetype
        };
        if (this.props.type === 2) { // 团课课表
            params.type = 1;
        }

        this.props.queryCourseList(params);
    }

    courseSelected() {
        let selectID = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.props.courseList.length; i++) {
            if (this.props.courseList[i].courseid === selectID) {
                this.props.selectedCourse && this.props.selectedCourse(this.props.courseList[i]);
                break;
            }
        }
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.courseid]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    handleSearchClick() {
        this.queryCourseList(1)
    }

    handleClearClick() {
        this.setState({
            coursename: '',
            coursetype: undefined
        })
    }

    handleCourseTypeChange(coursetype) {
        this.setState({
            coursetype
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>课程名称</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ coursename: e.target.value })}
                        value={this.state.coursename} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>课程类型</span>
                        <Select
                            style={styles.type_Select}
                            placeholder="请选择"
                            value={this.state.coursetype}
                            onChange={this.handleCourseTypeChange}
                            >
                            <Option value={1}>初级</Option>
                            <Option value={2}>中级</Option>
                            <Option value={3}>高级</Option>
                        </Select>
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

  render() {
    const { selectedRowKeys } = this.state;
    
    const rowSelection = {
        type: 'radio',
        onChange: this.onSelectedRowKeysChange,
        selectedRowKeys
    };

    const hasSelected = selectedRowKeys.length > 0;
    return (
        <div>
            <div style={PageStyles.tableContainer}>
                {
                    this.renderSearchBar()
                }
                <Button
                    type="primary"
                    onClick={this.courseSelected}
                    disabled={!hasSelected}
                >确定</Button>
                <Table
                    rowKey={record => record.courseid}
                    loading={this.props.loading}
                    columns={this.columns}
                    dataSource={this.props.courseList}
                    scroll={{ y: 485 }}
                    rowSelection={rowSelection}
                    pagination={{
                        total: this.props.totalpage * this.props.pagesize,
                        pageSize: this.props.pagesize,
                        current: this.props.page,
                        onChange: val => {
                            this.queryCourseList(val)
                        }
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            this.selectRow(record);
                        },
                    })}
                ></Table>
            </div>
        </div>
    );
  }
}

const mapStateToProps = store => {
  const courseStore = store['courseStore'];
  return {
    loading: courseStore.loading,
    courseList: courseStore.courseList,
    pagesize: courseStore.pagesize,
    totalpage: courseStore.totalpage,
    page: courseStore.page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryCourseList: params => {
        dispatch(courseActions.queryCourseList(params));
    },
    
    changeLoading: params => {
        dispatch(courseActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSelectView);

const styles = {
    type_Select: {
        width: 122,
        height: 32,
        marginLeft: 14
    },
}