import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class CoachFunc extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="教练线上功能" />

                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/7f2925adbb2a4fc48ae1dbacedaf9fdd.png" width={200} />
                <SectionTitle text="一.工作时间" />
                <NormalContent text={<span>1.教练可以设置自己每天工作的开始和结束时间。学员在预约教练的私教课时，只能在教练工作时间和场馆营业时间的交集内选择上课时间。</span>} />

                <SectionTitle text="二.课表" />
                <NormalContent text={<span>1.添加用户私教课上课。教练可以选择时间添加关联的会员私教课，但是要注意在添加前，确定已经与学员沟通完成，并双方同意。</span>} />
                <NormalContent text={<span>2.添加自定义安排。教练可以在课表中添加自己的自定义安排。</span>} />
                <NormalContent text={<span>3.添加的不论是私教课还是自定义安排，添加后占用的时间段，都无法被学员约课。</span>} />
                
                <SectionTitle text="三.学员预约" />
                <NormalContent text={<span>1.学员的私教课预约申请，会在“学员预约”页面展示。</span>} />
                <NormalContent text={<span>2.教练可以确认、更改时间确认、取消学员的预约申请，但是要注意在操作前，确定已经与学员沟通完成，并双方同意。</span>} />

            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachFunc);
