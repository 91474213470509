import React from 'react'

import { Select, Spin, Input, Button, Modal, Radio, Upload, message } from 'antd'
import { Link } from 'react-router-dom';

import { connect } from 'react-redux'
import { actions as managerActions, launchSaveManager } from '../../../../redux/manager-model';

import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';

import { FormStyles, PageStyles, TableStyles } from '../../../../style/common-styles';
import FormItem from '../../../../view/common/form-item';
import { UserAuth_List } from '../../../../constants/authConstants';
import LLCDateHelper from 'date-helper';
import { launchSavePartner } from '../../../../redux/partner-model';

const confirm = Modal.confirm;

const NAMESPACE = MAIN_LISTS.PARTNER.children.EDIT;

class PartnerSaveController extends BaseController {
  constructor(props) {
    super(props);

    let partner = { maxvenue: 1 };
    
    if (props.location.state && props.location.state.partner) {
        partner = props.location.state.partner;
    }

    this.state = {
        ...this.state,
        partner,
    };

    this.handleMaxvenueChanged = this.handleMaxvenueChanged.bind(this);
    this.handleExpiretimeChange = this.handleExpiretimeChange.bind(this);
    this.saveManager = this.saveManager.bind(this);
    this.handleCreateManagerButtonClick = this.handleCreateManagerButtonClick.bind(this);
  }

    componentDidMount() {
        console.log(this.props, 'this.props');
    }

    handleMaxvenueChanged(e) {
        let { partner } = this.state;
        partner.maxvenue = e;

        this.setState({ partner });
    }

    handleExpiretimeChange(selectTime, date, dateString) {
        let selectedEndTime = LLCDateHelper.timeStrToTimestamp(dateString);
        selectedEndTime = selectedEndTime + 24 * 60 * 60 - 1;
        
        let { partner } = this.state;
        partner.expiretime = selectedEndTime * 1000;

        this.setState({ partner });
    }

    handleCreateManagerButtonClick() {
        if (this.checkFormNotHasEmptyItem()) {
          // 没空项目, 进行上传操作
          this.saveManager();
        }
    }
    
      // 表单是不是都填满了?
    checkFormNotHasEmptyItem = () => {
        let { partner } = this.state;
        if (!partner.username) {
            message.error('请填写账户姓名');
            return false;
        }
        if (!partner.accountname) {
            message.error('请填写登录账户');
            return false;
        }
        if (!partner.phone) {
            message.error('请填写手机号');
            return false;
        }
        if (!partner.userid && !partner.passwd) {
            message.error('请填写密码');
            return false;
        }
        if (!partner.userid && partner.passwd !== partner.confirmPasswd) {
            message.error('两次密码输入不一致');
            return false;
        }
        if (!partner.maxvenue) {
            message.error('需输入最大场馆数');
            return false;
        }
        if (!partner.expiretime) {
            message.error('需输入到期时间');
            return false;
        }
        return true;
    }

    saveManager() {
    
        let that = this;
        confirm({
            title: '确定保存？',
            content: '',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                let params = {
                    ...that.state.partner
                };
                that.changeLoadingState(true);
                launchSavePartner(
                    params,
                    res => {
                        
                        message.success('更新成功');
                        that.props.history.goBack();
                        that.changeLoadingState(false);
                    },
                    err => { 
                        message.error(err);
                        that.changeLoadingState(false);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    renderForm() {
        let { partner }  = this.state;
        return (
        <div style={{ paddingTop: 20 }}>
            <div style={FormStyles.formItem}>
                <div style={FormStyles.leftBox}>
                    <span>商家名称:</span>
                </div>
                <div style={FormStyles.rightBox}>
                    <Input
                        allowClear
                        style={FormStyles.input_middle}
                        placeholder="请填写商家名称"
                        value={this.state.partner.username}
                        onChange={e => {
                            let { partner } = this.state;
                            let inputText = e.target.value;
                            partner.username = inputText;
                            
                            this.setState({ partner });
                        }}
                    />
                </div>
                </div>
                
                <div style={FormStyles.formItem}>
                <div style={FormStyles.leftBox}>
                    <span>登录账号:</span>
                </div>
                <div style={FormStyles.rightBox}>
                    <Input
                        disabled={partner.userid}
                        allowClear
                        style={FormStyles.input_middle}
                        placeholder="请填写登录账号"
                        value={this.state.partner.accountname}
                        onChange={e => {
                            let inputText = e.target.value;
                            let { partner } = this.state;
                            partner.accountname = inputText;

                            this.setState({ partner });
                        }}
                    />
                </div>
                </div>

                <div style={FormStyles.formItem}>
                <div style={FormStyles.leftBox}>
                    <span>手机号:</span>
                </div>
                <div style={FormStyles.rightBox}>
                    <Input
                        allowClear
                        style={FormStyles.input_middle}
                        placeholder="请填写手机号"
                        value={this.state.partner.phone}
                        onChange={e => {
                            let inputText = e.target.value;
                            if (inputText.length > 11) {
                                inputText = inputText.substr(0, 11);
                            }
                            let { partner } = this.state;
                            partner.phone = inputText;

                            this.setState({ partner });
                        }}
                    />
                </div>
                </div>
                
                {
                    !partner.userid ?
                        <div style={FormStyles.formItem}>
                            <div style={FormStyles.leftBox}>
                                <span>密码:</span>
                            </div>
                            <div style={FormStyles.rightBox}>
                                <Input
                                    allowClear
                                    style={FormStyles.input_middle}
                                    placeholder="请填写密码"
                                    value={this.state.partner.passwd}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        let { partner } = this.state;
                                        partner.passwd = inputText;

                                        this.setState({ partner });
                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                }
                
                {
                    !partner.userid ?
                        <div style={FormStyles.formItem}>
                            <div style={FormStyles.leftBox}>
                                <span>确认密码:</span>
                            </div>
                            <div style={FormStyles.rightBox}>
                                <Input
                                    allowClear
                                    style={FormStyles.input_middle}
                                    placeholder="请确认密码"
                                    value={this.state.partner.confirmPasswd}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        let { partner } = this.state;
                                        partner.confirmPasswd = inputText;
                                        this.setState({ partner });
                                    }}
                                    onBlur={() => {

                                    }}
                                />
                            </div>
                        </div>
                        :
                        null
                }

                <div style={{ width: '80%', height: 1, backgroundColor: '#f5f5f5', marginLeft:20, marginTop: 40, marginBottom: 40 }} />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormItem itemKey="到期时间：" type="date-picker" itemPlaceHolder="请填使用到期时间" valueStyles={{ width: 200 }} itemValue={this.state.partner.expiretime / 1000} onValueChanged={this.handleExpiretimeChange} />

                    <FormItem itemKey="最大场馆：" itemPlaceHolder="请填写商家最大场馆数" itemValue={this.state.partner.maxvenue} type="input-number" onValueChanged={this.handleMaxvenueChanged} />             
                </div>

        </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
        title: NAMESPACE.name,
        extra: (
            <div>
                <Button type="primary" size="small" onClick={this.handleCreateManagerButtonClick}>保存</Button>
            </div>
        ),
        onBack: () => {
            this.props.history.goBack();
        }
        });
    }

    render() {
        return (
        <Spin spinning={this.state.isLoading}>
        <div style={PageStyles.pageContainer}>
            {
                this.renderNavHeader()
            }

            <div style={TableStyles.tableBox}>
            {
                this.renderForm()
            }
            </div>
        </div>
        </Spin>
        )
    }
}

const mapStateToProps = store => {
  
  return {
    
  }
}

export default connect(mapStateToProps)(PartnerSaveController)