import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AppointBanner extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="约课页Banner" />

                <SectionTitle text="一.什么是约课页Banner" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/f54a968b92134b4ba11c903925963041.png" width={300} />
                <NormalContent text={<span>1.在<Highlight text="ONE ME平台" />的前端（小程序、APP等），用户进入场馆的约课页面后，展示在最上方的banner，就是约课页Banner，是可以场馆自己设置的。</span>} />

                <SectionTitle text="二.约课页Banner设置" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/74ad377f0a3a4ec5b26f267e92b26ae5.png"/>
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“banner管理”后，在右侧就会展示场馆的Banner列表。</span>} />
                <NormalContent text={<span>2.在banner管理页面，点击右上角的“<Highlight text="编辑" />”按钮，进入banner编辑状态。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/db3c3064cee344da92bf2023e54988b2.png"/>
                <NormalContent text={<span>3.点击“添加一个banner”框，可以新增一个空白banner。</span>} />
                <NormalContent text={<span>4.点击“内容”按钮，为这个banner编辑点击后进入的详情页。</span>} />
                <NormalContent text={<span>5.点击“图片”按钮，为这个banner上传展示图。</span>} />
                <NormalContent text={<span>6.点击“删除”按钮，删除此banner。</span>} />

                <SectionTitle text="三.保存/取消修改" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/b17e046c9fb845698ddc4b337589b674.png"/>
                <NormalContent text={<span>1.编辑完Banner后，如果需要保存修改，注意要点击右上角的“<Highlight text="完成" />”按钮。如果不想保存，可以点击右上角的“<Highlight text="取消" />”按钮。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointBanner);
