import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';


export async function launchGetUserAuthList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.QUERY_USERAUTH_LIST, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

export async function launchGetUserAuthDetail(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.QUERY_USERAUTH_DETAIL, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}


export async function launchPassAuth(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.PASS_AUTH, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}


export async function launchRejectAuth(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.REJECT_AUTH, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

  