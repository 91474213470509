import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar, Divider } from 'antd';
import { connect } from 'react-redux';
import { actions as vipUserActions, launchVipRelated, launchGetVipApplyList, launchCancelVipApply } from '../../../redux/vipuser-model';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import LLCDateHelper from "date-helper";
import { PageStyles, SearchBarStyles, TableStyles } from '../../../style/common-styles';
import AddVipUserController from './vipuser-save-controller';
import { launchGetVipCourses } from '../../../redux/vipcourse-model';
import { PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.VIPUSER_LIST;

const confirm = Modal.confirm;

class VipUserListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            username: '',
            phone: '',
            cardno: ''
        };

        let that = this;
        
        this.columns = [
            { title: '姓名', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '15%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '卡号', width: '15%', dataIndex: 'cardno', key: 'cardno', align: 'center' },
            { title: '卡类型', width: '10%', dataIndex: 'type', key: 'type', align: 'center',
                render: (type) => {
                    if (type === 1) {
                        return <Tag color="purple">私教卡</Tag>
                    } else if (type === 2) {
                        return <Tag color="magenta">会员卡</Tag>
                    } else {
                        return null;
                    }
                }
            },
            { title: '时限卡到期时间', width: '10%',  key: 'limitendtime', align: 'center', 
                render: vip => {
                    if (vip.limitendtime && vip.limitendtime > 0) {
                        return <span>{LLCDateHelper.formatDate(vip.limitendtime, "yyyy-MM-dd")}</span> 
                    } else {
                        return <span>-</span> 
                    }
                }
            },
            { title: '次卡剩余次数', width: '10%', key: 'resttimes', align: 'center', 
                render: vip => {
                    if (vip.resttimes > 0) {
                        return <span>{vip.resttimes}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
            { title: '次卡到期时间', width: '10%', dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{endtime === undefined || endtime === 0 ? '-' : LLCDateHelper.formatDate(endtime, "yyyy-MM-dd")}</span> },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center',
                render: (state) => {
                    if (state === 1) {
                        return <Tag color="#87d068">正常</Tag>
                    } else if (state === 2) {
                        return <Tag color="#2db7f5">冻结</Tag>
                    } else if (state === 10) {
                        return <Tag color="#f50">未激活</Tag>
                    } else {
                        return null;
                    }
                }
            },
            { title: '操作', width: '10%', key: 'operate', align: 'center', 
                render: (vip) => {
                    return <a href="javascript:void(0)" onClick={() => {
                        
                        // that.changeLoadingState(true);
                        // launchVipRelated(
                        //     { userid: vip.userid },
                        //     res => {
                                
                        //         that.changeLoadingState(false);
                                that.props.history.push({
                                    pathname: `${that.props.match.url}/${NAMESPACE.children.VIPUSER_DETAIL.path}`,
                                    state: { 
                                        vip, 
                                        // courses: res.courses,
                                        // details:  res.details,
                                        // nowtime: res.nowtime
                                    }
                                });
                        //     },
                        //     fail => {
                        //         that.changeLoadingState(false);
                        //     }
                        // );
                    }}>详情</a> 
                }
            }
        ];

        this.applyColumns = [
            { title: '姓名', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '15%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '卡号', width: '15%', dataIndex: 'cardno', key: 'cardno', align: 'center', 
                render: cardno => {
                    return <span>-</span>
                }
            },
            { title: '卡类型', width: '10%', dataIndex: 'type', key: 'type', align: 'center',
                render: (type) => {
                    if (type === 1) {
                        return <Tag color="purple">私教卡</Tag>
                    } else if (type === 2) {
                        return <Tag color="magenta">会员卡</Tag>
                    } else {
                        return null;
                    }
                }
            },
            { title: '时限卡到期时间', width: '10%',  key: 'limitendtime', align: 'center', 
                render: vip => {
                    if (vip.limitendtime && vip.limitendtime > 0) {
                        return <span>{LLCDateHelper.formatDate(vip.limitendtime, "yyyy-MM-dd")}</span> 
                    } else {
                        return <span>-</span> 
                    }
                }
            },
            { title: '次卡剩余次数', width: '10%', key: 'resttimes', align: 'center', 
                render: vip => {
                    if (vip.resttimes > 0) {
                        return <span>{vip.resttimes}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
            { title: '次卡到期时间', width: '10%', dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{endtime === undefined || endtime === 0 ? '-' : LLCDateHelper.formatDate(endtime, "yyyy-MM-dd")}</span> },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center',
                render: (state) => {
                    return <span>-</span>
                }
            },
            { title: '操作', width: '10%', key: 'operate', align: 'center', 
                render: (apply) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <a href="javascript:void(0)" onClick={() => {
                                this.props.history.push({
                                    pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_VIPUSER.path}`,
                                    state: {   
                                        apply
                                    }
                                });
                            }}>开卡</a> 

                            <a href="javascript:void(0)" style={{ marginLeft: 20, color: 'red' }} onClick={() => {
                                let that = this;
                                confirm({
                                    title: `确定要取消【${apply.username}】的入会申请吗吗？`,
                                    content: '',
                                    okText: '确定',
                                    cancelText: '取消',
                                    onOk() {
                                        that.requestCancelVipApply(apply);
                                    },
                                    onCancel() {
                                        
                                    },
                                });
                            }}>取消</a> 
                        </div>
                    )
                }
            }
        ];

        this.requestGetVipApplyList = this.requestGetVipApplyList.bind(this);
        this.requestCancelVipApply = this.requestCancelVipApply.bind(this);

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        this.props.queryVipUserList({ page: this.props.page });
        this.requestGetVipApplyList();
    }

    handleSearchClick() {
        this.queryVipUserList(1);
    }

    handleClearClick() {
        this.setState({
            username: '',
            phone: '',
            cardno: ''
        })
    }

    handleAddButtonClick() {
        this.props.history.push(`${this.props.match.url}/${NAMESPACE.children.SAVE_VIPUSER.path}`);
    }

    requestGetVipApplyList() {
        
        this.changeLoadingState(true);
        launchGetVipApplyList(
            {},
            res => {

                let { list } = res;
                
                this.setState({
                    vipApplyList: list,
                });
                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    requestCancelVipApply(apply) {
        
        this.changeLoadingState(true);
        launchCancelVipApply(
            { id: apply.id },
            res => {

                message.success('更新成功');
                this.requestGetVipApplyList();
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    queryVipUserList = page => {
        this.props.queryVipUserList({
            page,
            username: this.state.username,
            phone: this.state.phone,
            cardno: this.state.cardno
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>姓名</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ username: e.target.value })}
                        value={this.state.username} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ phone: e.target.value })}
                        value={this.state.phone} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>卡号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ cardno: e.target.value })}
                        value={this.state.cardno} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">新人开卡</Button>
            </div>
          )
        });
    }

    render() {
        let { vipApplyList } = this.state;
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderSearchBar()
                    }
                    
                    {
                        vipApplyList && vipApplyList.length > 0 ?

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 50, backgroundColor: '#f50', alignItems: 'center' }}>
                                    <span style={{ color: '#fff', fontWeight: 900, fontSize: 16, marginLeft: 10 }}>申请入会待处理</span> 
                                </div>
                                <Table
                                    style={{ backgroundColor: '#FCFCFC' }}
                                    rowKey={record => record.id}
                                    loading={this.state.isLoading}
                                    columns={this.applyColumns}
                                    dataSource={vipApplyList}
                                    scroll={{ 
                                        // x: 1200, 
                                        // y: 485 
                                    }}
                                    pagination={false}
                                ></Table>
                            </div>
                            :
                            null
                    }

                    {
                        vipApplyList && vipApplyList.length > 0 ?
                            <div style={{ marginTop: 80, marginBottom: 80 }}>
                                {/* <Divider /> */}
                            </div>
                            :
                            null
                    }
                    

                    {
                        vipApplyList && vipApplyList.length > 0 ?
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 50, backgroundColor: '#1990FF', alignItems: 'center' }}>
                                    <span style={{ color: '#fff', fontWeight: 900, fontSize: 16, marginLeft: 10 }}>会员列表</span> 
                                </div>
                            </div>
                            :
                            null
                    }
                    
                    <Table
                        rowKey={record => record.userid}
                        loading={this.props.loading}
                        columns={this.columns}
                        dataSource={this.props.vipUserList}
                        scroll={{ 
                            // x: 1200, 
                            // y: 485 
                        }}
                        pagination={{
                            total: this.props.totalpage * this.props.pagesize,
                            pageSize: this.props.pagesize,
                            current: this.props.page,
                            onChange: val => {
                                this.queryVipUserList(val)
                            }
                        }}
                    ></Table>
                </div>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
  const userStore = store['vipUserStore'];
  const utilStore = store['utilStore'];
  return {
    loading: userStore.loading,
    vipUserList: userStore.vipUserList,
    pagesize: userStore.pagesize,
    totalpage: userStore.totalpage,
    page: userStore.page,
    // isShow: utilStore.isShow[PathConstant.VIPUSER_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryVipUserList: params => {
      dispatch(vipUserActions.queryVipUserList(params));
    },
    changeLoading: params => {
      dispatch(vipUserActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipUserListController);
