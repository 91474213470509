import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Divider, Spin } from 'antd';
import { connect } from 'react-redux';

import { PageRoutes } from '../../constants/routeConstants';
import { actions as vipCardActions, launchUpdateVipCardMoldState } from '../../redux/vipcard-model';

import { PageStyles } from '../../style/common-styles';

import VIPCARD_BG from '../../resources/vipcard_bg.png';
import VIPCARD_BG_FREEZE from '../../resources/vipcard_bg_freeze.png';
import VIPCARD_SELECTED from '../../resources/vipcard_select.png';

const confirm = Modal.confirm;

class VipCardMoldSelectView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        typeIndex: -1,
        moldIndex: -1
    };

    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleStateButtonClick = this.handleStateButtonClick.bind(this);

    this.changeLoadingState = this.changeLoadingState.bind(this);
    this.updateMoldState = this.updateMoldState.bind(this);

    this.renderVipCards = this.renderVipCards.bind(this);
  }

  componentDidMount() {
    this.props.queryVipCardMoldList({ });
  }

  changeLoadingState(loading) {
    this.setState({
      loading
    });
  }

  updateMoldState(params) {
    this.changeLoadingState(true);
    launchUpdateVipCardMoldState(
      params,
      response => {
        message.success(response.msg);
        this.changeLoadingState(false);
        this.props.queryVipCardMoldList({ });
      },
      msg => {
        message.error(msg);
        this.changeLoadingState(false);
      }
    )
  }

  handleAddButtonClick() {
    this.props.history.push(`${this.props.match.url}/${PageRoutes.MAIN.children.VIPCARD_LIST.children.SAVE_VIPCARD.path}`);
  }

  handleEditButtonClick(vipCardMold) {
    this.props.history.push({
      pathname: `${this.props.match.url}/${PageRoutes.MAIN.children.VIPCARD_LIST.children.SAVE_VIPCARD.path}`,
      state: { vipCardMold }
    });
  }

  handleStateButtonClick(vipCardMold) {
    let operate = vipCardMold.state === 1 ? '禁用' : '恢复';
    let title = `确认要${operate}会员卡：${vipCardMold.cardname} 吗？`;
    let content = vipCardMold.state === 1 ? '禁用的会员卡无法进行绑定！' : '恢复后，可以使用会员卡进行绑定！'

    let that = this;
    confirm({
      title,
      content,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        let state = 1;
        if (vipCardMold.state === 1) {
          state = 2;
        }
        that.updateMoldState({ moldid: vipCardMold.moldid, state });
      },
      onCancel() {
        
      },
    });
  }

  renderVipCards() {
      return (
        <div style={styles.list}>
            {
                this.props.vipCardMoldList && this.props.vipCardMoldList.map((typeList, index) => {
                    let aType = typeList[0];
                    let typeName = '';
                    if (aType.cardtype === 1) {
                      typeName = '时限卡';
                    } else if (aType.cardtype === 2) {
                      typeName = '次卡';
                    }

                    return (
                        <div key={`typeList${index}`}>
                            <Divider orientation="left">{typeName}</Divider>

                            <div style={styles.typBox}>
                              {
                                typeList && typeList.map((aMold, moldIndex) => {
                                    if (aMold.state != 1) {
                                        return null;
                                    } else {

                                        let textColorStyles = aMold.state === 1 ? styles.normalTextColor : styles.freezeTextColor;
                                        let cardTypeStyles = aMold.cardtype === 1 ? styles.cardType : styles.cardType_times;

                                        return (
                                            <div
                                                onClick={() => {
                                                    this.setState({
                                                        typeIndex: index,
                                                        moldIndex
                                                    });

                                                    this.props.selectedVipCardMold && this.props.selectedVipCardMold(aMold);
                                                }} 
                                                key={`mold${moldIndex}`} 
                                                style={styles.moldBox}>
                                                <img src={ aMold.state === 1 ? VIPCARD_BG : VIPCARD_BG_FREEZE} style={styles.vipCardBg} alt="" />

                                                <span style={{...styles.cardName, ...textColorStyles}}>{aMold.cardname}</span>
                                                <span style={{...styles.cardPrice, ...textColorStyles}}>￥{aMold.cardprice / 100}</span>


                                                <span style={{...cardTypeStyles, ...textColorStyles}}>{typeName}</span>
                                                {
                                                    aMold.cardtype === 2 ?
                                                    <span style={{...styles.cardTimes, ...textColorStyles}}>次数：{aMold.cardtimes} 次</span>
                                                    :
                                                    null
                                                }
                                                <span style={{...styles.cardDuration, ...textColorStyles}}>有效期：{aMold.cardduration} 天</span>

                                                {
                                                    index === this.state.typeIndex && moldIndex === this.state.moldIndex ?
                                                        <img src={VIPCARD_SELECTED} style={styles.vipSelected} alt="" />
                                                        :
                                                        null
                                                }
                                            </div>
                                        )
                                    }
                                })
                              }
                            </div>
                        </div>
                    )
                })   
            }
        </div>
      );
  }

  render() {
    return (
      <div>

        <Spin spinning={this.state.loading || this.props.loading}>
          <div>
              {
                  this.renderVipCards()
              }
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const vipCardStore = store['vipCardStore'];
  const utilStore = store['utilStore'];
  return {
    loading: vipCardStore.loading,
    vipCardMoldList: vipCardStore.vipCardMoldList,
    pagesize: vipCardStore.pagesize,
    totalpage: vipCardStore.totalpage,
    page: vipCardStore.page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryVipCardMoldList: params => {
      dispatch(vipCardActions.queryVipCardMoldList(params));
    },
    changeLoading: params => {
      dispatch(vipCardActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipCardMoldSelectView);


const styles = {
    list: {
        display: 'relative'
    },
    typBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    moldBox: {
        width: 200,
        height: 123.125,
        position: 'relative',
        marginLeft: 10,
        marginBottom: 22
    },
    vipCardBg: {
        width: 200,
        height: 123.125,
        position: 'absolute',
        left: 0,
        top: 0
    },
    cardName: {
        left: 14,
        top: 15,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
        fontSize: 14,
        position: 'absolute'
    },
    cardPrice: {
        top: 10,
        right: 12,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
        fontSize: 20,
        position: 'absolute'
    },
    cardType: {
        bottom: 30,
        left: 14,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 14,
        position: 'absolute'
    },
    cardType_times: {
        bottom: 50,
        left: 14,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 14,
        position: 'absolute'
    },
    cardTimes: {
        bottom: 30,
        left: 14,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 14,
        position: 'absolute'
    },
    cardDuration: {
        bottom: 10,
        left: 14,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 14,
        position: 'absolute'
    },
    editButton: {
        bottom: 17,
        right: 88,
        position: 'absolute'
    },
    stateButton: {
        bottom: 17,
        right: 18,
        position: 'absolute'
    },
    normalTextColor: {
        color: '#9A6326'
    },
    freezeTextColor: {
        color: '#7D7D7D'
    },
    vipSelected: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 30,
        height: 30
    }
}