import React from 'react';
import { Upload, Button, message } from 'antd';
import Zmage from 'react-zmage';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class CustomPicturesWall extends React.Component {
    constructor(props) {
        super(props);
        
        let fileList = props.defaultPictures ? props.defaultPictures : [];
        let showPics = [];
        for (let i = 0; i < fileList.length; i++) {
            showPics.push({ src: fileList[i].url });
        }

        this.state = {
          previewVisible: false,
          previewImage: '',
          fileList,
          showPics
        };

        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    beforeUpload = async (file) => {
        console.log(file);
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {}
        
        let customFile = {};
        customFile.originFileObj = file;
        customFile.preview = await getBase64(customFile.originFileObj);
        customFile.name = file.name;
        
        this.state.fileList.push(customFile);
        this.state.showPics.push({ src: customFile.preview });

        this.setState({ 
            fileList: this.state.fileList,
            showPics: this.state.showPics
        });
        
        this.props.updatePictures && this.props.updatePictures(this.state.fileList);

        return false; // 不调用默认的上传方法
    }

    handleChange = async ({ fileList }) => {
        
        // for (let i = 0; i < fileList.length; i++) {
        //     let file = fileList[i];
        //     if (!file.url && !file.preview) {
        //         file.preview = await getBase64(file.originFileObj);
        //     }

        //     this.state.fileList.push(file);
        //     this.state.showPics.push({ src: file.preview });
        // }

        // this.setState({ 
        //     fileList: this.state.fileList,
        //     showPics: this.state.showPics
        // });
        
        // this.props.updatePictures && this.props.updatePictures(this.state.fileList);
    };

    handleDeleteButton(index) {
        let { showPics, fileList } = this.state;
        showPics.splice(index, 1);
        fileList.splice(index, 1);

        this.setState({
            showPics,
            fileList
        });

        this.props.updatePictures && this.props.updatePictures(this.state.fileList);
    }

    render() {
        const { showPics, fileList } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                <div>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        multiple={true}
                        beforeUpload={this.beforeUpload}
                        // onChange={this.handleChange}
                    >
                        {uploadButton}
                    </Upload>
                </div>
   
                {
                    fileList && fileList.map((item, index) => {
                        if (item.url) {
                            return (
                                <div key={`pic_${index}`} style={{ width: 100, height: 100, marginRight: 10, marginBottom: 10, position: 'relative' }}>
                                    <Zmage 
                                        key={`img_${index}`} 
                                        src={`${item.url}?imageMogr2/auto-orient/thumbnail/750x/blur/1x0/quality/75`} 
                                        style={{ width: 100, height: 100, objectFit: 'cover' }} 
                                        mode="aspectFill" 
                                        set={showPics}
                                        defaultPage={index}/>
                                    <Button 
                                        onClick={() => {this.handleDeleteButton(index)}} 
                                        style={{
                                            position: 'absolute',
                                            top: -5,
                                            right: -5
                                        }} 
                                        shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
                                </div>
                            )
                        } else {
                            return (
                                <div key={`pic_${index}`} style={{ width: 100, height: 100, marginRight: 10, marginBottom: 10, position: 'relative' }}>
                                    <Zmage 
                                        key={`img_${index}`} 
                                        src={item.preview} 
                                        style={{ width: 100, height: 100, objectFit: 'cover' }} 
                                        mode="aspectFill" 
                                        set={showPics}
                                        defaultPage={index}/>
                                    <Button 
                                        onClick={() => {this.handleDeleteButton(index)}} 
                                        style={{
                                            position: 'absolute',
                                            top: -5,
                                            right: -5
                                        }} 
                                        shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
                                </div>
                            )
                        }
                    })
                }
            </div>
        );
    }
}