import React from 'react';
import { Avatar, Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Popconfirm } from 'antd';
import { connect } from 'react-redux';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';
import BaseSelectView from '../common/base-select-view';
import { isEmpty } from '../../util/helper/string-helper';
import { launchGetValidVipCourses } from '../../redux/vipcourse-model';
import { CourseListStyles } from '../../style/page/course/course-list-styles';
import { filterParams } from '../../util/helper/request-helper';

const confirm = Modal.confirm;

class VipCourseSelectView extends BaseSelectView {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            dataInfo: {},

            selectedRowKeys: []
        };

        this.columns = [
            { title: '课程名称', width: 200, dataIndex: 'coursename', key: 'coursename', align: 'left', render: (coursename) => <span style={CourseListStyles.courseName}>{coursename}</span> },
            { title: '会员名称', width: 200, dataIndex: 'username', key: 'username', align: 'center' },
            { title: '教练名称', width: 200, dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '剩余次数', width: 200, dataIndex: 'coursetimes', key: 'coursetimes', align: 'center' },
        ]

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.handleDoneTapped = this.handleDoneTapped.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
       this.queryCourseList(1);
    
    }

    getQueryParams() {
        let params = {  };

        if (!isEmpty(this.state.coursename)) {
            params.coursename = this.state.coursename;
        }

        return params;
    }

    queryCourseList = page => {
        this.changeLoadingState(true);

        this.setState({ selectedRowKeys: [] });

        let params = { coursename: this.state.coursename, username: this.state.username };
        params.page = page;
        
        launchGetValidVipCourses(
            filterParams(params),
            res => {
                this.changeLoadingState(false);
                this.setState({
                    dataInfo: res
                });
                
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.id]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        console.log(selectedRowKeys, selectedRowKeys);
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    handleDoneTapped() {
        let selectedId = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.state.dataInfo.list.length; i++) {
            let aRecord = this.state.dataInfo.list[i];

            if (aRecord.id == selectedId) {
                this.props.onRecordSelect && this.props.onRecordSelect(aRecord);
                break;
            }
        }
    }

    handleSearchClick() {
        this.queryCourseList(1)
    }

    handleClearClick() {
        this.setState({
            nickname: '',
            parentname: ''
        })
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>课程名称</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入课程名称"
                            onChange={e => this.setState({ coursename: e.target.value })}
                            value={this.state.coursename} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>会员姓名</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入用户姓名"
                            onChange={e => this.setState({ username: e.target.value })}
                            value={this.state.username} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderOperateBar() {
        let totalCount = this.state.dataInfo.totalcount;
        
        return (
            <div style={styles.operateBar}>
                <Button
                    style={styles.doneButton}
                    type="primary"
                    disabled={!(this.state.selectedRowKeys.length > 0)}
                    onClick={this.handleDoneTapped}
                >确定</Button>
                
            </div>
        );
    }

    render() {
        const { selectedRowKeys } = this.state;
        
        const rowSelection = {
            type: 'radio',
            onChange: this.onSelectedRowKeysChange,
            selectedRowKeys
        };

        return (
            <Spin spinning={this.state.isLoading}>
            {/* <div style={{...styles.container, height: this.state.windowHeight-300, marginTop: 20}}> */}
            <div>
                <div style={PageStyles.tableContainer}>
                    {
                        this.renderSearchBar()
                    }
                    
                    {
                        this.renderOperateBar()
                    }

                    <Table
                        rowKey={record => record.id}
                        // loading={this.state.isLoading}
                        columns={this.columns}
                        dataSource={this.state.dataInfo.list}
                        scroll={{ y: 485 }}
                        rowSelection={rowSelection}
                        pagination={{
                            total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                            pageSize: this.state.dataInfo.pagesize,
                            current: this.state.dataInfo.page,
                            onChange: val => {
                                this.queryCourseList(val)
                            }
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                this.selectRow(record);
                            },
                        })}
                    ></Table>
                </div>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
  
  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipCourseSelectView);


const styles = {
    container: {
        display: 'flex',
    },
    operateBar: {
        marginBottom: 10,
    },

    selectedTip: {
        color: '#999999',
        fontSize: 13,
        marginRight: 15
    },

    doneButton: {
        marginRight: 15
    }
};