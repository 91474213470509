import React from 'react'
import { Select, Input, InputNumber, Button, message, Radio, Modal, Spin } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux'

import { actions as vipCardActions, launchSaveVipCardMold } from '../../../redux/vipcard-model';

import { MAIN_LISTS } from '../../../constants/routeConstants';

import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';
import { VipCardMoldSaveStyles } from '../../../style/page/vipcard/vipcard-mold-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import BaseController from '../../base/base-controller';

const NAMESPACE = MAIN_LISTS.VIPCARD_LIST.children.SAVE_VIPCARD;

const Option = Select.Option;
const confirm = Modal.confirm;

class SaveVipCardController extends BaseController {
    constructor(props) {
    super(props);

    let editVipCardMold = {};
    if (this.props.location.state && this.props.location.state.vipCardMold) {
      editVipCardMold = this.props.location.state.vipCardMold;
    }

    this.state = {
        ...this.state,
        moldid: undefined,
        cardtype: 1,
        cardname: '',
        cardprice: 100,
        cardduration: 1,
        cardtimes: 1,
        state: 2,

        ...editVipCardMold
    };

    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleCardTypeChange = this.handleCardTypeChange.bind(this);

    this.saveVipCardMold = this.saveVipCardMold.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  handleSaveButtonClick() {
    if (this.checkFormNotHasEmptyItem()) {

      let that = this;
      confirm({
        title: '确认提交？',
        content: '',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.saveVipCardMold();
        },
        onCancel() {
          
        },
      });
    }
  }

  handleCardTypeChange(cardtype) {
    this.setState({
      cardtype
    });
  }

  checkFormNotHasEmptyItem = () => {
    if (isEmpty(this.state.cardname)) {
      message.error('请填写会员卡名称');
      return false;
    }
    if (this.state.cardduration == null) {
      message.error('请填写时限卡天数');
      return false;
    }
    if (this.state.cardprice == null) {
      message.error('请填写售价');
      return false;
    }
    if (this.state.cardtype === 2 && this.state.cardtimes == null) {
      message.error('请填写次卡次数');
      return false;
    }
    return true;
  }

  saveVipCardMold = () => {
    const params = {
        cardtype: this.state.cardtype,
        cardname: this.state.cardname,
        cardprice: this.state.cardprice,
        cardduration: this.state.cardduration,
        cardtimes: this.state.cardtimes,
        state: this.state.state
    };
    
    if (this.state.moldid) {
      params.moldid = this.state.moldid;
    }

    this.changeLoadingState(true);
    launchSaveVipCardMold(
      params,
      response => {
        message.success(response.msg);
        this.changeLoadingState(false);

        this.props.dispatch(vipCardActions.queryVipCardMoldList({}));
        this.props.history.goBack();
      },
      msg => {
        message.error(msg);
        this.changeLoadingState(false);
      }
    )
  }

  renderHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button type="primary" size="small" onClick={this.handleSaveButtonClick}>提交</Button>
        </div>
      ),
      onBack: () => {
        this.props.history.goBack();
      }
    });
  }

  renderForm() {
    return (
      <div style={{ paddingTop: 40 }}>
        <div style={FormStyles.formItem}>
          <div style={FormStyles.leftBox}>
            <span>会员卡类型 :</span>
          </div>
          <div style={FormStyles.rightBox}>
            <Select
              style={VipCardMoldSaveStyles.cardType_Select}
              // placeholder="请选择"
              value={this.state.cardtype}
              onChange={this.handleCardTypeChange}
            >
              <Option value={1}>时限卡</Option>
              <Option value={2}>次卡</Option>
            </Select>
          </div>
        </div>
          
        <div style={FormStyles.formItem}>
          <div style={FormStyles.leftBox}>
            <span>会员卡名称 :</span>
          </div>
          <div style={FormStyles.rightBox}>
            <Input
              allowClear
              style={FormStyles.input_middle}
              className="middle-input"
              placeholder="请填写会员卡名称"
              value={this.state.cardname}
              onChange={e => {
                let inputText = e.target.value;
                this.setState({ cardname: inputText })
              }}
            />
          </div>
        </div>

        <div style={FormStyles.formItem}>
          <div style={FormStyles.leftBox}>
            <span>时限（天）:</span>
          </div>
          <div style={FormStyles.rightBox}>
            <InputNumber
              min={1}
              value={this.state.cardduration}
              onChange={number => {
                this.setState({ cardduration: number })
              }}
            />
          </div>
        </div>

        {
          this.state.cardtype === 2 ?
            <div style={FormStyles.formItem}>
              <div style={FormStyles.leftBox}>
                <span>次数 :</span>
              </div>
              <div style={FormStyles.rightBox}>
                <InputNumber
                  min={1}
                  value={this.state.cardtimes}
                  onChange={number => {
                    this.setState({ cardtimes: number })
                  }}
                />
              </div>
            </div>
            :
            null
        }

        <div style={FormStyles.formItem}>
          <div style={FormStyles.leftBox}>
            <span>售价（元）:</span>
          </div>
          <div style={FormStyles.rightBox}>
            <InputNumber
              min={0}
              value={this.state.cardprice/100}
              onChange={number => {
                this.setState({ cardprice: number*100 })
              }}
            />
          </div>
        </div>

        <div style={FormStyles.formItem}>
          <div style={FormStyles.leftBox}>
            <span>状态 :</span>
          </div>
          <div style={FormStyles.rightBox}>
            <Radio.Group
              className="radio"
              onChange={e => this.setState({ state: e.target.value })}
              value={this.state.state}
            >
              <Radio value={1}>开启</Radio>
              <Radio value={2}>禁用</Radio>
              <span style={FormStyles.hintText}>（禁用的会员卡无法进行绑定）</span>
            </Radio.Group>
          </div>
        </div>

        {/* <div style={VipCardMoldSaveStyles.operateBox}>
          <Button
            className="add-manager-button"
            type="primary"
            onClick={this.handleSaveButtonClick}
          >提交</Button>
        </div> */}
      </div>
    );
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
      <div style={PageStyles.pageContainer}>
        {
          this.renderHeader()
        }

        <div style={TableStyles.tableBox}>
          {
            this.renderForm()
          }    
        </div>
      </div>
      </Spin>
    )
  }
}

const mapStateToProps = store => {
  // const managerInfoData = store['managerlist'];
  const utilStore = store['utilStore'];
  // debugger
  return {
    // page: managerInfoData.page,
    // windowHeight: utilStore.windowHeight,
    // 没有子集不用有这个
    // isShow: utilStore.isShow[PathConstant.SAVE_VIPCARD.path]
  }
}

// export default CreateCommunitytController
export default connect(mapStateToProps)(SaveVipCardController)