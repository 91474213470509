import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'
import md5 from'js-md5';
import { launchPOSTRequest } from '../util/request'

export async function launchGetPartnerList(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.GET_PARTNER_LIST, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
          success(response.responseData.result);
        } else {
          failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}

export async function launchSavePartner(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {

      if (params.passwd) {
        params.passwd = md5(params.passwd)
      }
      
      const response = await launchPOSTRequest(Apis.SAVE_PARTNER, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}