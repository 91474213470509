import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryAppointmentTable: createAction('queryAppointmentTable'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordAppointmentTable = createAction('recordAppointmentTable')

const effects = {
  *queryAppointmentTable({ payload }) {
    yield put(changeLoading({ loading: true }))
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_TIMETABLE_COURSE_WITH_APPOINTMENT, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
        let list = response.responseData.result.list;
        let finalArr = collecteCourses(list);

        yield put(changeLoading({ loading: false }))
        yield put(recordAppointmentTable(finalArr))
    } else {
        yield put(changeLoading({ loading: false }))
        message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryAppointmentTable, effects.queryAppointmentTable);
  }
];

// reducer
export const appointmentStore = handleActions(
  {
    recordAppointmentTable(state, { payload: finalArr }) {
      return {
        ...state,
        appointmentTable: finalArr,
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    appointmentTable: []
  }
)

/******************
 ******* 团课 ******
 ******************/

// 查询课表
export async function launchQueryTimeTable(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.QUERY_TIMETABLE, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData);
      } else {
        failed(response.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  
  // 查询上期课表
  export async function launchQueryPreTimeTable(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.QUERY_PRE_TIMETABLE, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData);
      } else {
        failed(response.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }
  
  // 查询下期课表
  export async function launchQueryNextTimeTable(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.QUERY_NEXT_TIMETABLE, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData);
      } else {
        failed(response.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }

// 预约课程
export async function launchAppointmentCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.APPOINTMENT_COURSE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 查询课程预约列表
export async function launchAppointmentListOfTableCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.QUERY_APPOINTMENT_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 用户旷课
export async function launchCutCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CUT_COURSE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 用户取消预约
export async function launchCancelCourse(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CANCEL_COURSE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}


  // 整理课程
function collecteCourses(list) {
    let finalArr = [];
  
    let preTime = undefined;
    for (let i = 0; i < list.length; i++) {
      if (preTime === list[i].begindate) {
  
        let row = finalArr[finalArr.length - 1];
        if (list[i].weekdate === '星期一') {
          row.week1.courses.push(list[i]);
        } else if (list[i].weekdate === '星期二') {
          row.week2.courses.push(list[i]);
        } else if (list[i].weekdate === '星期三') {
          row.week3.courses.push(list[i]);
        } else if (list[i].weekdate === '星期四') {
          row.week4.courses.push(list[i]);
        } else if (list[i].weekdate === '星期五') {
          row.week5.courses.push(list[i]);
        } else if (list[i].weekdate === '星期六') {
          row.week6.courses.push(list[i]);
        } else if (list[i].weekdate === '星期日') {
          row.week7.courses.push(list[i]);
        }
  
      } else {
        let row = {
          begindate: list[i].begindate,
          week1: { courses: [] },
          week2: { courses: [] },
          week3: { courses: [] },
          week4: { courses: [] },
          week5: { courses: [] },
          week6: { courses: [] },
          week7: { courses: [] },
        };
  
        if (list[i].weekdate === '星期一') {
          row.week1.courses = [list[i]];
        } else if (list[i].weekdate === '星期二') {
          row.week2.courses = [list[i]];
        } else if (list[i].weekdate === '星期三') {
          row.week3.courses = [list[i]];
        } else if (list[i].weekdate === '星期四') {
          row.week4.courses = [list[i]];
        } else if (list[i].weekdate === '星期五') {
          row.week5.courses = [list[i]];
        } else if (list[i].weekdate === '星期六') {
          row.week6.courses = [list[i]];
        } else if (list[i].weekdate === '星期日') {
          row.week7.courses = [list[i]];
        }
  
        finalArr.push(row);
      }
  
      preTime = list[i].begindate;
    }
  
    return finalArr;
  }


/******************
 ******* 私教课 ******
 ******************/

 // 私教课预约列表
export async function launchGetAppointPrivateList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_APPOINTPRIVATE_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchCancelAppointPrivate(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CANCEL_APPOINTPRIVATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchAppointPrivate(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.ADD_APPOINTPRIVATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchChangeAppointCoach(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CHANGE_APPOINTPRIVATE_COACH, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchChangeAppointTime(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.CHANGE_APPOINTPRIVATE_DATEINFO, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

export async function launchUnhandlePrivateNotifyList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_UNHANDLE_APPOINT_NOTIFY_LIST, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData.result);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

