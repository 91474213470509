import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, DatePicker, InputNumber, Modal } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { priceFen, priceYuan } from '../../util/helper/number-helper';
import { isEmpty } from '../../util/helper/string-helper';
import BaseSelectView from '../base/base-select-view';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import CourseSelectView from '../../view/course/course-select-view';
import CoachSelectView from '../../view/coach/coach-select-view';

/**
 * 购买私教课程
 */
export default class EnrollPrivateCourseView extends BaseSelectView {
    constructor(props) {
        super(props);

        let vip = props.vip;
        let isLimit = false;
        let isTimes = false;
        if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
            isLimit = true;
        }
        if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
            isTimes = true;
        }

        let type = 1; // 1:时限卡 2:次卡 3:都有 4:都没有      
        if (isLimit && isTimes) {
            type = 3;
            if (vip.endtime > vip.limitendtime) {
                this.defaultStartTime = vip.endtime;
            } else {
                this.defaultStartTime = vip.limitendtime;
            }
            
        } else {
            if (isLimit && !isTimes) {
                type = 1;
                this.defaultStartTime = vip.limitendtime;
            }

            if (!isLimit && isTimes) {
                type = 2;
                this.defaultStartTime = vip.endtime;
            }

            if (!isTimes && !isLimit) {
                type = 4;
            }
        }

        this.type = type;

        let newCard = {};
        if (this.type !== 4) {
            newCard.starttime = this.defaultStartTime;
        }

        this.state = {
            vip,
            newCard, // 新开通会员的状态
            pay: { // 支付信息
                payment: 0,
                paytype: 1
            },

            vipCardMoldListVisible: false
        };

        this.handleCourseChanged = this.handleCourseChanged.bind(this);
        this.handleCoachChanged = this.handleCoachChanged.bind(this);
    }

    handleCourseChanged(course) {
        this.setState({ 
            course,
            modalCourseShowing: false
        });
    }

    handleCoachChanged(coach) {
        this.setState({ 
            coach,
            modalCoachShowing: false
        });
    }

    render() {                                                      
        let { vip } = this.state;

        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                <div style={{ paddingLeft: 250 }}>
                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>私教课程：</span>
                        </div>

                        <div onClick={() => { this.setState({ modalCourseShowing: true }) }}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要开通的私教课"
                                showArrow={false}
                                value={this.state.course ? this.state.course.coursename : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>绑定教练：</span>
                        </div>

                        <div onClick={() => { this.setState({ modalCoachShowing: true }) }}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要绑定的教练"
                                showArrow={false}
                                value={this.state.coach ? this.state.coach.username : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.course ?
                            <div>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>次数：</span>
                                    </div>
                                    <InputNumber
                                        min={1}
                                        placeholder='课程次数' 
                                        value={this.state.course.times}
                                        onChange={number => {
                                            this.setState({ course: {...this.state.course, times: number} })
                                        }}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.infoBox}>
                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>开始时间：</span>
                                        </div>
                                        <DatePicker 
                                            style={{ width: 200 }}
                                            placeholder='私教课生效时间' 
                                            onChange={(date, dateString) => {
                                                let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                this.setState({
                                                    course: {
                                                        ...this.state.course, 
                                                        starttime
                                                    }
                                                });
                                            }}
                                            value={this.state.course.starttime ? moment.unix(this.state.course.starttime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>结束时间：</span>
                                        </div>
                                        <DatePicker
                                            style={{ width: 200 }}
                                            placeholder='私教课过期时间' 
                                            onChange={(date, dateString) => {
                                                let endtime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                endtime = endtime + 24 * 60 * 60 - 1;
                                                console.log(endtime);
                                                this.setState({
                                                    course: {...this.state.course, endtime}
                                                });
                                            }}
                                            value={this.state.course.endtime ? moment.unix(this.state.course.endtime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>实际支付（元）：</span>
                                        </div>

                                        <InputNumber
                                            min={0}
                                            placeholder="实际售价"
                                            value={priceYuan(this.state.pay.payment)}
                                            onChange={number => {
                                                this.setState({ pay: {...this.state.pay, payment: priceFen(number)} })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                    {
                        this.state.course && this.state.coach ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { vip, newCard, course, coach, pay } = this.state;
                                    if (!course || !course.starttime || !course.endtime) {
                                        message.error('请选择开始/结束时间');
                                        return;
                                    }
                                    if (!course) {
                                        message.error('请选择课程');
                                        return;
                                    }
                                    if (!coach) {
                                        message.error('请选择教练');
                                        return;
                                    }
                                    if (!pay || !pay.payment) {
                                        message.error('请输入价格');
                                        return;
                                    }

                                    if (this.props.onSubmit) {
                                        this.props.onSubmit(vip, newCard, course, coach, pay);
                                    }
                                }}
                            >提交</Button>
                            :
                            null
                    }
                </div>

                <Modal
                    centered={true}
                    visible={this.state.modalCourseShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCourseShowing: false }) }}
                    footer={null}
                >
                    <CourseSelectView 
                        type={2}
                        onRecordSelect={this.handleCourseChanged} />
                </Modal>

                <Modal
                    centered={true}
                    visible={this.state.modalCoachShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCoachShowing: false }) }}
                    footer={null}
                >
                    <CoachSelectView 
                        onRecordSelect={this.handleCoachChanged} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}