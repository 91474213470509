import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
    queryVenueList: createAction('queryVenueList'),
    changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordVenueList = createAction('recordVenueList')

const effects = {
    *queryVenueList({ payload }) {
        yield put(changeLoading({ loading: true }))
        
        for (let key in payload) {
            if (!payload[key]) delete payload[key];
        };

        const response = yield call(launchPOSTRequest, Apis.QUERY_VENUE_LIST, payload);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
            yield put(changeLoading({ loading: false }))
            yield put(recordVenueList(response.responseData.result))
        } else {
            yield put(changeLoading({ loading: false }))
            message.error(response.msg)
        }
    },

    *changeLoading({ payload }) {
        payload.loading = payload.loading || false;
        yield put(changeLoading({ payload: payload.loading }))
    }
}

export const watchers = [
    function* () {
        yield takeLatest(actions.queryVenueList, effects.queryVenueList);
    }
];

// reducer
export const venueStore = handleActions(
{
    recordVenueList(state, { payload: result }) {
        
        return {
        ...state,
        venueList: result.list,
        loading: false
        }
    },
    changeLoading(state, { payload: result }) {
        return {
        ...state,
        loading: result.loading
        }
    }
},
{
    loading: false,
    venueList: []
});

// 保存场馆信息
export async function launchSaveVenue(
  params = {},
  success = () => {},
  failed = () => {}
) {
    try {
        const response = await launchPOSTRequest(Apis.SAVE_VENUE, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
        success(response.responseData);
        } else {
        failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}

export async function launchEnterVenue(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
      try {
          const response = await launchPOSTRequest(Apis.ENTER_VENUE, params);
          if (
          response.responseData.succ === RESPONSE_CODE.success &&
          response.responseData.result.status === SERVICE_CODE.Successed
          ) {
            window.localStorage.setItem("enterVenue", JSON.stringify(response.responseData.result.entervenue));
            success(response.responseData);
          } else {
            failed(response.msg);
          }
      } catch (rejectedValue) {
          failed(rejectedValue);
      } 
  }

export async function launchGetVenueDetail(
    params = {},
    success = () => {},
    failed = () => {}
) {
    try {
        const response = await launchPOSTRequest(Apis.GET_VENUE_DETAIL, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
        window.localStorage.setItem("enterVenue", JSON.stringify(response.responseData.result.entervenue));
        success(response.responseData.result);
        } else {
        failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}



export async function launchGetTwopeoplecost(
    params = {},
    success = () => {},
    failed = () => {}
) {
    try {
        const response = await launchPOSTRequest(Apis.GET_TWO_PEOPLE_COST, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
        window.localStorage.setItem("enterVenue", JSON.stringify(response.responseData.result.entervenue));
        success(response.responseData.result);
        } else {
        failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}