import React, { Component } from 'react';
import { Button, Modal, Select, InputItem, Popover, Input, List, Spin, message } from 'antd';
import BaseController from '../../base/base-controller';
import { HomeStyles } from '../../../style/web/home/home-pc-styles';

const { Option } = Select;
const { Search } = Input;

export default class Home extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        };
    }

    componentDidMount() {
       
    }

    render() {
        
        return (
            <div id="container" style={HomeStyles.container}>
                <div id="content" style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', flexShrink:0 }}>
                    {/* 首屏图 */}
                    <div id="first" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', height: 660, backgroundColor: '#fff', flexShrink:0 }}>
                        <img 
                            style={{ objectFit: 'cover', width: 680, height: 660, position: 'absolute', right: 0, top: 0 }} 
                            src="http://qiniu-media.soso-code.com/oneme/pic/custom/a8f5f272dbe348c485eea29af28094b2.jpg"  />
                        <img 
                            style={{ objectFit: 'contain', width: 325, height: 63, position: 'absolute', left: 119, top: 206 }} mode="contain"
                            src="http://qiniu-media.soso-code.com/oneme/pic/custom/31b7a28bd2b84b10ad9bb261d3b4ccf6.jpg"  />
                        <span style={{ fontSize: '50px', fontFamily: 'PingFangSC-Medium', fontWeight: 500, color: '#343434', lineHeight: '70px', letterSpacing: '2px', position: 'absolute', left: 120, top: 343}}>一站式专业SAAS平台</span>

                        <div style={{ width: 55, height: 8, background: '#F6B8B0', borderRadius: '2px', position: 'absolute', left: 120, top: 478 }} />
                        
                        <div style={{ width: 411, fontSize: '14px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#515151', lineHeight: '29px', position: 'absolute', left: 120, top: 527}}>ONE ME 平台不仅仅是系统管理工具，更是一个能维系会员，同时
        增加曝光量的综合平台，为您的场馆提升增值服务和价值。</div>
                    </div>

                    {/* 首年半价图 */}
                    <div id="half" style={{ marginTop: 74, display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', width: '100%', backgroundColor: '#fff', flexShrink:0 }}>
                        <img 
                            style={{ objectFit: 'contain', width: 1220, height: 149 }} mode="contain"
                            src="http://qiniu-media.soso-code.com/oneme/pic/custom/9723f82787a342f5bcf9525e2e181177.jpg"  />
                    </div>

                    {/* 服务区域 */}
                    <div id="service" style={{...HomeStyles.areaBox, marginTop: 74}}>
                        {/* 服务人群 */}
                        <div style={HomeStyles.areaTitle}>服务人群</div>

                        {/* 健身类工作室 */}
                        <div style={{...HomeStyles.serviceItem, marginTop: 73}}>
                            <div style={HomeStyles.serviceItemLeft}>
                                <img 
                                    style={{ objectFit: 'contain', width: 488, height: 340, position: 'absolute', right: 0, top: 0}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/9ce73726b4db454cb5640568cd93ab20.jpg"  />
                            </div>
                            <div style={HomeStyles.serviceItemRight}>
                                <img 
                                    style={{ objectFit: 'contain', width: 105, height: 77, position: 'absolute', left: 0, top: 66}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/2f699403e4f343eb88f39b3f228ac996.jpg"  />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 68, top: 107}}>健身类工作室</div>
                                <div style={{...HomeStyles.serviceItemDesc, position: 'absolute', left: 68, top: 183}}>瑜伽、健身、舞蹈等类型工作室，帮助工作室做到线上的 会员管理、教练管理、课程管理、约课管理等，有效提 高工作效率，增加工作室曝光量</div>
                            </div>
                        </div>

                        {/* 教练 */}
                        <div style={{...HomeStyles.serviceItem, marginTop: 73}}>
                            <div style={HomeStyles.serviceItemLeft}>
                                <img 
                                    style={{ objectFit: 'contain', width: 105, height: 77, position: 'absolute', left: 0, top: 66}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/2d564d8b3d9b45c79792c7796e5ca9f7.jpg"  />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 68, top: 107}}>教练</div>
                                <div style={{...HomeStyles.serviceItemDesc, position: 'absolute', left: 68, top: 183}}>线上认证教练后生成在线简历，随时随地一键转发，求职更便捷。在线安排课表，处理学生约课。教练多店课程可即时同步，无需另外添加。教练免费认证，后续享受多种服务。</div>
                            </div>
                            <div style={HomeStyles.serviceItemRight}>
                                <img 
                                    style={{ objectFit: 'contain', width: 488, height: 340, position: 'absolute', left: 0, top: 0}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/d73c2c85a05b40b58a1822cbb6677962.jpg"  />
                            </div>
                        </div>

                         {/* 健身爱好者 */}
                         <div style={{...HomeStyles.serviceItem, marginTop: 73}}>
                            <div style={HomeStyles.serviceItemLeft}>
                                <img 
                                    style={{ objectFit: 'contain', width: 488, height: 340, position: 'absolute', right: 0, top: 0}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/9ca6b5068fad436c8925db7c63f4b350.jpg"  />
                            </div>
                            <div style={HomeStyles.serviceItemRight}>
                                <img 
                                    style={{ objectFit: 'contain', width: 105, height: 77, position: 'absolute', left: 0, top: 66}} mode="contain"
                                    src="http://qiniu-media.soso-code.com/oneme/pic/custom/d49b85cc06514decbc22d3a293eef40a.jpg"  />
                                <div style={{...HomeStyles.serviceItemName, position: 'absolute', left: 68, top: 107}}>健身爱好者</div>
                                <div style={{...HomeStyles.serviceItemDesc, position: 'absolute', left: 68, top: 183}}>在线学习课程，掌握周边场馆动态，观看达人分享，
记录成长历程，交流运动健身经验</div>
                            </div>
                        </div>
                    </div>

                    {/* 产品特点 */}
                    <div id="product" style={{...HomeStyles.areaBox, marginTop: 130}}>
                        {/* 产品特点 */}
                        <div style={HomeStyles.areaTitle}>产品特点</div>

                        <div style={HomeStyles.productBox}>
                            <div style={{...HomeStyles.productItem, marginRight: 172}}>
                                <img style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/7d720a7a475941c1b58c5b07f29d489b.jpg" />
                                <span style={HomeStyles.productItemName}>终身免费升级</span>
                                <span style={HomeStyles.productItemDesc}>快速迭代，积极更新升级，</span>
                                <span style={HomeStyles.productItemDesc}>7*24小时技术支持，有问题</span>
                                <span style={HomeStyles.productItemDesc}>随时解决。根据各工作室需求进</span>
                                <span style={HomeStyles.productItemDesc}>行优化，匹配适用所有门店</span>
                            </div>

                            <div style={{...HomeStyles.productItem, marginRight: 172}}>
                                <img style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/63b5ceac47924d22b9a62f4baa04a869.jpg" />
                                <span style={HomeStyles.productItemName}>详细指导</span>
                                <span style={HomeStyles.productItemDesc}>专属客服讲解咨询，</span>
                                <span style={HomeStyles.productItemDesc}>有疑惑随时沟通，专业客服即时解答</span>
                                <span style={HomeStyles.productItemDesc}>详细的操作文档，随时查阅，快捷了解</span>
                            </div>
                            
                            <div style={HomeStyles.productItem}>
                                <img style={HomeStyles.productItemIcon} src="http://qiniu-media.soso-code.com/oneme/pic/custom/a4aea78672354c72b54c72035d868b73.jpg" />
                                <span style={HomeStyles.productItemName}>轻量操作，办公便捷</span>
                                <span style={HomeStyles.productItemDesc}>轻量级应用，操作便捷，</span>
                                <span style={HomeStyles.productItemDesc}>快速上手</span>
                            </div>
                            

                        </div>
                    </div>

                    {/* 功能介绍 */}
                    <div id="func" style={{...HomeStyles.areaBox, marginTop: 123}}>
                        {/* 功能结介绍 */}
                        <div style={HomeStyles.areaTitle}>功能结介绍</div>

                        <div style={HomeStyles.funcBox}>
                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/085c10b28c4b4e4da77e29bbcd16b7ce.png" />
                                <span style={HomeStyles.funcItemName}>会员管理</span>
                                <span style={HomeStyles.funcItemDesc}>告别杂乱无章，多类会员统一管理</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/2644fe3d0c6e4de78bbe7a06af201ce5.png" />
                                <span style={HomeStyles.funcItemName}>卡项管理</span>
                                <span style={HomeStyles.funcItemDesc}>支持多种卡项，满足各类会馆需求</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/9c8d11b5d93344f895059dbba4ca788b.png" />
                                <span style={HomeStyles.funcItemName}>课程管理</span>
                                <span style={HomeStyles.funcItemDesc}>线上安排课程，修改便捷团课私教都搞定</span>
                            </div>

                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/52bfdb52f67c400e9a2e8556c49b7d4d.png" />
                                <span style={HomeStyles.funcItemName}>教练管理</span>
                                <span style={HomeStyles.funcItemDesc}>教练统一管理，教练端实时同步课程</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/5c49822860524d28b32d29c95a017859.png" />
                                <span style={HomeStyles.funcItemName}>多店管理</span>
                                <span style={HomeStyles.funcItemDesc}>轻松管理多门店，一套系统就能搞定</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/2e8ccf46a7704db9a50dfc489ec67f54.png" />
                                <span style={HomeStyles.funcItemName}>约课管理</span>
                                <span style={HomeStyles.funcItemDesc}>会员一键约课，约满自动排队</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/5af3a8ee45cb4a08bbb972726ddc3e32.png" />
                                <span style={HomeStyles.funcItemName}>权限管理</span>
                                <span style={HomeStyles.funcItemDesc}>支持多角色账户，权限划分明晰</span>
                            </div>

                            <div style={HomeStyles.funcItem}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/45932e8f7c05485ca27b6d19af26cf3b.png" />
                                <span style={HomeStyles.funcItemName}>综合平台</span>
                                <span style={HomeStyles.funcItemDesc}>不仅仅是管理系统，更是可增加曝光的平台</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/04ea6a3df4ad453db677e4ba8d299294.png" />
                                <span style={HomeStyles.funcItemName}>简历生成</span>
                                <span style={HomeStyles.funcItemDesc}>教练免费认证，精美简历生成，一键转发</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/7bf35d7c580d4957b5acb366642b8de9.png" />
                                <span style={HomeStyles.funcItemName}>运动社区</span>
                                <span style={HomeStyles.funcItemDesc}>交流健身经验，互相激励一起进步</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, marginRight: 100}}>
                                <img style={HomeStyles.funcLogo} src="http://qiniu-media.soso-code.com/oneme/pic/custom/ea15749a9d174ad2a1d81d3c31ea04e8.png" />
                                <span style={HomeStyles.funcItemName}>在线课程</span>
                                <span style={HomeStyles.funcItemDesc}>随时随地，线上跟练，学习不停</span>
                            </div>

                            <div style={{...HomeStyles.funcItem, alignItems: 'center', justifyContent: 'center' }}>

                                <span style={{...HomeStyles.funcItemName, marginTop: 0}}>更多功能</span>
                                <span style={{...HomeStyles.funcItemName, marginTop: 0}}>欢迎咨询</span>
                            </div>
                        </div>

                    </div>

                    {/* 扫码体验 */}
                    <div id="experience" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: 100, position: 'relative'}}>
                        <div style={{ display: 'flex', width: 537, height: 613 }}>
                            <img style={{ objectFit: 'contain', width: 537, height: 613  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/23967fd8798a4fac9d70ba1c18ada143.jpg" />
                        </div>

                        <div style={{ display: 'flex', width: 461, height: 613, position: 'relative' }}>      
                            <span style={{ position: 'absolute', top: 238, left: 109, fontSize: '26px', fontFamily: 'PingFangSC-Medium', fontWeight: 500, color: '#000000', lineHeight: '37px' }}>扫码体验</span>
                            <span style={{ position: 'absolute', top: 299, left: 109, fontSize: '26px', fontFamily: 'PingFangSC-Medium', fontWeight: 400, color: '#000000', lineHeight: '37px' }}>ONE ME平台小程序</span>
                            <div style={{ position: 'absolute', top: 208, left: 231, width: 20, height: 20, borderRadius: 10, background: '#FF7C7C'}} />
                                <img style={{ position: 'absolute', top: 360, left: 109, objectFit: 'contain', width: 155, height: 155  }} src="http://qiniu-media.soso-code.com/oneme/pic/help/93f15e89d9724dd6a0736155ce043a40.jpg" />
                            </div>

                        <img style={{ objectFit: 'contain', position: 'absolute', right: 0, top: 200, width: 113, height: 312  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/3e5f661ea59c49d38b0e1f02beadf423.jpg" />
                        
                    </div>

                    <div id="contact" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: 66, position: 'relative'}}>
                        <img style={{ objectFit: 'widthFix',  width: '100%'  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/d38703e83b9648ebbfa6775da3aac3ab.jpg" />
                        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', width: '50%' }}>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: '50%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                    <span style={{ marginTop: 45, fontSize: '24px', fontFamily: 'PingFangSC-Medium', fontWeight: 500, color: '#3F3F3F', lineHeight: '33px' }}>诚邀代理</span>
                                    <span style={{ marginTop: 28, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#515151', lineHeight: '30px' }}>ONE ME平台现诚招全国各城市代理，</span>
                                    <span style={{ marginTop: 0, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#515151', lineHeight: '30px' }}>提成丰厚，诚邀合作</span>
                                    <span style={{ marginTop: 0, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#515151', lineHeight: '30px' }}>联系邮箱：chao.li@soso-code.com</span>
                                    <span style={{ marginTop: 0, fontSize: '15px', fontFamily: 'PingFangSC-Regular', fontWeight: 400, color: '#515151', lineHeight: '30px' }}>扫码登录ONE ME小程序，咨询客服！</span>
                                </div>
                                <div style={{ display: 'flex', height: '100%', flexDirection: 'column', position: 'relative' }}>
                                    <img style={{ marginLeft: 40, marginTop: 50, objectFit: 'contain', width: 155, height: 155  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/75c1412bd482444d9a726d5d0aeee12b.png" />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>   
            </div>
        );
    }
}