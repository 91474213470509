import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, DatePicker, InputNumber, Input, Modal } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { priceFen, priceYuan } from '../../util/helper/number-helper';
import { isEmpty } from '../../util/helper/string-helper';
import BaseSelectView from '../base/base-select-view';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import VipCardMoldSelectView from '../../view/vipuser/vipcard-mold-select-view';
import UserSelectView from '../../view/vipuser/user-select-view';
import { launchQueryVipState } from '../../redux/vipuser-model';

const { Option } = Select;

/**
 * 转让会员卡
 */
export default class TransferView extends BaseSelectView {
    constructor(props) {
        super(props);

        // 转让方
        let vip = props.vip;
        let isLimit = false;
        let isTimes = false;
        let transferType = 1;
        if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
            isLimit = true;
        }
        if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
            isTimes = true;
        }
        this.isLimit = isLimit;
        this.isTimes = isTimes;

        if (isLimit) {
            transferType = 1;
        } else if (isTimes) {
            transferType = 2;
        }
        
        let newCard = {};

        this.state = {
            ...this.state,
            giveVip: vip,
            newCard,
            transferType,

            userListVisible: false
        };

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.refreshReceiveState = this.refreshReceiveState.bind(this);
    }

    handleTypeChange(e) {
        let transferType = e;
        this.setState({
            transferType
        });

        if (this.state.user) {
            let { receiveVip } = this.state;

            // 受让方
            let vip = receiveVip;
            this.refreshReceiveState(vip, transferType);
        }
    }

    selectedUser(user) {
        let { transferType } = this.state;
        this.changeLoadingState(true);
        launchQueryVipState(
            { userid: user.userid },
            res => {
                this.setState({
                    user,
                    userListVisible: false
                });
                this.changeLoadingState(false);
                this.setState({  
                    receiveVip: res.vipcard
                });

                // 受让方
                let vip = res.vipcard;
                this.refreshReceiveState(vip, transferType);
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    refreshReceiveState(vip, transferType) {
        
        let { giveVip } = this.state;
        this.defaultStartTime = undefined;
        this.defaultEndTime = undefined;
        let canEditStartTime = true;
        let type = 4; // 1:时限卡 2:次卡 3:都有 4:都没有    
        if (vip) {
            let isLimit = false;
            let isTimes = false;
            if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
                isLimit = true;
            }
            if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
                isTimes = true;
            }

            if (isLimit && isTimes) {
                type = 3;
                
                if (transferType === 1) {
                    canEditStartTime = false;
                    this.defaultStartTime = vip.limitendtime;
                } else {
                    canEditStartTime = false;
                    this.defaultStartTime = vip.endtime;
                }
                
            } else {
                if (isLimit && !isTimes) {
                    type = 1;
                    if (transferType === 1) {
                        canEditStartTime = false;
                        this.defaultStartTime = vip.limitendtime;
                    }
                }

                if (!isLimit && isTimes) {
                    type = 2;

                    if (transferType === 2) {
                        canEditStartTime = false;
                        this.defaultStartTime = vip.endtime;
                    }
                }

                if (!isTimes && !isLimit) {
                    type = 4;
                }
            }
        }

        if (this.defaultStartTime) {
            let nowTimestamp = LLCDateHelper.achiveTimestampOfSecond();
            let todayStartTimestamp = LLCDateHelper.todayStartTimestampOfSecond();
            let duration = 0; // 转让方剩余时长
            if (transferType === 1) { // 时限卡
                if (giveVip.limitstarttime > nowTimestamp) {
                    duration = giveVip.limitendtime - giveVip.limitstarttime;
                } else {
                    duration = giveVip.limitendtime - todayStartTimestamp;
                }
            } else if (transferType === 2) {
                if (giveVip.starttime > nowTimestamp) {
                    duration = giveVip.endtime - giveVip.starttime;
                } else {
                    duration = giveVip.endtime - todayStartTimestamp;
                }
            }
            if (duration < 0) duration = 0;

            this.defaultEndTime = this.defaultStartTime + duration;
        }

        console.log(type, '受让方类型');
        this.setState({ 
            newCard: {
                ...this.state.newCard,
                starttime: this.defaultStartTime,
                endtime: this.defaultEndTime
            },
            canEditStartTime,
            receiveVipType: type 
        });
    }

    render() {                                                      
        let { giveVip, transferType, user, receiveVip } = this.state;
        let typeStr = '';
        if (transferType === 1) {
            typeStr = '时限卡';
        } else if (transferType === 2) {
            typeStr = '次卡';
        }

        let userShowStr = undefined;
        if (user) {
            userShowStr = user.nickname;
            
            if (user.phone && user.phone.length > 4) {
                userShowStr = `${userShowStr}（${user.phone.slice(-4)}）`;
            }
        }

        console.log(this.state.isLoading);
        return (
            <Spin spinning={this.props.isLoading || this.state.isLoading}>
            <div style={styles.itemContainer}>
                <div style={{ paddingLeft: 250 }}>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>转让类型：</span>
                        </div>

                        <Select
                            style={FormStyles.input_middle}
                            placeholder="请选择要转让的类型"
                            value={transferType}
                            onChange={this.handleTypeChange}
                        >
                            {
                                this.isLimit ? 
                                    <Option value={1}>时限卡</Option>
                                    :
                                    null
                            }

                            {
                                this.isTimes ?
                                    <Option value={2}>次卡</Option>
                                    :
                                    null
                            }
                            
                        </Select>
                        
                    </div>

                    {
                        this.state.transferType ? 
                            <div>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>受让方：</span>
                                    </div>

                                    <div onClick={() => { this.setState({ userListVisible: true }); }}>
                                        <Select
                                            style={FormStyles.input_long}
                                            placeholder="请选择受让方"
                                            showArrow={false}
                                            value={userShowStr}
                                            dropdownStyle={{ display: "none" }}>
                                        </Select>
                                    </div>
                                </div>

                            </div>
                            :
                            null
                    }

                    {
                        this.state.user ?
                            <div>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>受让方姓名：</span>
                                    </div>

                                    <div>
                                        <Input
                                            allowClear
                                            style={FormStyles.input_middle}
                                            placeholder="请填写受让方姓名"
                                            value={this.state.user.username}
                                            onChange={e => {
                                                let inputText = e.target.value;
                                                this.setState({ user: {...this.state.user, username: inputText} })
                                            }}
                                            />
                                    </div>
                                </div>

                                <div style={VipUserSaveStyles.infoBox}>
                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>开始时间：</span>
                                        </div>
                                        <DatePicker 
                                            disabled={!this.state.canEditStartTime}
                                            style={{ width: 200 }}
                                            placeholder='会员卡生效时间' 
                                            onChange={(date, dateString) => {

                                                let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                
                                                let nowTimestamp = LLCDateHelper.achiveTimestampOfSecond();
                                                let todayStartTimestamp = LLCDateHelper.todayStartTimestampOfSecond();
                                                let duration = 0; // 转让方剩余时长

                                                if (transferType === 1) { // 时限卡
                                                    if (giveVip.limitstarttime > nowTimestamp) {
                                                        duration = giveVip.limitendtime - giveVip.limitstarttime;
                                                    } else {
                                                        duration = giveVip.limitendtime - todayStartTimestamp;
                                                    }
                                                } else if (transferType === 2) {
                                                    if (giveVip.starttime > nowTimestamp) {
                                                        duration = giveVip.endtime - giveVip.starttime;
                                                    } else {
                                                        duration = giveVip.endtime - todayStartTimestamp;
                                                    }
                                                }
                                                if (duration < 0) duration = 0;

                                                this.setState({
                                                    newCard: {
                                                        ...this.state.newCard, 
                                                        starttime,
                                                        endtime: starttime + duration
                                                    }
                                                });
                                            }}
                                            disabledDate={currentDate => {
                                                let todayTimestamp = new Date(new Date().toLocaleDateString()).getTime() / 1000
                                                if (parseInt(currentDate.valueOf() / 1000) < todayTimestamp) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                            value={this.state.newCard.starttime ? moment.unix(this.state.newCard.starttime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>结束时间：</span>
                                        </div>
                                        <DatePicker
                                            style={{ width: 200 }}
                                            placeholder='会员卡过期时间' 
                                            onChange={(date, dateString) => {
                                                let selectedEndTime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                selectedEndTime = selectedEndTime + 24 * 60 * 60 - 1;

                                                this.setState({
                                                    newCard: {...this.state.newCard, endtime: selectedEndTime}
                                                });
                                            }}
                                            value={this.state.newCard.endtime ? moment.unix(this.state.newCard.endtime) : undefined}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                    {
                        this.state.user ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { user, transferType, newCard } = this.state;

                                    if (!newCard.starttime || !newCard.endtime) {
                                        message.error('请填写开始/结束时间');
                                        return;
                                    }

                                    if (this.props.onSubmit) {
                                        this.props.onSubmit(user, transferType, newCard);
                                    }
                                }}
                            >提交</Button>
                            :
                            null
                    }
                </div>

                <Modal
                    visible={this.state.userListVisible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ userListVisible: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <UserSelectView 
                        selectType={1}
                        initialQuery={false}
                        selectedUser={this.selectedUser} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}