import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';



// 预约课程
export async function launchGetCityInfoByLatlng(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
      const response = await launchPOSTRequest(Apis.GET_CITY_INFO_BY_LATLNG, params);
      if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
    } catch (rejectedValue) {
      failed(rejectedValue);
    } 
  }