import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { isEmpty } from '../../util/helper/string-helper';

const { Option } = Select;

export default class ProtimesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changetype: 1
        };

        this.handleTimesChange = this.handleTimesChange.bind(this);
        this.handleChangeTypeChanged = this.handleChangeTypeChanged.bind(this);
    }

    handleTimesChange(e) {

        this.setState({
            times: e
        });
    }

    handleChangeTypeChanged(e) {
        this.setState({
            changetype: e
        });
    }

    render() {
        let { vip } = this.state;
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                {/* <Select 
                    value={this.state.changetype}
                    style={{ width: 100}}
                    onChange={this.handleChangeTypeChanged}
                >
                    <Option value={1}>正常</Option>
                    <Option value={2}>冻结</Option>
                </Select> */}

                <FormItem itemKey="改变类型：" type="select" itemPlaceHolder="请选择增加/减少" keyStyles={{ width: 120 }} valueStyles={{ width: 200 }} itemValue={this.state.changetype} onValueChanged={this.handleChangeTypeChanged} valueList={[{text: '增加', value: 1}, {text: '减少', value: 2}]} allowClear={false} />
                <FormItem itemKey="改变次数：" type="input-number" itemPlaceHolder="请填写增加次数" keyStyles={{ width: 120 }} valueStyles={{ width: 200 }} itemValue={this.state.times} onValueChanged={this.handleTimesChange} />

                <div style={styles.operateBox}>
                    <Button onClick={ () => { this.props.onCancel && this.props.onCancel()} } >取消</Button>
                    <Button 
                        onClick={ () => { 
                            if (isEmpty(this.state.times)) {
                                message.error('请输入正确的次数');
                                return;
                            }
                            
                            this.props.onOk && this.props.onOk({
                                times: this.state.times,
                                changetype: this.state.changetype
                            });
                        } } 
                        style={{ marginLeft: 20 }} 
                        type="primary">确认</Button>
                </div>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}