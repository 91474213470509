import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class NewVip extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="新会员开卡/购课" />

                <SectionTitle text="一.新会员开卡" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/f791ef0ef1a340789d0d19366046028f.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，点击右上角“<Highlight text="新人开卡" />”按钮，弹出新人开卡页面。（注意：<Highlight text="此方式只适用新会员开卡，已经是会员的无法在这个入口进行续卡/购课。" />）</span>} />

                <NormalContent text={<span>2.新人开卡分两个步骤：<Highlight text="确认学员信息" />、<Highlight text="办理会员卡" />。</span>} />
                
                <NormalContent text={<span>3.<Highlight text="确认学员信息" />：</span>} />
                <KeyValue title="选择用户：" value={<span>点击“”，在弹出的用户搜索页面，输入用户的< Highlight text="ONE ME平台" />登录所绑定的完整手机号，或者完整昵称，或者已经在平台上办理过会员卡的用户的姓名，来查询用户。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/39aea17eb3594e34b3d97b4c7909075f.png" />
                <KeyValue title="补全姓名：" value={<span>选择完用户后，补全用户的真实姓名。完成后点击“下一步”进入办理会员卡页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/7aaa922b285e4d81a374591dc354b049.png" />
                
                <NormalContent text={<span>4.<Highlight text="办理会员卡" />：</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/6ec37073ebff4a8d965cb47e55e63407.png" />
                <KeyValue title="办理类型：" value={<span>新人入会可以通过<Highlight text="开卡" />、<Highlight text="私教" />两种形式。开卡适用于购买时限卡、次卡的用户；私教适合购买私教课的用户。</span>} />
                <KeyValue title="会员卡/私教课：" value={<span>选择要开通的会员卡或者私教课。</span>} />
                <KeyValue title="开始时间：" value={<span>设置会员卡的生效开始时间。（会员卡生效前会员无法约课）</span>} />
                <KeyValue title="结束时间：" value={<span>设置会员卡的到期时间。（会员卡到期后会员无法约课）</span>} />
                <KeyValue title="实际支付：" value={<span>记录此次购卡/购课的实际售出价格，用于以后统计时的财务报表。（单位：元）</span>} />

                <NormalContent text={<span>5.提交，完成开卡/开课。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVip);
