import React, { Component } from 'react';
import { connect } from 'react-redux';
// 路径

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
// 引入自定义组件
import DescEditView from '../../../view/desc/desc-edit-view'
import { PageStyles, TableStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN_LISTS.COURSE_LIST.children.SAVE_COURSE.children.DESC_EDIT;

class CourseDetailEditController extends BaseController {

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            
        </div>
      ),
      onBack: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    
    return (
      <div style={PageStyles.pageContainer}>
        {
            this.renderNavHeader()
        }


        <div style={TableStyles.tableBox}>
          <DescEditView 
            type={1}
            descid={this.props.descid}
          />
        </div>
      </div>
    );
  }
  componentDidMount() {}
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.descid !== this.props.descid) {
  //       this.props.history.goBack();
  //     // this.props.history.push(`/${PathConstants.BCG_ROOT_Name}/${PathConstants.SAVE_COURSE.rootPath}/${PathConstants.SAVE_COURSE.path}`);
  //   }
  // }
}
// 从store接收state数据
const mapStateToProps = store => {
  // 在这里如果id变化了就跳转
  const descStore = store['descStore'];
  return {
    descid: descStore.descid
  }
}
const mapDispatchToProps = dispatch => { return {} };

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailEditController);