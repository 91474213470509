import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class VipOperate extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="会员冻结/恢复/转让" />

                <SectionTitle text="一.会员卡冻结" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/07476018b1664f38b453d9cb1b41106d.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，找到想要续卡/购课的会员，然后点击这个会员记录后面的“<Highlight text="详情" />”按钮，进入会员详情页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/1bfc9b6cf1164878a6689ed3c105e8e4.png" />
                <NormalContent text={<span>2.如图所示，点击右上角的“<Highlight text="冻结" />”按钮，弹出冻结信息页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/fa071d08b7ae490e8a8564bcacdf9ca3.png" width={400} />
                <NormalContent text={<span>3.填写冻结开始时间和冻结天数后，点击“确认”按钮，提示更新成功后，完成冻结。</span>} />
               
                <SectionTitle text="二.会员卡恢复" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/1cccd0bc63844ff8abfca2ba7abd1ef3.png" />
                <NormalContent text={<span>1.如图所示，点击右上角的“<Highlight text="恢复" />”按钮，恢复已经冻结的会员卡。</span>} />

                <SectionTitle text="三.会员卡转让" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/00557e49293a434496dfe1070c67377e.png" />
                <NormalContent text={<span>1.如图所示，点击右上角的“<Highlight text="转让" />”按钮，弹出会员卡转让页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/b184ad59a49d4f80b2eb6fd0a5e1c1ce.png" />
                <KeyValue title="转让类型：" value={<span>选择转让用户的时限卡/次卡。</span>} />
                <KeyValue title="受让方：" value={<span>选择受让用户。</span>} />
                <KeyValue title="受让方姓名：" value={<span>补全受让用户的姓名。</span>} />
                <KeyValue title="开始时间：" value={<span>选择转让后会员卡的开始生效时间。</span>} />
                <KeyValue title="结束时间：" value={<span>选择转让后会员卡的到期时间。</span>} />
                <NormalContent text={<span>2.点击“提交”，提示更新成功后，完成转让。</span>} />
                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipOperate);
