import React, { Component } from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { Button, Modal, Select, InputItem, Popover, Input, List, Spin, message } from 'antd';
import BaseController from '../base/base-controller';
import { PageRoutes } from '../../constants/web-route-constants';
import { IndexStyles } from '../../style/web/index-pc-styles';
import Home from './home/home-pc';
import Register from './register/register-pc';
import Login from '../console/loginController';

export default class IndexController extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        };

        this.renderNav = this.renderNav.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
    }

    componentDidMount() {
        message.config({
            top: 100
        });

       
    }

    renderNav() {
        return (
            <div style={IndexStyles.navBar}>
                <div style={IndexStyles.content}>

                    <div style={IndexStyles.leftBox}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            this.props.history.push({
                                pathname: `${PageRoutes.MAIN.path}`,
                                state: {

                                }
                            });
                            window.scrollTo(0, 0);
                        }}>
                            <img style={{ objectFit: 'contain', width: 40, height: 40, marginLeft: 0  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/5bddea7ea3104b8687a4c5a1d4a4222c.png" />
                            <img style={{ objectFit: 'contain', width: 88, height: 17, marginLeft: 10  }} src="http://qiniu-media.soso-code.com/oneme/pic/custom/8771d127e9404c36aa0101a76257232a.png" />
                        </div>
                    </div>

                   
                    <div style={IndexStyles.rightBox}>
                        <div style={IndexStyles.loginBtn} onClick={() => {
                            // if (window.location.host.indexOf("localhost")) {
                                window.open(`http://${window.location.host}/login`, '_blank');
                            // } else {
                                // window.open(`https://${window.location.host}/login`, '_blank');
                            // }
                            
                        }}>
                            登录
                        </div>

                        <div style={IndexStyles.registerBtn} onClick={() => {
                            // window.open(`https://${window.location.host}/register`, '_blank');
                            this.props.history.push({
                                pathname: `${PageRoutes.MAIN.path}/${PageRoutes.MAIN.children.REGISTER.path}`,
                                state: {

                                }
                            });
                            // window.scrollTo(0, 0);
                        }}>
                            注册
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFooter() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#404040',
                // marginTop: 100,
                flexShrink:0
            }}>

                <div style={{ display: 'flex', flexDirection: 'column', width: 1124, paddingTop: 20, paddingBottom: 20 }}>
                    <span style={{ fontSize: '12px', color: '#fff', cursor: 'default' }}>Copyright © 2020 ONE ME Inc. 保留所有权利。</span>
                    <span style={{ fontSize: '12px', color: '#fff', marginTop: 5, cursor: 'default', cursor: 'pointer' }} onClick={() => {
                        window.open('https://beian.miit.gov.cn/', '_blank');
                    }}>京ICP备17020842号-1</span>
                </div>
            </div>
        );
    }   

    render() {
        
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', position: 'relative', alignItems: 'center', flexShrink:0 }}>
                {
                    this.renderNav()
                }

                <div id='content-scroll' style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100vw',
                    flexShrink:0
                }}>
                    
                    <Route path={`${PageRoutes.MAIN.path}`} exact component={Home} />
                    <Route path={`${PageRoutes.MAIN.path}/${PageRoutes.MAIN.children.REGISTER.path}`} exact component={Register} />
                </div>

                {
                    this.renderFooter()
                }
            </div>
            </Spin>
        );
    }
}