export const PageRoutes = {
    MAIN: {
      path: '/om',
      children: {
        
        REGISTER: {
          path: 'register',
          children: {

          }
        },

        LOGIN: {
          path: 'login',
          children: {

          }
        },
      }
    },
  };
  