export const VenueSaveStyles = {
    container: { 
        minHeight: 731,
        backgroundColor: '#ffffff',
        paddingTop: 36,
        paddingBottom: 36
    },
    container_nopadding: {
        minHeight: 731,
        paddingTop: 0,
        paddingBottom: 0
    },
    formItem: {
        marginBottom: 32,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formItem_topAlign: {
        marginBottom: 32,
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    leftBox: {
        width: 200,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    rightBox: {
        width: '100%',
        marginLeft: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    input_short: {
        width: 130
    },
    input_middle: {
        width: 180
    },
    input_long: {
        width: 250
    },
    hintText: {
        fontFamily: 'PingFangSC-Regular',
        color: 'rgba(169, 169, 169, 0.65)'
    },
    operateBox: {
        textAlign: 'center'
    },
    uploadButton_100: {
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    addressDetailInput: {
        width: 450,
        marginTop: 10
    },
    addressEditButton: {
        marginLeft: 20
    },
    previewButton: {
        marginLeft: 10
    }
};

export const SearchBarStyles = {
    searchBar: {
        // paddingRight: 146,
        textAlign: 'left',
        paddingBottom: 16
        /* 为了让活动名称不换行 */
        // minWidth: 1076,
        // backgroundColor: 'yellow'
    },
    searchRow: {
        paddingTop: 16
        // marginTop: 16,
        // backgroundColor: 'yellow',
        // display: 'flex',
        // flexDirection: 'row',
    },
    searchItem: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    searchInputTag: {
        display: 'inline-block',
        minWidth: 65,
        textAlign: 'right',
        // fontWeight: 400,
        color: 'rgba(0,0,0,0.85)',
        // lineHeight: 10
    },
    searchInput: {
        width: 201,
        height: 32,
        marginLeft: 14
    },
    searchButtonContainer: {
        marginTop: 20,
        textAlign: 'center'
    },
    searchButton: {
        width: 65
    },
    clearButton: {
        width: 65,
        marginLeft: 40
    }
}