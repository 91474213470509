import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class VipCard extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="会员卡模" />

                <SectionTitle text="一.创建会员卡模" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/3f11f6295ba64a2bb946d8157a6837ea.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员卡管理”后，在右侧就会展示场馆的所有会员卡模。</span>} />
                <NormalContent text={<span>2.会员卡模可以理解为传统意义上的会员卡，为了与会员手中的会员卡区分，我们可以理解为场馆创建会员卡模，在会员办卡时根据卡模为会员开通相应的会员卡。</span>} />
                <NormalContent text={<span>3.在会员卡管理页面，点击右上角的“<Highlight text="新建会员卡模" />”按钮，或者点击会员卡上的“<Highlight text="编辑" />”按钮，就可以创建或者编辑会员卡。</span>} />

                <SectionTitle text="一.会员卡模属性" />
                <KeyValue title="会员卡类型：" value={<span>会员卡模分为<Highlight text="时限卡" />，<Highlight text="次卡" />两种类型。时限卡为按时间办卡，比如月卡、年卡等；次卡为按次数办卡，比如10次卡，20次卡。</span>} />
                <KeyValue title="会员卡名称：" value={<span>用于在后台办卡时搜索用。</span>} />
                <KeyValue title="时限：" value={<span>会员卡的默认有效天数（单位：天）。</span>} />
                <KeyValue title="售价：" value={<span>会员卡的默认价格，在以后的统计功能，可以用于统计财务报表（单位：元）。</span>} />
                <KeyValue title="状态：" value={<span>当会员卡状态为“开启”时，在后台开卡时才可以被搜索到。</span>} />

                <SectionTitle text="三.保存" />
                <NormalContent text={<span>1.在编辑页面的右上角点击“<Highlight text="保存" />”，在提示更新成功后，完成编辑。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipCard);
