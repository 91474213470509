import React, { Component } from 'react';
import Exception from 'ant-design-pro/lib/Exception';
import 'ant-design-pro/dist/ant-design-pro.css';
import './App.css';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import LoginController from './page/console/loginController';
import HelpController from './page/help/instruction/index';
import WebController from './page/web/index-pc';
import WebController_Mobile from './page/web/index-mobile';
import RegisterController from './page/web/register/register-pc';
import BackgroundController from './page/console/backgroundController';
import { isDevicePC } from './util/helper/device-helper';
import { actions } from './redux/util';
import { PageStyles } from './style/common-styles';

class App extends Component {
  componentDidMount() {
    const dispatch = this.props.dispatch;
    window.addEventListener("resize", function (e) {
      // console.dir(e)
      // dispatch(actions.windowResize({ windowHeight: window.innerHeight }));
    });
  }
  render() {
    const NoMatch = () => <Exception type="404" backText="返回首页" redirect={'/login'} />;
    const RedirectWeb = () => <Redirect to="/om"/>;

    return (
      <div className="App">
        <Switch>
        
          <Route path='/' exact component={RedirectWeb} />
          <Route path='/om' component={isDevicePC() ? WebController : WebController_Mobile} />
          {/* <Route path='/register' exact component={RegisterController} /> */}
          <Route path='/login' exact component={LoginController} />
          <Route path='/console/:rootPath?/:subPath?' component={BackgroundController} />
          <Route path='/help/:rootPath?/:subPath?' component={HelpController} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export default connect()(App);
