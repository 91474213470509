
export const TextStyles = {
    artTitle: {
        fontSize: '24px', fontWeight: 'bold', color: '#000',
        display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',
        marginTop: 20, marginBottom: 20
    },
    sectionTitle: {
        fontSize: '18px', fontWeight: 'bold', color: '#000',
        display: 'flex', flexDirection: 'column',
        marginTop: 40, marginLeft: 10
    },
    normalContent: {
        fontSize: '14px', color: '#333',
        display: 'flex',
        marginTop: 10, marginBottom: 10, marginLeft: 10
    },
    subContent: {
        fontSize: '14px', color: '#333',
        display: 'flex',
        marginTop: 10, marginBottom: 10, marginLeft: 10,
        paddingLeft: 20
    },
    highlight: {
        fontWeight: 'bold', color: '#C68712'
    },
    pictureBox: {
        display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',
        marginTop: 20, marginBottom: 20
    },
    picture: {
        width: 800,
        objectFit: 'widthFix',
        border: 'solid 1px #f5f5f5'
    },
    pictureListBox: {
        display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',
    },
    keyValueBox: {
        display: 'flex', width: '100%', flexDirection: 'row',
        marginTop: 10, marginBottom: 10
    },
    keyBox: {
        display: 'flex', width: 120, flexDirection: 'row', justifyContent: 'flex-end',
        fontSize: '14px', color: '#333',
    },
    valueBox: {
        display: 'flex', flexDirection: 'row', flex: 1,
        fontSize: '14px', color: '#333',
    }
}