// desc-edit-controller.js
// React 组件
import React, { Component } from 'react';
import { connect } from 'react-redux';
// 关于请求
import { actions as descActions, launchGetDesc, launchSaveDesc } from '../../redux/desc-model';
// 样式
import { Button, Upload, Spin, message } from 'antd';
import '../../style/page/desc/desc-edit.css'

import { uploadProjectImageToQiniu, getWidthAndHeight } from '../../util/request';
import { DescEditStyles } from '../../style/page/desc/desc-edit-styles';
import { PlusOutlined, FileTextOutlined, PictureOutlined, MinusOutlined } from '@ant-design/icons';

class DescEditView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      descitems: [],

      loading: false
    };

    this.handleDescSaveClick = this.handleDescSaveClick.bind(this);
    this.handleDeleteButton = this.handleDeleteButton.bind(this);
  }
  render() {
    let pictureAndContentList = []
    this.state.descitems.forEach((item, itemIndex) => {
      pictureAndContentList.push(
        <div 
          style={DescEditStyles.editItem} key={itemIndex}
          key={itemIndex}>
          <div 
            // className="edit-bottom-item"
            style={DescEditStyles.editBottomItem}
            onClick={e => {this.handleAddTextButton(e, itemIndex)}}>
            
            <FileTextOutlined className="edit-icon" />
            <span>添加文字</span>
          </div>
          <div 
            // className="edit-bottom-item"
            style={DescEditStyles.editBottomItem}
            onClick={e => {this.handleAddPictureButton(e, itemIndex)}}>
            <PictureOutlined className="edit-icon" />
            <span>添加图片</span>
          </div>
        </div>
      )
      if (item.itemtype === 1) {
        pictureAndContentList.push(
          <div 
            style={DescEditStyles.editItem}
          >
            <Button onClick={() => {this.handleDeleteButton(itemIndex)}} style={DescEditStyles.minusButton} shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
            <textarea 
              placeholder="输入内容"
              style={DescEditStyles.edtiInput}
              onChange={e => {this.handleAreaTextChange(e, itemIndex)}}
              value={this.state.descitems[itemIndex].itemcontent}></textarea>
          </div>
        )
      } else if (item.itemtype === 2) {
        pictureAndContentList.push(
          <div style={DescEditStyles.uploadBox}>
            <Button onClick={() => {this.handleDeleteButton(itemIndex)}} style={DescEditStyles.minusButton} shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
            <Upload
              name="avatar"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={this.beforeUpload}
              onChange={info => {this.handleUploadChange(info, itemIndex)}}
                >
              {
                this.state.descitems[itemIndex].itemurl ? 

                  <img style={DescEditStyles.itemImage} src={this.state.descitems[itemIndex].itemurl} alt="avatar" /> 
                  :
                  <div style={DescEditStyles.uploadButton}>
                    <PlusOutlined />
                    <div>上传照片</div>
                  </div>
              }
            </Upload>
          </div>
        )
      }
    })
    return (
      <Spin spinning={this.state.loading}>
      <div style={this.props.isModal ? DescEditStyles.container_modal : DescEditStyles.container}>
        <div style={DescEditStyles.editBox}>
          {this.state.isLoading ? (<Spin/>) : pictureAndContentList}
        </div>
        <Button 
            type="primary"
            className="edit-finish-button"
            onClick={this.handleDescSaveClick}>完成</Button>
      </div>
      </Spin>
    );
  }
  componentDidMount() {
    // 根据 this.state.id 查询 descitems 然后进行数据处理
    if (this.props.descid) {
      this.changeLoadState(true);
      launchGetDesc(
        { id: this.props.descid, type: this.props.type },
        res => { 
          this.changeLoadState(false);
          // loading结束
          if (res.responseData.result.status === -1) {
            this.setState({ isLoading: false })
          } else {
            // 这里调用处理数据函数
            let descitems
            if (res.responseData.result.desc)
              descitems = PictureAndContentItem.dealBackgroundData(res.responseData.result.desc.descitems)
            else
              descitems = PictureAndContentItem.dealBackgroundData()
            this.setState({
              descitems,
              isLoading: false
            })
          }
        },
        err => { 
          message.error(err);
          this.changeLoadState(false);
        }
      )
    } else {
      let descitems = PictureAndContentItem.dealBackgroundData()
      this.setState({
        descitems
      })
    }
  }

  changeLoadState(state) {
    this.setState({ loading: state });
  }

  // Antd的Upload组件上传前操作的钩子, 如返回false则不进行上传, 返回true进行上传
  beforeUpload(file) {
    const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isPermitionImgType) {
      message.error('请上传格式为png或jpg的图片文件');
    }
    const isPassSizeLimit = file.size / 1024 / 1024 < 2;
    if (!isPassSizeLimit) {
      message.error('图片大小需小于2Mb');
    }
    return isPermitionImgType && isPassSizeLimit;
  }
  handleUploadChange = (info, itemIndex) => {
    if (info.file.status === 'uploading') {
      let descitems = this.state.descitems
      uploadProjectImageToQiniu(
        info.file, 
        2,
        res => {       
          getWidthAndHeight(res.result.url)
          .then(widthAndHeight => {
            descitems[itemIndex].setPictureUrl(res.result.url)   
            descitems[itemIndex].setWidthAndHeight(widthAndHeight.w, widthAndHeight.h)
            this.setState({ descitems });
          })
        },
        res => {
          message.error('上传失败')
          message.error(res)
        }
      );
      descitems[itemIndex].changeUploadLoading()
      this.setState({ descitems });
    }
  }
  // 转换成base64Url供页面上预览
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  handleDeleteButton(itemIndex) {
    let descitems = this.state.descitems
    descitems.splice(itemIndex, 1)
    this.setState({
      descitems
    })
  }
  handleAddTextButton(e, itemIndex) {
    let descitems = this.state.descitems
    descitems.splice(itemIndex, 0, new ContentItem())
    this.setState({
      descitems
    })
  }
  handleAddPictureButton(e, itemIndex) {
    let descitems = this.state.descitems
    descitems.splice(itemIndex, 0, new PictureItem())
    this.setState({
      descitems
    })
  }
  handleAreaTextChange = (e, itemIndex) => {
    let descitems = this.state.descitems
    descitems[itemIndex].setContent(e.target.value)
    this.setState({
      descitems
    })
  }
  handleDescSaveClick = () => {
    // 把最后一个type -1 结束
    this.state.descitems.pop();

    let params = { descitems: this.state.descitems, type: this.props.type };
    if (this.props.descid) {
      params.id = this.props.descid;
    }

    if (this.props.saveSuccessed) {
      let that = this;
      this.changeLoadState(true);
      launchSaveDesc(
        params,
        response => {
          this.changeLoadState(false);
          message.success(response.msg);

          this.props.saveSuccessed(response.result.desc.id);
        },
        msg => {
          this.changeLoadState(false);
          message.success(msg);
        }
      );
    } else {
      this.props.saveDesc(params);
    }

    
  }
}

class PictureAndContentItem {
  constructor(itemtype = 1) {
    this.itemtype = itemtype
  }
  static dealBackgroundData(descItems) {
    let descitems = []
    if (Array.isArray(descItems)){
      descItems.forEach((item, itemIndex) => {
        if (item.itemtype === 1) {
          descitems.push(new ContentItem(item.itemcontent))
        } else if (item.itemtype === 2) {
          descitems.push(new PictureItem(item.itemurl, item.picwidth, item.picheight))
        }
      })
    }
    descitems.push(new PictureAndContentItem(-1))
    return descitems;
  }
}
class PictureItem extends PictureAndContentItem {
  constructor(itemurl, picwidth, picheight) {
    super(2);
    this.itemurl = itemurl || '';
    this.uploadLoading = false;
    this.picwidth = picwidth || 0;
    this.picheight = picheight || 0;
  }
  /**
   * 设置图片链接
   */
  setPictureUrl(url) {
    this.itemurl = url
  }
  setWidthAndHeight(width, height) {
    this.picwidth = width;
    this.picheight = height;
  }
  changeUploadLoading() {
    this.uploadLoading = !this.uploadLoading
  }
}
class ContentItem extends PictureAndContentItem {
  constructor(itemcontent = '') {
    super(1)
    this.itemcontent = itemcontent
    this.isWrite = false
  }
  setContent(itemcontent) {
    this.itemcontent = itemcontent
  }
}
// 从store接收state数据
const mapStateToProps = store => { return {

} }

const mapDispatchToProps = dispatch => {
  return {
    saveDesc: (params) => {
      dispatch(descActions.saveDesc(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DescEditView);