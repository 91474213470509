export const PageStyles = {
    pageContainer: { 
        display: 'flex', 
        width: '100%', 
        flexDirection: 'column',
        paddingBottom: 30,
        position: 'relative'
    },
    contentContainer: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        marginTop: '50px'
    },
    contentContainer_edit: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column'
    },
    contentContainer_nopadding: {
        width: '100%',
        marginTop: '50px'
    },
    tableContainer: {
        fontfamily: 'PingFangSC-Regular',
        fontsize: 14,
        paddingRight:32,
        paddingBottom: 25,
        paddingLeft: 32
    },
    tableContainer_nopadding: {

    },
    addButtonContainer_Left: {
        textAlign: 'left'
    },
    addButton: {
        marginLeft: 32,
        marginTop: 15
    },
    addButton_table: {
        marginTop: 15,
        marginBottom: 15
    }
};

export const FormStyles = {
    container: { 
        minHeight: 731,
        backgroundColor: '#ffffff',
        paddingTop: 36,
        paddingBottom: 36
    },
    container_nopadding: {
        minHeight: 731,
        paddingTop: 0,
        paddingBottom: 0
    },
    formItem: {
        marginBottom: 32,
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    leftBox: {
        width: 100,
        // height: 32,
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'flex-end'
    },
    leftBox_Middle: {
        width: 150,
        // height: 32,
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'flex-end'
    },
    leftBox_center: {
        width: 100,
        // height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    rightBox: {
        // width: 468,
        marginLeft: 10,
        display: 'flex',
        justifyContent: 'flex-start'
    },
    input_short: {
        width: 130
    },
    input_middle: {
        width: 180
    },
    input_long: {
        width: 250
    },
    hintText: {
        fontFamily: 'PingFangSC-Regular',
        color: 'rgba(169, 169, 169, 0.65)'
    },
    operateBox: {
        textAlign: 'center'
    },
    uploadButton_100: {
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

export const SearchBarStyles = {
    searchBar: {
        // paddingRight: 146,
        textAlign: 'left',
        paddingBottom: 16,
        /* 为了让活动名称不换行 */
        // minWidth: 1076,
        // backgroundColor: 'yellow'
    },
    plainContainer: {
        display: 'flex',
        flexDirection: 'row'
    },

    searchRow: {
        paddingTop: 16
        // marginTop: 16,
        // backgroundColor: 'yellow',
        // display: 'flex',
        // flexDirection: 'row',
    },
    searchItem: {
        // height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    searchInputTag: {
        
        minWidth: 65,
        textAlign: 'right',
        // fontWeight: 400,
        color: 'rgba(0,0,0,0.85)',
        // lineHeight: 10
    },
    plainSearchInput: {
        width: 150,
        height: 25,
        marginLeft: 5,
        fontSize: 10
    },
    searchInput: {
        width: 201,
        height: 32,
        marginLeft: 14
    },
    searchButtonContainer: {
        marginTop: 20,
        textAlign: 'center'
    },
    searchButton: {
        width: 65
    },
    clearButton: {
        width: 65,
        marginLeft: 40
    }
}

export const TableOperateBarStyles = {
    operateBar: {
        marginBottom: 10,
    },

    selectedTip: {
        color: '#999999',
        fontSize: 13,
        marginRight: 15
    },

    doneButton: {
        marginRight: 15
    },

    operateBtn_Right: {
        marginLeft: 10
    }
}

export const TableSearchStyles = {
    searchBox: {

    },
    searchIputBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'left',
        paddingBottom: 16,
    },
    operateBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 20
    },
    searchItem: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    searchItem_margin: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10
    },
    searchInputTag: {
        display: 'inline-block',
        minWidth: 65,
        textAlign: 'right',
        // fontWeight: 400,
        color: 'rgba(0,0,0,0.85)',
        // lineHeight: 10
    },
    searchInput: {
        width: 201,
        height: 32,
        marginLeft: 14
    },
    searchInput_shortpadding: {
        width: 201,
        height: 32,
        marginLeft: 5
    },
    searchButtonContainer: {
        textAlign: 'center'
    },
    searchButton: {
        width: 65
    },
    clearButton: {
        width: 65,
        marginLeft: 40
    }
}

export const TableStyles = {
    tableBox: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        marginTop: '50px',
        paddingBottom: '50px'
        // height: '60vh',
        // overFlow: 'scroll'
    }
}