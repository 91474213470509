import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { isEmpty } from '../../util/helper/string-helper';

export default class FreezeView extends Component {
    constructor(props) {
        super(props);

        console.log(props.vip);

        let vip = props.vip;
        let isLimit = false;
        let isTimes = false;
        if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
            isLimit = true;
        }
        if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
            isTimes = true;
        }

        let type = 1; // 1:时限卡 2:次卡 3:都有        
        if (isLimit && isTimes) {
            type = 3;
        } else {
            if (isLimit && !isTimes) {
                type = 1;
            }

            if (!isLimit && isTimes) {
                type = 2;
            }
        }

        this.state = {
            freezeday: '',
            vip
        };

        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleFreezeDateChange = this.handleFreezeDateChange.bind(this);
    }

    handleDayChange(e) {

        this.setState({
            freezeday: e
        });
    }

    handleFreezeDateChange(selectTime, date, dateString) {
        console.log(LLCDateHelper.timeStrToTimestamp(dateString));
        this.setState({ freezestarttime: LLCDateHelper.timeStrToTimestamp(dateString) });
    }

    render() {
        let { vip } = this.state;
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                <FormItem itemKey="冻结开始时间：" type="date-picker" itemPlaceHolder="请填写冻结开始时间" keyStyles={{ width: 120 }} valueStyles={{ width: 200 }} endtime={vip.endtime > vip.limitendtime ? vip.endtime : vip.limitendtime} itemValue={this.state.freezestarttime} onValueChanged={this.handleFreezeDateChange} />
                <FormItem itemKey="冻结时间（天）：" type="input-number" itemPlaceHolder="请填写冻结天数" keyStyles={{ width: 120 }} valueStyles={{ width: 200 }} itemValue={this.state.freezeday} onValueChanged={this.handleDayChange} />

                <div style={styles.operateBox}>
                    <Button onClick={ () => { this.props.onCancel && this.props.onCancel()} } >取消</Button>
                    <Button 
                        onClick={ () => { 
                            if (isEmpty(this.state.freezeday)) {
                                message.error('请输入正确的天数');
                                return;
                            }
                            if (isEmpty(this.state.freezestarttime)) {
                                message.error('请选择开始冻结的时间');
                                return;
                            }
                            this.props.onOk && this.props.onOk({
                                freezeday: this.state.freezeday,
                                freezestarttime: this.state.freezestarttime
                            })} 
                        } 
                        style={{ marginLeft: 20 }} 
                        type="primary">确认</Button>
                </div>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}