import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';

import { actions as coachActions } from '../../redux/coach-model';

// 更改日期格式
import LLCDateHelper from "date-helper";

import * as PathConstant from '../../constants/routeConstants';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';

const confirm = Modal.confirm;
const Option = Select.Option;

class CoachSelectView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            phone: '',
            coachtype: undefined,

            selectedRowKeys: []
        };

        this.columns = [
            { title: '姓名', width: 80, dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: 120, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '教练类型', width: 55, dataIndex: 'coachtype', key: 'coachtype', align: 'center', render: coachtype => <span>{coachtype === 1 ? '全职' : '兼职'}</span> }
        ]

        this.coachSelected = this.coachSelected.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleCoachTypeChange = this.handleCoachTypeChange.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        this.props.queryCoachList({ state: 1, page: this.props.page });
    }
    queryCoachList = page => {
        this.props.queryCoachList({
            page,
            state: 1,
            username: this.state.username,
            phone: this.state.phone,
            coachtype: this.state.coachtype
        });
    }

    coachSelected() {
        let selectID = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.props.coachList.length; i++) {
            if (this.props.coachList[i].coachid === selectID) {
                this.props.selectedCoach && this.props.selectedCoach(this.props.coachList[i]);
                break;
            }
        }
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.coachid]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    handleSearchClick() {
        this.queryCoachList(1)
    }

    handleClearClick() {
        this.setState({
            username: '',
            phone: '',
            coachtype: undefined
        })
    }

    handleCoachTypeChange(coachtype) {
        this.setState({
            coachtype
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>姓名</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ username: e.target.value })}
                        value={this.state.username} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ phone: e.target.value })}
                        value={this.state.phone} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>教练类型</span>
                        <Select
                            style={styles.type_Select}
                            placeholder="请选择"
                            value={this.state.coachtype}
                            onChange={this.handleCoachTypeChange}
                            >
                            <Option value={1}>全职</Option>
                            <Option value={2}>兼职</Option>
                        </Select>
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

  render() {
    const { selectedRowKeys } = this.state;
    
    const rowSelection = {
        type: 'radio',
        onChange: this.onSelectedRowKeysChange,
        selectedRowKeys
    };

    const hasSelected = selectedRowKeys.length > 0;
    return (
        <div>
            <div style={PageStyles.tableContainer}>
                {
                    this.renderSearchBar()
                }
                <Button
                    type="primary"
                    onClick={this.coachSelected}
                    disabled={!hasSelected}
                >确定</Button>
                <Table
                    rowKey={record => record.coachid}
                    loading={this.props.loading}
                    columns={this.columns}
                    dataSource={this.props.coachList}
                    scroll={{ y: 485 }}
                    rowSelection={rowSelection}
                    pagination={{
                        total: this.props.totalpage * this.props.pagesize,
                        pageSize: this.props.pagesize,
                        current: this.props.page,
                        onChange: val => {
                            this.queryCoachList(val)
                        }
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            this.selectRow(record);
                        },
                    })}
                ></Table>
            </div>
        </div>
    );
  }
}

const mapStateToProps = store => {
  const coachStore = store['coachStore'];
  return {
    loading: coachStore.loading,
    coachList: coachStore.coachList,
    pagesize: coachStore.pagesize,
    totalpage: coachStore.totalpage,
    page: coachStore.page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryCoachList: params => {
        dispatch(coachActions.queryCoachList(params));
    },
    
    changeLoading: params => {
        dispatch(coachActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachSelectView);

const styles = {
    type_Select: {
        width: 122,
        height: 32,
        marginLeft: 14
    },
}