import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Avatar, Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Popconfirm } from 'antd';
import { connect } from 'react-redux';

import { PageStyles, SearchBarStyles } from '../../style/common-styles';
import BaseSelectView from '../common/base-select-view';
import { launchGetTagList } from '../../redux/tag-model';
import { isEmpty } from '../../util/helper/string-helper';
import { PictureOutlined } from '@ant-design/icons';

const confirm = Modal.confirm;

class TagSelectView extends BaseSelectView {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            dataInfo: {},
            queryParams: {},

            selectedRowKeys: [],

            isLoading: false
        };

        this.columns = [
            { title: '编号', width: '25%', dataIndex: 'id', key: 'id', align: 'center' },
            { title: '名称', width: '25%', dataIndex: 'name', key: 'name', align: 'center' },
            { title: '图片', width: '25%', dataIndex: 'logo', key: 'logo', align: 'center', render: url => <Avatar shape="square" src={url} size={35} icon={<PictureOutlined />} /> },
            { title: '层级', width: '25%', dataIndex: 'level', key: 'level', align: 'center' },
        ]

        this.getQueryParams = this.getQueryParams.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);

        this.handleDoneTapped = this.handleDoneTapped.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        this.queryTagList(1);
    }

    getQueryParams() {
        const { queryParams } = this.state;
        let params = {};
        for (let key in queryParams) {
            if (!isEmpty(queryParams[key])) {
                params[key] = queryParams[key];
            }
        }

        return params;
    }
    
    queryTagList(page) {
        this.setState({ isLoading: true });
        let params = this.getQueryParams();
        params.page = page;

        if (this.props.selectType === 1) {
            params.level = 1;
            params.state = 1;
        }

        launchGetTagList(
            params,
            res => {
            
            this.setState({
                dataInfo: res,
                isLoading: false
            });
            },
            fail => {
                this.setState({ isLoading: false });
            }
        );
    }

    selectRow(record) {
        this.setState({
            selectedRowKeys: [record.id]
        });
    }

    onSelectedRowKeysChange(selectedRowKeys, selectedRows) {
        console.log(selectedRowKeys, selectedRowKeys);
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    handleDoneTapped() {
        let selectedId = this.state.selectedRowKeys[0];
        for (let i = 0; i < this.state.dataInfo.list.length; i++) {
            let aRecord = this.state.dataInfo.list[i];

            if (aRecord.id == selectedId) {
                this.props.onRecordSelect && this.props.onRecordSelect(aRecord);
                break;
            }
        }
    }

    handleSearchClick() {
        this.queryTagList(1)
    }

    handleClearClick() {
        this.setState({
            queryParams: {}
        });
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>标签名称</span>
                        <Input
                            allowClear
                            style={SearchBarStyles.searchInput}
                            placeholder="请输入标签名称"
                            onChange={e => {
                                const { queryParams } = this.state;
                                queryParams.name = e.target.value;
                                this.setState({ queryParams })
                            }}
                            value={this.state.queryParams.name} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderOperateBar() {
        let totalCount = this.state.dataInfo.totalcount;
        
        return (
            <div style={styles.operateBar}>
                <Button
                    style={styles.doneButton}
                    type="primary"
                    disabled={!(this.state.selectedRowKeys.length > 0)}
                    onClick={this.handleDoneTapped}
                >确定</Button>
                
            </div>
        );
    }

    render() {
        const { selectedRowKeys } = this.state;
        
        const rowSelection = {
            type: 'radio',
            onChange: this.onSelectedRowKeysChange,
            selectedRowKeys
        };

        return (
            <Spin spinning={this.state.isLoading}>
            <div style={{...styles.container, height: this.state.windowHeight-300, marginTop: 20}}>
                <div style={PageStyles.tableContainer}>
                    {
                        this.renderSearchBar()
                    }
                    
                    {
                        this.renderOperateBar()
                    }

                    <Table
                        rowKey={record => record.id}
                        // loading={this.state.isLoading}
                        columns={this.columns}
                        dataSource={this.state.dataInfo.list}
                        scroll={{ y: this.state.windowHeight-550 }}
                        rowSelection={rowSelection}
                        pagination={{
                            total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                            pageSize: this.state.dataInfo.pagesize,
                            current: this.state.dataInfo.page,
                            onChange: val => {
                                this.queryTagList(val)
                            }
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                this.selectRow(record);
                            },
                        })}
                    ></Table>
                </div>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
  
  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagSelectView);


const styles = {
    container: {
        display: 'flex',
    },
    operateBar: {
        marginBottom: 10,
    },

    selectedTip: {
        color: '#999999',
        fontSize: 13,
        marginRight: 15
    },

    doneButton: {
        marginRight: 15
    }
};