import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message, DatePicker, InputNumber, Modal } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { priceFen, priceYuan } from '../../util/helper/number-helper';
import { isEmpty } from '../../util/helper/string-helper';
import BaseSelectView from '../base/base-select-view';
import { FormStyles, PageStyles, TableStyles } from '../../style/common-styles';
import { VipUserSaveStyles } from '../../style/page/vipuser/vipuser-save';
import VipCardMoldSelectView from '../../view/vipuser/vipcard-mold-select-view';

/**
 * 续卡
 */
export default class EnrollView extends BaseSelectView {
    constructor(props) {
        super(props);

        let vip = props.vip;
        // let isLimit = false;
        // let isTimes = false;
        // if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
        //     isLimit = true;
        // }
        // if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
        //     isTimes = true;
        // }

        // let type = 1; // 1:时限卡 2:次卡 3:都有 4:都没有   
        // let canEditStartTime = true;   
        // if (isLimit && isTimes) {
        //     type = 3;
        //     if (vip.endtime > vip.limitendtime) {
        //         this.defaultStartTime = vip.endtime;
        //     } else {
        //         this.defaultStartTime = vip.limitendtime;
        //     }
            
        // } else {
        //     if (isLimit && !isTimes) {
        //         type = 1;
        //         this.defaultStartTime = vip.limitendtime;
        //     }

        //     if (!isLimit && isTimes) {
        //         type = 2;
        //         this.defaultStartTime = vip.endtime;
        //     }

        //     if (!isTimes && !isLimit) {
        //         type = 4;
        //     }
        // }

        // this.type = type;

        let newCard = {};
        // if (this.type !== 4) {
        //     newCard.starttime = this.defaultStartTime;
        // }

        this.state = {
            vip,
            newCard, // 新开通会员的状态
            pay: { // 支付信息
                payment: 0,
                paytype: 1
            },

            vipCardMoldListVisible: false
        };

        this.selectedVipCardMold = this.selectedVipCardMold.bind(this);
    }

    selectedVipCardMold(mold) {
        let { vip } = this.state;
        let endtime = undefined;
        if (this.state.newCard.starttime) {
            endtime = this.state.newCard.starttime + 60 * 60 * 24 * mold.cardduration - 1;
        }
        this.setState({
            mold,
            newCard: {
                ...this.state.newCard,
                endtime
            },
            pay: {
                ...this.state.pay,
                payment: mold.cardprice
            }
        });

        this.refreshCardState(vip, mold);
    }

    refreshCardState(vip, mold) {
        this.defaultStartTime = undefined;
        this.defaultEndTime = undefined;
        let canEditStartTime = true;
        let type = 4; // 1:时限卡 2:次卡 3:都有 4:都没有    
        if (mold) {
            let isLimit = false;
            let isTimes = false;
            if (vip.limitendtime > LLCDateHelper.achiveTimestampOfSecond()) {
                isLimit = true;
            }
            if (vip.endtime > LLCDateHelper.achiveTimestampOfSecond() && vip.resttimes > 0) {
                isTimes = true;
            }

            if (isLimit && isTimes) {
                type = 3;
                
                if (mold.cardtype === 1) {
                    canEditStartTime = false;
                    this.defaultStartTime = vip.limitendtime;
                } else {
                    canEditStartTime = false;
                    this.defaultStartTime = vip.endtime;
                }
                
            } else {
                if (isLimit && !isTimes) {
                    type = 1;
                    if (mold.cardtype === 1) {
                        canEditStartTime = false;
                        this.defaultStartTime = vip.limitendtime;
                    }
                }

                if (!isLimit && isTimes) {
                    type = 2;

                    if (mold.cardtype === 2) {
                        canEditStartTime = false;
                        this.defaultStartTime = vip.endtime;
                    }
                }

                if (!isTimes && !isLimit) {
                    type = 4;
                }
            }
        }

        if (this.defaultStartTime) {
            // let nowTimestamp = LLCDateHelper.achiveTimestampOfSecond();
            let duration = 0; // 转让方剩余时长
            if (mold.cardtype === 1) { // 时限卡
                duration = 60 * 60 * 24 * mold.cardduration;
                // if (giveVip.limitstarttime > nowTimestamp) {
                //     duration = giveVip.limitendtime - giveVip.limitstarttime;
                // } else {
                //     duration = giveVip.limitendtime - nowTimestamp;
                // }
            } else if (mold.cardtype === 2) {
                duration = 60 * 60 * 24 * mold.cardduration;
                // if (giveVip.starttime > nowTimestamp) {
                //     duration = giveVip.endtime - giveVip.starttime;
                // } else {
                //     duration = giveVip.endtime - nowTimestamp;
                // }
            }
            if (duration < 0) duration = 0;

            this.defaultEndTime = this.defaultStartTime + duration;
        }

        console.log(type, '受让方类型');
        this.setState({ 
            newCard: {
                ...this.state.newCard,
                starttime: this.defaultStartTime,
                endtime: this.defaultEndTime
            },
            canEditStartTime,
            enrollVipType: type 
        });
    }

    render() {                                                      
        let { vip } = this.state;

        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                <div style={{ paddingLeft: 250 }}>
                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>会员卡：</span>
                        </div>

                        <div onClick={() => { this.setState({ vipCardMoldListVisible: true }); }}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要开通的会员卡"
                                showArrow={false}
                                value={this.state.mold ? this.state.mold.cardname : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.mold && this.state.mold.cardtype === 2 ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>次数：</span>
                                </div>
                                <InputNumber
                                    min={1}
                                    placeholder='课程次数' 
                                    value={this.state.mold.cardtimes}
                                    onChange={number => {
                                        this.setState({ mold: {...this.state.mold, cardtimes: number} })
                                    }}
                                />
                            </div>
                            :
                            null
                    }

                    {
                        this.state.mold ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>时限（天）：</span>
                                </div>
                                <span>{this.state.mold.cardduration}</span>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.mold ?
                            <div style={VipUserSaveStyles.infoBox}>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>开始时间：</span>
                                    </div>
                                    <DatePicker 
                                        disabled={!this.state.canEditStartTime}
                                        style={{ width: 200 }}
                                        placeholder='会员卡生效时间' 
                                        onChange={(date, dateString) => {
                                            let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                            this.setState({
                                                newCard: {
                                                    ...this.state.newCard, 
                                                    starttime,
                                                    endtime: starttime + (60 * 60 * 24 * this.state.mold.cardduration) - 1
                                                }
                                            });
                                        }}
                                        disabledDate={currentDate => {
                                            let todayTimestamp = new Date(new Date().toLocaleDateString()).getTime() / 1000
                                            if (parseInt(currentDate.valueOf() / 1000) < todayTimestamp) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }}
                                        value={this.state.newCard.starttime ? moment.unix(this.state.newCard.starttime) : undefined}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>结束时间：</span>
                                    </div>
                                    <DatePicker
                                        style={{ width: 200 }}
                                        placeholder='会员卡过期时间' 
                                        onChange={(date, dateString) => {
                                            let endtime = LLCDateHelper.timeStrToTimestamp(dateString);
                                            endtime = endtime + 24 * 60 * 60 - 1;

                                            this.setState({
                                                newCard: {...this.state.newCard, endtime}
                                            });
                                        }}
                                        value={this.state.newCard.endtime ? moment.unix(this.state.newCard.endtime) : undefined}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>实际支付（元）：</span>
                                    </div>

                                    <InputNumber
                                        min={0}
                                        placeholder="实际售价"
                                        value={priceYuan(this.state.pay.payment)}
                                        onChange={number => {
                                            this.setState({ pay: {...this.state.pay, payment: priceFen(number)} })
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                    {
                        this.state.mold ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { newCard, mold, pay } = this.state;
                                    if (this.props.onSubmit) {
                                        this.props.onSubmit(mold, newCard, pay);
                                    }
                                }}
                            >提交</Button>
                            :
                            null
                    }
                </div>

                <Modal
                    visible={this.state.vipCardMoldListVisible}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ vipCardMoldListVisible: false }) }}
                    onOk={() => { this.setState({ vipCardMoldListVisible: false }) }}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <VipCardMoldSelectView selectedVipCardMold={this.selectedVipCardMold} />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}