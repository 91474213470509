import React from 'react';
import { Menu, Button, Modal, PageHeader, message, Spin, Tag, Empty, Table } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { MAIN, MAIN_LISTS } from '../../../constants/routeConstants';
import LLCDateHelper from "date-helper";
import BaseController from '../../base/base-controller';
import { PageStyles, SearchBarStyles, TableStyles } from '../../../style/common-styles';
import { VipUserDetailStyles } from '../../../style/page/vipuser/vipuser-detail';
import FormItem from '../../../view/common/form-item';
import { CourseListStyles } from '../../../style/page/course/course-list-styles';
import FreezeView from '../../../view/vipuser/freeze-view';
import EnrollView from '../../../view/vipuser/enroll-view';
import EnrollPrivateCourseView from '../../../view/vipuser/enroll-private-course-view';
import TransferView from '../../../view/vipuser/transfer-view';
import UsernameEditView from '../../../view/vipuser/username-edit-view';
import { 
    launchFreezeVipUser, launchResumeVipUser, 
    launchRenewVipUser, launchUpgrade, 
    launchDegrade, launchVipRelated, 
    launchBuyPrivateCourse, launchProlongVip, 
    launchTransfer, launchUpdateVipUsername 
} from '../../../redux/vipuser-model';
import ProlongView from '../../../view/vipuser/prolong-view';
import ChangeCoachView from '../../../view/vipuser/change-coach-view';
import { launchProlongCourses, launchChangeCoach, launchTransferCourse } from '../../../redux/vipcourse-model';
import TransferCourseView from '../../../view/vipuser/transfer-course-view';
import { EditOutlined, IdcardOutlined, InsertRowAboveOutlined, FileTextOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.VIPUSER_LIST.children.VIPUSER_DETAIL;

const { confirm } = Modal;

class VipuserDetailController extends BaseController {
    constructor(props) {
        super(props)

        let vip = props.location.state ? props.location.state.vip : undefined;
        // let courses = props.location.state.courses;
        // let details = props.location.state.details;
        // let nowtime = props.location.state.nowtime;

        // this.nowtime = nowtime;

        this.state = {
            ...this.state,
            vip: vip ? vip : {},
            // nowtime,
            // courses,
            // details,

            currentStep: '1',

            modalFreezeShowing: false,
            modalEnrollShowing: false,
            modalUpgradeBuyShowing: false,
            modalGiveShowing: false,
            modalPrivateCourseShowing: false,
            modalProlongCourseShowing: false,
            modalProlongVipShowing: false,
            modalChangeCoachShowing: false,
            modalUsernameEditShowing: false
        };

        let that = this;
        this.courseColumns = [
            { title: '课程名称', width: '16%', dataIndex: 'coursename', key: 'coursename', align: 'left', render: (coursename) => <span style={CourseListStyles.courseName}>{coursename}</span> },
            { title: '教练名称', width: '16%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '剩余次数', width: '10%', dataIndex: 'coursetimes', key: 'coursetimes', align: 'center' },
            { title: '开课时间', width: '16%', dataIndex: 'starttime', key: 'starttime', align: 'center', render: starttime => <span>{ LLCDateHelper.formatDate(starttime) }</span> },
            { title: '到期时间', width: '16%', dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{ LLCDateHelper.formatDate(endtime) }</span> },
            
            { title: '状态', width: '10%', align: 'center', 
                render: course => 
                {
                    if (course.state === 10) {
                        return <Tag color='#f50'>已转让</Tag> 
                    } else {
                        if (course.coursetimes === 0) {
                            return <Tag color="#f50">次数用尽</Tag> 
                        } else {
                            return <Tag color={course.expired===0 ? '#87d068' : '#f50'} >{course.expired === 0 ? '正常' : '已过期'}</Tag> 
                        }
                    }
                }
            },
            { title: '操作', width: '16%', key: 'operate', align: 'center', 
                render: (course) => {

                    if (course.state !== 10 && course.coursetimes !== 0) {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <a href="javascript:void(0)" onClick={() => {
                                    that.setState({ 
                                        modalProlongCourseShowing: true,
                                        prolongCourse: course 
                                    });
                            
                                }}>延长</a> 
                                
                                {
                                    course.expired === 0 ?
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <a style={{ marginLeft: 20 }} href="javascript:void(0)" onClick={() => {
                                                that.setState({
                                                    modalChangeCoachShowing: true,
                                                    changeCoachCourse: course
                                                });
                                            
                                            }}>换绑</a> 

                                            <a style={{ marginLeft: 20 }} href="javascript:void(0)" onClick={() => {
                                                that.setState({
                                                    modalTransferCourseShowing: true,
                                                    transferCourse: course
                                                });
                                            
                                            }}>转让</a> 
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        )
                    } else {
                        return null;
                    }
                }
            }
        ];

        this.detailColumns = [
            { title: '编号', width: '10%', dataIndex: 'detailid', key: 'detailid', align: 'left', render: (coursename) => <span style={CourseListStyles.courseName}>{coursename}</span> },
            { title: '操作类型', width: '18%', key: 'operatetype', align: 'center', 
                render: detail => {
                    let typeStr = '-';
                    let color = '';
                    let operatetype = detail.operatetype;
                    // 操作类型（1:开卡 2:续卡 3:受让 10:升级 11:降级 19:恢复 20:冻结 30:转让 31:退卡）
                    switch(operatetype) {
                        case 1: // 开卡
                            if (detail.coursename) {
                                typeStr = '开课';
                            } else {
                                typeStr = '开卡';
                            }
                            color = '#87d068';
                            break;
                        case 2: // 续卡
                            typeStr = '续卡';
                            color = '#87d068';
                            break;
                        case 3: // 受让
                            typeStr = '受让';
                            color = '#87d068';
                            break;
                        case 7:
                            typeStr = '减少次数';
                            color = '#F67D1C';
                        case 8:
                            typeStr = '增加次数';
                            color = '#87d068';
                            break;
                        case 9:
                            typeStr = '延长时效';
                            color = '#87d068';
                            break;
                        case 10: // 升级
                            typeStr = '升级';
                            color = '#E30A84';
                            break;
                        case 11: // 降级
                            typeStr = '降级';
                            color = '#F67D1C';
                            break;
                        case 19: // 恢复
                            typeStr = '恢复';
                            color = '#87d068';
                            break;
                        case 20: // 冻结
                            typeStr = '冻结';
                            color = '#2db7f5';
                            break;
                        case 30: // 转让
                            typeStr = '转让';
                            color = '#f50';
                            break;
                        case 31: // 退卡
                            typeStr = '退卡';
                            color = '#f50';
                            break;
                    }

                    return (
                        <Tag color={color}>{typeStr}</Tag>
                    )
                }
            },
            { title: '相关', width: '18%', key: 'related', align: 'center', 
                render: detail => {

                    let relatedStr = '-';

                    // 开卡，续卡，受让
                    if (detail.operatetype === 1 || detail.operatetype === 2 || detail.operatetype === 3) {
                        if (detail.coursename) {
                            relatedStr = `${detail.coursename}（${detail.coursetimes}次）`
                        } else {
                            if (detail.cardname) {
                                relatedStr = detail.cardname;
                                if (detail.cardtype === 1) {
                                    relatedStr = `${relatedStr}（${detail.cardduration}天）`;
                                } else if (detail.cardtype === 2) {
                                    relatedStr = `${relatedStr}（${detail.cardtimes}次）`;
                                }
                            } else {
                                if (detail.cardtype === 1) {
                                    relatedStr = `时限卡（${detail.cardduration}天）`;
                                } else if (detail.cardtype === 2) {
                                    relatedStr = `次卡（${detail.cardtimes}次）`;
                                }
                            }
                        }
                    } else if (detail.operatetype === 19 || detail.operatetype === 20) {
                        if (detail.coursename) {
                            relatedStr = detail.coursename;
                        } else {
                            relatedStr = '会员卡';
                        }
                    } else if (detail.operatetype === 8) {
                        
                        relatedStr = `${detail.coursename}（${detail.coursetimes}次）`;
                    } else if (detail.operatetype === 9) {
                        
                        if (detail.coursename) {
                            relatedStr = detail.coursename;
                        } else {
                            relatedStr = '会员卡';
                        }
                    } else if (detail.operatetype === 10 || detail.operatetype === 11) {
                        relatedStr = '会员卡';
                    } else if (detail.operatetype === 30) { // 转让
                        if (detail.coursename) {
                            relatedStr = `${detail.coursename}（${detail.coursetimes}次）`
                        } else {
                            if (detail.cardname) {
                                relatedStr = detail.cardname;
                                if (detail.cardtype === 1) {
                                    relatedStr = `${relatedStr}（${detail.cardduration}天）`;
                                } else if (detail.cardtype === 2) {
                                    relatedStr = `${relatedStr}（${detail.cardtimes}次）`;
                                }
                            } else {
                                if (detail.cardtype === 1) {
                                    relatedStr = `时限卡（${detail.cardduration}天）`;
                                } else if (detail.cardtype === 2) {
                                    relatedStr = `次卡（${detail.cardtimes}次）`;
                                }
                            }
                        }
                    }

                    return (
                        <span>{relatedStr}</span>
                    )
                }
            },
            { title: '起始时间', width: '18%', dataIndex: 'starttime', key: 'starttime', align: 'center', render: starttime => <span>{ starttime ? LLCDateHelper.formatDate(starttime) : '-' }</span> },
            { title: '结束时间', width: '18%', dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{ endtime ? LLCDateHelper.formatDate(endtime) : '-'}</span> },
            { title: '操作人员', width: '18%', key: 'operate', align: 'center', 
                render: detail => {

                    let operateStr = '';
                    
                    if (detail.operateaccount) {
                        operateStr = `${detail.operateusername}（${detail.operateaccount}）`;
                    }

                    return (
                        <span>{operateStr}</span>
                    )
                }
            },
        ];

        this.reloadVipRelated = this.reloadVipRelated.bind(this);

        this.handleChangeStep = this.handleChangeStep.bind(this);
        this.handleFreeze = this.handleFreeze.bind(this);
        this.handleResume = this.handleResume.bind(this);
        this.handleEnrollVip = this.handleEnrollVip.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
        this.handleDegrade = this.handleDegrade.bind(this);
        this.handleBuyPrivateCourse = this.handleBuyPrivateCourse.bind(this);
        this.handleProlongCourse = this.handleProlongCourse.bind(this);
        this.handleProlongVip = this.handleProlongVip.bind(this);
        this.handleChangeCoach = this.handleChangeCoach.bind(this);
        this.handleTransfer = this.handleTransfer.bind(this);
        this.handleTransferCourse = this.handleTransferCourse.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
    }

    componentDidMount() {
        if (!this.state.vip || !this.state.vip.userid) {
            this.reloadVipRelated(() => {
                this.props.history.push({
                    pathname: `/${MAIN.path}/${MAIN_LISTS.VIPUSER_LIST.path}`,
                    state: { 
                    }
                });
            });
        } else {
            this.reloadVipRelated(() => {
                this.props.history.push({
                    pathname: `/${MAIN.path}/${MAIN_LISTS.VIPUSER_LIST.path}`,
                    state: { 
                    }
                });
            });
        }
    }

    checkFormNotHasEmptyItem = (mold, newCard, pay) => {
        
        if (!mold) {
            message.error('请选择会员卡');        
            return false;
        }
        if (!newCard.starttime && !newCard.endtime) {
            message.error('请填写开始/结束时间');        
            return false;
        }
        if (!pay.payment) {
            message.error('请填写支付价格');
            return false;
        }

        return true;
    }

    reloadVipRelated(failback = () => {}) {
        let { vip } = this.state;
        this.changeLoadingState(true);
        launchVipRelated(
            { userid: vip.userid },
            res => {
                this.changeLoadingState(false);
                this.setState({
                    courses: res.courses,
                    details:  res.details,
                    vip: res.vip,
                    nowtime: res.nowtime
                });
                this.nowtime = res.nowtime;
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
                if (failback) {
                    failback();
                }
            }
        );
    }

    handleChangeUsername(username) {
        let { vip } = this.state;

        this.changeLoadingState(true);
        launchUpdateVipUsername(
            { userid: vip.userid, venueid: vip.venueid, username },
            res => {
                message.success('修改成功');
                this.setState({ modalUsernameEditShowing: false });
                this.reloadVipRelated();
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    /**
     * Handlers 
     */
    handleChangeStep(e) {
        this.setState({ currentStep: e.key });
    }

    // 续卡
    handleEnrollVip(mold, newCard, pay) {
        if (this.checkFormNotHasEmptyItem(mold, newCard, pay)) {

            let that = this;
            confirm({
                title: '确认提交续卡？',
                content: '',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    let params = {
                        vip: that.state.vip,
                        mold,
                        card: newCard,
                        pay
                    };

                    that.changeLoadingState(true);
                    launchRenewVipUser(
                        params,
                        res => {
                            // that.changeLoadingState(false);
                            message.success('续卡成功');
                            
                            that.setState({ modalEnrollShowing: false, modalUpgradeBuyShowing: false })
                            that.reloadVipRelated();
                        },
                        msg => {
                            that.changeLoadingState(false);
                            message.error(msg);
                        }
                    );
                },
                onCancel() {
                
                },
            });
        }
    }

    // 购课
    handleBuyPrivateCourse(vip, newCard, course, coach, pay) {
        this.changeLoadingState(true);
        launchBuyPrivateCourse(
            {
                vip,
                card: newCard,
                pay,
                course,
                coach
            },
            res => {
                // this.changeLoadingState(false);
                this.setState({ 
                    modalPrivateCourseShowing: false,
                    // vip: res.vipcard
                });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    // 换绑教练
    handleChangeCoach(e) {
        const { coach } = e;
        const { changeCoachCourse } = this.state;
        this.changeLoadingState(true);
        launchChangeCoach(
            {
                id: changeCoachCourse.id,
                coachid: coach.coachid
            },
            res => {
                // this.changeLoadingState(false);
                this.setState({ modalChangeCoachShowing: false, changeCoachCourse: undefined });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    // 转让
    handleTransfer(user, transferType, newCard) {
        let { vip } = this.state;
        this.changeLoadingState(true);
        launchTransfer(
            {
                vip,
                card: newCard,
                user,
                cardtype: transferType
            },
            res => {
                // this.changeLoadingState(false);
                message.success('转让成功');
                this.setState({ 
                    // vip: res.vipcard,
                    modalGiveShowing: false 
                });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    handleTransferCourse(vip, user, course, newCard) {
        this.changeLoadingState(true);
        launchTransferCourse(
            {
                user,
                course,
                card: newCard,
                vip
            },
            res => {
                message.success('转让成功');
                this.setState({ 
                    // vip: res.vipcard,
                    modalTransferCourseShowing: false 
                });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    // 升级
    handleUpgrade() {
        let that = this;
        confirm({
            title: '确定要升级为私教可约团课？',
            content: <div>
                <p>升级后，会员私教课有效期间，可预约场馆内的团课。</p>
                <p>1.此方式只适用私教会员可以免费上大班课。（非开卡，不会生成订单）</p>
                <p>2.私教会员购买会员卡请通过“购买会员卡”按钮</p>
                </div>,
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);

                launchUpgrade(
                    {
                        userid: that.state.vip.userid
                    },
                    res => {
                        // that.changeLoadingState(false);
                        message.success('升级成功');
                        // that.setState({ 
                        //     vip: res.vipcard,
                        // });
                        that.reloadVipRelated();
                    },
                    fail => {
                        that.changeLoadingState(false);
                        message.error(fail);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    // 降级
    handleDegrade() {
        
        let that = this;
        confirm({
            title: '确定要降级为私教不可约团课？',
            content: '降级后，仅购买私教课的会员，在私教课有效期间失去预约团课的权利',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);

                launchDegrade(
                    {
                        userid: that.state.vip.userid
                    },
                    res => {
                        // that.changeLoadingState(false);
                        message.success('降级成功');
                        // that.setState({ 
                        //     vip: res.vipcard,
                        // });
                        that.reloadVipRelated();
                    },
                    fail => {
                        that.changeLoadingState(false);
                        message.error(fail);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    // 延长课程时效
    handleProlongCourse(e) {
        const { prolongCourse, vip } = this.state;
        this.changeLoadingState(true);
        launchProlongCourses(
            {
                id: prolongCourse.id,
                prolongday: e.day,
                userid: vip.userid,
            },
            res => {
                // this.changeLoadingState(false);
                this.setState({ modalProlongCourseShowing: false, prolongCourse: undefined });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    // 延长会员卡时效
    handleProlongVip(e) {
        const { vip, prolongVipType } = this.state;
        this.changeLoadingState(true);
        launchProlongVip(
            {
                userid: vip.userid,
                cardtype: prolongVipType,
                prolongday: e.day
            },
            res => {
                // this.changeLoadingState(false);
                this.setState({ 
                    modalProlongVipShowing: false, 
                    prolongVipType: undefined,
                    // vip: res.vipcard
                });
                this.reloadVipRelated();
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    // 冻结
    handleFreeze(e) {
        console.log(e);
        let that = this;
        confirm({
            title: '确定要冻结吗？',
            content: '冻结期间无法使用会员卡',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);
                launchFreezeVipUser(
                    {
                        userid: that.state.vip.userid,
                        freezeday: e.freezeday,
                        freezestarttime: e.freezestarttime
                    },
                    res => {
                        // that.changeLoadingState(false);
                        message.success('冻结成功');
                        that.setState({ 
                            // vip: res.vipcard,
                            modalFreezeShowing: false 
                        });
                        that.reloadVipRelated();
                    },
                    fail => {
                        that.changeLoadingState(false);
                        message.error(fail);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    // 恢复
    handleResume() {
        let that = this;
        confirm({
            title: '确定要恢复吗？',
            content: '恢复后，剩余冻结时间将扣除',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                that.changeLoadingState(true);

                launchResumeVipUser(
                    {
                        userid: that.state.vip.userid
                    },
                    res => {
                        // that.changeLoadingState(false);
                        message.success('恢复成功');
                        // that.setState({ 
                            // vip: res.vipcard,
                        // });
                        that.reloadVipRelated();
                    },
                    fail => {
                        that.changeLoadingState(false);
                        message.error(fail);
                    }
                );
            },
            onCancel() {
                
            },
        });
    }

    renderContent() {
        let { nowtime } = this.state;
        if (this.state.currentStep === "1") {
            let typeStr = '';
            if (this.state.vip.type === 1) {
                typeStr = '私教卡';
            } else if (this.state.vip.type === 2) {
                typeStr = '会员卡';
            }

            let stateStr = '';
            if (this.state.vip.state === 1) {
                stateStr = '正常';
            } else if (this.state.vip.state === 2) {
                stateStr = '冻结';
            } else if (this.state.vip.state === 10) {
                stateStr = '未激活';
            }

            let formLength = { width: 130 };

            return (
                <div style={{ ...VipUserDetailStyles.detailArea, display: this.state.currentStep === "1" ? 'flex' : 'none'}}>
                    <div style={VipUserDetailStyles.productInfoArea}>
                        <div style={{...VipUserDetailStyles.detailInfoLeftArea, width: '100%'}}>
                            <div style={{ marginTop: 20 }}>
                                <FormItem itemKey="会员卡号：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={this.state.vip.cardno} />
                                <FormItem itemKey="卡类型：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={`${typeStr}${this.state.vip.pca === 1 ? '（私教课生效期间可约团课）' : ''}`} />
                                <FormItem itemKey="会员姓名：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={this.state.vip.username} extraArea={<Button icon={<EditOutlined />} type="link" onClick={() => { this.setState({ modalUsernameEditShowing: true }) }}>修改</Button>} />
                                <FormItem itemKey="卡状态：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={stateStr} />
                            </div>

                            {/* 私教卡, 显示私教课到期时间 */}

                            {
                                (this.state.vip.type === 1 || (this.state.vip.type === 2 && this.state.vip.privateendtime > nowtime && this.state.vip.limitendtime < nowtime && (this.state.vip.endtime < nowtime || this.state.vip.restimes === 0))) ?
                                    <div style={{ marginTop: 20 }}>
                                        <FormItem itemKey="私教卡开通时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.privatestarttime)} />
                                        <FormItem itemKey="私教卡到期时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.privateendtime)} />
                                    </div>
                                    :
                                    null
                            }   

                            {/* 会员卡, 显示 会员卡到期时间 和 次卡到期时间 */}
                            {
                                this.state.vip.type === 2 && this.state.vip.limitendtime && this.state.vip.limitendtime > 0 ?
                                    <div style={{ marginTop: 20 }}>
                                        <FormItem itemKey="时限卡开通时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.limitstarttime)} />
                                        <FormItem itemKey="时限卡到期时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.limitendtime)} 
                                            extraArea={<a onClick={ () => { this.setState({ modalProlongVipShowing: true, prolongVipType: 1 }) } } href="javascript:void(0)"  >延长时限卡</a>}
                                        />
                                        
                                    </div>
                                    :
                                    null
                            } 

                            {/* && this.state.vip.resttimes > 0 */}
                            {
                                this.state.vip.type === 2 && this.state.vip.endtime && this.state.vip.endtime > 0 ?
                                    <div style={{ marginTop: 20 }}>
                                        <FormItem itemKey="次卡剩余次数：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={this.state.vip.resttimes} />
                                        <FormItem itemKey="次卡开通时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.starttime)} />
                                        <FormItem itemKey="次卡到期时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.endtime)} 
                                            extraArea={<a onClick={ () => { this.setState({ modalProlongVipShowing: true, prolongVipType: 2 }) } } href="javascript:void(0)"  >延长次卡</a>}
                                        />
                                    </div>
                                    :
                                    null
                            } 

                            {
                                this.state.vip.freezestarttime && this.state.vip.freezestarttime > 0 ?
                                    <div style={{ marginTop: 20 }}>
                                        <FormItem itemKey="冻结开始时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.freezestarttime)} />
                                        <FormItem itemKey="冻结结束时间：" type="show" keyStyles={formLength} valueStyles={{ width: 300 }} itemValue={LLCDateHelper.formatDate(this.state.vip.freezeendtime)} />
                                    </div>
                                    :
                                    null
                            }
                        </div>

                    </div>
                </div>
            );
        } else if (this.state.currentStep === "2") {
            return (
                <div style={{ ...VipUserDetailStyles.detailArea, display: this.state.currentStep === "2" ? 'flex' : 'none'}}>
                    <div style={VipUserDetailStyles.productInfoArea}>
                        <div style={{...VipUserDetailStyles.detailInfoLeftArea, width: '100%'}}>
                            <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                                {
                                    this.state.courses && this.state.courses.length > 0 ?
                                        <Table
                                            key="courseTable"
                                            rowKey={record => `course_${record.id}`}
                                            columns={this.courseColumns}
                                            dataSource={this.state.courses}
                                            pagination={false}
                                            scroll={{ 
                                                // x: 1200, 
                                                // y: 485 
                                            }}
                                        ></Table>
                                        :
                                        <Empty description="没有私教课信息" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.currentStep === "3") {
            return (
                <div style={{ ...VipUserDetailStyles.detailArea, display: this.state.currentStep === "3" ? 'flex' : 'none'}}>
                    <div style={VipUserDetailStyles.productInfoArea}>
                        <div style={{...VipUserDetailStyles.detailInfoLeftArea, width: '100%'}}>
                            <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                                {
                                    this.state.details && this.state.details.length > 0 ?
                                        <Table
                                            key="detailTable"
                                            rowKey={record => `detail_${record.detailid}`}
                                            columns={this.detailColumns}
                                            dataSource={this.state.details}
                                            pagination={false}
                                            scroll={{ 
                                                // x: 1200, 
                                                // y: 485 
                                            }}
                                        ></Table>
                                        :
                                        <Empty description="没有明细信息" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderNav() {
        return (
            <div style={{width: '100%', paddingLeft: 24, paddingRight: 24}}>
                <Menu onClick={this.handleChangeStep} selectedKeys={[this.state.currentStep]} mode="horizontal">
                    <Menu.Item key="1"> <IdcardOutlined /> 会员卡信息 </Menu.Item>
                    <Menu.Item key="2"> <InsertRowAboveOutlined /> 私教课信息 </Menu.Item>
                    <Menu.Item key="3"> <FileTextOutlined /> 会员卡明细 </Menu.Item>
                </Menu>
            </div>
        );
    }

    render() {
        let { vip, nowtime } = this.state;
        if (!vip) return <Redirect to={`/${MAIN.path}/${MAIN_LISTS.VIPUSER_LIST.path}`}/>;
        // let nowTime = LLCDateHelper.achiveTimestampOfSecond();
        let addButton = null;

        // 是否可降级
        let degradable = false;
        if (
            this.state.courses && this.state.courses.length > 0 && // 有私教课 
            this.state.vip.limitendtime < LLCDateHelper.achiveTimestampOfSecond() && // 没有时限卡
            (this.state.vip.restimes === 0 || this.state.vip.endtime < LLCDateHelper.achiveTimestampOfSecond()) // 没有次卡
        ) {
            degradable = true;
        }

        // 是否可转让
        let giveable = false;
        if (vip.limitendtime > nowtime || vip.endtime > nowtime) {
            giveable = true;
        }

        // 冻结/恢复
        let isFreezeButton = true;
        if (this.state.vip.state === 2 || this.state.vip.freezestarttime > 0) {
            isFreezeButton = false;
        }
        if (this.state.vip.type === 1 && this.state.vip.privateendtime > nowtime) { // 私教卡，可以升级为会员卡
            // if (this.state.vip.state === 10) { // 未激活，不显示升降级
            //     addButton = <div>
            //         <Button style={{ marginLeft: 20 }} onClick={ () => { this.setState({ modalUpgradeBuyShowing: true }) } } type="primary" size="small">购买会员卡</Button>
            //         <Button style={{ marginLeft: 20 }} onClick={ () => { this.setState({ modalPrivateCourseShowing: true }) } } type="primary" size="small">购买私教课</Button>
            //     </div>;
            // } else {
            addButton = <div>
                <Button style={{ marginLeft: 20 }} onClick={ () => { this.setState({ modalUpgradeBuyShowing: true }) } } type="primary" size="small">购买会员卡</Button>
                <Button style={{ marginLeft: 20 }} onClick={ () => { this.setState({ modalPrivateCourseShowing: true }) } } type="primary" size="small">购买私教课</Button>
                {
                    vip.pca === 0 ?
                        <Button style={{ marginLeft: 20 }} onClick={ this.handleUpgrade } type="primary" size="small">升级为私教可约团课</Button>
                        :
                        null
                }
                {
                    vip.pca === 1 ?
                        <Button style={{ marginLeft: 20 }} onClick={ this.handleDegrade } type="primary" size="small">降级为私教不可约团课</Button>
                        :
                        null
                }
            </div>;
            // }
        } else if (this.state.vip.type === 2) { // 会员卡，可以续卡
            addButton = <div>
                <Button onClick={ () => { this.setState({ modalEnrollShowing: true }) } } type="primary" size="small">续卡</Button>
                <Button 
                    style={{ marginLeft: 20 }} 
                    onClick={ () => { 
                        if (this.state.vip.state === 2 || this.state.vip.freezestarttime > 0) {
                            this.handleResume();
                        } else {
                            this.setState({ modalFreezeShowing: true });
                        }
                    }} 
                    type="primary" 
                    size="small">{isFreezeButton ? '冻结' : '恢复'}</Button>
                
                {
                    giveable ?
                        <Button style={{ marginLeft: 20 }}  onClick={ () => { this.setState({ modalGiveShowing: true }) } } type="primary" size="small">转让</Button>
                        :
                        null
                }
                <Button style={{ marginLeft: 20 }} onClick={ () => { this.setState({ modalPrivateCourseShowing: true }) } } type="primary" size="small">购买私教课</Button>
                {
                    vip.pca === 0 && vip.privateendtime > nowtime ? 
                        <Button style={{ marginLeft: 20 }}  onClick={ this.handleUpgrade } type="primary" size="small">升级为私教可约团课</Button>
                        :
                        null
                }
                {
                    vip.pca === 1 ?
                        <Button style={{ marginLeft: 20 }} onClick={ this.handleDegrade } type="primary" size="small">降级为私教不可约团课</Button>
                        :
                        null
                }

                
            </div>;
        }

        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderPageHeader({
                        title: NAMESPACE.name,
                        onBack: () => { this.props.history.goBack(); },
                        extra: <div>
                            {
                                addButton
                            }
                            
                        </div>
                    })
                }
                <div style={PageStyles.contentContainer_nopadding}>
                    {
                        this.renderNav()
                    }
                    
                    {
                        this.renderContent()
                    }
                </div>

                <Modal
                    title="填写冻结时长"
                    visible={this.state.modalFreezeShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalFreezeShowing: false }) }}
                    footer={null}
                >
                    <FreezeView 
                        onCancel={() => { this.setState({ modalFreezeShowing: false }) }}
                        onOk={this.handleFreeze}
                        vip={this.state.vip}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="换绑教练"
                    visible={this.state.modalChangeCoachShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                    footer={null}
                >
                    <ChangeCoachView 
                        onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                        onOk={this.handleChangeCoach}
                        isLoading={this.state.isLoading}
                    />
                </Modal>
                
                <Modal
                    title="延长私教课时效"
                    visible={this.state.modalProlongCourseShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalProlongCourseShowing: false }) }}
                    footer={null}
                >
                    <ProlongView 
                        onCancel={() => { this.setState({ modalProlongCourseShowing: false, prolongCourse: undefined }) }}
                        onOk={this.handleProlongCourse}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="延长会员卡时效"
                    visible={this.state.modalProlongVipShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalProlongVipShowing: false }) }}
                    footer={null}
                >
                    <ProlongView 
                        onCancel={() => { this.setState({ modalProlongVipShowing: false, prolongCourse: undefined }) }}
                        onOk={this.handleProlongVip}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="修改会员姓名"
                    visible={this.state.modalUsernameEditShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalUsernameEditShowing: false }) }}
                    footer={null}
                >
                    <UsernameEditView
                        onCancel={() => { this.setState({ modalUsernameEditShowing: false, prolongCourse: undefined }) }}
                        onOk={this.handleChangeUsername}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="续卡"
                    centered={true}
                    visible={this.state.modalEnrollShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalEnrollShowing: false }) }}
                    footer={null}
                >
                    <EnrollView 
                        vip={this.state.vip}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleEnrollVip}
                    />
                </Modal>

                <Modal
                    title="购卡"
                    centered={true}
                    visible={this.state.modalUpgradeBuyShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalUpgradeBuyShowing: false }) }}
                    footer={null}
                >
                    <EnrollView 
                        vip={this.state.vip}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleEnrollVip}
                    />
                </Modal>

                <Modal
                    title="购课"
                    centered={true}
                    visible={this.state.modalPrivateCourseShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalPrivateCourseShowing: false }) }}
                    footer={null}
                >
                    <EnrollPrivateCourseView 
                        vip={this.state.vip}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleBuyPrivateCourse}
                    />
                </Modal>

                <Modal
                    title="转让"
                    centered={true}
                    visible={this.state.modalGiveShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalGiveShowing: false }) }}
                    footer={null}
                >
                    <TransferView 
                        vip={this.state.vip}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleTransfer}
                    />
                </Modal>

                <Modal
                    title="转让课程"
                    centered={true}
                    visible={this.state.modalTransferCourseShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalTransferCourseShowing: false }) }}
                    footer={null}
                >
                    <TransferCourseView
                        vip={this.state.vip}
                        course={this.state.transferCourse}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleTransferCourse}
                    />
                </Modal>

                
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = store => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
  return {
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipuserDetailController);

