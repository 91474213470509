import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import { create } from 'handlebars';

export const actions = {
  saveDesc: createAction('saveDesc'),
  recordDescid: createAction('recordDescid')
};

const effects = {
  *saveDesc({ payload }) {
    const response = yield call(launchPOSTRequest, Apis.SAVE_DESC_INFO, payload)
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      
      message.success(response.responseData.msg);
      
      yield put(actions.recordDescid(response.responseData.result.desc.id))
    } else {
      message.error(response.msg)
    }
  }
};

export const watchers = [
  function* () {
    yield takeLatest(actions.saveDesc, effects.saveDesc);
  }
];

// reducer
export const descStore = handleActions(
  {
    recordDescid(state, { payload }) {
      console.log(payload, 'payload');
      return {
        ...state,
        descid: payload
      }
    }
  },
  {
    descid: undefined
  }
)

export async function launchGetDesc(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.GET_DESC_INFO, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response);
    } else {
      failed(response);
    }
  } catch (err) {
    failed(err);
  } 
}

export async function launchSaveDesc(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.SAVE_DESC_INFO, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.responseData);
    }
  } catch (err) {
    failed(err);
  } 
}