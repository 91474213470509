export const BCG_ROOT_Name = 'help';


export const PageRoutes = {
  MAIN: {
    path: 'help',
    children: {
      FLOW: { path: 'flow', name: '流程',
        children: {
          USE_FLOW: {
              path: 'use-flow',
              name: '使用流程'
          },
        }
      },
      LOGIN: { path: 'login', name: '登录',
        children: {
          MANAGER_LOGIN: {
              path: 'manager-login',
              name: '管理员登录'
          },
        
        }
      },
      VENUE: { path: 'venue', name: '场馆',
        children: {
          CREATE_VENUE: {
              path: 'create-venue',
              name: '创建场馆'
          },
          CHANGE_VENUE: {
            path: 'change-venue',
            name: '切换场馆'
          },
        }
      },
      MANAGER: { path: 'manager', name: '账户',
        children: {
          MAIN_MANAGER: {
            path: 'main-manager',
            name: '主账户'
          },
          SUB_MANAGER: {
            path: 'sub-manager',
            name: '子账户'
          }
        }
      },
      COACH: { path: 'coach', name: '教练',
        children: {
          ADD_COACH: {
            path: 'add-coach',
            name: '添加教练'
          },
          EDIT_COACH: {
            path: 'edit-coach',
            name: '编辑教练'
          },
          COACH_FUNC: {
            path: 'coach-func',
            name: '教练线上功能'
          }
        }
      },
      COURSE: { path: 'course', name: '课程',
        children: {
          EDIT_COURSE: {
            path: 'edit-course',
            name: '编辑课程'
          },
          TIMETABLE: {
            path: 'timetable',
            name: '团课课表'
          },
          APPOINT_CLASS: {
            path: 'appoint-class',
            name: '团课预约'
          },
          APPOINT_PRIVATE: {
            path: 'appoint-private',
            name: '私教预约'
          },
          APPOINT_PRIVATE_UNHANDLE: {
            path: 'appoint-private-unhandle',
            name: '私教预约申请'
          },
          APPOINT_EXPERIENCE: {
            path: 'appoint-experience',
            name: '体验课预约'
          },
        }
      },
      VIP: { path: 'vip', name: '会员',
        children: {
          VIP_CARD: {
            path: 'vip-card',
            name: '会员卡模'
          },
          VIP_USER: {
            path: 'vip-user',
            name: '会员'
          },
          NEW_VIP: {
            path: 'new-vip',
            name: '新会员开卡'
          },
          RENEW_VIP: {
            path: 'renew-vip',
            name: '老会员续卡'
          },
          VIP_OPERATE: {
            path: 'vip-operate',
            name: '会员冻结/恢复/转让'
          },
          VIP_CHANGEGRADE: {
            path: 'vip-changegrade',
            name: '会员升级/降级'
          },
          VIP_COURSE: {
            path: 'vip-course',
            name: '会员私教课'
          },
        }
      },
      MARKET: { path: 'market', name: '营销',
        children: {
          APPOINT_BANNER: {
            path: 'appoint-banner',
            name: '约课页Banner'
          }
        }
      },
    }
  },
};

export const MAIN = PageRoutes.MAIN;
export const MAIN_LISTS = MAIN.children;

const addParent = function(node, parent) {
  for (let name in node) {
    if (parent) {
      node[name].parent = parent;
    }
    if (node[name].children) {
      addParent(node[name].children, node[name]);
    } 
  }
  return;
}

addParent(PageRoutes);
