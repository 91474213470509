import React, { Component } from 'react';
import moment from 'moment';
import { Input, InputNumber, Select, Radio, Upload, Button, message, DatePicker, Checkbox } from 'antd';
import PicturesWall from './picture-wall-view';
import CustomPicturesWall from './custom-pictures-wall';
import { FormStyles } from '../../style/common-styles';
import LLCDateHelper from 'date-helper';
import { isEmpty } from '../../util/helper/string-helper';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default class FormItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editPicture: undefined
        };

        this.handleDeletePicButton = this.handleDeletePicButton.bind(this);
    }

    beforeUpload = (file) => {
        console.log(file);
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('Image must smaller than 10MB!');
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
        
        }
        return false; // 不调用默认的上传方法
    }

    handleChange = async ({ file, fileList }) => {

        let preview = await getBase64(file);
        
        this.state.editPicture = { 
            preview,
            file: fileList[fileList.length-1] 
        };
        
        this.setState({ editPicture: this.state.editPicture });
        this.props.updatePictures && this.props.updatePictures(fileList[fileList.length-1]);
    };

    handleDeletePicButton() {
        this.setState({ editPicture: undefined });
        this.props.deletePicture && this.props.deletePicture();
    }

    renderValueArea() {
        if (this.props.type === 'input') {
            return (
                <Input
                    allowClear
                    style={{...styles.input, ...this.props.valueStyles}}
                    placeholder={this.props.itemPlaceHolder}
                    value={this.props.itemValue}
                    onPressEnter={() => { this.props.onOk && this.props.onOk() }}
                    onChange={e => {
                        let inputText = e.target.value;
                        this.props.onValueChanged && this.props.onValueChanged(inputText);
                    }}
                />
            );
        } else if (this.props.type === 'input-number') {
            return (
                <InputNumber
                    allowClear
                    min={this.props.min ? this.props.min : 0}
                    style={{...styles.input, ...this.props.valueStyles}}
                    placeholder={this.props.itemPlaceHolder}
                    value={this.props.itemValue}
                    onPressEnter={() => { this.props.onOk && this.props.onOk() }}
                    onChange={value => {
                        let inputText = value;
                        this.props.onValueChanged && this.props.onValueChanged(inputText);
                    }}
                />
            );
        } else if (this.props.type === 'picture') {
            let src = this.props.itemValue;
            if (this.state.editPicture) {
                src = this.state.editPicture.preview;
            }
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}   
                    >
                        {
                            src ? 
                            <img style={styles.itemImage} src={src} alt="品牌图片" /> 
                            :
                            <div style={FormStyles.uploadButton_100}>
                                <PlusOutlined />
                                <div> 上传</div>
                            </div>
                        }
                    </Upload>
                    {   
                        src && this.props.deletePicture ?
                            <Button onClick={() => {this.handleDeletePicButton()}} style={styles.minusButton} shape="circle" icon={<MinusOutlined />} type="danger" size="small" ></Button>
                            :
                            null
                    }
                </div>
            );
        } else if (this.props.type === 'picture-wall') {
            return (
                // <PicturesWall 
                //     defaultPictures={this.props.defaultPictures}
                //     updatePictures={this.props.updatePictures}
                // />
                <CustomPicturesWall 
                    defaultPictures={this.props.defaultPictures}
                    updatePictures={this.props.updatePictures}
                />
            );
        } else if (this.props.type === 'select') {
            return (
                <Select
                    defaultValue={this.props.defaultValue}
                    style={{...styles.input, ...this.props.valueStyles}}
                    placeholder={this.props.itemPlaceHolder}
                    allowClear={this.props.allowClear !== undefined ? this.props.allowClear : true}
                    onChange={(e) => { 
                        console.log(e);
                        this.props.onValueChanged && this.props.onValueChanged(e) }
                    }
                    value={this.props.itemValue}
                >
                    {
                        this.props.valueList && this.props.valueList.map((item, index) => {
                            return (
                                <Option key={`${item.value}_${index}`} value={item.value}>{item.text}</Option>
                            )
                        })
                    }
                </Select>
            );
        } else if (this.props.type === 'select-modal') {
            return (
                <div onClick={ () => { this.props.onSelectTapped && this.props.onSelectTapped() }}>
                    <Select
                        showArrow={false}
                        style={{...styles.input, ...this.props.valueStyles}}
                        placeholder={this.props.itemPlaceHolder}
                        allowClear={true}
                        onChange={(e) => { this.props.onClearSelect && this.props.onClearSelect(undefined) }}
                        value={this.props.itemValue}
                        dropdownStyle={{ display: "none" }}>
                    </Select>
                </div>  
            );
        } else if (this.props.type === 'radio') {
            return (
                <Radio.Group onChange={(e) => { this.props.onValueChanged && this.props.onValueChanged(e.target.value); }} value={this.props.itemValue}>
                    {
                        this.props.valueList && this.props.valueList.map((item, index) => {
                            return <Radio key={`${item.value}_${index}`} value={item.value}>{item.text}</Radio>
                        })
                    }
                </Radio.Group>
            )
        } else if (this.props.type === 'check-box') {
            return (
                <Checkbox.Group 
                    options={this.props.valueList} 
                    value={this.props.itemValue} 
                    onChange={(checkedValue) => {
                        this.props.onValueChanged(checkedValue);
                    }} />
            )
        } else if (this.props.type === 'textarea') {
            return (
                <TextArea 
                    style={{...styles.textArea, ...this.props.valueStyles}}
                    rows={4} 
                    autoSize={false} 
                    value={this.props.itemValue} 
                    onChange={(e) => {
                        let inputText = e.target.value;
                        this.props.onValueChanged && this.props.onValueChanged(inputText);
                    }}
                    placeholder={this.props.itemPlaceHolder} />
            )
        } else if (this.props.type === 'range-time') {
            return (
                <RangePicker 
                    showTime={this.props.showTime ? { format: 'HH:mm' } : false}
                    format={this.props.showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
                    onChange={(date, dateString) => {
                        
                        if (isEmpty(dateString[0])) {
                            this.props.onValueChanged && this.props.onValueChanged(undefined, undefined);
                            return;
                        }   
                        
                        let beginTime = LLCDateHelper.timeStrToTimestamp(dateString[0]);
                        let endTime =  LLCDateHelper.timeStrToTimestamp(dateString[1]);
                        if (!this.props.showTime) {
                            endTime += (24 * 60 * 60 - 1);
                        }

                        this.props.onValueChanged && this.props.onValueChanged(beginTime, endTime);
                    }} 
                    value={this.props.itemValue} 
                />
            )
        } else if (this.props.type === 'date-picker') {
            
            return (
                <DatePicker 
                    style={{...this.props.valueStyles}}
                    placeholder={this.props.itemPlaceHolder}
                    disabledDate={currentDate => {
                        let todayTimestamp = new Date(new Date().toLocaleDateString()).getTime() / 1000
                        if (parseInt(currentDate.valueOf() / 1000) < todayTimestamp) {
                            return true;
                        } else if (this.props.endtime && parseInt(currentDate.valueOf() / 1000) > this.props.endtime) {
                            return true;
                        } else {
                            return false;
                        }
                    }}
                    value={this.props.itemValue ? moment.unix(this.props.itemValue) : undefined}
                    onChange={(date, dateString) => {
                        let selectTime = date.unix();
                        this.props.onValueChanged && this.props.onValueChanged(selectTime, date, dateString);
                    }}
                />
            )
        } else if (this.props.type === 'show') {
            return (
                <div>
                    {
                        this.props.itemValue
                    }
                </div>
                // <span>{this.props.itemValue}</span>
            );
        }

        return null;
    }

    render() {
        if(this.props.mode === 'query') {
            return (
                <div style={styles.searchItem_margin}>
                    <span>{this.props.itemKey}</span>
                    
                    {
                        this.renderValueArea()
                    }
                </div>
            )
        }
        return (
            <div style={styles.itemContainer}>
                <div style={{...styles.itemArea, ...this.props.itemStyles}}>
                    <div style={{...styles.itemKeyArea, ...this.props.keyStyles}}>
                        <span style={styles.itemKeyText}>{this.props.itemKey}</span>
                    </div>
                    
                    <div style={styles.itemValueArea}>
                        {
                            this.renderValueArea()
                        }
                    </div>

                    <div style={styles.guideArea}>
                        <span style={styles.itemGuideText}>{this.props.itemGuide}</span>
                    </div>

                    <div style={styles.extraArea}>
                        {
                            this.props.extraArea
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    itemArea: {
        display: 'flex',
        flexDirection: 'row',
    },
    itemKeyArea: {
        display: 'flex',
        minWidth: 100,
        // height: 30,
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    itemKeyText: {
        fontWeight: 'bold',
        minWidth: 100,
        textAlign: 'right'
    },
    itemValueArea: {
        marginLeft: 5,
        display: 'flex',
        alignItems: 'center',
        // width: '80%',
        // flexWrap: 'wrap'
    },
    input: {
        width: 200,
        // height: 30,
    },
    textArea: {
        width: 200,
        resize: 'none'
    },
    uploadButton: {
        // width: '90%',
        width: '100px',
        height: '100px',
        
        // backgroundColor: '#F6F6F6',
    },
    itemImage: {
        // width: '90%',
        width: '100px',
    },
    guideArea: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginLeft: 20
    },
    extraArea: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginLeft: 20
    },
    itemGuideText: {
        fontSize: '10px',
        color: '#999'
    },
    minusButton: {
        position: 'absolute',
        top: -5,
        right: -5,
        zIndex: 10
    },
    searchItem_margin: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10
    },
}