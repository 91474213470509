import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class MainManager extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="主账户" />

                <SectionTitle text="一.什么是主账户？" />
                <NormalContent text={<span>1.在您开通ONE ME平台服务时，工作人员给您的账户，就是<Highlight text="主账户" />。</span>} />

                <SectionTitle text="二.主账户的作用" />
                <NormalContent text={<span>1.主账户具有独有的权限，如：<Highlight text="场馆管理，账户管理" />。这类独有权限是无法分配给子账户的，只有主账户才可操作。</span>} />
                <NormalContent text={<span>2.场馆管理：主账户登录后，可以编辑场馆信息，以控制场馆对外展示与否等关键操作。</span>} />
                <NormalContent text={<span>3.账户管理：主账户登录后，可以创建子账户，以分配权限给其他管理人员。还可以对子账户进行管理，如修改密码，禁用/恢复，编辑信息和权限等。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainManager);
