import React from 'react';
import { Table, Statistic, Divider, Modal, DatePicker, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu, Card, Spin, Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment'

// import { Bar } from '@antv/g2plot';
import LLCDateHelper from 'date-helper'
import Decimal from 'decimal.js';
import { priceYuan } from '../../../util/helper/number-helper';
import { MAIN, MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { filterParams } from '../../../util/helper/request-helper';
import FormItem from '../../../view/common/form-item';
import { actions as queryActions } from '../../../redux/query-model';
import { TableOperateBarStyles, TableStyles, TableSearchStyles, FormStyles, PageStyles } from '../../../style/common-styles';
import { isEmpty } from '../../../util/helper/string-helper';
import { launchGetStaticAppointOverview } from '../../../redux/static-model';
import { kAdmin_User_Auth, UserAuth } from '../../../constants/authConstants';

const NAMESPACE = MAIN.children.OVER_VIEW;

const { RangePicker } = DatePicker;

class OverViewController extends BaseController {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            dataInfo: {}
        };

        this.handleTabChanged = this.handleTabChanged.bind(this);
        this.handleGroupChanged = this.handleGroupChanged.bind(this);
        this.renderTabContent = this.renderTabContent.bind(this);
        this.handlePointTime = this.handlePointTime.bind(this);

        this.appointClassColumns = [
            { title: '用户', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '10%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '课程', width: '50%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '教练', width: '10%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
                render: state => {
                    if (state === 1) {
                        return <Tag color='red'>待上课</Tag>;
                    } else if (state === 2) {
                        return <Tag color='blue'>已签到</Tag>;
                    } else if (state === 10) {
                        return <Tag color='green'>已完成</Tag>;
                    } else if (state === 14) {
                        return <Tag color='red'>排队中</Tag>;
                    } else if (state === 15) {
                        return <Tag>已取消</Tag>;
                    } else {
                        return null;
                    }
                }
            },
            { title: '上课时间', width: '10%', align: 'center', 
                render: appoint => {
                    return <span>{`${appoint.daydate} ${appoint.begindate}`}</span>
                }
            }
        ];

        this.experienceColumns = [
            { title: '用户', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '10%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '课程', width: '50%', dataIndex: 'coursename', key: 'coursename', align: 'center' },
            { title: '教练', width: '10%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
                render: state => {
                    if (state === 1) {
                        return <Tag color='red'>待沟通</Tag>;
                    } else if (state === 2) {
                        return <Tag color='blue'>待上课</Tag>;
                    } else if (state === 5) {
                        return <Tag color='green'>已完成</Tag>;
                    } else if (state === 10) {
                        return <Tag>已失约</Tag>;
                    } else if (state === 15) {
                        return <Tag>已取消</Tag>;
                    } else {
                        return null;
                    }
                }
            },
            { title: '上课时间', width: '10%', align: 'center', 
                render: experience => {
                    if (experience.daydate) {
                        return <span>{`${experience.daydate} ${experience.begindate}`}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
        ];
    }

    componentDidMount() {
        let queryParams = this.queryParams();
        if (queryParams.tabIndex == undefined) queryParams.tabIndex = 1;
        if (!queryParams.timetype) queryParams.timetype = 1;
        this.props.configQueryParams(queryParams);

        this.getAppointStaticOverview(queryParams);
    }

    getAppointStaticOverview(extra) {
        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }

        this.changeLoadingState(true);
        launchGetStaticAppointOverview(
            filterParams(queryParams),
            res => {
                this.setState({
                    dataInfo: res
                });
                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    handleTabChanged(index) {
        let queryParams = this.queryParams();
        queryParams.tabIndex = index;
        // queryParams.timetype = 1;
        queryParams.beginTime = undefined;
        queryParams.endTime = undefined;
        this.props.configQueryParams(queryParams);
    }

    handleGroupChanged(index) {
        let queryParams = this.queryParams();
        queryParams.timetype = index;
        queryParams.beginTime = undefined;
        queryParams.endTime = undefined;
        this.props.configQueryParams(queryParams);

        this.getAppointStaticOverview();
    }

    handlePointTime(date, dateString) {
        
        let queryParams = this.queryParams();

        if (isEmpty(dateString[0])) {
            queryParams.timetype = 1;
            queryParams.beginTime = undefined;
            queryParams.endTime = undefined;    
        } else {
            
            queryParams.timetype = 0;
            queryParams.beginTime = LLCDateHelper.timeStrToTimestamp(dateString[0]);
            queryParams.endTime =  LLCDateHelper.timeStrToTimestamp(dateString[1]) + (24 * 60 * 60 - 1);
        }

        this.props.configQueryParams(queryParams);
        this.getAppointStaticOverview();
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    renderTab() {
        let queryParams = this.queryParams();

        let beginTime = "";
        let endTime = "";
        let timeValue = [beginTime, endTime];
        
        if (queryParams.beginTime) {
            let convert = LLCDateHelper.formatDate(queryParams.beginTime);
            timeValue[0] = moment(convert, 'YYYY-MM-DD HH-mm');
        }
        if (queryParams.endTime) {
            let convert = LLCDateHelper.formatDate(queryParams.endTime);
            timeValue[1] = moment(convert, 'YYYY-MM-DD HH-mm');
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                <span style={ queryParams.timetype === 1 ? styles.groupTitle_select : styles.groupTitle } onClick={() => { this.handleGroupChanged(1) }}>今天</span>
                <span style={ queryParams.timetype === 2 ? styles.groupTitle_select : styles.groupTitle } onClick={() => { this.handleGroupChanged(2) }}>明天</span>

                <div style={{ marginLeft: 20 }}>
                    <RangePicker 
                        format={"YYYY-MM-DD"}
                        onChange={this.handlePointTime} 
                        value={timeValue}
                    />
                </div>
            </div>
        );
    }

    renderTabContent() {
        const { skustatics } = this.state;
        let queryParams = this.queryParams();
        if (queryParams.tabIndex === 1) {

            return (
                <div style={{ marginTop: 20 }}>
                    <Table
                        loading={this.state.isLoading}
                        rowKey={record => record.id}
                        columns={this.appointClassColumns}
                        dataSource={this.state.dataInfo.appoints}
                        scroll={{ 
                            // x: 1200, 
                            // y: 450
                        }}
                    ></Table>
                </div>
            );
        } else if (queryParams.tabIndex === 2) {
            return (
                <div style={{ marginTop: 20 }}>
                    <Table
                        loading={this.state.isLoading}
                        rowKey={record => record.id}
                        columns={this.appointClassColumns}
                        dataSource={this.state.dataInfo.privates}
                        scroll={{ 
                            // x: 1200, 
                            // y: 450
                        }}
                    ></Table>
                </div>
            );
        } else if (queryParams.tabIndex === 3) {
            return (
                <div style={{ marginTop: 20 }}>
                    <Table
                        loading={this.state.isLoading}
                        rowKey={record => record.id}
                        columns={this.experienceColumns}
                        dataSource={this.state.dataInfo.experiences}
                        scroll={{ 
                            // x: 1200, 
                            // y: 450
                        }}
                    ></Table>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { dataInfo } = this.state;
        const { user } = this.props;
        let queryParams = this.queryParams();

        let authList = [];
        if (user && user.userauth) {
            authList = user.userauth.split(',');
        }

        return (
            // <Spin spinning={this.state.isLoading}>
            <div style={{...PageStyles.pageContainer, backgroundColor: '#ECEFF3', width: '100%'}}>
                
                <div style={{...TableStyles.tableBox, marginTop: 20}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <Statistic title="会员总数" value={dataInfo.vipusertotalcount} />
                                <Divider />
                                
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>今日新增</span>
                                    <Statistic value={dataInfo.newvipusercountintoday} valueStyle={{ fontSize: '16px' }} />
                                </div>
                            </Card>
                        </div>
                        <div style={{ width: 30 }} />
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <Statistic title={<span>未处理的<span style={{ color: '#000', fontWeight: 'bold' }}>私教预约</span>申请</span>} value={dataInfo.unhandleappointprivatenotify} />
                                <Divider />
                                
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button type="primary" size="small" onClick={() => {
                                        
                                        if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                                            this.props.history.push({
                                                pathname: `${MAIN_LISTS.APPOINTMENT_PRIVATE_UNHANDLE.path}`,
                                                state: {
                                                    showBack: true
                                                }
                                            });
                                        } else {
                                            message.warn('此账号未开通课程查看权限');
                                        }
                                    }}>去查看</Button>
                                </div>
                            </Card>
                        </div>
                        <div style={{ width: 30 }} />
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <Statistic prefix="" title={<span>未处理的<span style={{ color: '#000', fontWeight: 'bold' }}>体验课</span>预约</span>} value={dataInfo.experiencesintoday} />
                                <Divider />
                                
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button type="primary" size="small" onClick={() => {
                                        if (user && (user.authrole === kAdmin_User_Auth || authList.indexOf(UserAuth.COURSE) !== -1)) {
                                            this.props.history.push({
                                                pathname: `${MAIN_LISTS.COURSE_EXPERIENCE.path}`,
                                                state: {
                                                    showBack: true
                                                }
                                            });
                                        } else {
                                            message.warn('此账号未开通课程查看权限');
                                        }
                                    }}>去处理</Button>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                        <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 0, paddingTop: 20, paddingRight: 0, paddingBottom: 10 }}>
                            <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <span style={ queryParams.tabIndex === 1 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(1) }}>团课预约{ this.state.dataInfo.appoints ? `（${this.state.dataInfo.appoints.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 2 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(2) }}>私教课预约{ this.state.dataInfo.privates ? `（${this.state.dataInfo.privates.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 3 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(3) }}>体验课预约{ this.state.dataInfo.experiences ? `（${this.state.dataInfo.experiences.length}）` : '' }</span>
                                </div>

                                {
                                    this.renderTab()
                                }
                            </div>
                            <div style={{ marginTop: 20, width: '100%', height: 1, backgroundColor: '#E2E2E2' }} />

                            {/* <div id="main" style={{ paddingLeft: 20, paddingRight:20, marginTop: 20, width: '100%', minHeight: 400 }}></div> */}
                            <div style={{ paddingLeft: 20, paddingRight:20, width: '100%' }}>
                                {
                                    this.renderTabContent()
                                }
                            </div>
                        </Card>
                    </div>
                </div>

                
            </div>
            // </Spin>
        );
    }
}


const mapStateToProps = ({ queryStore, loginStore }) => {
    
    return {
        queryStore,
        user: loginStore.user
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverViewController);

const styles = {
    tabTitle: { marginLeft: 20 },
    tabTitle_select: { marginLeft: 20, color: '#1875F6' },
    groupTitle: { marginLeft: 20 },
    groupTitle_select: { marginLeft: 20, color: '#1875F6' }
}