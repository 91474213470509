import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'

export async function launchSaveTag(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.SAVE_TAG, params);
        if (
        response.responseData.succ === RESPONSE_CODE.success &&
        response.responseData.result.status === SERVICE_CODE.Successed
        ) {
          success(response.responseData.result);
        } else {
          failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
}


export async function launchGetTagList(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
      const response = await launchPOSTRequest(Apis.QUERY_TAG_LIST, params);
      if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
      ) {
        success(response.responseData.result);
      } else {
        failed(response.msg);
      }
  } catch (rejectedValue) {
      failed(rejectedValue);
  } 
}

  