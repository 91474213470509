export const VipUserSaveStyles = {
    editBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    editLeftBox: {
        flex: 1,
        // textAlign: 'center'
        // display: 'flex',
        // justifyContent: 'center'
    },
    editRightBox: {
        flex: 1,
        // display: 'flex',
        // justifyContent: 'center'
    },
    infoBox: {
        marginTop: 80
    },
    vipStateBox: {
        marginTop: 60
    },
    baseInfoItem: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        marginTop: 16
    },
    vipStateItem: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
    },
    baseInfoLeftBox: {
        width: 200, 
        textAlign: 'right',
    },
    vipStateLeftBox: {
        width: 80, 
        textAlign: 'right',
    },
    createUserLink: {
        marginLeft: 15,
        fontSize: 12
    },
    vipCardLeftBox: {
        width: 130, 
        textAlign: 'right',
    },
    identifyItem: {
        display: 'flex', 
        flexDirection: 'row', 
        // alignItems: 'center',
        marginTop: 16
    },
    identifyLeftBox: {
        width: 100, 
        textAlign: 'right',
    },
    uploadButton: {
        width: 256,
        height: 108,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    operateBox: {
        width: 100,
        marginTop: 80,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clearButton: {
        marginRight: 80
    },
    saveButton: {
        // marginLeft: 80
    },
    cardTypeTitle: {
        fontFamily: 'PingFangSC-Medium',
        fontSize: 16,
    }
}