export const VipUserDetailStyles = {
    detailArea: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
    },
    detailInfoArea: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        marginLeft: 24,
        marginRight: 24
    },
    detailInfoLeftArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}