import React, { Component } from 'react';
import { Spin, Select, Radio, Button, message } from 'antd';
import FormItem from '../common/form-item';
import moment from 'moment';
import LLCDateHelper from 'date-helper';
import { isEmpty } from '../../util/helper/string-helper';

export default class ProlongView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.handleDayChange = this.handleDayChange.bind(this);
    }

    handleDayChange(e) {

        this.setState({
            day: e
        });
    }

    render() {
        let { vip } = this.state;
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                <FormItem itemKey="延长时间（天）：" type="input-number" itemPlaceHolder="请填写延长天数" keyStyles={{ width: 120 }} valueStyles={{ width: 200 }} itemValue={this.state.day} onValueChanged={this.handleDayChange} />

                <div style={styles.operateBox}>
                    <Button onClick={ () => { this.props.onCancel && this.props.onCancel()} } >取消</Button>
                    <Button 
                        onClick={ () => { 
                            if (isEmpty(this.state.day)) {
                                message.error('请输入正确的天数');
                                return;
                            }
                            
                            this.props.onOk && this.props.onOk({
                                day: this.state.day,
                            })} 
                        } 
                        style={{ marginLeft: 20 }} 
                        type="primary">确认</Button>
                </div>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}