import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';
import FormItem from '../../../view/common/form-item';
import { actions as queryActions } from '../../../redux/query-model'
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

// 更改日期格式
import LLCDateHelper from "date-helper";

import { PageStyles, SearchBarStyles, TableStyles, TableSearchStyles } from '../../../style/common-styles';

import { 
    launchGetVipCourses, launchGetVipCourseList, 
    launchTransferCourse, launchChangeCoach, launchProlongCourses, launchProtimesCourses 
} from '../../../redux/vipcourse-model';
import { filterParams } from '../../../util/helper/request-helper';
import { CourseListStyles } from '../../../style/page/course/course-list-styles';
import ProlongView from '../../../view/vipuser/prolong-view';
import TransferCourseView from '../../../view/vipuser/transfer-course-view';
import ChangeCoachView from '../../../view/vipuser/change-coach-view';
import ProtimesView from '../../../view/vipuser/protimes-view';

const NAMESPACE = MAIN_LISTS.VIPCOURSE_LIST;

const confirm = Modal.confirm;

class VipCourseListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            
            dataInfo: {}
        };

        let that = this;
        this.columns = [
            { title: '课程名称', width: '20%', dataIndex: 'coursename', key: 'coursename', align: 'left', render: (coursename) => <span style={CourseListStyles.courseName}>{coursename}</span> },
            { title: '会员姓名', width: '10%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '教练姓名', width: '10%', dataIndex: 'coachname', key: 'coachname', align: 'center' },
            { title: '剩余次数', width: '10%', dataIndex: 'coursetimes', key: 'coursetimes', align: 'center' },
            { title: '开课时间', width: '10%', dataIndex: 'starttime', key: 'starttime', align: 'center', render: starttime => <span>{ LLCDateHelper.formatDate(starttime, 'yyyy-MM-dd') }</span> },
            { title: '到期时间', width: '10%', dataIndex: 'endtime', key: 'endtime', align: 'center', render: endtime => <span>{ LLCDateHelper.formatDate(endtime, 'yyyy-MM-dd') }</span> },
            
            { title: '状态', width: '10%', align: 'center', 
                render: course => 
                {
                    if (course.state === 10) {
                        return <Tag color='#f50'>已转让</Tag> 
                    } else {
                        if (course.coursetimes === 0) {
                            return <Tag color="#f50">次数用尽</Tag> 
                        } else {
                            return <Tag color={course.expired===0 ? '#87d068' : '#f50'} >{course.expired === 0 ? '正常' : '已过期'}</Tag> 
                        }
                    }
                }
            },
            // { title: '效期', width: 80, align: 'center', 
            //     render: course => 
            //     {
            //         if (course.state === 1 && course.coursetimes > 0) {
            //             return <Tag color='#87d068' >有效</Tag> 
            //         } else {
            //             return <Tag color='#f50' >无效</Tag> 
            //         }
            //     }
            // },
            { title: '操作', width: '20%', key: 'operate', align: 'center', 
                render: (course) => {
                    // if (course.state !== 10 && course.coursetimes !== 0) {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <a href="javascript:void(0)" onClick={() => {
                                    that.setState({ 
                                        modalProlongCourseShowing: true,
                                        prolongCourse: course 
                                    });
                            
                                }}>时限</a> 

                                <a style={{ marginLeft: 20 }} href="javascript:void(0)" onClick={() => {
                                    that.setState({
                                        modalProtimesCourseShowing: true,
                                        protimesCourse: course
                                    });
                                
                                }}>次数</a> 
                                
                                {
                                    course.expired === 0 ?
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <a style={{ marginLeft: 20 }} href="javascript:void(0)" onClick={() => {
                                                that.setState({
                                                    modalChangeCoachShowing: true,
                                                    changeCoachCourse: course
                                                });
                                            
                                            }}>换绑</a> 

                                            <a style={{ marginLeft: 20 }} href="javascript:void(0)" onClick={() => {
                                                that.setState({
                                                    modalTransferCourseShowing: true,
                                                    transferCourse: course
                                                });
                                            
                                            }}>转让</a> 
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        )
                    // }
                }
            }
        ];

        this.queryVipCourseList = this.queryVipCourseList.bind(this);

        this.handleChangeCoach = this.handleChangeCoach.bind(this);
        this.handleProlongCourse = this.handleProlongCourse.bind(this);
        this.handleProtimesCourse = this.handleProtimesCourse.bind(this);
        this.handleTransferCourse = this.handleTransferCourse.bind(this);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();

        this.queryVipCourseList(queryParams.page ? queryParams.page : 1, queryParams);
    }

    handleSearchClick() {
        this.queryVipCourseList(1)
    }
    
    
    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    queryVipCourseList(page) {
        
        let queryParams = this.queryParams();
        queryParams.page = page;

        this.changeLoadingState(true);
        launchGetVipCourseList(
            filterParams(queryParams),
            res => {
                
                this.setState({
                    dataInfo: res
                });
                console.log(res);
                this.changeLoadingState(false);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }

    // 延长课程时效
    handleProlongCourse(e) {
        const { prolongCourse, vip } = this.state;
        this.changeLoadingState(true);
        launchProlongCourses(
            {
                id: prolongCourse.id,
                prolongday: e.day,
                userid: prolongCourse.userid,
            },
            res => {
                // this.changeLoadingState(false);
                message.success('延长成功');
                this.setState({ modalProlongCourseShowing: false, prolongCourse: undefined });
                this.queryVipCourseList(this.state.dataInfo.page);
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }


    handleProtimesCourse(e) {
        const { protimesCourse, vip } = this.state;
        this.changeLoadingState(true);
        launchProtimesCourses(
            {
                id: protimesCourse.id,
                coursetimes: e.times,
                changetype: e.changetype,
                userid: protimesCourse.userid,
            },
            res => {
                // this.changeLoadingState(false);
                message.success('增加成功');
                this.setState({ modalProtimesCourseShowing: false, protimesCourse: undefined });
                this.queryVipCourseList(this.state.dataInfo.page);
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }


    // 换绑教练
    handleChangeCoach(e) {
        const { coach } = e;
        const { changeCoachCourse } = this.state;
        
        this.changeLoadingState(true);
        launchChangeCoach(
            {
                id: changeCoachCourse.id,
                coachid: coach.coachid
            },
            res => {
                message.success('换绑成功');
                // this.changeLoadingState(false);
                this.setState({ modalChangeCoachShowing: false, changeCoachCourse: undefined });
                this.queryVipCourseList(this.state.dataInfo.page);
            },
            fail => {
                this.changeLoadingState(false);
                message.error(fail);
            }
        );
    }

    handleTransferCourse(vip, user, course, newCard) {
        this.changeLoadingState(true);
        launchTransferCourse(
            {
                user,
                course,
                card: newCard,
                vip
            },
            res => {
                message.success('转让成功');
                this.setState({ 
                    vip: res.vipcard,
                    modalTransferCourseShowing: false 
                });
                this.queryVipCourseList(this.state.dataInfo.page);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    renderSearchBar() {
        let queryParams = this.queryParams();

        return (
            <div style={SearchBarStyles.searchBar}>
                
                <div style={TableSearchStyles.searchIputBox}>
                    
                    <FormItem 
                        mode="query" itemKey="课程名称：" type="input" itemPlaceHolder="请输入课程名称"
                        itemValue={queryParams.coursename} 
                        onValueChanged={(coursename) => {
                            queryParams.coursename = coursename;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="教练名称：" type="input" itemPlaceHolder="请输入教练名称"
                        itemValue={queryParams.coachname} 
                        onValueChanged={(coachname) => {
                            queryParams.coachname = coachname;
                            this.props.configQueryParams(queryParams);
                        }} />
                    <FormItem 
                        mode="query" itemKey="会员名称：" type="input" itemPlaceHolder="请输入会员名称"
                        itemValue={queryParams.username} 
                        onValueChanged={(username) => {
                            queryParams.username = username;
                            this.props.configQueryParams(queryParams);
                        }} />
                    <FormItem 
                        mode="query" itemKey="会员手机号：" type="input" itemPlaceHolder="请输入会员手机号"
                        itemValue={queryParams.phone} 
                        onValueChanged={(phone) => {
                            queryParams.phone = phone;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="状态：" type="select"
                        itemValue={queryParams.state !== undefined ? queryParams.state : 0} 
                        valueList={[{value: 0, text: '全部'}, {value: 1, text:'正常'}, {value: 10, text:'已转让'}]} 
                        onValueChanged={(state) => {
                            queryParams.state = state;
                            this.props.configQueryParams(queryParams);
                        }} />

                    <FormItem 
                        mode="query" itemKey="效期：" type="select"
                        itemValue={queryParams.isvalid !== undefined ? queryParams.isvalid : 0} 
                        valueList={[{value: 0, text: '全部'}, {value: 1, text:'有效'}, {value: 2, text:'无效'}]} 
                        onValueChanged={(isvalid) => {
                            queryParams.isvalid = isvalid;
                            this.props.configQueryParams(queryParams);
                        }} />
                </div>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                
            </div>
          )
        });
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderSearchBar()
                    }

                    <Table
                        rowKey={record => record.id}
                        loading={this.props.loading}
                        columns={this.columns}
                        dataSource={this.state.dataInfo.list}
                        scroll={{ 
                            // x: 1200, 
                            // y: 485 
                        }}
                        pagination={{
                            total: this.state.dataInfo.totalpage * this.state.dataInfo.pagesize,
                            pageSize: this.state.dataInfo.pagesize,
                            current: this.state.dataInfo.page,
                            onChange: val => {
                                this.queryCourseList(val)
                            }
                        }}
                    ></Table>
                </div>

                <Modal
                    title="延长私教课时效"
                    visible={this.state.modalProlongCourseShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalProlongCourseShowing: false }) }}
                    footer={null}
                >
                    <ProlongView 
                        onCancel={() => { this.setState({ modalProlongCourseShowing: false, prolongCourse: undefined }) }}
                        onOk={this.handleProlongCourse}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="调整私教课次数"
                    visible={this.state.modalProtimesCourseShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalProtimesCourseShowing: false }) }}
                    footer={null}
                >
                    <ProtimesView 
                        onCancel={() => { this.setState({ modalProtimesCourseShowing: false, prolongCourse: undefined }) }}
                        onOk={this.handleProtimesCourse}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="换绑教练"
                    visible={this.state.modalChangeCoachShowing}
                    destroyOnClose={true}
                    width={500}
                    onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                    footer={null}
                >
                    <ChangeCoachView 
                        onCancel={() => { this.setState({ modalChangeCoachShowing: false }) }}
                        onOk={this.handleChangeCoach}
                        isLoading={this.state.isLoading}
                    />
                </Modal>

                <Modal
                    title="转让课程"
                    centered={true}
                    visible={this.state.modalTransferCourseShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalTransferCourseShowing: false }) }}
                    footer={null}
                >
                    <TransferCourseView
                        vip={this.state.vip}
                        course={this.state.transferCourse}
                        isLoading={this.state.isLoading}
                        onSubmit={this.handleTransferCourse}
                    />
                </Modal>
            </div>
            </Spin>
        );
    }
}

const mapStateToProps = ({ queryStore }) => {
    return {
      queryStore
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(VipCourseListController);
