import React from 'react'
import { Select, Input, Result, Button, message, DatePicker, Modal, Spin, Steps, Card, InputNumber } from 'antd'
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment';

import { actions as vipUserActions, launchQueryVipState, launchHandleUser, launchHandleVipUser } from '../../../redux/vipuser-model';
import { launchUpdateUserTmpUsername } from "../../../redux/user-model";

import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';

import { isEmpty } from '../../../util/helper/string-helper';
import { priceFen, priceYuan } from '../../../util/helper/number-helper';
import LLCDateHelper from '../../../util/date-helper/index';

import { VipUserSaveStyles } from '../../../style/page/vipuser/vipuser-save';
import { FormStyles, PageStyles, TableStyles } from '../../../style/common-styles';

import UserSelectView from '../../../view/vipuser/user-select-view';
import CourseSelectView from '../../../view/course/course-select-view';
import CoachSelectView from '../../../view/coach/coach-select-view';

import VipCardMoldSelectView from '../../../view/vipuser/vipcard-mold-select-view';
import { uploadProjectImageToQiniu } from '../../../util/request';

const NAMESPACE = MAIN_LISTS.VIPUSER_LIST.children.SAVE_VIPUSER;

const Option = Select.Option;
const confirm = Modal.confirm;
const Step = Steps.Step;

class AddVipUserController extends BaseController {
    constructor(props) {
        super(props);

        let editVipCardMold = {};
        let apply = {};
        let user = undefined;
        if (this.props.location.state && this.props.location.state.vipCardMold) {
            editVipCardMold = this.props.location.state.vipCardMold;
        }

        if (this.props.location.state && this.props.location.state.apply) {
            apply = this.props.location.state.apply;
            user = { userid: apply.userid, phone: apply.phone, username: apply.username }
        }

        this.state = {
            user,
            vipcard: {}, // 当前会员状态
            apply,
            mold: undefined,
            newCard: {}, // 新开通会员的状态
            pay: { // 支付信息
                payment: 0,
                paytype: 1
            },
            course: undefined, // 私教课信息
            coach: undefined, // 私教课教练信息
            viptype: 1,

            loading: false,
            currentStep: 0,
            userListVisible: false,
            vipCardMoldListVisible: false,
            modalCourseShowing: false,
            modalCoachShowing: false,
            uploadLoading: false,

            ...editVipCardMold
        };

        this.showUserList = this.showUserList.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.createUser = this.createUser.bind(this);
        this.saveFirstStep = this.saveFirstStep.bind(this);
        this.queryVipState = this.queryVipState.bind(this);

        this.showVipCardMoldList = this.showVipCardMoldList.bind(this);
        this.selectedVipCardMold = this.selectedVipCardMold.bind(this);
        this.saveSecondStep = this.saveSecondStep.bind(this);

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);

        this.handlePreStepButtonClick = this.handlePreStepButtonClick.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
        this.handleUserSelected = this.handleUserSelected.bind(this);
        this.handleCourseChanged = this.handleCourseChanged.bind(this);
        this.handleCoachChanged = this.handleCoachChanged.bind(this);

        this.renderBaseInfoBox = this.renderBaseInfoBox.bind(this);
        this.renderIdentifyBox = this.renderIdentifyBox.bind(this);
        this.renderIdentifyBox = this.renderIdentifyBox.bind(this);
        this.renderStepContent = this.renderStepContent.bind(this);
    }

    showUserList() {
        this.setState({ userListVisible: true });
    }

    selectedUser(user) {
        this.setState({
            user,
            userListVisible: false
        });
    }

    createUser() {
        this.setState({
            user: {}
        });
    }

    // 保存第一步
    saveFirstStep() {
        this.changeLoadingState(true);
        launchUpdateUserTmpUsername(
            {
                ...this.state.user,
            },
            response => {
                message.success('保存成功');
                this.queryVipState();
            },
            msg => {
                message.error(msg);
                this.changeLoadingState(false);
            }
        );
    }

    queryVipState() {
        if (this.state.user.userid) {
            this.changeLoadingState(true);
            launchQueryVipState(
                { userid: this.state.user.userid },
                response => {
                    this.changeLoadingState(false);

                    if (response.vipcard) {
                        this.setState({ vipcard: response.vipcard });
                    } else {
                        this.setState({ vipcard: {} });
                    }
                },
                msg => {
                    this.changeLoadingState(false);
                    message.error(msg);
                }
            );
        } else {
            this.changeLoadingState(false);
            this.setState({ vipcard: {} });
        }

        this.setState({ currentStep: this.state.currentStep + 1 });
    }

    showVipCardMoldList() {
        this.setState({ vipCardMoldListVisible: true });
    }

    selectedVipCardMold(mold) {
        let endtime = undefined;
        if (this.state.newCard.starttime) {
            endtime = this.state.newCard.starttime + 60 * 60 * 24 * mold.cardduration;
        }
        this.setState({
            mold,
            newCard: {
                ...this.state.newCard,
                endtime
            },
            pay: {
                ...this.state.pay,
                payment: mold.cardprice
            }
        });
    }

    // 保存第二步
    saveSecondStep() {
        this.changeLoadingState(true);
        const { viptype, course, user, pay, mold, newCard, coach, apply } = this.state;
        let params = {};
        if (viptype === 1) { // 私教
            params = {
                viptype,
                user,
                pay,
                course,
                coach
            }
        } else if (viptype === 2) { // 会员卡
            params = {
                viptype,
                user,
                mold,
                card: newCard,
                pay
            };
        }

        if (apply && apply.id) {
            params.applyid = apply.id;
        }

        launchHandleVipUser(
            params,
            response => {
                this.changeLoadingState(false);
                message.success(response.msg);
                this.setState({ currentStep: this.state.currentStep + 1 });
                this.props.queryVipUserList();
            },
            msg => {
                this.changeLoadingState(false);
                message.error(msg);
            }
        );
    }

    checkFormNotHasEmptyItem = (index) => {
        const { viptype, course, mold, coach, pay, newCard } = this.state;
        if (index === 0) {
            if (!this.state.user.username || isEmpty(this.state.user.username)) {
                message.error('请填写姓名');        
                return false;
            }
            
        } else if (index === 1) { // 私教课
            if (viptype === 1) {
                if (!course) {
                    message.error('请选择私教课程');        
                    return false;
                }
                if (!coach) {
                    message.error('请选择绑定教练');        
                    return false;
                }
                // if (!course.times) {
                //     message.error('请填写课程次数');
                //     return false;
                // }
                if (!course.starttime && !course.endtime) {
                    message.error('请填写开始/结束时间');        
                    return false;
                }
                if (!pay.payment) {
                    message.error('请填写支付价格');
                    return false;
                }
            } else if (viptype === 2) { // 会员卡
                if (!mold) {
                    message.error('请选择会员卡');        
                    return false;
                }
                if (!newCard.starttime && !newCard.endtime) {
                    message.error('请填写开始/结束时间');        
                    return false;
                }
                if (!pay.payment) {
                    message.error('请填写支付价格');
                    return false;
                }
                
            }
        }

        return true;
    }

    beforeUpload(file) {
        const isPermitionImgType = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isPermitionImgType) {
          message.error('请上传格式为png或jpg的图片文件');
        }
        const isPassSizeLimit = file.size / 1024 / 1024 < 4;
        if (!isPassSizeLimit) {
          message.error('图片大小需小于4Mb');
        }
        return isPermitionImgType && isPassSizeLimit;
    }

    handleUploadChange(info, index) {
        if (info.file.status === 'uploading' && this.state.uploadLoading === false) {
            this.setState({
                uploadLoading: true
            });

            uploadProjectImageToQiniu(
                info.file,
                1,
                res => {
                    console.log(res, index, 'uploadProjectImageToQiniu');
                    if (index === 0) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                cardFront: res.result.url 
                            } 
                        });
                    } else if (index === 1) {
                        this.setState({ 
                            user: { 
                                ...this.state.user, 
                                cardBack: res.result.url 
                            } 
                        });
                    }

                    this.setState({ uploadLoading: false });
                },
                res => {
                    this.setState({
                        uploadLoading: false
                    });
                    message.error('上传失败')
                    message.error(res)
                }
            );          
        }
    }

    handleCourseChanged(course) {
        this.setState({ 
            course,
            modalCourseShowing: false
        });
    }

    handleCoachChanged(coach) {
        this.setState({ 
            coach,
            modalCoachShowing: false
        });
    }

    handlePreStepButtonClick() {
        if (this.state.currentStep === 1) {
            this.setState({
                currentStep: 0
            });
        }
    }

    handleSaveButtonClick() {
        if (!this.checkFormNotHasEmptyItem(this.state.currentStep)) {
            return;
        } 
        if (this.state.currentStep === 0) {
            this.saveFirstStep();
        } else if (this.state.currentStep === 1) {
            this.saveSecondStep();
        } else if (this.state.currentStep === 2) {
            
            this.props.history.goBack();
        }        
    }

    handleUserSelected() {
        
    }

    changeLoadingState(loading) {
        this.setState({
            loading
        }); 
    }

    renderStepContent() {
        if(this.state.currentStep === 0) {
            return (
                <div style={VipUserSaveStyles.editBox}>
                    {
                        this.renderBaseInfoBox()
                    }

                    {
                        this.renderIdentifyBox()
                    }
                </div>
            );
        } else if (this.state.currentStep === 1) {
            return (
                <div style={VipUserSaveStyles.editBox}>
                    {
                        this.renderVipStateBox()
                    }
                    {
                        this.renderVipCardBox()
                    }
                </div>
            );
        } else if (this.state.currentStep == 2) {
            return (
                <Result
                    style={{ marginTop: 40 }}
                    status="success"
                    title="开通成功！"
                    subTitle="在会员卡/私教课生效后，会员可在ONE ME平台小程序进行线上约课。"
                    extra={[
                        <Button type="primary" onClick={() => {
                            this.props.history.goBack();
                        }}>完成</Button>,
                    ]}
                />
            );
        } else {
            return null;
        }
    }

    renderBaseInfoBox() {
        return (
            <div style={VipUserSaveStyles.editLeftBox}>
                <div style={VipUserSaveStyles.infoBox}>
                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.baseInfoLeftBox}>
                            <span>用户：</span>
                        </div>
                        <div onClick={this.showUserList}>
                            <Select
                                showArrow={false}
                                style={{...FormStyles.input_middle, width: 250}}
                                placeholder="选择用户"
                                // disabled={true}
                                // this.state.user ? this.state.user.nickname : undefined
                                value={this.state.user ? `${this.state.user.nickname ? this.state.user.nickname : '-'} （${this.state.user.phone ? this.state.user.phone : '-'}）` : undefined}
                                dropdownStyle={{ display: "none" }}>
                            {/* {this.props.communityOption} */}
                            </Select>
                        </div>

                        {/* <a 
                            href="javascript:void(0)" 
                            style={VipUserSaveStyles.createUserLink}
                            onClick={this.createUser}
                        >新建用户</a> */}
                    </div>

                    {
                        this.state.user ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.baseInfoLeftBox}>
                                    <span>姓名：</span>
                                </div>
                                <Input
                                    allowClear
                                    style={FormStyles.input_middle}
                                    placeholder="请填写姓名"
                                    value={this.state.user.username}
                                    onChange={e => {
                                        let inputText = e.target.value;
                                        this.setState({ user: {...this.state.user, username: inputText} })
                                    }}
                                    />
                            </div>
                            :
                            null
                    }

                    {/* {
                        this.state.user ?
                        <div style={VipUserSaveStyles.baseInfoItem}>
                            <div style={VipUserSaveStyles.baseInfoLeftBox}>
                                <span>手机号：</span>
                            </div>
                            <Input
                                allowClear
                                style={FormStyles.input_middle}
                                placeholder="请填写手机号"
                                value={this.state.user.phone}
                                onChange={e => {
                                    let inputText = e.target.value;
                                    this.setState({ user: {...this.state.user, phone: inputText} })
                                }}
                                />
                        </div>
                        :
                        null
                    } */}
                </div>
            </div>
        );
    }

    renderIdentifyBox() {
        // const uploadButton = (
        //     <div style={VipUserSaveStyles.uploadButton}>
        //         <Icon type={this.state.loading ? 'loading' : 'plus'} />
        //         <div> 上传</div>
        //     </div>
        // );

        // if (this.state.user) {
        //     return (
        //         <div style={VipUserSaveStyles.editRightBox}>
        //             <div style={VipUserSaveStyles.infoBox}>
        //                 <div style={VipUserSaveStyles.identifyItem}>
        //                     <div style={VipUserSaveStyles.identifyLeftBox}>
        //                         <span>身份证正面：</span>
        //                     </div>
        //                         <Upload
        //                         name="avatar"
        //                         listType="picture-card"
        //                         // className="avatar-uploader"
        //                         showUploadList={false}
        //                         beforeUpload={this.beforeUpload}
        //                         onChange={info => {
        //                             console.log('1-1');
        //                             this.handleUploadChange(info, 0);
        //                         }}   
        //                         >
        //                             {this.state.user.cardFront ? <img style={{width: 256, height: 108, objectFit: 'cover'}} src={this.state.user.cardFront} alt="avatar" /> : uploadButton}
        //                         </Upload>
        //                 </div>

        //                 <div style={VipUserSaveStyles.identifyItem}>
        //                     <div style={VipUserSaveStyles.identifyLeftBox}>
        //                         <span>身份证背面：</span>
        //                     </div>
        //                         <Upload
        //                             name="avatar"
        //                             listType="picture-card"
        //                             // className="avatar-uploader"
        //                             showUploadList={false}
        //                             beforeUpload={this.beforeUpload}
        //                             onChange={info => {
        //                                 console.log('2-2');
        //                                 this.handleUploadChange(info, 1);
        //                             }}
        //                             >
        //                                 {this.state.user.cardBack ? <img style={{width: 256, height: 108, objectFit: 'cover'}} src={this.state.user.cardBack} alt="avatar" /> : uploadButton}
        //                             </Upload>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // } else {
            return null;
        // }
    }

    renderVipStateBox() {
        return (
            <div style={VipUserSaveStyles.editLeftBox}>
                <div style={VipUserSaveStyles.vipStateBox}>

                    <Card title="当前时限卡信息" bordered={false} style={{ marginLeft: 200, minWidth: 250 }}>
                        <div style={VipUserSaveStyles.vipStateItem}>
                            <div style={VipUserSaveStyles.vipStateLeftBox}>
                                <span>开始时间：</span>
                            </div> 
                            <span>{(this.state.vipcard.limitstarttime === undefined || this.state.vipcard.limitstarttime === 0) ? '-' : LLCDateHelper.formatDate(this.state.vipcard.limitstarttime )  }</span>
                        </div>

                        <div style={VipUserSaveStyles.baseInfoItem}>
                            <div style={VipUserSaveStyles.vipStateLeftBox}>
                                <span>结束时间：</span>
                            </div>
                            <span>{(this.state.vipcard.limitendtime === undefined || this.state.vipcard.limitendtime === 0) ? '-' : LLCDateHelper.formatDate(this.state.vipcard.limitendtime) }</span>
                        </div>
                    </Card>
                </div>

                <div>

                    <Card title="当前次卡信息" bordered={false} style={{ marginLeft: 200, minWidth: 250 }}>
                        <div style={VipUserSaveStyles.vipStateItem}>
                            <div style={VipUserSaveStyles.vipStateLeftBox}>
                                <span>剩余次数：</span>
                            </div> 
                            <span>{(this.state.vipcard.resttimes === undefined || this.state.vipcard.resttimes === 0) ? '-' : this.state.vipcard.resttimes}</span>
                        </div>

                        <div style={VipUserSaveStyles.baseInfoItem}>
                            <div style={VipUserSaveStyles.vipStateLeftBox}>
                                <span>开始时间：</span>
                            </div>
                            <span>{(this.state.vipcard.starttime === undefined || this.state.vipcard.starttime === 0) ? '-' : LLCDateHelper.formatDate(this.state.vipcard.starttime) }</span>
                        </div>

                        <div style={VipUserSaveStyles.baseInfoItem}>
                            <div style={VipUserSaveStyles.vipStateLeftBox}>
                                <span>结束时间：</span>
                            </div>
                            <span>{(this.state.vipcard.endtime === undefined || this.state.vipcard.endtime === 0) ? '-' : LLCDateHelper.formatDate(this.state.vipcard.endtime)}</span>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

    renderHandleVipContent() {
        const { viptype } = this.state;
        if (viptype === 1) {
            return (
                <div>
                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>私教课程：</span>
                        </div>

                        <div onClick={() => { this.setState({ modalCourseShowing: true }) }}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要开通的私教课"
                                showArrow={false}
                                value={this.state.course ? this.state.course.coursename : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>绑定教练：</span>
                        </div>

                        <div onClick={() => { this.setState({ modalCoachShowing: true }) }}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要绑定的教练"
                                showArrow={false}
                                value={this.state.coach ? this.state.coach.username : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.course ?
                            <div>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>次数：</span>
                                    </div>
                                    <InputNumber
                                        min={0}
                                        placeholder='课程次数' 
                                        value={this.state.course.times}
                                        onChange={number => {
                                            this.setState({ course: {...this.state.course, times: number} })
                                        }}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.infoBox}>
                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>开始时间：</span>
                                        </div>
                                        <DatePicker 
                                            size='default' 
                                            placeholder='私教课生效时间' 
                                            onChange={(date, dateString) => {
                                                let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                this.setState({
                                                    course: {
                                                        ...this.state.course, 
                                                        starttime
                                                    }
                                                });
                                            }}
                                            disabledDate={currentDate => {
                                                let todayTimestamp = new Date(new Date().toLocaleDateString()).getTime() / 1000
                                                if (parseInt(currentDate.valueOf() / 1000) < todayTimestamp) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                            value={this.state.course.starttime ? moment.unix(this.state.course.starttime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                                            <span>结束时间：</span>
                                        </div>
                                        <DatePicker
                                            size='default' 
                                            placeholder='私教课过期时间' 
                                            onChange={(date, dateString) => {
                                                let endtime = LLCDateHelper.timeStrToTimestamp(dateString);
                                                endtime = endtime + 24 * 60 * 60 - 1;
                                                this.setState({
                                                    course: {...this.state.course, endtime}
                                                });
                                            }}
                                            value={this.state.course.endtime ? moment.unix(this.state.course.endtime) : undefined}
                                        />
                                    </div>

                                    <div style={VipUserSaveStyles.baseInfoItem}>
                                        <div style={VipUserSaveStyles.baseInfoLeftBox}>
                                            <span>实际支付（元）：</span>
                                        </div>

                                        <InputNumber
                                            min={0}
                                            placeholder="实际售价"
                                            value={priceYuan(this.state.pay.payment)}
                                            onChange={number => {
                                                this.setState({ pay: {...this.state.pay, payment: priceFen(number)} })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            );
        } else if (viptype === 2) { // 会员卡
            return (
                <div>
                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>会员卡：</span>
                        </div>

                        <div onClick={this.showVipCardMoldList}>
                            <Select
                                style={FormStyles.input_middle}
                                placeholder="请选择要开通的会员卡"
                                showArrow={false}
                                value={this.state.mold ? this.state.mold.cardname : undefined}
                                dropdownStyle={{ display: "none" }}>
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.mold && this.state.mold.cardtype === 2 ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>次数：</span>
                                </div>
                                <InputNumber
                                    min={0}
                                    placeholder='课程次数' 
                                    value={this.state.mold.cardtimes}
                                    onChange={number => {
                                        this.setState({ mold: {...this.state.mold, cardtimes: number} })
                                    }}
                                />
                            </div>
                            :
                            null
                    }

                    {
                        this.state.mold ?
                            <div style={VipUserSaveStyles.baseInfoItem}>
                                <div style={VipUserSaveStyles.vipCardLeftBox}>
                                    <span>时限（天）：</span>
                                </div>
                                <span>{this.state.mold.cardduration}</span>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.mold ?
                            <div style={VipUserSaveStyles.infoBox}>
                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>开始时间：</span>
                                    </div>
                                    <DatePicker 
                                        style={{ width: 200 }}
                                        placeholder='会员卡生效时间' 
                                        onChange={(date, dateString) => {
                                            let starttime = LLCDateHelper.timeStrToTimestamp(dateString);
                                            this.setState({
                                                newCard: {
                                                    ...this.state.newCard, 
                                                    starttime,
                                                    endtime: starttime + (60 * 60 * 24 * this.state.mold.cardduration) -1
                                                }
                                            });
                                        }}
                                        value={this.state.newCard.starttime ? moment.unix(this.state.newCard.starttime) : undefined}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.vipCardLeftBox}>
                                        <span>结束时间：</span>
                                    </div>
                                    <DatePicker
                                        style={{ width: 200 }}
                                        placeholder='会员卡过期时间' 
                                        onChange={(date, dateString) => {
                                            let endtime = LLCDateHelper.timeStrToTimestamp(dateString);
                                            endtime = endtime + 24 * 60 * 60 - 1;
                                            this.setState({
                                                newCard: {...this.state.newCard, endtime}
                                            });
                                        }}
                                        value={this.state.newCard.endtime ? moment.unix(this.state.newCard.endtime) : undefined}
                                    />
                                </div>

                                <div style={VipUserSaveStyles.baseInfoItem}>
                                    <div style={VipUserSaveStyles.baseInfoLeftBox}>
                                        <span>实际支付（元）：</span>
                                    </div>

                                    <InputNumber
                                        min={0}
                                        placeholder="实际售价"
                                        value={priceYuan(this.state.pay.payment, 100)}
                                        onChange={number => {
                                            this.setState({ pay: {...this.state.pay, payment: priceFen(number)} })
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            );
        } else {

        }
    }

    renderVipCardBox() {

        return (
            <div style={VipUserSaveStyles.editRightBox}>
                <div style={VipUserSaveStyles.infoBox}>

                    <div style={VipUserSaveStyles.baseInfoItem}>
                        <div style={VipUserSaveStyles.vipCardLeftBox}>
                            <span>办理类型：</span>
                        </div>

                        <Select
                            style={FormStyles.input_middle}
                            placeholder="请选择办理类型"
                            value={this.state.viptype}
                            onChange={(e) => { 
                                if (this.state.viptype !== e) {
                                    this.setState({ mold: undefined, course: undefined });
                                }
                                this.setState({ viptype: e });
                            }}>
                            <Option value={2}>开卡</Option>
                            <Option value={1}>私教</Option>
                        </Select>
                    </div>

                    {
                        this.renderHandleVipContent()
                    }
                </div>
            </div>
        );
    }

    renderOperate() {
        let buttonTitle = '';
        switch (this.state.currentStep) {
            case 0:
                buttonTitle = '下一步';
                break;
            case 1:
                buttonTitle = '提交';
                break;
            case 2:
                buttonTitle = '完成';
                break;
            default:

        }

        let visible = false;
        if (this.state.currentStep === 0 && this.state.user) {
            visible = true;
        } else if (this.state.currentStep === 1 && (this.state.mold || this.state.course)) {
            visible = true;
        } else if (this.state.currentStep === 2) {
            visible = false;
        }

        return (
            <div style={VipUserSaveStyles.operateBox}>

                {
                    this.state.currentStep === 1 ?
                        <Button
                            style={VipUserSaveStyles.clearButton}
                            onClick={this.handlePreStepButtonClick}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        >上一步</Button>
                        :
                        null
                }

                {
                    visible ?
                        <Button
                            type="primary"
                            style={VipUserSaveStyles.saveButton}
                            onClick={this.handleSaveButtonClick}
                        >{buttonTitle}</Button>
                        :
                        null
                }
            </div>
        );
    }

    renderHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                
            </div>
          ),
          onBack: () => {
            this.props.history.goBack();
          }
        });
    }

    render() {
        
        return (
            
            <Spin spinning={this.state.loading}>
            <div style={PageStyles.pageContainer}>
                {
                    this.renderHeader()
                }
                
                <div style={TableStyles.tableBox}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '60%'}}>
                            <Steps current={this.state.currentStep}>
                                <Step title="确认信息"  />
                                <Step title="办理会员" />
                                <Step title="完成" />
                            </Steps>
                        </div>
                    </div>

                    {
                        this.renderStepContent()
                    }

                    {
                        this.renderOperate()
                    }
                </div>

                <Modal
                    title="输入完整手机号搜索用户"
                    visible={this.state.userListVisible}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ userListVisible: false }) }}
                    footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <UserSelectView 
                        selectType={2}
                        initialQuery={false}
                        selectedUser={this.selectedUser} />
                </Modal>

                <Modal
                    visible={this.state.vipCardMoldListVisible}
                    onOk={() => { this.setState({ vipCardMoldListVisible: false }) }}
                    onCancel={() => { this.setState({ vipCardMoldListVisible: false }) }}
                    // footer={null}
                    width={"956px"}
                    bodyStyle={{ padding: "20px 48px 48px 48px" }}
                >
                    <VipCardMoldSelectView selectedVipCardMold={this.selectedVipCardMold} />
                </Modal>

                <Modal
                    centered={true}
                    visible={this.state.modalCourseShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCourseShowing: false }) }}
                    footer={null}
                >
                    <CourseSelectView 
                        type={2}
                        onRecordSelect={this.handleCourseChanged} />
                </Modal>

                <Modal
                    centered={true}
                    visible={this.state.modalCoachShowing}
                    destroyOnClose={true}
                    width={"956px"}
                    onCancel={() => { this.setState({ modalCoachShowing: false }) }}
                    footer={null}
                >
                    <CoachSelectView 
                        onRecordSelect={this.handleCoachChanged} />
                </Modal>
                    
            </div>
            </Spin>
        )
    }
}

const mapStateToProps = store => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
    return {
      queryVipUserList: params => {
        dispatch(vipUserActions.queryVipUserList(params));
      },
      changeLoading: params => {
        dispatch(vipUserActions.changeLoading(params));
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AddVipUserController)