import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions';
import cookie from 'cookie-lza';

import * as Apis from '../constants/apiConstants';
import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants";
import { launchPOSTRequest } from '../util/request';
import md5 from'js-md5';
import { message } from 'antd';
import { kAdmin_User_Auth } from '../constants/authConstants';

// cookie.clear();

const cacheUser = {
    accesstoken: cookie.get('accesstoken'),
    refreshtoken: cookie.get('refreshtoken'),
    status: Number(cookie.get('status')),
    venueSelected: Number(cookie.get('venueSelected')),
    user: {
        username: cookie.get('username'),
        authrole: cookie.get('authrole'),
        userauth: cookie.get('userauth'),
        
    },
    entervenue: {
        venueid: cookie.get('venueid'),
        venuelogo: cookie.get('venuelogo'),
        venuename: cookie.get('venuename')
    }
};

export const actions = {
    loginRequest: createAction('login/launchLoginRequest'),
    queryVenueList: createAction('login/queryVenueList'),
    enterVenueRquest: createAction('login/enterVenueRquest'),
    recordSelectedVenue: createAction('recordSelectedVenue'),
    logoutUser: createAction('logoutUser'),
    changeLoading: createAction('changeLoading')
};

const loginSuccessed = createAction('loginSuccessed');
const recordVenueList = createAction('recordVenueList');
const recordSelectedVenue = createAction('recordSelectedVenue');
const changeLoading = createAction('changeLoading')



const effects = {
    launchLoginRequest: function* ({ payload: { accountname, passwd, remember }, type }) {
        const params = {
            accountname,
            passwd: md5(passwd)
        };
        yield put(changeLoading({ loading: true }))
        console.log('Apis.MANAGER_LOGIN: '+ Apis.MANAGER_LOGIN);
        const response = yield call(launchPOSTRequest, Apis.MANAGER_LOGIN, params);
        yield put(changeLoading({ loading: false }))
        if (
            response.responseData.succ === RESPONSE_CODE.success &&
            response.responseData.result.status === SERVICE_CODE.Successed
        ) {
            
            cookie.set("accesstoken", response.responseData.result.accesstoken, remember ? 5 : undefined);
            cookie.set("refreshtoken", response.responseData.result.refreshtoken, remember ? 5 : undefined);
            cookie.set("status", 1, remember ? 5 : undefined);
            cookie.set("username", response.responseData.result.user.username, remember ? 5 : undefined);
            cookie.set("authrole", response.responseData.result.user.authrole, remember ? 5 : undefined);
            cookie.set("userauth", response.responseData.result.user.userauth, remember ? 5 : undefined);

            if (remember) {
                cookie.set("remember", 1, remember ? 5 : undefined);
            }

            if (response.responseData.result.entervenue) {

                // cookie.set("venuelogo", response.responseData.result.entervenue.venuelogo, remember ? 5 : undefined);
                yield put(recordSelectedVenue(response.responseData.result));
            }
            
            yield put(loginSuccessed(response.responseData.result));
        } else {
            message.error(response.responseData.msg);
        }
    },
    queryVenueList: function* ({ payload }) {
        
        yield put(changeLoading({ loading: true }))
        const params = {
            
        };
        const response = yield call(launchPOSTRequest, Apis.QUERY_VENUE_LIST, params);

        if (
            response.responseData.succ === RESPONSE_CODE.success &&
            response.responseData.result.status === SERVICE_CODE.Successed
        ) {
            yield put(changeLoading({ loading: false }))
            yield put(recordVenueList(response.responseData.result));
        } else {
            message.error(response.responseData.msg);
            yield put(changeLoading({ loading: false }))
        }
    },
    enterVenueRquest: function* ({ payload: { venueid }, type }) {
        yield put(changeLoading({ loading: true }))
        const params = {
            venueid
        };
        const response = yield call(launchPOSTRequest, Apis.ENTER_VENUE, params);

        if (
            response.responseData.succ === RESPONSE_CODE.success &&
            response.responseData.result.status === SERVICE_CODE.Successed
        ) {
            yield put(changeLoading({ loading: false }))
            yield put(recordSelectedVenue(response.responseData.result));
        } else {
            message.error(response.responseData.msg);
            yield put(changeLoading({ loading: false }))
        }
    },
    *changeLoading({ payload }) {
        payload.loading = payload.loading || false;
        yield put(changeLoading({ payload: payload.loading }))
    }
};

export const watchers = [
    function* () {
        yield takeLatest(actions.loginRequest, effects.launchLoginRequest);
    },
    function* () {
        yield takeLatest(actions.enterVenueRquest, effects.enterVenueRquest);
    },
    function* () {
        yield takeLatest(actions.queryVenueList, effects.queryVenueList);
    }
]

/* 
    reducer

    loginResult五个字段: 
    username accesstoken userid refreshtoken portrait
*/
export const loginStore = handleActions(
    {
        loginSuccessed(state, { payload: loginResult }) {
            let venueSelected = 0;

            if (loginResult.user.authrole === kAdmin_User_Auth) {
                if (loginResult.venues && loginResult.venues.length === 1) {
                    venueSelected = 1;
                }
            } else {
                venueSelected = 1;
            }

            return {
                ...state,
                loginState: 1,
                ...loginResult,
                venueSelected,
                venueList: loginResult.venues,

                loading: false
            }
        },
        recordVenueList(state, { payload: result }) {
            return {
                ...state,
                venueList: result.list,
                loading: false
            }
        },
        recordSelectedVenue(state, { payload: result }) {
            console.log(result);
            
            let remember = Number(cookie.get('status'));
            cookie.set("venueid", result.entervenue.venueid, remember ? 5 : undefined);
            cookie.set("venuelogo", result.entervenue.venuelogo, remember ? 5 : undefined);
            cookie.set("venuename", result.entervenue.venuename, remember ? 5 : undefined);
            cookie.set("venueSelected", 1, remember ? 5 : undefined);

            cookie.set("venueid", result.entervenue.venueid, 5);
            cookie.set("venuelogo", result.entervenue.venuelogo, 5);
            cookie.set("venuename", result.entervenue.venuename, 5);
            cookie.set("venueSelected", 1, 5);
            
            return {
                ...state,
                venueSelected: 1,
                venueid: result.venueid,
                enterVenue: result.entervenue,
                loading: false
            }
        },
        // 注销,清空所有store中的值
        logoutUser(state) {
            return {
                ...state,
                loginState: 0,
                user: undefined,
                enterVenue: undefined,
                venueid: undefined,
                accesstoken: '',
                refreshtoken: '',

                venueList: [],
                venueSelected: 0
            }
        },
        changeLoading(state, { payload: result }) {
            return {
                ...state,
                loading: result.loading
            }
        }
    },
    {
        loginState: cacheUser.status || 0,
        
        accesstoken: cacheUser.accesstoken || '',
        refreshtoken: cacheUser.refreshtoken || '',

        // username: user.username || '',
        // userid: user.userid || '',
        // userauth: user.userauth || 0,
        // portrait: user.portrait || '',

        user: cacheUser.user || undefined,

        venueList: [],
        venueSelected: cacheUser.venueSelected || undefined,
        
        enterVenue: cacheUser.entervenue || undefined,
        loading: false
    }
);

// 修改管理员密码
export async function launchChangePasswd(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        params.oldpasswd = md5(params.oldpasswd);
        params.newpasswd = md5(params.newpasswd);
        const response = await launchPOSTRequest(Apis.CHANGE_PASSWD, params);
        if (
            response.responseData.succ === RESPONSE_CODE.success &&
            response.responseData.result.status === SERVICE_CODE.Successed
        ) {
            success(response);
        } else {
            failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(rejectedValue);
    } 
  }