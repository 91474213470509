import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight, PictureItem, PictureList
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class AppointExperience extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{...PageStyles.pageContainer, paddingLeft: 20, paddingRight: 20}}>
                <ArtTitle text="体验课预约" />

                <SectionTitle text="一.查看体验课预约情况" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/790917c181ed444c99eca6ca57c69d54.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“体验课预约”后，在右侧就会展示当前场馆的体验课预约列表。</span>} />

                <SectionTitle text="二.添加用户体验课" />
                <NormalContent text={<span>1.在体验课预约页面，点击右上角的“<Highlight text="新增约课" />”按钮，弹出预约信息填充区域。</span>} />
                <NormalContent text={<span>2.点击“选择体验课程”，会展示当前场馆所有课程，选中想要预约的体验课，点击“确定”。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/3738e96288be443eb1b9b149811a8f8b.png" />
                <NormalContent text={<span>3.点击学员框，为此次体验课选择上课用户。</span>} />
                <NormalContent text={<span>4.点击教练框，为此次体验课预约选择教练。</span>} />
                <NormalContent text={<span>5.点击上课日期，选择此次体验课的上课日期。</span>} />
                <NormalContent text={<span>6.在日期选择完成后，下方会出现所选教练在这一天的时间安排。其中绿色区域为可选时间段，点击绿色区域后，可以选择具体上课时间。</span>} />
                <NormalContent text={<span>7.点击提交，提示更新成功后，完成学员体验课预约。</span>} />
                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointExperience);
