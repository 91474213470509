import React from 'react';
import { Layout, Menu, message, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import Exception from 'ant-design-pro/lib/Exception';
import 'ant-design-pro/dist/ant-design-pro.css';
import cookie from 'cookie-lza';
// css
import '../../../style/background.css'
import creatHistory from 'history/createHashHistory' 
// import { actions as loginActions, launchChangePasswd } from '../../redux/loginModel'
// import { actions as utilActions } from '../../redux/util';
// import { actions as queryActions } from '../../redux/query-model'
import { PageRoutes } from '../../../constants/help-route-constants';

import ManagerLogin from './login/manager-login';
import CreateVenue from './venue/creat-venue';
import ChangeVenue from './venue/change-venue';
import MainManager from './manager/main-manager';
import SubManager from './manager/sub-manager';
import AddCoach from './coach/add-coach';
import EditCoach from './coach/edit-coach';
import CoachFunc from './coach/coach-func';
import EditCourse from './course/edit-course';
import Timetable from './course/timetable';
import AppointClass from './course/appoint-class';
import AppointPrivate from './course/appoint-private';
import AppointPrivateUnhandle from './course/appoint-private-unhandle';
import AppointExperience from './course/appoint-experience';
import VipCard from './vip/vip-card';
import VipUser from './vip/vip-user';
import NewVip from './vip/new-vip';
import RenewVip from './vip/renew-vip';
import VipOperate from './vip/vip-operate';
import VipChangegrade from './vip/vip-changegrade';
import VipCourse from './vip/vip-course';
import AppointBanner from './market/appoint-banner';
import UseFlow from './flow/use-flow';

import { BackgroundStyles } from '../../../style/background-styles';
import { PageStyles } from '../../../style/common-styles';


const MAIN = PageRoutes.MAIN;
const MAIN_LISTS = MAIN.children;

const { Sider, Content } = Layout;
const confirm = Modal.confirm;
const { SubMenu } = Menu;

class BackgroundController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  // 渲染信息栏
  renderInfoBar() {
    return (
      <div style={BackgroundStyles.topBox}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '200px' }}>
          <span style={{ cursor: 'pointer', marginLeft: 24 }} onClick={() => {
            window.open(`http://${window.location.host}/help`, '_blank');
          }}>帮助</span>
        </div>


        <div style={BackgroundStyles.operateBox}>

          {/* 用户信息 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>{this.props.user ? this.props.user.username : ''}</span>
            <div style={BackgroundStyles.operateItemSep} />
            <a
              onClick={this.handleChangePasswdClick}
              style={BackgroundStyles.operateItem}
            >修改密码</a>
            <div style={BackgroundStyles.operateItemSep} />
            <a
              onClick={this.handleLogoutClick}
              style={BackgroundStyles.operateItem}
            >退出</a>
          </div>
        </div>
      </div>
    );
  }

  // 渲染菜单
  renderMenu() {
    return (
      <Menu
        style={{ textAlign: 'left' }}
        // theme="dark"
        mode="inline"
        defaultSelectedKeys={[this.props.match.params.rootPath]}
        onClick={({ item, key, keyPath, domEvent }) => {
          window.scrollTo(0, 0);
        }}
      >

        <Menu.Item key={MAIN_LISTS.FLOW.children.USE_FLOW.path}>
          <MenuItem path={MAIN_LISTS.FLOW.children.USE_FLOW} />
        </Menu.Item>

        <SubMenu
            key="login-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>一.</span>
                <span>登录</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.LOGIN.children.MANAGER_LOGIN.path}>
                <MenuItem path={MAIN_LISTS.LOGIN.children.MANAGER_LOGIN} />
            </Menu.Item>

            {/* <Menu.Item key={MAIN_LISTS.VENUE.children.CHANGE_VENUE.path}>
                <MenuItem path={MAIN_LISTS.VENUE.children.CHANGE_VENUE} />
            </Menu.Item> */}
        </SubMenu>

        <SubMenu
            key="venue-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>二.</span>
                <span>场馆</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.VENUE.children.CREATE_VENUE.path}>
                <MenuItem path={MAIN_LISTS.VENUE.children.CREATE_VENUE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.VENUE.children.CHANGE_VENUE.path}>
                <MenuItem path={MAIN_LISTS.VENUE.children.CHANGE_VENUE} />
            </Menu.Item>
        </SubMenu>

        <SubMenu
            key="manager-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>三.</span>
                <span>账户</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.MANAGER.children.MAIN_MANAGER.path}>
                <MenuItem path={MAIN_LISTS.MANAGER.children.MAIN_MANAGER} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.MANAGER.children.SUB_MANAGER.path}>
                <MenuItem path={MAIN_LISTS.MANAGER.children.SUB_MANAGER} />
            </Menu.Item>
        </SubMenu>

        <SubMenu
            key="coach-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>四.</span>
                <span>教练</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.COACH.children.ADD_COACH.path}>
                <MenuItem path={MAIN_LISTS.COACH.children.ADD_COACH} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COACH.children.EDIT_COACH.path}>
                <MenuItem path={MAIN_LISTS.COACH.children.EDIT_COACH} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COACH.children.COACH_FUNC.path}>
                <MenuItem path={MAIN_LISTS.COACH.children.COACH_FUNC} />
            </Menu.Item>
        </SubMenu>

        <SubMenu
            key="course-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>五.</span>
                <span>课程</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.COURSE.children.EDIT_COURSE.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.EDIT_COURSE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COURSE.children.TIMETABLE.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.TIMETABLE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COURSE.children.APPOINT_CLASS.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.APPOINT_CLASS} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COURSE.children.APPOINT_PRIVATE.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.APPOINT_PRIVATE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COURSE.children.APPOINT_PRIVATE_UNHANDLE.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.APPOINT_PRIVATE_UNHANDLE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.COURSE.children.APPOINT_EXPERIENCE.path}>
                <MenuItem path={MAIN_LISTS.COURSE.children.APPOINT_EXPERIENCE} />
            </Menu.Item>
        </SubMenu>

        <SubMenu
            key="vipuser-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>六.</span>
                <span>会员</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.VIP.children.VIP_CARD.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.VIP_CARD} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.VIP.children.VIP_USER.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.VIP_USER} />
            </Menu.Item>
            
            <Menu.Item key={MAIN_LISTS.VIP.children.NEW_VIP.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.NEW_VIP} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.VIP.children.RENEW_VIP.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.RENEW_VIP} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.VIP.children.VIP_OPERATE.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.VIP_OPERATE} />
            </Menu.Item>
            
            <Menu.Item key={MAIN_LISTS.VIP.children.VIP_CHANGEGRADE.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.VIP_CHANGEGRADE} />
            </Menu.Item>

            <Menu.Item key={MAIN_LISTS.VIP.children.VIP_COURSE.path}>
                <MenuItem path={MAIN_LISTS.VIP.children.VIP_COURSE} />
            </Menu.Item>
            
        </SubMenu>

        <SubMenu
            key="market-sub-menu"
            title={
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>七.</span>
                <span>营销</span>
            </div>
            }
        >
            <Menu.Item key={MAIN_LISTS.MARKET.children.APPOINT_BANNER.path}>
                <MenuItem path={MAIN_LISTS.MARKET.children.APPOINT_BANNER} />
            </Menu.Item>
        </SubMenu>
        
      </Menu>
    );
  }

  render() {
    console.log(this.props.match.params.rootPath);
    const NotMatch = <div style={PageStyles.pageContainer}>
      <Exception type="404" backText="返回首页" redirect={'/help/use-flow'} />
    </div>;
    
    return (
      <Layout>
        <Sider style={{...BackgroundStyles.slideBar, backgroundColor: '#fff'}}>
          
          {
            this.renderMenu()
          }
        </Sider>
        <Layout>

        <Content style={{ display: 'flex', position: 'relative', paddingLeft: '200px', width: '100%', height: '100%', flexDirection: 'column' }}>
        <div style={{ 
              display: 'flex',
              padding: 0, 
              boxSizing: 'border-box', 
              background: '#fff', 
              width: '100%',
              height: '100%',
            }}>
            <Route path={`/${MAIN.path}/:rootPath?/:subPath?`}
              render={(props) => {
                switch (this.props.match.params.rootPath) {

                  case MAIN_LISTS.FLOW.children.USE_FLOW.path: 
                    return <UseFlow {...props}/>;

                  case MAIN_LISTS.LOGIN.children.MANAGER_LOGIN.path: 
                      return <ManagerLogin {...props}/>;

                  case MAIN_LISTS.VENUE.children.CREATE_VENUE.path: 
                      return <CreateVenue {...props}/>;
                  case MAIN_LISTS.VENUE.children.CHANGE_VENUE.path: 
                    return <ChangeVenue {...props}/>;

                  case MAIN_LISTS.MANAGER.children.MAIN_MANAGER.path: 
                    return <MainManager {...props}/>;
                  case MAIN_LISTS.MANAGER.children.SUB_MANAGER.path: 
                    return <SubManager {...props}/>;

                  case MAIN_LISTS.COACH.children.ADD_COACH.path: 
                    return <AddCoach {...props}/>;
                  case MAIN_LISTS.COACH.children.EDIT_COACH.path: 
                    return <EditCoach {...props}/>;
                  case MAIN_LISTS.COACH.children.COACH_FUNC.path: 
                    return <CoachFunc {...props}/>;

                  case MAIN_LISTS.COURSE.children.EDIT_COURSE.path: 
                    return <EditCourse {...props}/>;
                  case MAIN_LISTS.COURSE.children.TIMETABLE.path: 
                    return <Timetable {...props}/>;
                  case MAIN_LISTS.COURSE.children.APPOINT_CLASS.path: 
                    return <AppointClass {...props}/>;
                  case MAIN_LISTS.COURSE.children.APPOINT_PRIVATE.path: 
                    return <AppointPrivate {...props}/>;
                  case MAIN_LISTS.COURSE.children.APPOINT_PRIVATE_UNHANDLE.path: 
                    return <AppointPrivateUnhandle {...props}/>;
                  case MAIN_LISTS.COURSE.children.APPOINT_EXPERIENCE.path: 
                    return <AppointExperience {...props}/>;
                    
                  case MAIN_LISTS.VIP.children.VIP_CARD.path: 
                    return <VipCard {...props}/>;
                  case MAIN_LISTS.VIP.children.VIP_USER.path: 
                    return <VipUser {...props}/>;
                  case MAIN_LISTS.VIP.children.NEW_VIP.path: 
                    return <NewVip {...props}/>;
                  case MAIN_LISTS.VIP.children.RENEW_VIP.path: 
                    return <RenewVip {...props}/>;
                  case MAIN_LISTS.VIP.children.VIP_OPERATE.path: 
                    return <VipOperate {...props}/>;
                  case MAIN_LISTS.VIP.children.VIP_CHANGEGRADE.path: 
                    return <VipChangegrade {...props}/>;
                  case MAIN_LISTS.VIP.children.VIP_COURSE.path: 
                    return <VipCourse {...props}/>;

                  case MAIN_LISTS.MARKET.children.APPOINT_BANNER.path: 
                    return <AppointBanner {...props}/>;
                    
                  default:
                    return NotMatch;
                }
              }}
            />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
  componentDidMount() {
    
  }
  /* 头部显示标题开始 */
  // componentWillMount() {
  //   const { history } = this.props;
  //   history.listen(this.handleLocationChange);
  //   this.handleLocationChange(history.location);
  // }

  handleLocationChange = location => {
    // 在这分析match,按照常量url进行匹配,不是去404
    // 先搜索本身看本身的名字,看是否有父级,如果有父级直接把父级的url放到返回中.
    // 如果有上一级,绑定上一级的链接到返回三角上
    let paths = location.pathname.split("/");
    let headerName = '',rootPath = '',localPath = '';
    localPath = paths[paths.length - 1];
    let PathConstant = MAIN_LISTS.children;
    for (let key in PathConstant)
      if (PathConstant[key].path === paths[paths.length - 1]) {
        headerName = PathConstant[key].name;
        if (PathConstant[key].rootPath) {
          // 删除当前path,删除前面一个空缺和项目根路由
          paths.pop();
          paths.shift();
          paths.shift();
          rootPath = paths.join('/');
        }
      }
    // this.props.setPageHiddenState(localPath)
    // this.props.setHeaderContent({ title: headerName, rootPath })
  }

  handleHistoryClick = () => {
    this.props.history.push(`/${MAIN.path}/${this.parentPath}`);
  }
  /* 头部显示标题结束 */

  // 注销退出,退回登录页
  handleLogoutClick = () => {

    let that = this;
    confirm({
      title: '确定要退出吗？',
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        that.props.logoutUser(that);
      },
      onCancel() {
        
      },
    });
  }
}

const mapStateToProps = state => {

  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundController);


const MenuItem = (props) => {
  return (
    <Link to={'/' + MAIN.path + '/' + props.path.path}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {
          props.type === 'img' ?
            <img src={props.src} style={BackgroundStyles.menuIcon} alt="" />
            :
            null
        }
        
        <span>{props.path.name}</span>
      </div>
    </Link>
  );
}