import React from 'react';
import { Route } from 'react-router-dom';

import { PageRoutes, MAIN, MAIN_LISTS } from '../../../constants/routeConstants';
import List from './vipuser-list-controller';
import EDIT from './vipuser-save-controller';
import DETAIL from './vipuser-detail-controller';
import { PageStyles } from '../../../style/common-styles';

const NAMESPACE = MAIN.children.VIPUSER_LIST;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      <Route
        path={`${baseUrl}/${children.VIPUSER_DETAIL.path}`}
        component={DETAIL} />
      <Route
        path={`${baseUrl}/${children.SAVE_VIPUSER.path}`}
        component={EDIT} />
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

