// desc-list-controller.js
import React from 'react';
import { message, Spin } from 'antd'
import { launchGetDesc } from '../../redux/desc-model';
import '../../style/page/desc/desc-show.css'
class DescShowView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      descitems: [],
      isLoading: true
    };
  }
  render() {
    let pictureAndContentList = []
    this.state.descitems.forEach(item => {
      if (item.itemtype === 1) {
        pictureAndContentList.push(
          <div className="desc-show-item">
            <p className="desc-content">{item.itemcontent}</p>
          </div>
        )
      } else if (item.itemtype === 2) {
        pictureAndContentList.push(
          <div className="desc-show-item desc-image-container">
            <img className="desc-image" src={item.itemurl} alt="社群详情图"></img>
          </div>
        )
      }
    })
    return (
      <div>
        {this.state.isLoading ? (<Spin/>) : pictureAndContentList}
      </div>
    );
  }
  componentDidMount = () => {
    // 开始loading
    this.setState({
      isLoading: true
    })
    let params = {
      id: this.props.descid,
      type: this.props.type
    }
    launchGetDesc(
      params,
      res => { 
        // loading结束
        if (res.responseData.result.status === -1 || !res.responseData.result.desc) {
          message.error('图文详情未进行编写')
          this.setState({ isLoading: false })
        }
        else
          this.setState({
            descitems: res.responseData.result.desc.descitems,
            isLoading: false
          })
      },
      err => { message.error(err) }
    )
  }
  
}
export default DescShowView;