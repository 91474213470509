export const ManagerListStyles = {
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    stateButton: {
        marginLeft: 10
    }
}