import React from 'react';
import { Table, Statistic, Divider, Modal, DatePicker, Select, Input, Button, List, Avatar, Tag, Rate, Dropdown, message, Menu, Card, Spin, Empty, Radio } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment'

// import { Bar } from '@antv/g2plot';
import LLCDateHelper from 'date-helper'
import Decimal from 'decimal.js';
import { priceYuan } from '../../../../util/helper/number-helper';
import { MAIN, MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import { filterParams } from '../../../../util/helper/request-helper';
import FormItem from '../../../../view/common/form-item';
import { actions as queryActions } from '../../../../redux/query-model';
import { TableOperateBarStyles, TableStyles, TableSearchStyles, FormStyles, PageStyles } from '../../../../style/common-styles';
import { isEmpty } from '../../../../util/helper/string-helper';
import { launchGetStaticAppointOverview } from '../../../../redux/static-model';
import { kAdmin_User_Auth, UserAuth } from '../../../../constants/authConstants';
import { launchGetCoachFinanceList, launchGetCourseTimesList } from '../../../../redux/finance-model';
import courseTimes from '.';

const NAMESPACE = MAIN.children.STATIC_COURSE_TIMES;

const { RangePicker } = DatePicker;

class CourseStaticListController extends BaseController {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            mode: 1,
            list: [],
            dataInfo: {}
        };

        let that = this;
        this.columns = [
            { title: '会员', width: '16%', dataIndex: 'username', key: 'username', align: 'center' },
            // { title: '手机号', width: 120, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '私教卡销课次数', width: '16%', dataIndex: 'privatetimes', key: 'privatetimes', align: 'center', 
                render: privatetimes => {
                    let str = '-';
                    if (privatetimes > 0) str = privatetimes;
                    return <span>{str}</span> 
                }
            },
            
            { title: '团课销私教卡次数', width: '16%', dataIndex: 'courseprivatetimes', key: 'courseprivatetimes', align: 'center', 
                render: courseprivatetimes => {
                    let str = '-';
                    if (courseprivatetimes > 0) str = courseprivatetimes;
                    return <span>{str}</span> 
                }
            },
            { title: '会员卡销卡次数', width: '16%', dataIndex: 'coursetimes', key: 'coursetimes', align: 'center', 
                render: coursetimes => {
                    let str = '-';
                    if (coursetimes > 0) str = coursetimes;
                    return <span>{str}</span> 
                }
            },
            { title: '总销卡次数', width: '16%', key: 'totaltimes', align: 'center', 
                render: courseTimes => {

                    let totaltimes = courseTimes.privatetimes + courseTimes.coursetimes + courseTimes.courseprivatetimes;

                    let str = '-';
                    if (totaltimes > 0) str = totaltimes;
                    return <span>{str}</span> 
                }
            },
            { title: '操作', width: '20%', key: 'operate', align: 'center', 
                render: (courseTimes) => {
                    return (
                        <a 
                            href="javascript:void(0)" 
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `${this.props.match.url}/${NAMESPACE.children.VIP_DETAIL.path}`,
                                    state: {
                                        courseTimes,
                                        selecteddate: that.state.selecteddate
                                    }
                                });
                            }
                        }>详情</a> 
                    );
                }
            }
        ];

        this.coachColumns = [
            { title: '教练', width: 80, dataIndex: 'coachname', key: 'coachname', align: 'center' },
            // { title: '手机号', width: 120, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '私教卡销课次数', width: 120, dataIndex: 'privatetimes', key: 'privatetimes', align: 'center', 
                render: privatetimes => {
                    let str = '-';
                    if (privatetimes > 0) str = privatetimes;
                    return <span>{str}</span> 
                }
            },
            
            { title: '团课销私教卡次数', width: 120, dataIndex: 'courseprivatetimes', key: 'courseprivatetimes', align: 'center', 
                render: courseprivatetimes => {
                    let str = '-';
                    if (courseprivatetimes > 0) str = courseprivatetimes;
                    return <span>{str}</span> 
                }
            },
            { title: '会员卡销卡次数', width: 120, dataIndex: 'coursetimes', key: 'coursetimes', align: 'center', 
                render: coursetimes => {
                    let str = '-';
                    if (coursetimes > 0) str = coursetimes;
                    return <span>{str}</span> 
                }
            },
            { title: '总销卡次数', width: 120, key: 'totaltimes', align: 'center', 
                render: courseTimes => {

                    let totaltimes = courseTimes.privatetimes + courseTimes.coursetimes + courseTimes.courseprivatetimes;

                    let str = '-';
                    if (totaltimes > 0) str = totaltimes;
                    return <span>{str}</span> 
                }
            },
            { title: '操作', width: 80, key: 'operate', align: 'center', 
                render: (courseTimes) => {
                    return (
                        <a 
                            href="javascript:void(0)" 
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `${this.props.match.url}/${NAMESPACE.children.COACH_DETAIL.path}`,
                                    state: {
                                        courseTimes,
                                        selecteddate: that.state.selecteddate
                                    }
                                });
                            }
                        }>详情</a> 
                    );
                }
            }
        ];

        this.requestGetCourseTimesList = this.requestGetCourseTimesList.bind(this);

        this.onSelectedDateChanged = this.onSelectedDateChanged.bind(this);

        this.calculateCourseTimes = this.calculateCourseTimes.bind(this);

        this.handleModeChanged = this.handleModeChanged.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();
        if (!queryParams.mode) {
            queryParams.mode = 1;
        }
        this.props.configQueryParams(queryParams);

        this.requestGetCourseTimesList(queryParams);
    }

    requestGetCourseTimesList(extra) {
        
        let queryParams = this.queryParams();
        if (extra) {
            queryParams = {
                ...queryParams,
                ...extra
            }
        }

        this.changeLoadingState(true);
        launchGetCourseTimesList(
            filterParams(queryParams),
            res => {
                let list = this.calculateCourseTimes(res.list);
                // let { list } = res;
                this.setState({
                    list,
                    selecteddate: res.selecteddate
                });
                this.changeLoadingState(false);
            },
            fail => {
                this.changeLoadingState(false);
            }
        );
    }

    calculateCourseTimes(list) {
        let staticCourseTimes = 0;
        let staticPrivateTimes = 0;
        for (let i = 0; i < list.length; i++) {
            let aCT = list[i];
            // aCT.totaltimes = aCT.privatetimes + aCT.courseprivatetimes + aCT.coursetimes;

            // staticTimes += aCT.totaltimes;

            staticCourseTimes += aCT.coursetimes;
            staticPrivateTimes += (aCT.privatetimes + aCT.courseprivatetimes);
        }

        this.setState({
            staticPrivateTimes,
            staticCourseTimes
        });

        return list;
    }

    onSelectedDateChanged(date, dateString) {
        let { mode } = this.state;
        this.setState({ selecteddate: dateString });

        let queryParams = this.queryParams();
        queryParams.selecteddate = dateString;
        queryParams.mode = mode;
        this.props.configQueryParams(queryParams);

        this.requestGetCourseTimesList(queryParams);
    }

    handleModeChanged(e) {
        this.setState({
            mode: e.target.value
        });
        this.requestGetCourseTimesList({ mode: e.target.value });
    }

   
    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    render() {
        let { list, selecteddate, staticPrivateTimes, staticCourseTimes, mode } = this.state;
        let dateValue = undefined;
        if (selecteddate) {
            selecteddate = selecteddate+"-01"
            dateValue = LLCDateHelper.timeStrToTimestamp(selecteddate);
        }

        return (
            <Spin spinning={this.state.isLoading}>
            <div style={{...PageStyles.pageContainer, backgroundColor: '#ECEFF3', width: '100%'}}>
                
                <div style={{...TableStyles.tableBox, marginTop: 20}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span>统计日期：</span>
                                    <DatePicker value={moment.unix(dateValue)} onChange={this.onSelectedDateChanged} picker="month" />

                                    <div style={{ marginLeft: 20 }}>
                                        <Radio.Group value={this.state.mode} onChange={this.handleModeChanged}>
                                            <Radio value={1}>按会员</Radio>
                                            <Radio value={10}>按教练</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <Divider />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Statistic title="私教卡销卡总次数" value={staticPrivateTimes} />
                                    <Divider type='vertical' style={{ marginLeft: 20, marginRight: 20, height: 60 }} />
                                    <Statistic title="会员卡销卡总次数" value={staticCourseTimes} />
                                </div>
                                
                            </Card>
                        </div>
                        <div style={{ width: 30 }} />
                       
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                        {
                            mode === 1 ?
                                <Table
                                    rowKey={record => record.userid}
                                    loading={this.props.loading}
                                    columns={this.columns}
                                    dataSource={list}
                                    scroll={{ 
                                        // x: 1200, 
                                        // y: 485 
                                    }}
                                    // pagination={}
                                ></Table>
                                :
                                null
                        }

                        {
                            mode === 10 ?
                                <Table
                                    rowKey={record => record.coachid}
                                    loading={this.props.loading}
                                    columns={this.coachColumns}
                                    dataSource={list}
                                    scroll={{ 
                                        // x: 1200, 
                                        // y: 485 
                                    }}
                                    // pagination={}
                                ></Table>
                                :
                                null
                        }
                        
                        {/* <Card hoverable style={{ width: '100%' }} bodyStyle={{ paddingLeft: 0, paddingTop: 20, paddingRight: 0, paddingBottom: 10 }}>
                            <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <span style={ queryParams.tabIndex === 1 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(1) }}>团课预约{ this.state.dataInfo.appoints ? `（${this.state.dataInfo.appoints.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 2 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(2) }}>私教课预约{ this.state.dataInfo.privates ? `（${this.state.dataInfo.privates.length}）` : '' }</span>
                                    <span style={ queryParams.tabIndex === 3 ? styles.tabTitle_select : styles.tabTitle } onClick={() => { this.handleTabChanged(3) }}>体验课预约{ this.state.dataInfo.experiences ? `（${this.state.dataInfo.experiences.length}）` : '' }</span>
                                </div>

                                {
                                    this.renderTab()
                                }
                            </div>
                            <div style={{ marginTop: 20, width: '100%', height: 1, backgroundColor: '#E2E2E2' }} />

                            <div style={{ paddingLeft: 20, paddingRight:20, width: '100%' }}>
                                {
                                    this.renderTabContent()
                                }
                            </div>
                        </Card> */}
                    </div>
                </div>

                
            </div>
            </Spin>
        );
    }
}


const mapStateToProps = ({ queryStore, loginStore }) => {
    
    return {
        queryStore,
        user: loginStore.user
    };
};

const mapDispatchToProps = dispatch => {
  return {
    configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseStaticListController);
