export const LoginStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: '#fff'
    },
    IMG_LT_1: {
        position: 'absolute',
        left: 176,
        top: 98,
        width: 230,
        height: 164
    },
    IMG_LT_2: {
        position: 'absolute',
        left: 188,
        top: 235,
        width: 40,
        height: 41
    },
    IMG_LB: {
        position: 'absolute',
        left: 77,
        bottom: 54,
        width: 135,
        height: 96
    },
    IMG_RT: {
        position: 'absolute',
        right: 274,
        top: 71,
        width: 61,
        height: 64
    },
    IMG_RB: {
        position: 'absolute',
        right: 0,
        bottom: 88,
        width: 234,
        height: 232
    }
};

export const VenueListStyles = {
    container: {
        width: '100%',
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 36,
        paddingRight: 36
    },
    header: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 15
    },
    headerTitle: {
        fontSize: 24,
        // color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
    },
    venueList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    venueItem: {
        width: 240,
        marginRight: 25,
        marginBottom: 25
    },
    venueLogoBox: {
        width: 240, 
        height: 240, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    venueLogo: { 
        width: 200, 
        height: 200, 
        objectFit: 'cover' 
    },
    plusBodyStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}