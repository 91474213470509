export const IndexStyles = {
    navBar: {
        zIndex: 99, 
        display: 'flex', 
        position: 'fixed', 
        top: 0, left: 0, 
        width: '100%', height: 50, 
        alignItems: 'center', justifyContent: 'center',
        backgroundColor: '#fff',
        boxShadow: '0px 1px 1px rgba(0,0,0,0.15)',
    },
    content: { 
        display: 'flex', position: 'relative', 
        flexDirection: 'row', alignItems: 'center', 
        height: 50, width: '80%',
        backgroundColor: '#fff',

    },
    leftBox: {
        display: 'flex', flexDirection: 'row', alignItems: 'center',
        flex: 1, height: 50,
        backgroundColor: 'transparent'
    },
    rightBox: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end',
        flex: 1, height: 50,
        backgroundColor: 'transparent'
    },
    loginBtn: {
        width: 67,
        height: 30,
        background: '#FFE361',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        fontSize: '14px',
        fontFamily: 'PingFangSC-Regular',
        color: '#000000',
    },
    registerBtn: {
        marginLeft: 48,

        width: 67,
        height: 30,
        background: '#FFFFFF',
        borderRadius: 4,
        border: '1px solid #414141',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',


        fontSize: '14px',
        fontFamily: 'PingFangSC-Regular',
        color: '#000000',
    }
};