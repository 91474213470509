import React, { Component } from 'react';
import {
    Route,
    Switch,
    Redirect,
    Link
} from 'react-router-dom';
import { PageRoutes } from '../../constants/web-route-constants';

import Home from './home/home-mobile';
import { connect } from 'react-redux';
import { Drawer, Accordion, Button, Modal, InputItem, Toast, SearchBar, ListView, List, Popover } from 'antd-mobile';
// import '../../styles/index-mobile-styles.css';
import { message } from 'antd';

import BaseController from '../base/base-controller';


class IndexController extends BaseController {
    constructor(props) {
        super(props);

        

        this.state = {

        };

        this.isLoading = false;


        this.renderNav = this.renderNav.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
    }

    componentDidMount() {
        // document.body.addEventListener("touchmove", this.scroll, {passive: false });
        // document.body.style.overflow = 'hidden';
    }

    renderNav() {
        return (
            <div 
                style={{ 
                    boxShadow: '0px 1px 1px rgba(0,0,0,0.15)',
                    zIndex: 10, display: 'flex', flexDirection: 'row', position: 'fixed', top: 0, left: 0,
                    width: '100%', height: 50, alignItems: 'center', background: '#fff' 
                }}
            >
                {/* <div style={{ marginLeft: 20 }}>
                    <img 
                        src={this.state.open ? MOBILE_MENU_CLOSE : MOBILE_MENU} 
                        style={{ width: 30, height: 30, objectFit: 'contain' }}
                        onClick={this.onOpenChange} />
                </div> */}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img 
                        src="http://qiniu-media.soso-code.com/oneme/pic/custom/d46571ab1f2f43c2baae26b996f28572.png" 
                        style={{ width: 100, mode: 'widthFix', marginLeft: 20 }} 
                        onClick={() => {
                            this.props.history.push({
                                pathname: `${PageRoutes.MAIN.path}`,
                                state: {

                                }
                            });
                        }} 
                    />
                </div>

                <div style={{ marginRight: 20 }}>
                    {/* <Popover 
                        mask={true}
                        overlayClassName="fortest"
                        overlayStyle={{ color: 'currentColor' }}
                        visible={this.state.visible}
                        overlay={
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'center' }}>
                                <img src={MALL_QRCODE} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                <span style={{ marginTop: 10, fontSize: '15px', color: '#000' }}>长按或截屏保存</span>
                                <span style={{ marginTop: 5, fontSize: '13px', color: '#666' }}>使用微信扫描，进入小程序</span>
                            </div>
                        }
                        
                    >
                        <img 
                            src={MALL_QRCODE} 
                            style={{ width: 30, height: 30, objectFit: 'contain' }} />
                    </Popover> */}
                </div>
            </div>
        );
    }

    renderContent() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            
            </div>
        );
    }

    renderFooter() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#404040', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
                
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: '12px', color: '#fff', cursor: 'default' }}>Copyright © 2020 ONE ME Inc. 保留所有权利。</span>
                    <span style={{ fontSize: '12px', color: '#fff', marginTop: 5, cursor: 'default' }}>京ICP备17020842号-1</span>
                </div>
            </div>
        );
    }

    render() {

        return (
            <div
                ref={ref => this.scroll = ref}
                style={{ display: 'flex', width: '100%', flexDirection: 'column', position: 'relative', alignItems: 'center' }}
            >
                {
                    this.renderNav()
                }

                <div id='content-scroll' style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: '#F2F2F8',
                }}>
                    
                    <Route path={`${PageRoutes.MAIN.path}`} exact component={Home} />
                </div>

                {
                    this.renderFooter()
                }
            </div>
        );
    }
}


const mapStateToProps = (store) => {
    return {
        
    };
};

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexController);
