import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class ManagerLogin extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="管理员登录" />

                <SectionTitle text="一.登录" />
                <NormalContent text={<span>1.管理员通过输入账号、密码登录后台会员管理系统。</span>} />
                <NormalContent text={<span>2.登录状态在不清除浏览器缓存的情况下，可以保持5天的自动登录。如果在陌生环境或者不希望保持登录状态的情况时，请记得“退出”登录。</span>} />
                <NormalContent text={<span>3.同一个账号只可以同时在一台设备上登录，其他登录者会被迫退出登录。</span>} />
                
                <SectionTitle text="二.进入场馆" />
                <NormalContent text={<span>1.主账号登录后，如果账号下有多个场馆，会首先进入场馆选择页面，选择场馆后，进入该场馆的后台管理系统。只有一个场馆的，直接进入该场馆的后台管理系统</span>} />
                <NormalContent text={<span>2.子账号登录后直接进入所属场馆的后台管理系统。</span>} />

                <SectionTitle text="三.退出登录" />
                <NormalContent text={<span>1.进入后台管理系统后，右上角有“退出”按钮，点击后会退出此账号的登录。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerLogin);
