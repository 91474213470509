import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Divider, Spin } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { actions as vipCardActions, launchUpdateVipCardMoldState } from '../../../redux/vipcard-model';
import { PageStyles, TableStyles } from '../../../style/common-styles';
import { VipCardMoldListStyles } from '../../../style/page/vipcard/vipcard-mold-styles';
import VIPCARD_BG from '../../../resources/vipcard_bg.png';
import VIPCARD_BG_FREEZE from '../../../resources/vipcard_bg_freeze.png';
import { PlusOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.VIPCARD_LIST;

const confirm = Modal.confirm;

class VipCardMoldListController extends BaseController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    };

    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleStateButtonClick = this.handleStateButtonClick.bind(this);

    this.changeLoadingState = this.changeLoadingState.bind(this);
    this.updateMoldState = this.updateMoldState.bind(this);

    this.renderVipCards = this.renderVipCards.bind(this);
  }

  componentDidMount() {
    this.props.queryVipCardMoldList({ });
  }

  updateMoldState(params) {
    this.changeLoadingState(true);
    launchUpdateVipCardMoldState(
      params,
      response => {
        message.success(response.msg);
        this.changeLoadingState(false);
        this.props.queryVipCardMoldList({ });
      },
      msg => {
        message.error(msg);
        this.changeLoadingState(false);
      }
    )
  }

  handleAddButtonClick() {
    this.props.history.push({
      pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_VIPCARD.path}`,
      state: {
          
      }
  });
  }

  handleEditButtonClick(vipCardMold) {
    this.props.history.push({
      pathname: `${this.props.match.url}/${NAMESPACE.children.SAVE_VIPCARD.path}`,
      state: { vipCardMold }
    });
  }

  handleStateButtonClick(vipCardMold) {
    let operate = vipCardMold.state === 1 ? '禁用' : '恢复';
    let title = `确认要${operate}会员卡：${vipCardMold.cardname} 吗？`;
    let content = vipCardMold.state === 1 ? '禁用的会员卡无法进行绑定！' : '恢复后，可以使用会员卡进行绑定！'

    let that = this;
    confirm({
      title,
      content,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        let state = 1;
        if (vipCardMold.state === 1) {
          state = 2;
        }
        that.updateMoldState({ moldid: vipCardMold.moldid, state });
      },
      onCancel() {
        
      },
    });
  }

  renderVipCards() {
      return (
        <div style={VipCardMoldListStyles.list}>
            {
                this.props.vipCardMoldList && this.props.vipCardMoldList.map((typeList, index) => {
                    let aType = typeList[0];
                    let typeName = '';
                    if (aType.cardtype === 1) {
                      typeName = '时限卡';
                    } else if (aType.cardtype === 2) {
                      typeName = '次卡';
                    }

                    return (
                        <div key={`typeList${index}`}>
                            <Divider orientation="left">{typeName}</Divider>

                            <div style={VipCardMoldListStyles.typBox}>
                              {
                                typeList && typeList.map((aMold, moldIndex) => {
                                  let textColorStyles = aMold.state === 1 ? VipCardMoldListStyles.normalTextColor : VipCardMoldListStyles.freezeTextColor;
                                  let cardTypeStyles = aMold.cardtype === 1 ? VipCardMoldListStyles.cardType : VipCardMoldListStyles.cardType_times;
                                  
                                  return (
                                    <div key={`mold${moldIndex}`} style={VipCardMoldListStyles.moldBox}>
                                      <img src={ aMold.state === 1 ? VIPCARD_BG : VIPCARD_BG_FREEZE} style={VipCardMoldListStyles.vipCardBg} alt="" />

                                      <span style={{...VipCardMoldListStyles.cardName, ...textColorStyles}}>{aMold.cardname}</span>
                                      <span style={{...VipCardMoldListStyles.cardPrice, ...textColorStyles}}>￥{aMold.cardprice / 100}</span>


                                      <span style={{...cardTypeStyles, ...textColorStyles}}>{typeName}</span>
                                      {
                                        aMold.cardtype === 2 ?
                                          <span style={{...VipCardMoldListStyles.cardTimes, ...textColorStyles}}>次数：{aMold.cardtimes} 次</span>
                                          :
                                          null
                                      }
                                      <span style={{...VipCardMoldListStyles.cardDuration, ...textColorStyles}}>有效期：{aMold.cardduration} 天</span>

                                      <Button 
                                        style={VipCardMoldListStyles.editButton}
                                        onClick={() => {
                                          this.handleEditButtonClick(aMold);
                                        }}  
                                      >编辑</Button>
                                      <Button 
                                        style={VipCardMoldListStyles.stateButton} 
                                        type={aMold.state === 1 ? 'danger' : 'primary'}
                                        onClick={() => {
                                          this.handleStateButtonClick(aMold);
                                        }}  
                                      >{aMold.state === 1 ? '禁用' : '恢复'}</Button>
                                    </div>
                                  )
                                })
                              }
                            </div>
                        </div>
                    )
                })   
            }
        </div>
      );
  }

  renderHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">新建会员卡模</Button>
        </div>
      )
    });
  }

  render() {
    return (
      <Spin spinning={this.state.isLoading || this.props.loading}>
        <div style={PageStyles.pageContainer}>
          {
            this.renderHeader()
          }

          <div style={TableStyles.tableBox}>
            {
                this.renderVipCards()
            }
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = store => {
  const vipCardStore = store['vipCardStore'];
  const utilStore = store['utilStore'];
  return {
    loading: vipCardStore.loading,
    vipCardMoldList: vipCardStore.vipCardMoldList,
    pagesize: vipCardStore.pagesize,
    totalpage: vipCardStore.totalpage,
    page: vipCardStore.page,
    // isShow: utilStore.isShow[PathConstant.VIPCARD_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryVipCardMoldList: params => {
      dispatch(vipCardActions.queryVipCardMoldList(params));
    },
    changeLoading: params => {
      dispatch(vipCardActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipCardMoldListController);
