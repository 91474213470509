export const TimeTableListStyles = {
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 16,
        marginBottom: 16,
        position: 'relative'
    },
    headerLeftButton: {
        
    },
    headerRightButton: {

    },
    headerTitle: {
        marginLeft: 15,
        marginRight: 15
    },
    headerOperate: {
        position: 'absolute',
        left: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerSwitch: {
    },
    headerLine: {
        marginLeft: 20,
        marginRight: 20,
        color: '#999999'
    },
    headerAdd: {
    },
    emptyBox: {
        display: 'flex',
        flexDirection: 'column',
        // textAlign: 'center'
        alignItems: 'center'
    },
    emptyIcon: {
        width: 100,
        height: 100
    },
    emptyDesc: {
        marginTop: 10
    },
    emptyOperate: {
        marginTop: 16
    },
    copyOperate: {
        marginTop: 16,
        marginLeft: 20
    },
    emptyCourseCell: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 30
    },
    courseItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    courseTime: {
        fontFamily: 'PingFangSC-Medium',
        fontSize: '15px',
        color: '#4A4A4A'
    },
    courseName: {
        fontFamily: 'PingFangSC-Regular',
        fontSize: '14px',
        color: '#000000',
        // width: 100,
        display: 'block',
        wordBreak: 'normal',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
    },
    coachName: {
        fontFamily: 'PingFangSC-Regular',
        fontSize: '14px',
        color: '#929292',
        marginTop: '15px'
    },
    itemLine: {
        marginTop: 15,
        mariginBottom: 15
    }
}