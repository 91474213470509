import { all } from 'redux-saga/effects';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { utilStore } from './util';
import { watchers as loginWatcher, loginStore } from './loginModel';
import { watchers as vipCardWatcher, vipCardStore } from './vipcard-model';
import { watchers as vipUserWatcher, vipUserStore } from './vipuser-model';
import { watchers as userWatcher, userStore } from './user-model';
import { watchers as coachWatcher, coachStore } from './coach-model';
import { watchers as courseWatcher, courseStore } from './course-model';
import { watchers as timetableWatcher, timetableStore } from './timetable-model';
import { watchers as appointmentWatcher, appointmentStore } from './appointment-model';
import { watchers as managerWatcher, managerStore } from './manager-model';
import { watchers as descWatcher, descStore } from './desc-model';
import { watchers as venueWatcher, venueStore } from './venue-model';
import { watchers as queryWatcher, queryStore } from './query-model';

function* rootSaga() {
  yield all(combineWatherGenerators(
    loginWatcher,
    queryWatcher,
    vipCardWatcher,
    vipUserWatcher,
    userWatcher,
    coachWatcher,
    courseWatcher,
    timetableWatcher,
    appointmentWatcher,
    managerWatcher,
    descWatcher,
    venueWatcher
  ));
}

const rootReducer = combineReducers({
  utilStore,
  queryStore,
  loginStore,
  vipCardStore,
  vipUserStore,
  userStore,
  coachStore,
  courseStore,
  timetableStore,
  appointmentStore,
  managerStore,
  descStore,
  venueStore
});

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  )
);
sagaMiddleware.run(rootSaga);


function combineWatherGenerators(...watchers) {
  const watcherGenerators = [];
  watchers.forEach(item => {
    item.forEach(watcherItem => {
      watcherGenerators.push(watcherItem());
    })
  });
  return watcherGenerators;
}