export const BannerListStyles = {
    bannerList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    bannerItem: {
        width: 265.5,
        // height: 72,
        // minHeight: 240,
        marginRight: 25,
        marginBottom: 25
    },
    plusBodyStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    bannerLogoBox: {
        width: 265.5, 
        height: 108, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    bannerLogo: { 
        width: 265.5, 
        height: 108, 
        objectFit: 'cover'
    },
    uploadButton: {
        // width: 265.5,
        // height: 108,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}