import React from 'react';
import { Divider } from 'antd';
import { TextStyles } from '../../style/help/text-styles';

export const ArtTitle = (props) => {
    return (
        <div style={TextStyles.artTitle}>{props.text}</div>
    );
}

export const SectionTitle = (props) => {
    return (
        <div style={TextStyles.sectionTitle}>{props.text}<Divider /></div>
    );
}

export const NormalContent = (props) => {
    return (
        <div style={TextStyles.normalContent}>{props.text}</div>
    );
}

export const Highlight = (props) => {
    return (
        <span style={TextStyles.highlight}>{props.text}</span>
    );
}

export const Picture = (props) => {
    if (props.width === undefined) {
        return (
            <div style={TextStyles.pictureBox}><img style={TextStyles.picture} mode="widthFix" src={props.src} /></div>
        );
    } else {
        return (
            <div style={TextStyles.pictureBox}><img style={{...TextStyles.picture, width: props.width}} mode="widthFix" src={props.src} /></div>
        );
    }
}

export const PictureItem = (props) => {
    if (props.width === undefined) {
        return (
            <img style={{...TextStyles.picture, ...props.style}} mode="widthFix" src={props.src} />
        );
    } else {
        return (
            <img style={{...TextStyles.picture, width: props.width, ...props.style}} mode="widthFix" src={props.src} />
        );
    }
}


export const PictureList = (props) => {
    return (
        <div style={TextStyles.pictureListBox}>
            {
                props.pictureList && props.pictureList.map((item, index) => {
                    return item
                })
            }
        </div>
    );
}

export const KeyValue = (props) => {
    return (
        <div style={TextStyles.keyValueBox}>
            <div style={TextStyles.keyBox}>
                {props.title}
            </div>
            <div style={TextStyles.valueBox}>
                {props.value}
            </div>
        </div>
    );
}