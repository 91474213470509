import BaseController from '../../page/base/base-controller';

export default class BaseSelectView extends BaseController {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            windowHeight: window.screen.height,
        }
    }
}