import React from 'react';
import { Route } from 'react-router-dom';

import { MAIN } from '../../../../constants/routeConstants';
import List from './partner-list-controller';
import Edit from './partner-edit-controller';
import { PageStyles } from '../../../../style/common-styles';

const NAMESPACE = MAIN.children.PARTNER;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      <Route
        path={`${baseUrl}/${children.EDIT.path}`}
        component={Edit} />
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

