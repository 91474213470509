export const VipCardMoldListStyles = {
    list: {
        display: 'relative'
    },
    typBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    moldBox: {
        width: 320,
        height: 197,
        position: 'relative',
        marginLeft: 22,
        marginBottom: 22
    },
    vipCardBg: {
        width: 320,
        height: 197,
        position: 'absolute',
        left: 0,
        top: 0
    },
    cardName: {
        left: 24,
        top: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
        fontSize: 16,
        position: 'absolute'
    },
    cardPrice: {
        top: 17,
        right: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Medium',
        fontSize: 24,
        position: 'absolute'
    },
    cardType: {
        bottom: 54,
        left: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16,
        position: 'absolute'
    },
    cardType_times: {
        bottom: 84,
        left: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16,
        position: 'absolute'
    },
    cardTimes: {
        bottom: 54,
        left: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16,
        position: 'absolute'
    },
    cardDuration: {
        bottom: 24,
        left: 24,
        color: '#9A6326',
        fontFamily: 'PingFangSC-Regular',
        fontSize: 16,
        position: 'absolute'
    },
    editButton: {
        bottom: 17,
        right: 88,
        position: 'absolute'
    },
    stateButton: {
        bottom: 17,
        right: 18,
        position: 'absolute'
    },
    normalTextColor: {
        color: '#9A6326'
    },
    freezeTextColor: {
        color: '#7D7D7D'
    }
}

export const VipCardMoldSaveStyles = {
    cardType_Select: {
        width: 122,
        height: 32
    },
    operateBox: {
        textAlign: 'center'
    }
}