import React from 'react'
import { Tag, Input, Tooltip } from 'antd';
import { isEmpty } from '../../util/helper/string-helper';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

export default class EditableTagGroup extends React.Component {

    constructor(props) {
        super(props);

        let tags = props.defaultTags ? props.defaultTags : [];

        this.state = {
            tags,
            inputVisible: false,
            inputValue: '',
        };

        this.props.onRef(this);
        this.updateTags = this.updateTags.bind(this);
    }

    getTags() {
      return this.state.tags;
    }

  addTags(tags) {
    
    for(let key in tags) {
      let aTag = tags[key];
      if (this.state.tags.indexOf(aTag) === -1) {
        this.state.tags.push(aTag);
      }
    }

    this.setState({
      tags: this.state.tags
    });
  }

  handleClose = removedTag => {
      const tags = this.state.tags.filter(tag => tag !== removedTag);
      console.log(tags);
      this.setState({ tags });

      this.updateTags(tags);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });

    this.updateTags(tags);
  };

  updateTags(tags) {

    this.props.updateTags && this.props.updateTags(tags);
  }

  saveInputRef = input => (this.input = input);

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag 
            style={{marginBottom: 10}}
            key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              {/* {isLongTag ? `${tag.slice(0, 20)}...` : tag} */}
              {tag}
            </Tag>
          );

          return tagElem;
          // return 
          //  isLongTag ? (
          //   <Tooltip title={tag} key={tag}>
          //     {tagElem}
          //   </Tooltip>
          // ) : (
          //   tagElem
          // );
        })}
        <Tag onClick={() => {
          this.props.triggerImport && this.props.triggerImport()
        }} style={{ background: '#fff', borderStyle: 'dashed' }}>
          <DownloadOutlined /> {this.props.selectHint}
        </Tag>

        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 100, marginBottom: 10, }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {(!inputVisible && !this.props.disableInput) && (
          <Tag onClick={this.showInput} style={{ marginBottom: 10, background: '#fff', borderStyle: 'dashed' }}>
            <PlusOutlined /> {this.props.addHint}
          </Tag>
        )}

        
      </div>
    );
  }
}