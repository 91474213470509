import React, { Component } from 'react';
import { Input, Button, Spin } from 'antd';
import FormItem from '../common/form-item';

export default class UsernameEditView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value:  props.defaultValue ? props.defaultValue : ''
        };
    }

    
    render() {
        return (
            <Spin spinning={this.props.isLoading}>
            <div style={styles.itemContainer}>
                <FormItem 
                    type="input" 
                    itemKey="会员姓名：" 
                    itemValue={this.state.value} 
                    onValueChanged={(value) => { this.setState({ value }); }} 
                />
                <div style={styles.operateBox}>
                    <Button onClick={ () => { this.props.onCancel && this.props.onCancel()} } >取消</Button>
                    <Button onClick={ () => { this.props.onOk && this.props.onOk(this.state.value) } } style={{ marginLeft: 20 }} type="primary">确认</Button>
                </div>
            </div>
            </Spin>
        );
    }
}

const styles = {
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 20,
    },
    operateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}