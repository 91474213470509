import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { actions as queryActions } from '../../../../redux/query-model'
import TagSelectView from '../../../../view/tags/tag-select-view';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import { ManagerListStyles } from '../../../../style/page/manager/manager-list-styles';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { launchGetPartnerList } from '../../../../redux/partner-model';
import { filterParams } from '../../../../util/helper/request-helper';
import { PictureOutlined, PlusOutlined } from '@ant-design/icons';
import LLCDateHelper from 'date-helper';

const NAMESPACE = MAIN_LISTS.PARTNER;

const confirm = Modal.confirm;
const { Option } = Select;

class PartnerListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            dataInfo: {},
            queryParams: {}
        };

        let that = this;
        this.columns = [
            { title: '编号', width: '10%', dataIndex: 'userid', key: 'userid', align: 'center' },
            { title: '名称', width: '20%', dataIndex: 'username', key: 'username', align: 'center' },
            { title: '手机号', width: '20%', dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '最大场馆', width: '10%', dataIndex: 'maxvenue', key: 'maxvenue', align: 'center' },
            { title: '到期日期', width: '20%', dataIndex: 'expiretime', key: 'expiretime', align: 'center', 
            render: expiretime => {
                    if (this.state.dataInfo.nowtime) {
                        if (expiretime <= this.state.dataInfo.nowtime) {
                            return <span style={{ color: 'red' }}>{LLCDateHelper.formatDate(expiretime / 1000)}</span>    
                        } else if (expiretime - this.state.dataInfo.nowtime <= 31 * 24 * 60 * 60 * 1000) {
                            return <span style={{ color: '#FF9D09' }}>{LLCDateHelper.formatDate(expiretime / 1000)}</span>    
                        }
                    } 
                    return <span>{LLCDateHelper.formatDate(expiretime / 1000)}</span>
            }
            },
            { title: '状态', width: '10%', dataIndex: 'state', key: 'state', align: 'center', 
            render: state => {
                if (state === 1) {
                return <Tag color="#87d068">正常</Tag>
                } else {
                return <Tag>冻结</Tag>
                }
            }
            },
            { title: '操作', width: '10%', key: 'operate', align: 'center',
            render: (partner) => {
                return (
                    <div style={ManagerListStyles.operateBox}>

                        <a
                        onClick={(e) => {
                        e.stopPropagation();
                                        
                        that.props.history.push({
                            pathname: `${this.props.match.url}/${NAMESPACE.children.EDIT.path}`,
                            state: {
                                partner
                            }
                        });
                        }}
                        >编辑</a>
                    </div>
                );
            }    
        }];

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleQueryPtagChange = this.handleQueryPtagChange.bind(this);
    }

    componentDidMount() {
        let queryParams = this.queryParams();

        this.queryPartnerList(queryParams.page ? queryParams.page : 1, queryParams);
    }
  
    queryPartnerList(page) {
        let queryParams = this.queryParams();
        queryParams.page = page;
        this.changeLoadingState(true);

        launchGetPartnerList(
            filterParams(queryParams),
            res => {
                
                this.setState({
                    dataInfo: res
                });
                this.changeLoadingState(false);
            },
            fail => {
                message.error(fail);
                this.changeLoadingState(false);
            }
        );
    }
  
    handleAddButtonClick = e => {
        this.props.history.push({
            pathname: `${this.props.match.url}/${NAMESPACE.children.EDIT.path}`,
            state: {
                        
            }
        });
    }

    /**
     * 
     */
    handleQueryPtagChange(e) {
        let queryParams = this.getQueryParams();
        if (e) {
            queryParams.pname = e.name;
            queryParams.pid = e.id;
        } else {
            queryParams.pname = undefined;
            queryParams.pid = undefined;
        }

        this.setState({ 
            modalTagShowing: false
        });

        this.props.configQueryParams(queryParams);
    }

    handleSearchClick() {
        this.queryPartnerList(1)
    }


    handleClearClick() {
        this.props.configQueryParams({});
    }

    queryParams() {
        let queryParams = this.props.queryStore[NAMESPACE.name];
        if (!queryParams) queryParams = {};
        return queryParams;
    }

    renderSearchBar() {
        let queryParams = this.queryParams();

        return (
            <div style={TableSearchStyles.searchBox}>
                <div style={TableSearchStyles.searchIputBox}>

                    <div style={TableSearchStyles.searchItem_margin}>
                    <span>名称：</span>
                    
                    <Input
                        allowClear
                        style={TableSearchStyles.searchInput_shortpadding}
                        placeholder="请输入商家名称"
                        onChange={e => {
                        queryParams.username = e.target.value;
                        this.props.configQueryParams(queryParams);
                        }}
                        value={this.state.queryParams.username} />
                    </div>

                    <div style={TableSearchStyles.searchItem_margin}>
                    <span>手机：</span>
                    
                    <Input
                        allowClear
                        style={TableSearchStyles.searchInput_shortpadding}
                        placeholder="请输入商家手机号"
                        onChange={e => {
                        queryParams.phone = e.target.value;
                        this.props.configQueryParams(queryParams);
                        }}
                        value={this.state.queryParams.phone} />
                    </div>

                    <div style={TableSearchStyles.searchItem_margin}>
                    <span>状态：</span>
                    
                    <Select 
                        value={queryParams.state ? queryParams.state : 0}
                        style={{...TableSearchStyles.searchInput_shortpadding, width: 100}}
                        onChange={e => {
                        queryParams.state = e;
                        this.props.configQueryParams(queryParams);
                        }}>
                        <Option value={0}>全部</Option>
                        <Option value={1}>正常</Option>
                        <Option value={2}>冻结</Option>
                    </Select>
                    </div>

                    <div style={TableSearchStyles.searchItem_margin}>
                    <span>筛选：</span>
                    
                    <Select 
                        value={queryParams.valid ? queryParams.valid : 0}
                        style={{...TableSearchStyles.searchInput_shortpadding, width: 200}}
                        onChange={e => {
                        queryParams.valid = e;
                        this.props.configQueryParams(queryParams);
                        }}>
                        <Option value={0}>全部</Option>
                        <Option value={1}>一月内过期</Option>
                        <Option value={10}>已过期</Option>
                    </Select>
                    </div>

                    
                </div>

                <div style={TableSearchStyles.operateBox}>
                    <div style={TableSearchStyles.searchButtonContainer}>
                        <Button
                            style={TableSearchStyles.searchButton}
                            type="primary"
                            onClick={this.handleSearchClick}>搜索</Button>
                        <Button
                            style={TableSearchStyles.clearButton}
                            onClick={this.handleClearClick}>清空</Button>
                    </div>
                </div>
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
        title: NAMESPACE.name,
        extra: (
            <div>
                <Button onClick={ this.handleAddButtonClick } type="primary" icon={<PlusOutlined />} size="small">新增合作商家</Button>
            </div>
        )
        });
    }

    render() {
        const { dataInfo } = this.state;
        const { loginUser } = this.props;

        return (
        <div style={PageStyles.pageContainer}>
            {
                this.renderNavHeader()
            }

            <div style={TableStyles.tableBox}>
            
            {
                this.renderSearchBar()
            }

            <Table
                className="manager-table"
                rowKey={record => record.userid}
                loading={this.state.isLoading}
                columns={this.columns}
                dataSource={dataInfo.list}
                // scroll={{ y: 485 }}
                pagination={{
                total: dataInfo.totalpage * dataInfo.pagesize,
                pageSize: dataInfo.pagesize,
                current: dataInfo.page,
                onChange: val => {
                    this.queryPartnerList(val)
                }
            }}
            ></Table>
            </div>

            <Modal
            centered={true}
            visible={this.state.modalTagShowing}
            destroyOnClose={true}
            width={"956px"}
            onCancel={() => { this.setState({ modalTagShowing: false }) }}
            footer={null}
            >
            <TagSelectView onRecordSelect={this.handleQueryPtagChange} />
            </Modal>
        </div>
        );
    }
}

const mapStateToProps = ({ queryStore }) => {
    return {
        queryStore
    };
}

const mapDispatchToProps = dispatch => {
    return {
        configQueryParams: params => {
        const pageQuery = {};
        pageQuery[NAMESPACE.name] = params;
        dispatch(queryActions.configQueryParams(pageQuery));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerListController);
