import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Input, Row, Col, Button, Table, Tag, Modal, message, Spin, Avatar } from 'antd';
import { connect } from 'react-redux';
import { actions as userActions } from '../../../redux/user-model';
import LLCDateHelper from "date-helper";
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { PageStyles, SearchBarStyles, TableStyles } from '../../../style/common-styles';
import { UserOutlined } from '@ant-design/icons';

const NAMESPACE = MAIN_LISTS.USER_LIST;

const confirm = Modal.confirm;

class UserListController extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
            nickname: '',
            phone: ''
        };

        this.columns = [
            { title: '头像', width: 100, dataIndex: 'portrait', key: 'portrait', align: 'center', render: url => <Avatar src={url} icon={<UserOutlined />} /> },
            { title: '昵称', width: 150, dataIndex: 'nickname', key: 'nickname', align: 'center' },
            { title: '手机号', width: 170, dataIndex: 'phone', key: 'phone', align: 'center' },
            { title: '注册时间', width: 170, dataIndex: 'registertime', key: 'registertime', align: 'center', render: registertime => <span>{LLCDateHelper.formatDate(registertime)}</span> }
        ]

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        this.renderSearchBar = this.renderSearchBar.bind(this);
    }

    componentDidMount() {
        this.props.queryUserList({ page: this.props.page });
      }
    
    queryUserList = page => {
        this.props.queryUserList({
            page,
            nickname: this.state.nickname,
            phone: this.state.phone
        });
    }

    handleSearchClick() {
        this.queryUserList(1)
    }

    handleClearClick() {
        this.setState({
            nickname: '',
            phone: ''
        })
    }

    renderSearchBar() {
        return (
            <div style={SearchBarStyles.searchBar}>
                <Row style={SearchBarStyles.searchRow}>
                   
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>昵称</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e => this.setState({ nickname: e.target.value })}
                        value={this.state.nickname} />
                    </Col>
                    <Col span={8} style={SearchBarStyles.searchItem}>
                        <span style={SearchBarStyles.searchInputTag}>手机号</span>
                        <Input
                        allowClear
                        style={SearchBarStyles.searchInput}
                        placeholder="请输入"
                        onChange={e =>this.setState({ phone: e.target.value })}
                        value={this.state.phone} />
                    </Col>
                </Row>

                <div style={SearchBarStyles.searchButtonContainer}>
                    <Button
                        style={SearchBarStyles.searchButton}
                        type="primary"
                        onClick={this.handleSearchClick}>搜索</Button>
                    <Button
                        style={SearchBarStyles.clearButton}
                        onClick={this.handleClearClick}>清空</Button>
                </div>
            </div>
        );
    }

    renderNavHeader() {
        return this.renderPageHeader({
          title: NAMESPACE.name,
          extra: (
            <div>
                
            </div>
          )
        });
      }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                {
                    this.renderNavHeader()
                }

                <div style={TableStyles.tableBox}>
                    {
                        this.renderSearchBar()
                    }
                    <Table
                        rowKey={record => record.userid}
                        loading={this.props.loading}
                        columns={this.columns}
                        dataSource={this.props.userList}
                        // scroll={{ y: 485 }}
                        pagination={{
                            total: this.props.totalpage * this.props.pagesize,
                            pageSize: this.props.pagesize,
                            current: this.props.page,
                            onChange: val => {
                                this.queryUserList(val)
                            }
                        }}
                    ></Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
  const userStore = store['userStore'];
  const utilStore = store['utilStore'];
  return {
    loading: userStore.loading,
    userList: userStore.userList,
    pagesize: userStore.pagesize,
    totalpage: userStore.totalpage,
    page: userStore.page,
    // isShow: utilStore.isShow[PathConstant.USER_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryUserList: params => {
      dispatch(userActions.queryUserList(params));
    },
    changeLoading: params => {
      dispatch(userActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListController);
