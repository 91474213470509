import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Input, Divider, Button, Table, Tag, Modal, message, Popconfirm, Switch, Spin, Popover } from 'antd';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { TimeTableListStyles } from '../../../style/page/timetable/timetaable-list-styles';
import { MAIN_LISTS } from '../../../constants/routeConstants';
import BaseController from '../../base/base-controller';
import { 
  actions as timetableActions, 
  launchQueryTimeTable, 
  launchCreateTimeTable, 
  launchSaveTimeTableCourse, 
  launchQueryNextTimeTable, 
  launchQueryPreTimeTable, 
  launchUpdateTimeTableState,
  launchUpdateTimeTableCourseState,
  launchQueryRecent,
  launchCopyTable
} from '../../../redux/timetable-model';
import CourseSaveView from '../../../view/timetable/course-save-view';
import { PageStyles, TableStyles } from '../../../style/common-styles';
import { LeftOutlined, RightOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';

import TIMETABLE_404 from '../../../resources/timetable_404.png'
import TIMETABLE_LEFT_ARROW from '../../../resources/timetable_left_arrow.png';
import TIMETABLE_RIGHT_ARROW from '../../../resources/timetable_right_arrow.png';

const NAMESPACE = MAIN_LISTS.TIMETABLE_LIST;

const confirm = Modal.confirm;  
const cancel = Modal.cancel;

class TimtTableListController extends BaseController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      currentTimeTable: undefined,
      currentMonday: undefined,
      currentSunday: undefined,
      preTableid: undefined,
      nextTableid: undefined,

      recent: [],

      courseSaveVisible: false,
      popoverVisible: false
    };

    let columnWidth = 100;

    this.columns = [
      { title: '课表', width: 80, dataIndex: 'begindate', key: 'begindate', align: 'center' },
      { title: '星期一', key: 'week1', width: columnWidth, render: (row) => { return this.renderTableCell(row.week1.courses, 1, row.begindate); }},
      { title: '星期二', key: 'week2', width: columnWidth, render: (row) => { return this.renderTableCell(row.week2.courses, 2, row.begindate); }},
      { title: '星期三', key: 'week3', width: columnWidth, render: (row) => { return this.renderTableCell(row.week3.courses, 3, row.begindate); }},
      { title: '星期四', key: 'week4', width: columnWidth, render: (row) => { return this.renderTableCell(row.week4.courses, 4, row.begindate); }},
      { title: '星期五', key: 'week5', width: columnWidth, render: (row) => { return this.renderTableCell(row.week5.courses, 5, row.begindate); }},
      { title: '星期六', key: 'week6', width: columnWidth, render: (row) => { return this.renderTableCell(row.week6.courses, 6, row.begindate); }},
      { title: '星期日', key: 'week7', width: columnWidth, render: (row) => { return this.renderTableCell(row.week7.courses, 7, row.begindate); }}
    ];

    this.changeLoadingState = this.changeLoadingState.bind(this);

    this.queryTimeTable = this.queryTimeTable.bind(this);

    this.handleDeleteTimeTableCourse = this.handleDeleteTimeTableCourse.bind(this);
    this.handleEditTimeTableCourse = this.handleEditTimeTableCourse.bind(this);
    this.hanldeEditCourse = this.hanldeEditCourse.bind(this);
    this.handleCreateTable = this.handleCreateTable.bind(this);
    this.handleSaveCourse = this.handleSaveCourse.bind(this);
    this.handleSaveCourseCancel = this.handleSaveCourseCancel.bind(this);
    this.handleQueryTable = this.handleQueryTable.bind(this);
    this.handleTableStateChange = this.handleTableStateChange.bind(this);
    this.handleCourseCellClick = this.handleCourseCellClick.bind(this);
    this.handleCopyTable = this.handleCopyTable.bind(this);
    this.handlePopoverVisibled = this.handlePopoverVisibled.bind(this);
    this.handleCopyTable = this.handleCopyTable.bind(this);

    this.renderTableCell = this.renderTableCell.bind(this);
    this.renderRecentTable = this.renderRecentTable.bind(this);
    this.renderNotFoundTimeTable = this.renderNotFoundTimeTable.bind(this);
  }



  componentDidMount() {
    console.log(document.body.clientWidth);

    this.changeLoadingState(true);
    launchQueryTimeTable(
      {},
      response => {
        this.changeLoadingState(false);
        // message.success(response.msg);
        this.setState({
          currentTimeTable: response.result.timetable,
          currentMonday: response.result.monday,
          currentSunday: response.result.sunday,
          preTableid: response.result.pretable,
          nextTableid: response.result.nexttable
        });

        if (response.result.timetable) {
          this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
        }
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  queryTimeTable(tableid) {
    launchQueryTimeTable(
      { tableid },
      response => {
        
        this.setState({
          currentTimeTable: response.result.timetable,
          currentMonday: response.result.monday,
          currentSunday: response.result.sunday,
          preTableid: response.result.pretable,
          nextTableid: response.result.nexttable
        });

        this.props.queryTimeTableList({ tableid: tableid });
      },
      msg => {
        message.error(msg);
      }
    );
  }

  // 新增课程
  hanldeEditCourse() {
    this.setState({ courseSaveVisible: true });
  }

  handleDeleteTimeTableCourse(course) {
    let that = this;
    confirm({
      title: `确定删除 ${course.weekdate} 的课程：${course.coursename}？`,
      content: '请确认删除课程不会影响用户的预约',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        that.changeLoadingState(true);
        launchUpdateTimeTableCourseState(
          {
            tcid: course.tcid,
            state: 2
          },
          response => {
            that.changeLoadingState(false);
            message.success(response.msg);

            that.props.queryTimeTableList({ tableid: that.state.currentTimeTable.tableid });
          },
          msg => {
            that.changeLoadingState(false);
            message.error(msg);
          }
        );
      },
      onCancel() {
        
      },
    });
  }

  // 编辑课表
  handleEditTimeTableCourse(course) {

    let weekindex = undefined;
    switch (course.weekdate) {
      case '星期一':
        weekindex = 1;
        break;
      case '星期二':
        weekindex = 2;
        break;
      case '星期三':
        weekindex = 3;
        break;
      case '星期四':
        weekindex = 4;
        break;
      case '星期五':
        weekindex = 5;
        break;
      case '星期六':
        weekindex = 6;
        break;
      case '星期日':
        weekindex = 7;
        break;

      default:
        weekindex = undefined;
    }

    this.setState({ 
      editCourse: course,
      editCoach: course,
      editTcid: course.tcid,
      editCoursedate: course.begindate,
      editCoursetime: course.begintime,
      editWeekindex: weekindex,
      coursedateShow: `${course.weekdate} （${course.daydate}）`,
      coursedateNoWeek: course.daydate,

      courseSaveVisible: true
    });
  }

  handleCourseCellClick(weekindex, timeStr) {
    
    let week = undefined;
    let date = undefined;
    switch (weekindex) {
      case 1:
        week = '星期一';
        date = this.state.currentTimeTable.monday;
        break;
      case 2:
        week = '星期二';
        date = this.state.currentTimeTable.tuesday;
        break;
      case 3:
        week = '星期三';
        date = this.state.currentTimeTable.wednesday;
        break;
      case 4:
        week = '星期四';
        date = this.state.currentTimeTable.thursday;
        break;
      case 5:
        week = '星期五';
        date = this.state.currentTimeTable.friday;
        break;
      case 6:
        week = '星期六';
        date = this.state.currentTimeTable.saturday;
        break;
      case 7:
        week = '星期日';
        date = this.state.currentTimeTable.sunday;
        break;

      default:
        week = undefined;
        date = undefined;
    }

    let temp = `${date} ${timeStr}:00`;
    let convertDate = new Date(temp);
    let coursetime = Date.parse(convertDate) / 1000;
    
    console.log('点击课表新增', weekindex);
    this.setState({ 
      editCoursedate: timeStr,
      editCoursetime: coursetime,
      editWeekindex: weekindex,

      coursedateShow: `${week} （${date}）`,
      coursedateNoWeek: date,

      courseSaveVisible: true
    });
  }

  // 保存课表课程
  handleSaveCourse(info) {
    // let info = this.courseSaveView.getCourse();
    
    if (!info.course || !info.coach || !info.weekindex || !info.coursedate) {
      console.log(info);
      message.error('需补全内容');
      return; 
    }

    this.changeLoadingState(true);

    let params = {
      course: { courseid: info.course.courseid },
      coach: { coachid: info.coach.coachid },
      coursedate: info.coursedate,
      coursetime: info.coursetime,
      weekindex: info.weekindex,
      timetable: { tableid: this.state.currentTimeTable.tableid }
    };

    if (info.tcid !== undefined) {
      params.tcid = info.tcid;
    }

    launchSaveTimeTableCourse(
      params,
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.setState({ 
          courseSaveVisible: false,
          editCourse: undefined,
          editCoach: undefined,
          editCoursedate: undefined,
          editCoursetime: undefined,
          editWeekindex: undefined,
          editTcid: undefined,
          coursedateShow: undefined,
          coursedateNoWeek: undefined
        });
        this.props.queryTimeTableList({ tableid: this.state.currentTimeTable.tableid });
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  handleSaveCourseCancel() {
    this.setState({ 
      courseSaveVisible: false,
      editCourse: undefined,
      editCoach: undefined,
      editCoursedate: undefined,
      editCoursetime: undefined,
      editWeekindex: undefined,
      editTcid: undefined,
      coursedateShow: undefined,
      coursedateNoWeek: undefined
    }); 
  }

  // 创建课表
  handleCreateTable() {
    this.changeLoadingState(true);
    launchCreateTimeTable(
      {
        monday: this.state.currentMonday
      },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.setState({
          currentMonday: response.result.monday,
          currentSunday: response.result.sunday,
          currentTimeTable: response.result.timetable,
          preTableid: response.result.pretable,
          nextTableid: response.result.nexttable
        });

        this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
      },
      msg => {
        this.changeLoadingState(false);
        message.success(msg);
      }
    );
  }

  handlePopoverVisibled(visible) {

    if (visible === true) { 
      this.changeLoadingState(true);
      launchQueryRecent(
        { monday: this.state.currentMonday },
        response => {
          this.changeLoadingState(false);
          this.setState({
            recent: response.result.list,
            popoverVisible: visible
          });
        },
        msg => {
          this.changeLoadingState(false);
          message.error(msg);
        }
      );
    } else {
      this.setState({
        popoverVisible: visible
      });
    }
  }

  // 拷贝课表
  handleCopyTable(table) {
    this.setState({
      popoverVisible: false
    });
    
    this.changeLoadingState(true);
    launchCopyTable(
      { monday: this.state.currentMonday, tableid: table.tableid },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);

        this.queryTimeTable(response.result.tableid);        
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );
  }

  // 课表状态变更
  handleTableStateChange(checked, event) {

    if (!this.state.currentTimeTable) {
      message.error('需先创建课表');
      return;
    }

    let tableState = checked === true ? 1 : 2;

    this.changeLoadingState(true);
    launchUpdateTimeTableState(
      { 
        tableid: this.state.currentTimeTable.tableid,
        state: tableState
      },
      response => {
        this.changeLoadingState(false);
        message.success(response.msg);
        
        this.setState({
          ...this.state,
          currentTimeTable: {
            ...this.state.currentTimeTable,
            state: tableState
          }
        });
      },
      msg => {
        this.changeLoadingState(false);
        message.error(msg);
      }
    );

    
  }

  // 查询上一期/下一期 课表
  handleQueryTable(isLeft) {
    if (isLeft === true) {
      if (this.state.preTableid !== undefined) {
        launchQueryTimeTable(
          {tableid: this.state.preTableid},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      } else {
        launchQueryPreTimeTable(
          {monday: this.state.currentMonday},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,  
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      }
    } else {
      if (this.state.nextTableid !== undefined) {
        launchQueryTimeTable(
          {tableid: this.state.nextTableid},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      } else {
        launchQueryNextTimeTable(
          {monday: this.state.currentMonday},
          response => {
            this.changeLoadingState(false);
            // message.success(response.msg);
            this.setState({
              currentTimeTable: response.result.timetable,
              currentMonday: response.result.monday,
              currentSunday: response.result.sunday,  
              preTableid: response.result.pretable,
              nextTableid: response.result.nexttable
            });
    
            if (response.result.timetable) {
              this.props.queryTimeTableList({ tableid: response.result.timetable.tableid });
            }
          },
          msg => {
            this.changeLoadingState(false);
            message.error(msg);
          }
        );
      }
    }
  }

  renderHeader() {
    let switchState = false;
    if (this.state.currentTimeTable) {
      switchState = this.state.currentTimeTable.state === 1 ? true : false;
    }
    
    return (
      <div style={TimeTableListStyles.header}>
        <Button onClick={()=>{this.handleQueryTable(true)}} style={TimeTableListStyles.headerLeftButton} icon={<LeftOutlined />} type="primary" size="default" ></Button>
        <span style={TimeTableListStyles.headerTitle}>{this.state.currentMonday ? `${this.state.currentMonday} 至 ${this.state.currentSunday}` : '-'}</span>
        <Button onClick={()=>{this.handleQueryTable(false)}} style={TimeTableListStyles.headerRightButton} icon={<RightOutlined />} type="primary" size="default" ></Button>

        <div style={TimeTableListStyles.headerOperate}>
          <Switch onClick={this.handleTableStateChange} style={TimeTableListStyles.headerSwitch} checkedChildren="开" unCheckedChildren="关" checked={switchState} />
          <span style={TimeTableListStyles.headerLine}> |</span>
          <Button onClick={this.hanldeEditCourse} style={TimeTableListStyles.headerAdd} shape="circle" icon={<PlusOutlined />} type="primary" size="small" ></Button>
        </div>
        
      </div>
    );
  }

  renderTableCell(arr, weekindex, timeStr) {
    if (arr.length > 0) {       
      return (
        <div>
          {
            arr.map((item, index) => {
              return (
                
                <div>
                  <Popconfirm 
                    title="选择操作" 
                    onConfirm={() => { this.handleEditTimeTableCourse(item) }} 
                    onCancel={() => { this.handleDeleteTimeTableCourse(item) }} 
                    okText="编辑" 
                    cancelText="删除">
                    <div style={TimeTableListStyles.courseItem}>
                      <span style={TimeTableListStyles.courseTime}>{`${item.begindate} - ${item.enddate}`}</span>
                      <span style={TimeTableListStyles.courseName}>{item.coursename}</span>
                      <span style={TimeTableListStyles.coachName}>{item.coachname}</span>
                    </div>
                  </Popconfirm>
                  {
                    index !== arr.length-1 ?
                      <div style={TimeTableListStyles.itemLine}>
                        <Divider dashed />
                      </div>
                      :
                      null
                  }
                </div>
              )
            })
          }
        </div>
      );
    } else {
      return (
        <div 
          style={TimeTableListStyles.emptyCourseCell} 
          onClick={() => {
            this.handleCourseCellClick(weekindex, timeStr);
          }}>
        </div>
      )
    }
  }

  renderRecentTable() {
    return (
      <div style={{width: 380, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        {
          this.state.recent && this.state.recent.map((item, index) => {
            return (
              <Tag 
                onClick={() => { this.handleCopyTable(item) }} 
                style={{marginBottom: 10, marginRight: 10}}
              >
                {`${item.monday} ~ ${item.sunday}`}
              </Tag>
            )
          })
        }
      </div>
    );
  }

  renderNotFoundTimeTable() {
    if (!this.state.currentTimeTable && this.state.isLoading === false && this.props.loading === false) {

      let popContent = this.renderRecentTable();

      return (
        <div style={TimeTableListStyles.emptyBox}> 
            <img style={TimeTableListStyles.emptyIcon} src={TIMETABLE_404} alt="" />

            <span style={TimeTableListStyles.emptyDesc}> 本周还未创建课表 </span>
          
            <div>
              <Button 
                icon={<PlusOutlined />}
                onClick={this.handleCreateTable}
                style={TimeTableListStyles.emptyOperate} 
                type="primary">现在创建</Button>

              <Popover 
                content={popContent} 
                title="选择要复制哪一周的课表" 
                trigger="click"
                visible={this.state.popoverVisible}
                onVisibleChange={(visible) => { 
                  this.handlePopoverVisibled(visible); 
                }}
              >
                <Button 
                  icon={<CopyOutlined />}
                  // onClick={this.handleCopyTable}
                  style={TimeTableListStyles.copyOperate} 
                  type="primary">复制课表</Button>
              </Popover>
            </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderNavHeader() {
    return this.renderPageHeader({
      title: NAMESPACE.name,
      extra: (
        <div>
            
        </div>
      )
    });
  }

  render() {
    
    return (
        <Spin spinning={this.state.isLoading || this.props.loading}>
          <div style={PageStyles.pageContainer}>
            {
                this.renderNavHeader()
            }

            <div style={TableStyles.tableBox} id="timetable-scroll">
              {
                this.renderHeader()
              }

              {
                this.renderNotFoundTimeTable()
              }

              {
                this.state.currentTimeTable ?
                  <Table 
                      ref={(ref)=>this.table=ref}
                      rowKey={record => record.begindate}
                      columns={this.columns} 
                      dataSource={this.props.timetableList} 
                      scroll={{ x: '1800' }} 
                      pagination={false}  
                      bordered
                  />
                  :
                  null
              }

              <div 
                style={{ zIndex: 99, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', left: 205, bottom: 100, width: 50, height: 50, borderRadius: 25, backgroundColor: 'rgba(24, 120, 254, 0.6)', cursor: 'pointer' }}
                onClick={() => {
                  if (this.table) {
                    const table = ReactDOM.findDOMNode(this.table),
                    tableBody = table.querySelector('.ant-table-body');
                    tableBody.scrollLeft = 0;
                  }
                }}
              > 
                <img src={TIMETABLE_LEFT_ARROW} mode="contain" style={{ width: 40, height: 40 }} />
              </div>

              <div 
                style={{ zIndex: 99, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', right: 5, bottom: 100, width: 50, height: 50, borderRadius: 25, backgroundColor: 'rgba(24, 120, 254, 0.6)', cursor: 'pointer' }}
                onClick={() => {
                  if (this.table) {
                    const table = ReactDOM.findDOMNode(this.table),
                    tableBody = table.querySelector('.ant-table-body');
                    tableBody.scrollLeft = 2000;
                  }
                }}
              > 
                <img src={TIMETABLE_RIGHT_ARROW} mode="contain" style={{ width: 40, height: 40 }} />
              </div>
            </div>

            <Modal
              title="编辑课表课程"
              visible={this.state.courseSaveVisible}
              // onOk={this.handleSaveCourse}
              footer={null}
              destroyOnClose={true}
              onCancel={() => { 
                this.setState({ 
                  courseSaveVisible: false,
                  editCourse: undefined,
                  editCoach: undefined,
                  editCoursedate: undefined,
                  editCoursetime: undefined,
                  editWeekindex: undefined,
                  editTcid: undefined,
                  coursedateShow: undefined,
                  coursedateNoWeek: undefined
                }); 
              }}
              // okText="确认"
              // cancelText="取消"
              confirmLoading={this.state.isLoading}
            >
              <CourseSaveView 
                cancelTapped={this.handleSaveCourseCancel}
                okTapped={this.handleSaveCourse}
                isLoading={this.state.isLoading}
                // onRef={ ref => this.courseSaveView = ref} 
                timetable={this.state.currentTimeTable} 
                editCourse={this.state.editCourse}
                editCoach={this.state.editCoach ? {...this.state.editCoach, username: this.state.editCoach.coachname} : undefined}
                editTcid={this.state.editTcid}
                editCoursedate={this.state.editCoursedate}
                editCoursetime={this.state.editCoursetime}
                editWeekindex={this.state.editWeekindex}
                coursedateShow={this.state.coursedateShow}
                coursedateNoWeek={this.state.coursedateNoWeek}
              />
            </Modal>
          </div>
        </Spin>
    );
  }
}



const mapStateToProps = store => {
  const timetableStore = store['timetableStore'];
  const utilStore = store['utilStore'];
  return {
    loading: timetableStore.loading,
    timetableList: timetableStore.timetableList,
    // isShow: utilStore.isShow[PathConstant.TIMETABLE_LIST.path]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    queryTimeTableList: params => {
      dispatch(timetableActions.queryTimeTableList(params));
    },
    changeLoading: params => {
      dispatch(timetableActions.changeLoading(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimtTableListController);


