import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Select, Avatar, Input, Row, Col, Button, Table, Tag, Modal, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { MAIN_LISTS } from '../../../../constants/routeConstants';
import BaseController from '../../../base/base-controller';
import {
    ArtTitle, SectionTitle, Picture, NormalContent, KeyValue, Highlight
} from '../../../../view/help/text-components';
import { PageStyles, TableStyles, TableSearchStyles } from '../../../../style/common-styles';
import { TextStyles } from '../../../../style/help/text-styles';

const NAMESPACE = MAIN_LISTS.TAGS_LIST;

class RenewVip extends BaseController {
    constructor(props) {
        super(props)
        this.state = {
        
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={PageStyles.pageContainer}>
                <ArtTitle text="老会员续卡/购课" />

                <SectionTitle text="一.老会员续卡/购课" />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/07476018b1664f38b453d9cb1b41106d.png" />
                <NormalContent text={<span>1.如图所示，在左侧菜单栏点击“会员管理”后，找到想要续卡/购课的会员，然后点击这个会员记录后面的“<Highlight text="详情" />”按钮，进入会员详情页面。</span>} />
                <Picture src="http://qiniu-media.soso-code.com/oneme/pic/help/e4a340d9d64d4e848ac016ac5911f900.png" />
                <NormalContent text={<span>1.如图所示，在会员详情页，可以点击右上角的“<Highlight text="续卡" />”按钮，进行老会员时限卡/次卡的购买。或者点击右上角的“<Highlight text="购买私教课" />”，进行老会员的私教课购买。</span>} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewVip);
