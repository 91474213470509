import React from 'react';
import { Route } from 'react-router-dom';

import { MAIN } from '../../../../constants/routeConstants';
import List from './static-order-list';

import { PageStyles } from '../../../../style/common-styles';

const NAMESPACE = MAIN.children.STATIC_ORDER;
const children = NAMESPACE.children;

export default function (props) {
  const baseUrl = `/${MAIN.path}/${NAMESPACE.path}`;

  return (
    <div style={PageStyles.pageContainer}>
      {/* <Route
        path={`${baseUrl}/${children.VIP_DETAIL.path}`}
        component={VIP_DETAIL} />
        <Route
        path={`${baseUrl}/${children.COACH_DETAIL.path}`}
        component={COACH_DETAIL} /> */}
        
      <Route
        exact
        path={`${baseUrl}`}
        component={List} />
    </div>
  );
}

