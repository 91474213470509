import { call, put, takeLatest } from 'redux-saga/effects'
import { handleActions, createAction } from 'redux-actions'
import { message } from 'antd'

import { RESPONSE_CODE, SERVICE_CODE } from "../constants/dominConstants"
import * as Apis from '../constants/apiConstants'

import { launchPOSTRequest } from '../util/request'
import md5 from'js-md5';

export const actions = {
  queryVipCardMoldList: createAction('queryVipCardMoldList'),
  changeLoading: createAction('changeLoading')
};
const changeLoading = createAction('changeLoading')
const recordVipCardMoldList = createAction('recordVipCardMoldList')

const effects = {
  *queryVipCardMoldList({ payload }) {
    yield put(changeLoading({ loading: true }))
    payload.page = payload.page || 1;
    
    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    };
    
    const response = yield call(launchPOSTRequest, Apis.QUERY_VIPCARD_MOLD_LIST, payload);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {

      let cardList = response.responseData.result.list;

      let collection = [];

      let preType = undefined;
      
      for (let i = 0; i < cardList.length; i++) {
        let aCard = cardList[i];

        if (aCard.cardtype != preType) {
          let tempArr = [];
          tempArr.push(aCard);
          collection.push(tempArr);
        } else {
          let tempArr = collection[collection.length-1];
          tempArr.push(aCard);
        }

        preType = aCard.cardtype;
      }

      yield put(changeLoading({ loading: false }))
      yield put(recordVipCardMoldList(collection))
    } else {
      yield put(changeLoading({ loading: false }))
      message.error(response.msg)
    }
  },

  *changeLoading({ payload }) {
    payload.loading = payload.loading || false;
    yield put(changeLoading({ payload: payload.loading }))
  }
}

export const watchers = [
  function* () {
    yield takeLatest(actions.queryVipCardMoldList, effects.queryVipCardMoldList);
  }
];

// reducer
export const vipCardStore = handleActions(
  {
    recordVipCardMoldList(state, { payload: collection }) {
      return {
        ...state,
        vipCardMoldList: collection,
        loading: false
      }
    },
    changeLoading(state, { payload: result }) {
      return {
        ...state,
        loading: result.loading
      }
    }
  },
  {
    loading: false,
    vipCardMoldList: []
  }
)

// 保存会员卡模
export async function launchSaveVipCardMold(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.SAVE_VIPCARD_MOLD, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}

// 更新会员卡模状态
export async function launchUpdateVipCardMoldState(
  params = {},
  success = () => {},
  failed = () => {}
) {
  try {
    const response = await launchPOSTRequest(Apis.UPDATE_VIPCARD_MOLD_STATE, params);
    if (
      response.responseData.succ === RESPONSE_CODE.success &&
      response.responseData.result.status === SERVICE_CODE.Successed
    ) {
      success(response.responseData);
    } else {
      failed(response.msg);
    }
  } catch (rejectedValue) {
    failed(rejectedValue);
  } 
}